import { get } from './axiosClient'

export const getWard = (query) => get('/address/ward', query)
export const getDistrict = (query) => get('/address/district', query)
export const getProvince = (query) => get('/address/province', query)
export const getDistricts = (query) => get('/address/district', query)
export const getProvinces = (query) => get('/address/province', query)
export const getWards = (query) => get('/address/ward', query)
export const getCountries = (query) => get('/address/country', query)
export const searchAddress = (query) => get('/address/search-address', query)
export const getPlaceDetail = (query) => get('/address/place-detail', query)
