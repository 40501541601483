import { get } from './axiosClient'

export const enumShipping = () => get('/enum/shipping')
export const enumTransport = () => get('/enum/transport')
export const enumPackage = () => get('/enum/package')
export const enumExport = () => get('/enum/export')
export const enumImport = () => get('/enum/import')
export const enumInventory = () => get('/enum/inventory')
export const getPlatforms = () => get('/enum/platform')
export const enumImportOrderProcess = (query) => get('/enum/import-order-process', query)
export const enumExportOrderProcess = () => get('/enum/export-order-process')
