import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ui.module.scss'
import logo from 'assets/images/logo.png'
import { removeAccents } from 'utils'

//components
import Permission from 'components/permission'
import SuffixIconCustom from 'components/suffix-icon-custom'

//components antd
import {
  Affix,
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Upload,
  Spin,
  Checkbox,
} from 'antd'

//icons antd
import {
  BellFilled,
  BellOutlined,
  DashboardOutlined,
  DownOutlined,
  DropboxOutlined,
  ExportOutlined,
  HistoryOutlined,
  LogoutOutlined,
  MenuOutlined,
  PartitionOutlined,
  ReconciliationOutlined,
  UserOutlined,
  FolderOpenOutlined,
  FileOutlined,
  ExceptionOutlined,
  ImportOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  ApartmentOutlined,
  TeamOutlined,
  CarOutlined,
  LockOutlined,
  IeOutlined,
  BarChartOutlined,
  FieldTimeOutlined,
  BranchesOutlined,
  DollarOutlined,
} from '@ant-design/icons'

//consts
import { ACTION, PERMISSIONS, ROUTES, TYPE_DEPLOY } from 'consts'

//libs
import moment from 'moment'

// apis
import { updateCustomer, getCustomers } from 'apis/customer'
import {
  getNoties,
  updateBulkNotification,
  getSettingNotification,
  updateSettingNotification,
} from 'apis/notification'
import { uploadFile } from 'apis/upload'
import { getUsers, updateUser } from 'apis/user'
import { resetPassword } from 'apis/auth'

const { Sider, Content } = Layout
export default function UI(props) {
  const WIDTH_MENU_OPEN = 235
  const WIDTH_MENU_CLOSE = 60
  const routeMatch = useRouteMatch()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const rootSubmenuKeys = ['balance', 'setting', 'report']
  const [openKeys, setOpenKeys] = useState([])
  const isCollapsed = localStorage.getItem('collapsed')
    ? JSON.parse(localStorage.getItem('collapsed'))
    : false
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const [isMobile, setIsMobile] = useState(false)
  const [imgUpload, setImgUpload] = useState('')

  const [notifications, setNotifications] = useState([])
  const [countNotification, setCountNotification] = useState(0)
  const [pageNotification, setPageNotification] = useState(1)
  const [loadingNotification, setLoadingNotification] = useState(false)
  const [loadingSettingNotification, setLoadingSettingNotification] = useState(false)
  const [settingNotification, setSettingNotification] = useState({
    noti_date: false,
    noti_inventory: false,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [form] = Form.useForm()
  const isCustomer = useSelector((state) => state.login.dataUser.customer_id) ? true : false
  const loginAccountId = useSelector((state) => state.login.dataUser.customer_id)
  const dataUser = useSelector((state) => state.login.dataUser)

  const _updateNotification = async () => {
    try {
      let notificationsWatched = []

      const body = {
        notify_id: notifications.map((noti) => {
          notificationsWatched.push({ ...noti, is_view: true })
          return noti.notify_id
        }),
        is_view: true,
      }
      if (body.notify_id && body.notify_id.length !== 0) {
        const res = await updateBulkNotification(body)
        if (res.status === 200) setNotifications([...notificationsWatched])
      }
    } catch (error) {}
  }

  const _getNotifications = async () => {
    try {
      setLoadingNotification(true)
      const query = {
        customer_id: dataUser && dataUser.customer_id,
        page: pageNotification,
        page_size: 20,
      }
      const res = await getNoties(query)
      setLoadingNotification(false)
      if (res.status === 200) {
        setNotifications([...notifications, ...res.data.data])
        setCountNotification(res.data.count)
        setPageNotification(pageNotification + 1)
      }
    } catch (error) {
      setLoadingNotification(false)
    }
  }

  const ModalModifyPassword = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [form] = Form.useForm()

    const _resetPassword = async () => {
      try {
        await form.validateFields()
        const dataForm = form.getFieldsValue()
        if (dataForm.passwordNew !== dataForm.passwordNewAgain) {
          notification.error({ message: 'Xác nhận mật khẩu không đúng' })
          return
        }

        const body = {
          username: dataUser?.username,
          password: dataForm.passwordNew,
        }
        dispatch({ type: ACTION.LOADING, data: true })

        const res = await resetPassword(body)
        console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Đổi mật khẩu thành công' })
            toggle()
          } else
            notification.error({
              message: res.data.message || 'Đổi mật khẩu thất bại, vui lòng thử lại',
            })
        } else
          notification.error({
            message: res.data.message || 'Đổi mật khẩu thất bại, vui lòng thử lại',
          })

        dispatch({ type: ACTION.LOADING, data: false })
      } catch (err) {
        dispatch({ type: ACTION.LOADING, data: false })
        console.log(err)
      }
    }

    return (
      <>
        <div onClick={toggle}>
          <div style={{ color: '#565656', paddingLeft: 10 }}>
            <LockOutlined style={{ fontSize: '1rem', marginRight: 10, color: ' #565656' }} />
            Đổi mật khẩu
          </div>
        </div>
        <Modal
          onOk={_resetPassword}
          width={550}
          okText="Xác nhận"
          cancelText="Đóng"
          visible={visible}
          onCancel={() => {
            toggle()
            form.resetFields()
          }}
          title="Đổi mật khẩu"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Mật khẩu mới"
              name="passwordNew"
              rules={[{ message: 'Vui lòng nhập mật khẩu mới', required: true }]}
            >
              <Input.Password onPressEnter={_resetPassword} placeholder="Nhập mật khẩu mới" />
            </Form.Item>

            <Form.Item
              label="Xác nhận khẩu mới"
              name="passwordNewAgain"
              rules={[{ message: 'Vui lòng xác nhận mật khẩu mới', required: true }]}
            >
              <Input.Password onPressEnter={_resetPassword} placeholder="Xác nhận mật khẩu mới" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const _updateAccount = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateUser(dataUser.user_id, formData)
      if (res.status === 200) {
        notification.success({ message: 'Cập nhật thông tin thành công!' })
        toggleModalInfo()
        dispatch({
          type: ACTION.LOGIN,
          data: { accessToken: res.data.accessToken, refreshToken: res.data.refreshToken },
        })
      } else {
        notification.error({ message: res.data.message || 'Cập nhật thông tin thất bại!' })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const handleAvatar = async (info) => {
    if (info.file.status !== 'done') info.file.status = 'done'
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const urlImg = await uploadFile(info.file.originFileObj)
      if (urlImg) {
        form.setFieldsValue({ avatar: urlImg })
        setImgUpload(urlImg)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const saveScrollTop = () => {
    setTimeout(() => {
      const scrollTopMenu = document.querySelector('#scrollTopMenu')
      const scrollTop = localStorage.getItem('scrollTop')
      if (scrollTopMenu && scrollTop) {
        scrollTopMenu.scrollTop = scrollTop
        localStorage.removeItem('scrollTop')
      }
    }, 250)
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      localStorage.setItem('openKey', latestOpenKey)
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  const toggleModalInfo = () => {
    if (!isModalVisible) form.setFieldsValue({ ...dataUser })
    setIsModalVisible(!isModalVisible)
  }

  const onCollapse = (collapsed) => {
    setCollapsed({ collapsed })
  }

  const toggle = () => {
    localStorage.setItem('collapsed', JSON.stringify(!collapsed))
    setCollapsed(!collapsed)
  }

  const onSignOut = () => {
    dispatch({ type: ACTION.LOGOUT })
  }

  const content = (
    <div className={styles['user_information']}>
      <div onClick={toggleModalInfo}>
        <div style={{ color: '#223B4F', paddingLeft: 10 }}>
          <UserOutlined style={{ fontSize: '1rem', marginRight: 10, color: ' #223B4F' }} />
          Tài khoản của tôi
        </div>
      </div>
      <ModalModifyPassword />
      <Link
        to={ROUTES.LOGIN}
        onClick={onSignOut}
        className={styles['user_information_link']}
        style={{ color: '#223B4F', fontWeight: '600', paddingLeft: 10 }}
      >
        <div>
          <ExportOutlined
            rotate={180}
            style={{ fontSize: '1rem', marginRight: 10, color: '#223B4F' }}
          />
          Đăng xuất
        </div>
      </Link>
    </div>
  )

  const _updateSettingNotification = async (body) => {
    try {
      setLoadingSettingNotification(true)
      const res = await updateSettingNotification(body)
      setLoadingSettingNotification(false)
      if (res.status === 200) {
        if (res.data.success) {
          setSettingNotification(body)
          notification.success({ message: 'Cập nhật thành công' })
        } else notification.error({ message: res.data.message })
      } else notification.error({ message: res.data.message })
    } catch (error) {
      setLoadingSettingNotification(false)
    }
  }

  const contentBell = () => (
    <div className={styles['bell']}>
      <div className={styles['bell-setting']}>
        <b>Cài đặt thông báo</b>
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            onChange={(e) =>
              _updateSettingNotification({ ...settingNotification, noti_date: e.target.checked })
            }
            checked={settingNotification.noti_date}
          >
            Thông báo khi mặt hàng còn 1 tháng nữa hết hạn
          </Checkbox>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            onChange={(e) =>
              _updateSettingNotification({
                ...settingNotification,
                noti_inventory: e.target.checked,
              })
            }
            checked={settingNotification.noti_inventory}
          >
            Thông báo khi mặt hàng xuống dưới hoặc bằng tồn kho tổi thiểu
          </Checkbox>
        </div>
        {loadingSettingNotification && (
          <>
            <br />
            <Row justify="center">
              <Spin />
            </Row>
          </>
        )}
      </div>
      {/* {notifications.length > 0 ? (
        <div className={styles['news_show']}>
          {notifications.map((noti, index) => {
            return (
              <div
                style={{
                  width: '100%',
                  paddingBottom: index !== notifications.length - 1 && '1rem',
                  paddingTop: index !== 0 && '1rem',
                  borderBottom: index !== notifications.length - 1 && '1px solid #ededed',
                }}
              >
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '4',
                    '-webkit-box-orient': 'vertical',
                    display: '-webkit-box',
                    fontSize: '14.5px',
                    marginBottom: 5,
                  }}
                >
                  <b>{noti.title}</b>
                  <div>{noti.content}</div>
                </div>
                <span style={{ fontSize: 14.5 }}>
                  {moment(noti.create_date).format('DD/MM/YYYY HH:mm')}
                </span>
                {countNotification !== notifications.length && index === notifications.length - 1 && (
                  <Row justify="center" align="middle">
                    {loadingNotification ? (
                      <Spin />
                    ) : (
                      <a style={{ fontSize: 18 }} onClick={_getNotifications}>
                        Xem thêm
                      </a>
                    )}
                  </Row>
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '13.5rem',
            padding: '0 50px',
          }}
        >
          <div>
            <BellOutlined style={{ fontSize: '3.5rem', color: 'grey' }} />
          </div>
          <div style={{ margin: '0.5rem 0', color: 'grey' }}>Không có thông báo</div>
        </div>
      )} */}
    </div>
  )

  const MENUS = [
    {
      path: ROUTES.DASHBOARD,
      title: 'Tổng quan',
      permissions: [],
      icon: <DashboardOutlined />,
    },

    {
      path: ROUTES.PRODUCT,
      title: 'Quản lý sản phẩm',
      permissions: [PERMISSIONS.giao_dien_san_pham],
      icon: <DropboxOutlined />,
    },
    {
      path: ROUTES.IMPORT_WAREHOUSE,
      title: 'Nhập kho',
      permissions: [PERMISSIONS.giao_dien_nhap_kho],
      icon: <ImportOutlined />,
    },
    {
      path: ROUTES.EXPORT_WAREHOUSE,
      title: 'Xuất kho',
      permissions: [PERMISSIONS.giao_dien_xuat_kho],
      icon: <ExportOutlined />,
    },
    {
      path: ROUTES.ORDER_COLLECTION_EXPORT,
      title: 'Tổng hợp đơn hàng xuất',
      permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_xuat],
      icon: <InboxOutlined />,
      pathsChild: [ROUTES.ORDER_DETAIL_EXPORT],
    },
    {
      path: ROUTES.ORDER_COLLECTION_IMPORT,
      title: 'Tổng hợp đơn hàng nhập',
      permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_nhap],
      icon: <InboxOutlined />,
      pathsChild: [ROUTES.ORDER_DETAIL_IMPORT],
    },
    {
      path: 'report',
      title: 'Báo cáo tồn kho',
      permissions: [
        PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop,
        PERMISSIONS.giao_dien_bao_cao_nhap_kho,
        PERMISSIONS.giao_dien_bao_cao_xuat_kho,
        PERMISSIONS.giao_dien_bao_cao_kiem_hang,
        PERMISSIONS.giao_dien_bao_cao_nhap_hang,
        PERMISSIONS.giao_dien_bao_cao_xuat_hang,
        PERMISSIONS.giao_dien_bao_cao_dong_goi,
        PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen,
      ],
      icon: <BarChartOutlined />,
      menuItems: [
        {
          path: ROUTES.REPORT_INVENTORY,
          title: 'Báo cáo tồn kho tổng hợp',
          permissions: [PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop],
          pathsChild: [],
        },
        // {
        //   path: ROUTES.REPORT_IMPORT_WAREHOUSE,
        //   title: 'Báo cáo nhập kho',
        //   permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_kho],
        //   pathsChild: [],
        // },
        // {
        //   path: ROUTES.REPORT_EXPORT_WAREHOUSE,
        //   title: 'Báo cáo xuất kho',
        //   permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_kho],
        //   pathsChild: [],
        // },
        // {
        //   path: ROUTES.REPORT_PRODUCT_CHECKING,
        //   title: 'Báo cáo kiểm hàng',
        //   permissions: [PERMISSIONS.giao_dien_bao_cao_kiem_hang],
        //   pathsChild: [],
        // },
        {
          path: ROUTES.REPORT_IMPORT_ORDER,
          title: 'Báo cáo nhập hàng',
          permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_EXPORT_ORDER,
          title: 'Báo cáo xuất hàng',
          permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_hang],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_PACK_ORDER,
          title: 'Báo cáo đóng gói',
          permissions: [PERMISSIONS.giao_dien_bao_cao_dong_goi],
          pathsChild: [],
        },
        {
          path: ROUTES.REPORT_TRANSPORT_ORDER,
          title: 'BC bàn giao vận chuyển',
          permissions: [PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen],
          pathsChild: [],
        },
      ],
    },
    // {
    //   path: ROUTES.ORDER_FEE,
    //   title: 'Bảng kê chi phí',
    //   permissions: [],
    //   icon: <DollarOutlined />,
    // },
    // {
    //   path: ROUTES.DEBIT,
    //   title: 'Quản lý công nợ',
    //   permissions: [PERMISSIONS.giao_dien_quan_ly_cong_no],
    //   icon: <ReconciliationOutlined />,
    // },
    {
      path: ROUTES.ORDER_E_COMMERCE,
      title: 'Đơn hàng đa kênh',
      permissions: [PERMISSIONS.giao_dien_don_thuong_mai_dien_tu],
      icon: <IeOutlined />,
    },
    {
      path: ROUTES.SITE,
      title: 'Kết nối đa kênh',
      permissions: [PERMISSIONS.giao_dien_ket_noi_san_tmdt],
      icon: <PartitionOutlined />,
    },
    // {
    //   path: ROUTES.SUPPLIER_LIST,
    //   title: 'Quản lý nhà cung cấp',
    //   permissions: [PERMISSIONS.giao_dien_nha_cung_cap],
    //   icon: <TeamOutlined />,
    //   pathsChild: [ROUTES.SUPPLIER_CREATE, ROUTES.SUPPLIER_UPDATE],
    // },
    // {
    //   path: ROUTES.BRANCH_LIST,
    //   title: 'Quản lý chi nhánh',
    //   // permissions: [PERMISSIONS.giao_dien_chi_nhanh],
    //   icon: <BranchesOutlined />,
    // },
    {
      path: ROUTES.EMPLOYEE,
      title: 'Quản lý nhân viên',
      permissions: [PERMISSIONS.giao_dien_quan_ly_nhan_vien],
      icon: <TeamOutlined />,
    },
    {
      path: ROUTES.BUSSINESS_PROFILE,
      title: 'Hồ sơ doanh nghiệp',
      permissions: [PERMISSIONS.giao_dien_ho_so_doanh_nghiep],
      icon: <FolderOpenOutlined />,
    },
    {
      path: ROUTES.IMPORTEXPORTMANAGEMENT,
      title: 'Lịch sử xuất/nhập file',
      permissions: [PERMISSIONS.giao_dien_xuat_nhap_file],
      icon: <FileOutlined />,
    },
    {
      path: ROUTES.ACTIVITY_HISTORY,
      title: 'Lịch sử thao tác',
      permissions: [PERMISSIONS.giao_dien_lich_su_thao_tac],
      icon: <FieldTimeOutlined />,
      pathsChild: [],
    },
    /* {
      path: ROUTES.SHIPPING_CONTROL,
      title: 'Đối soát vận chuyển',
      permissions: [PERMISSIONS.giao_dien_doi_soat_van_chuyen],
      icon: <CarOutlined />,
    }, */
    {
      path: ROUTES.ROLE_MANAGEMENT,
      title: 'Quản lý phân quyền',
      permissions: [PERMISSIONS.giao_dien_quan_ly_phan_quyen],
      icon: (
        <span className="anticon">
          <ApartmentOutlined />
        </span>
      ),
      pathsChild: [],
    },
    // {
    //   path: ROUTES.HISTORY_WAREHOUSE,
    //   title: 'Lịch sử nhập xuất kho',
    //   permissions: [],
    //   icon: <ReconciliationOutlined />,
    // },
  ]

  const renderMenuItem = (menu) => (
    <Permission permissions={menu.permissions} key={menu.path}>
      {menu.menuItems ? (
        <Menu.SubMenu
          key={menu.path}
          title={
            <div
              style={{
                fontSize: '1em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: collapsed && 'center',
                lineHeight: '25px',
                /*  fontWeight: 700, */
              }}
            >
              {collapsed && menu.icon}
              {menu.title}
            </div>
          }
          icon={menu.icon}
        >
          {menu.menuItems.map(
            (e) =>
              // <Permission permissions={e.permissions}>
              !collapsed ? (
                <Menu.Item
                  key={e.path}
                  style={{
                    /*  fontWeight: 700, */
                    color: 'black',
                    backgroundColor: location.pathname === e.path && '#e7e9fb',
                  }}
                  icon={e.icon}
                >
                  <Link
                    to={e.path}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: collapsed && 'center',
                      lineHeight: '25px',
                      // fontWeight: 700,
                    }}
                  >
                    {e.title}
                  </Link>
                </Menu.Item>
              ) : (
                <Link to={e.path}>
                  <Menu.Item
                    key={e.path}
                    style={{
                      /*  fontSize: '1rem', */
                      backgroundColor: location.pathname === e.path && '#e7e9fb',
                      color: 'black',
                      // fontWeight: 700,
                    }}
                    icon={e.icon}
                  >
                    {e.title}
                  </Menu.Item>
                </Link>
              )
            // </Permission>
          )}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={menu.path}
          style={{
            // fontSize: '1rem',
            color: 'black',
            backgroundColor: location.pathname === menu.path && '#e7e9fb',
          }}
          icon={menu.icon}
          className={location.pathname.includes(menu.path) && 'ant-menu-item-selected'}
        >
          <Link
            to={menu.path}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: collapsed && 'center',
              lineHeight: '25px',
              /* fontWeight: 700, */
            }}
          >
            {menu.title}
          </Link>
        </Menu.Item>
      )}
    </Permission>
  )

  const _getSettingNotification = async () => {
    try {
      const res = await getSettingNotification()
      if (res.status === 200) if (res.data.success) setSettingNotification(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getSettingNotification()
    _getNotifications()

    if (window.innerWidth < 768) {
      setIsMobile(true)
      setCollapsed(true)
    } else setIsMobile(false)

    saveScrollTop()
    if (localStorage.getItem('openKey')) setOpenKeys([localStorage.getItem('openKey')])
  }, [])

  return (
    <Layout>
      <Sider
        className={styles['site-layout-background']}
        trigger={null}
        collapsible
        width={isMobile ? '100%' : WIDTH_MENU_OPEN}
        collapsedWidth={isMobile ? 0 : WIDTH_MENU_CLOSE}
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ zIndex: 99 }}
      >
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: isMobile ? 'row' : 'column',
            width: '100%',
            display: 'flex',
            maxHeight: 80,
            margin: '15px 0',
          }}
        >
          <img
            src={logo}
            style={{
              width: `${isMobile ? '50%' : '70%'}`,
              display: `${collapsed ? 'none' : 'block'}`,
              objectFit: 'contain',
              marginTop: 12,
              cursor: 'pointer',
            }}
            onClick={() => history.push(ROUTES.DASHBOARD)}
            alt=""
          />
          <div
            style={{
              position: 'absolute',
              display: isMobile ? 'block' : 'none',
              top: 10,
              right: 10,
              marginBottom: 15,
            }}
          >
            <MenuOutlined onClick={toggle} style={{ fontSize: 25, fontWeight: 'bold' }} />
          </div>
        </div>
        <Menu
          id="scrollTopMenu"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={(e) => {
            const scrollTopMenu = document.querySelector('#scrollTopMenu')
            if (scrollTopMenu) localStorage.setItem('scrollTop', scrollTopMenu.scrollTop)

            if (e.keyPath && e.keyPath.length === 1) localStorage.removeItem('openKey')
          }}
          theme="dark"
          mode="inline"
          selectedKeys={routeMatch.path}
          style={{
            height: `calc(100vh - ${collapsed ? 4 : 108}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          className={styles['menu-left']}
        >
          {MENUS.map(renderMenuItem)}
          <Menu.Item onClick={onSignOut} key="9" icon={<LogoutOutlined />}>
            <Link
              to={ROUTES.LOGIN}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: collapsed && 'center',
                justifyContent: 'center',
                lineHeight: '25px',
              }}
            >
              Đăng xuất
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: isMobile ? 0 : collapsed ? 60 : WIDTH_MENU_OPEN,
          overflow: 'hidden',
        }}
      >
        <Affix
          style={{ backgroundColor: '#33405b !important', width: '100%', overflow: 'hidden' }}
          offsetTop={0}
        >
          <Row className={styles['background_right_top']}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row wrap={false} justify="space-between" className={styles['navbar']}>
                <Row
                  align="middle"
                  wrap={false}
                  style={{
                    width: '100%',
                    paddingLeft: 5,
                    paddingRight: 0,
                    marginTop: 10,
                    marginBottom: 15,
                  }}
                  justify=""
                >
                  <div className={styles['navbar_left_parent']}>
                    <MenuOutlined onClick={toggle} className={styles['header_navbar_left_icon']} />
                  </div>
                </Row>
                <Row justify="center" wrap={false} style={{ width: '100%' }}>
                  <div style={{ fontWeight: 600, fontSize: 15 }}>
                    {process.env.REACT_APP_API_ENDPOINT_DEV === 'https://apisgl.cbox.vn/api'
                      ? 'SAND BOX'
                      : 'LIVE'}
                  </div>
                </Row>
                <div className={styles['navbar_right']}>
                  <div className={styles['navbar_notification']}>
                    <Dropdown
                      overlay={contentBell}
                      arrow
                      placement="bottomRight"
                      trigger={['click']}
                      // onClick={_updateNotification}
                    >
                      {/* <Badge
                        count={notifications.filter((noti) => !noti.is_view).length}
                        size="small"
                        offset={[-3, 3]}
                      > */}
                      <BellFilled
                        style={{ color: 'rgb(253, 170, 62)', fontSize: 25, cursor: 'pointer' }}
                      />
                      {/* </Badge> */}
                    </Dropdown>
                  </div>
                  <Dropdown overlay={content} trigger="click">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          padding: '0 0.75rem 0 0.5rem',
                        }}
                      >
                        {/* {(user && user.avatar === ' ') || !user.avatar ? (
                          <Avatar
                            style={{
                              color: '#FFF',
                              backgroundColor: '#FDAA3E',
                            }}
                          >
                            {username ? (
                              <span style={{ textTransform: 'capitalize' }}>{username[0]}</span>
                            ) : (
                              <span style={{ textTransform: 'capitalize' }}>
                                {login.username[0]}
                              </span>
                            )}
                          </Avatar>
                        ) : (
                          <Avatar src={user.avatar} />
                        )} */}
                        <Avatar icon={<UserOutlined />} src={dataUser.avatar || ''} />
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            textTransform: 'capitalize',
                            color: 'black',
                            fontWeight: '600',
                          }}
                        >
                          {dataUser.fullname}
                        </span>
                      </div>
                      <div className={styles['navbar_right_left_name']}>
                        <div
                          style={{
                            color: '#FFFF',
                            fontWeight: '600',
                            fontSize: '1rem',
                          }}
                        >
                          {/* {username ? (
                            <span style={{ textTransform: 'capitalize' }}>{username}</span>
                          ) : (
                            <span style={{ textTransform: 'capitalize' }}>{login.username}</span>
                          )} */}
                          <DownOutlined style={{ color: '#223B4F' }} />
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </Row>
            </Col>
          </Row>
        </Affix>
        <Content
          style={{
            minHeight: 'calc(100vh - 64px)',
            backgroundColor: '#f0f2f5',
            padding: isMobile ? '20px 15px' : 15,
          }}
        >
          <div
            style={{
              backgroundColor: 'white' /* , margin: '1em' */,
              padding: '1em',
              borderRadius: '5px',
              height: '100%',
            }}
          >
            {props.children}
          </div>
        </Content>
        <Modal
          title={<h2 style={{ textAlign: 'center' }}>Thông tin cá nhân</h2>}
          centered
          width={1000}
          footer={
            <Space size="large" style={{ marginRight: 50 }}>
              <Button type="primary" danger style={{ minWidth: 100 }} onClick={toggleModalInfo}>
                Hủy
              </Button>
              <Button
                type="primary"
                onClick={_updateAccount}
                style={{ backgroundColor: '#FF9C64', borderColor: '#FF9C64', minWidth: 100 }}
              >
                Lưu
              </Button>
            </Space>
          }
          visible={isModalVisible}
          onCancel={toggleModalInfo}
        >
          <Form form={form} layout="vertical" className={styles['layout-form']}>
            <Row>
              <Col md={12} style={{ padding: '0 40px' }}>
                <Form.Item
                  name="username"
                  label="Tên tài khoản"
                  rules={[{ required: false, message: 'Vui lòng nhập tên tài khoản' }]}
                >
                  <Input disabled placeholder="Nhập tên tài khoản" />
                </Form.Item>
                <Form.Item
                  name="fullname"
                  label="Họ và tên"
                  rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
                >
                  <Input allowClear placeholder="Nhập họ và tên" />
                </Form.Item>
                <Form.Item label="Số điện thoại" name="phone">
                  <Input
                    allowClear
                    maxLength={15}
                    style={{ width: '100%' }}
                    placeholder="Nhập Số điện thoại"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      required: false,
                      message: 'Vui lòng nhập đúng định dạng email',
                    },
                  ]}
                >
                  <Input placeholder="Nhập tên email" />
                </Form.Item>
              </Col>
              <Col md={12} style={{ padding: '0 40px' }}>
                <Form.Item
                  name="address"
                  label="Địa chỉ"
                  rules={[{ message: 'Vui lòng nhập địa chỉ' }]}
                >
                  <Input placeholder="Nhập dịa chỉ" />
                </Form.Item>
                <Form.Item name="avatar" className={styles['upload-image']} label="Ảnh đại diện">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleAvatar}
                  >
                    {imgUpload ? (
                      <img src={imgUpload} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Layout>
    </Layout>
  )
}
