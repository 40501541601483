import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { isAcceptPermissions } from 'utils'
import { ACTION, PERMISSIONS } from 'consts'

//components
import treeData from './tree-data'

//antd
import { Col, notification, Row, Tree } from 'antd'

//apis
import { updateRole } from 'apis/role'

const list = [...treeData]
const half = Math.ceil(list.length / 2)
const firstHalf = list.splice(0, half)
const secondHalf = list.splice(-half)
export default function PermissionForm({ search, role, _getRoles }) {
  const [rolePermision, setRolePermision] = useState(role.permissions)
  const [checkedKeys, setCheckedKeys] = useState(role.permissions)
  const dispatch = useDispatch()

  const _handleSubmit = async (checkedKeys) => {
    const body = { ...role, permissions: checkedKeys }
    let res
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      res = await updateRole(role.role_id, body)
      if (res.status === 200) {
        setRolePermision(rolePermision.filter((e) => e !== checkedKeys))
        _getRoles()
        notification.success({
          message: 'Cập nhật vai trò thành công!',
          key: 'update-success',
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Cập nhật vai trò thất bại!',
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  return (
    <Row>
      <Col span={12}>
        <Tree
          checkable
          treeData={firstHalf}
          checkedKeys={checkedKeys}
          checkStrictly
          defaultExpandAll
          selectable={false}
          onCheck={(value, e) => {
            setCheckedKeys(value.checked)
            _handleSubmit(value.checked)
          }}
          disabled={!isAcceptPermissions(PERMISSIONS.cap_nhat_phan_quyen)}
        />
      </Col>
      <Col span={12}>
        <Tree
          disabled={!isAcceptPermissions(PERMISSIONS.cap_nhat_phan_quyen)}
          checkable
          treeData={secondHalf}
          checkedKeys={checkedKeys}
          checkStrictly
          defaultExpandAll
          selectable={false}
          onCheck={(value, e) => {
            setCheckedKeys(value.checked)
            _handleSubmit(value.checked)
          }}
        />
      </Col>
    </Row>
  )
}
