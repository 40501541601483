import { get, post, patch, destroy } from './axiosClient'

export const getOrders = (query) => get('/importorder', query)

//import
export const getImportOrders = (query) => get('/importorder', query)
export const addImportOrders = (body) =>
  post('/importorder/create', { ...body, from_platform: 'Merchant' })
export const updateImportOrders = (id, body) =>
  patch('/importorder/update/' + id, { ...body, from_platform: 'Merchant' })
export const scanImportOrders = (body) => post('/importorder/scanimport', body)
export const deleteImportOrders = (body) =>
  destroy('/importorder/delete', { ...body, from_platform: 'Merchant' })

//export
export const getEnumTabExportOrders = (query) => get('/enum/export-order-process', query)
export const getExportOrders = (query) => get('/exportorder', query)
export const addExportOrders = (body) =>
  post('/exportorder/create', { ...body, from_platform: 'Merchant' })
export const findBucket = (query) => get('/exportorder/findbucket', query)
export const importFileOrder = (body) => post('/exportorder/file/import', body)
export const importFileOrderFromLazada = (body) => post('/exportorder/file/import/lazada', body)
export const importFileOrderFromTiki = (body) => post('/exportorder/file/import/tiki', body)
export const importFileOrderFromShoppe = (body) => post('/exportorder/file/import/shopee', body)
export const updateExportOrders = (order_id, body) => patch(`/exportorder/update/${order_id}`, body)
export const updateExportOrdersComplete = (body) => patch(`/exportorder/complete`, body)
export const scanExportOrder = (data) => post('/exportorder/scanexport', data)
export const deleteExportOrders = (body) =>
  destroy('/exportorder/delete', { ...body, from_platform: 'Merchant' })
export const uploadPdf = (body) => post('/exportorder/url/pdf/import', body)
export const getPdfs = (query) => get('/exportorder/url/pdf', query)
