import React, { useEffect, useRef, useState } from 'react'
import styles from './export-wh-create.module.scss'
import { IMAGE_DEFAULT, PERMISSIONS, ROUTES } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { formatCash, getFileName } from 'utils'

// antd
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  Table,
  InputNumber,
  notification,
  Space,
  Dropdown,
  Menu,
  Upload,
  Modal,
} from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'

// api
import SuffixIconCustom from 'components/suffix-icon-custom'
import { getShippingCompany, addShippingCompany, checkShippingFee } from 'apis/shipping-company'
import { getWarehouses } from 'apis/warehouse'
import { getProducts } from 'apis/product'
import { testEventError, reportCrashLog } from 'apis/error'
import { addExportOrders, updateExportOrders, getExportOrders } from 'apis/order'
import { getWard, getDistrict, getProvince } from 'apis/address'
import { uploadFile, uploadSingle } from 'apis/upload'

//components
import Permission from 'views/permission'
import Loading from 'components/loading-page'
import ModalError from 'components/modal-error'
import ZoomImage from 'components/zoom-image'

const { Option } = Select
const { TextArea } = Input
export default function ExportWarehouseCreate() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loadingSelect, setLoadingSelect] = useState(false)
  const [dataTableProduct, setDataTableProduct] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [selectKeyProduct, setSelectKeyProduct] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [products, setProducts] = useState([])
  const [shippingCompany, setShippingCompany] = useState([])
  const [updateOrder, setUpdateOrder] = useState()
  const [isShowLoading, setIsShowLoading] = useState()
  const [visibleError, setVisibleError] = useState(false)
  const [file, setFile] = useState('')

  const typingTimeoutRef = useRef()
  const location = useLocation()
  const [form] = Form.useForm()
  const user = useSelector((state) => state.login.dataUser)
  const order_id = useParams()

  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])
  const [valueProvince, setValueProvince] = useState('Lào Cai')
  const [valueDistrict, setValueDistrict] = useState('Huyện Xi Ma Cai')
  const dataUser = useSelector((state) => state.login.dataUser)
  const [shippingNameCreate, setShippingNameCreate] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [isShippingCompanyDefault, setIsShippingCompanyDefault] = useState(false)

  const [valueSearch, setValueSearch] = useState('')
  const [valueOption, setValueOption] = useState('name')
  const [paramsFilter, setParamsFilter] = useState({})

  const columnsProduct = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: 'Mã sản phẩm',
      render: (text, record) => (record.barcode ? record.barcode : record.code),
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Thông số',
      render: (text, record) =>
        `Dài: ${record.length}, Rộng: ${record.width}, Cao: ${record.height}, Nặng: ${record.weight}`,
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'group_info',
      render: (group_info) => group_info && group_info.name,
    },
    {
      title: 'Loại sản phẩm',
      dataIndex: `type_info`,
      render: (type_info) => type_info && type_info.name,
    },
    {
      title: 'Hình ảnh',
      dataIndex: `images`,
      render: (text) =>
        text && text.length ? (
          <ZoomImage image={text[0] || IMAGE_DEFAULT}>
            <img width={40} height={40} src={text[0] || IMAGE_DEFAULT} alt="" />
          </ZoomImage>
        ) : (
          ''
        ),
    },
    // {
    //   title: 'SL có thể xuất',
    //   render: (text, record) =>
    //     (record.available_quantity && formatCash(record.available_quantity || 0)) || 0,
    // },
    {
      fixed: 'right',
      title: 'Số lượng xuất',
      dataIndex: `require_quantity`,
      width: 150,
      render: (text, record) => (
        <InputNumber
          disabled={isDisable}
          style={{ width: 130 }}
          value={record.require_quantity}
          min={1}
          onChange={(value) => _onHandleInputChange(value, record.product_id)}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        />
      ),
    },
  ]

  const _getShippingCompany = async () => {
    try {
      const res = await getShippingCompany({
        customer_id: user.customer_id,
        page: 1,
        page_size: 1000,
      })
      if (res.status === 200) setShippingCompany(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)
        if (!updateOrder) {
          const warehouse = res.data.data.find(
            (e) => dataUser && e.warehouse_id === dataUser.warehouse_id
          )
          if (warehouse) {
            form.setFieldsValue({ warehouse_id: warehouse.warehouse_id })
            setParamsFilter({ ...paramsFilter, warehouse_id: warehouse.warehouse_id })
          } else {
            if (res.data.data && res.data.data.length) {
              form.setFieldsValue({ warehouse_id: res.data.data[0].warehouse_id })
              setParamsFilter({ ...paramsFilter, warehouse_id: res.data.data[0].warehouse_id })
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getDataProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        setDataProvince(res.data.data)
        if (!order_id) {
          if (res.data.data.length) {
            form.setFieldsValue({ to_province: res.data.data[0].province_name })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getDataDistrict = async (value) => {
    try {
      if (value) {
        const res = await getDistrict(value)
        if (res.status === 200) {
          const data = res.data.data
          setDataDistrict(data)
          setValueDistrict(data[0]?.district_name)
          if (!order_id) {
            if (res.data.data.length) {
              form.setFieldsValue({ to_district: data[0]?.district_name })
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getDataWard = async (value) => {
    try {
      const res = await getWard(value)
      if (res.status === 200) {
        setDataWard(res.data.data)
        if (!order_id) {
          if (res.data.data.length) {
            form.setFieldsValue({ to_ward: res.data.data[0].ward_name })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddOrUpdateExportOrder = async (body) => {
    try {
      setLoadingTable(true)

      let res

      if (updateOrder) {
        if (
          updateOrder.products &&
          body.products &&
          JSON.stringify(updateOrder.products) === JSON.stringify(body.products)
        )
          body.is_update = true

        res = await updateExportOrders(updateOrder.order_id, body)
      } else res = await addExportOrders(body)
      setLoadingTable(false)

      if (res.status === 200) {
        notification.success({
          message:
            res.data.message ||
            (updateOrder ? 'Cập nhật phiếu xuất kho thành công' : 'Thêm phiếu xuất kho thành công'),
        })
        history.push(ROUTES.EXPORT_WAREHOUSE)
      } else {
        notification.error({
          message:
            res.data.message ||
            (updateOrder ? 'Cập nhật phiếu xuất kho thất bại' : 'Thêm phiếu xuất kho thất bại'),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _addOrUpdateExportOrder = async (body = {}) => {
    try {
      if (isShippingCompanyDefault) {
        const dataForm = form.getFieldsValue()
        const ward = dataWard.find((item) => dataForm.to_ward === item.ward_name)
        const province = dataProvince.find((item) => dataForm.to_province === item.province_name)
        const district = dataDistrict.find((item) => dataForm.to_district === item.district_name)

        const bodyCheckShippingFee = {
          warehouse_id: body.warehouse_id,
          cod: body.shipping_info.cod,
          cost_of_goods: body.shipping_info.cost_of_goods,
          address: {
            province_id: province ? province.province_id : '',
            district_id: district ? district.district_id : '',
            ward_code: ward ? ward.ward_code : '',
            address: dataForm.to_address,
          },
          products: body.products.map((product) => ({
            height: product.height,
            width: product.width,
            length: product.length,
            weight: product.weight,
            quantity: product.require_quantity,
          })),
        }
        setLoadingTable(true)
        const res = await checkShippingFee(bodyCheckShippingFee)
        setLoadingTable(false)

        if (res.status === 200) {
          if (res.data.success) {
            Modal.confirm({
              okText: 'Xác nhận tạo đơn',
              cancelText: 'Hủy',
              title: `Đơn hàng của bạn có phí vận chuyển là ${formatCash(res.data.data.fee || 0)}`,
              onOk: () => handleAddOrUpdateExportOrder(body),
            })
          }
        }
      } else handleAddOrUpdateExportOrder(body)
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const _onHandleInputChange = (value, product_id) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const newTableProduct = dataTableProduct.map((item) => {
        if (item.product_id === product_id) {
          return { ...item, require_quantity: value }
        }
        return item
      })
      setDataTableProduct(newTableProduct)
    }, 100)
  }

  const deleteProductTable = () => {
    const dataTableNew = dataTableProduct.filter(
      (item) => !selectKeyProduct.includes(item.product_id)
    )
    setDataTableProduct(dataTableNew)
    setSelectKeyProduct([])
  }

  const _createShippingCompany = async () => {
    try {
      if (!shippingNameCreate) {
        notification.warning({ message: 'Vui lòng nhập tên đơn vị vận chuyển' })
        return
      }
      setLoadingBtn(true)
      const res = await addShippingCompany({
        name: shippingNameCreate,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        if (res.data.success) {
          await _getShippingCompany()
          notification.success({ message: 'Tạo đơn vị vận chuyển thành công' })
          setShippingNameCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo đơn vị vận chuyển thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo đơn vị vận chuyển thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  // const isDisable = updateOrder && ['WAITING'].indexOf(updateOrder.export_status) < 0
  const isDisable = updateOrder ? updateOrder.is_received : false //tạm thời chỉ cho update khi là đơn hàng nháp

  const _submitOrder = async () => {
    await form.validateFields()
    if (dataTableProduct.length === 0) {
      notification.error({
        message: 'Vui lòng chọn ít nhất 1 sản phẩm',
      })
      return false
    }
    const isQuantityHasValue = dataTableProduct.every((item) => {
      return item.require_quantity
    })
    if (!isQuantityHasValue) {
      notification.error({
        message: 'Vui lòng nhập số lượng sản phẩm',
      })
      return false
    }

    const dataForm = form.getFieldsValue()

    const shipping = shippingCompany.find(
      (e) => e.shipping_company_id === dataForm.shipping_company_id
    )

    let shipping_company_info = {
      shipping_company_address: shipping ? shipping.address | '' : '',
      shipping_company_code: shipping ? shipping.code | '' : '',
      shipping_company_district: shipping ? shipping.district | '' : '',
      shipping_company_email: shipping ? shipping.email | '' : '',
      shipping_company_name: shipping ? shipping.name | '' : '',
      shipping_company_phone: shipping ? shipping.phone | '' : '',
      shipping_company_province: shipping ? shipping.province | '' : '',
      shipping_company_ward: shipping ? shipping.ward | '' : '',
    }

    const shipping_info = {
      ...updateOrder.shipping_info,
      ...shipping_company_info,
      link_tracking_number: file,
      shipping_company_id: dataForm.shipping_company_id || '',
      shipping_code: dataForm.shipping_code || '',
      shipping_fee: dataForm.shipping_fee || 0,
      cod: dataForm.cod || 0,
      cost_of_goods: dataForm.cost_of_goods || 0,
      to_name: dataForm.to_name || '',
      to_phone: dataForm.to_phone || '',
      to_email: dataForm.to_email || '',
      to_address: dataForm.to_address || '',
      to_ward: dataForm.to_ward || '',
      to_district: dataForm.to_district || '',
      to_province: dataForm.to_province || '',
    }
    const warehouse_info = { warehouse_id: dataForm.warehouse_id }
    const body = {
      customer_id: user.customer_id,
      shipping_info,
      warehouse_id: dataForm.warehouse_id,
      warehouse_info,
      products: dataTableProduct,
      note: dataForm.note || '',
      customer_order_code: dataForm.customer_order_code || '',
    }
    _addOrUpdateExportOrder(body)
  }

  const handleUploadFileTracking = async (info) => {
    try {
      setLoadingUpload(true)
      if (info.file.status !== 'done') info.file.status = 'done'
      const formData = new FormData()
      formData.append('file', info.file.originFileObj)
      const res = await uploadSingle(formData)
      setLoadingUpload(false)
      if (res.status === 200) setFile(res.data.data || '')
    } catch (error) {
      setLoadingUpload(false)
    }
  }

  const _getProducts = async () => {
    try {
      setLoadingSelect(true)
      const res = await getProducts({
        ...paramsFilter,
        customer_id: dataUser.customer_id,
      })
      if (res.status === 200) {
        if (res.data.success) {
          const data = res.data.data
          setProducts(data)
        }
      }
      setLoadingSelect(false)
    } catch (error) {
      console.log(error)
      setLoadingSelect(false)
    }
  }

  const _HandleChangeValueOption = (value) => {
    delete paramsFilter[valueOption]
    setParamsFilter({ ...paramsFilter, [value]: valueSearch })
    setValueOption(value)
  }

  const _handleSearchProduct = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) {
        delete paramsFilter.name
        delete paramsFilter.code
        delete paramsFilter.barcode

        paramsFilter[valueOption] = value
        setParamsFilter({ ...paramsFilter })
      } else {
        setParamsFilter({
          customer_id: (dataUser && dataUser.customer_id) || '',
          merge: true,
        })
      }
    }, 500)
  }

  const menu = (
    <Menu style={{ maxHeight: 350, overflowX: 'auto' }}>
      <Menu.Item
        onClick={() => {
          window.open(ROUTES.PRODUCT_DETAIL)
        }}
      >
        <Space style={{ height: 50 }}>
          <div>
            <PlusSquareOutlined
              style={{
                fontSize: 20,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
              }}
            />
          </div>
          <div>Thêm sản phẩm mới</div>
        </Space>
      </Menu.Item>
      {products.map((data, index) => {
        const isAdded = dataTableProduct.find((product) => product.product_id === data.product_id)

        return (
          <Menu.Item
            key={index}
            onClick={() => {
              const products = [...dataTableProduct]
              const product = { ...data }
              const productIndex = dataTableProduct.findIndex(
                (item) => item?.product_id === product.product_id
              )
              if (productIndex !== -1) {
                products[productIndex].require_quantity =
                  products[productIndex].require_quantity + 1
              } else {
                product.require_quantity = 1
                products.push(product)
              }
              setDataTableProduct([...products])
              notification.success({ message: 'Thêm sản phẩm thành công', key: 'add-product' })
            }}
          >
            <Space>
              <div Style={{ width: 200 }}>
                <img
                  src={(data.images.length && data.images[0]) || IMAGE_DEFAULT}
                  alt=""
                  height={70}
                  width={70}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  Tên :<b> {data?.name}</b>
                </span>
                <span>
                  Mã hàng/barcode:<b> {data?.barcode}</b>
                </span>
                <span>
                  SL có thể xuất:<b> {data.locations && data.locations.available_quantity}</b>
                </span>
                {isAdded && (
                  <Row wrap={false} align="middle" style={{ color: 'green' }}>
                    Đã thêm <CheckCircleOutlined style={{ marginLeft: 6, fontSize: 15 }} />
                  </Row>
                )}
              </div>
            </Space>
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const _increaseQuantityProduct = (product) => {
    const index = dataTableProduct.findIndex((item) => item.product_id === product.product_id)
    if (index >= 0) {
      dataTableProduct[index].require_quantity = dataTableProduct[index].require_quantity + 1
      setDataTableProduct([...dataTableProduct])
    }
  }

  useEffect(() => {
    if (dataUser && dataUser.customer_id !== undefined && paramsFilter.warehouse_id) _getProducts()
  }, [paramsFilter, dataUser])

  useEffect(() => {
    _getDataDistrict({ province_name: valueProvince })
    _getDataWard({ province_name: valueProvince })
  }, [valueProvince])

  useEffect(() => {
    _getDataWard({ district_name: valueDistrict })
  }, [valueDistrict])

  useEffect(() => {
    if (updateOrder) {
      const shipping_info = { ...updateOrder.shipping_info }
      setDataTableProduct(updateOrder.products)
      setFile(shipping_info.link_tracking_number || '')
      form.setFieldsValue({ ...updateOrder, ...shipping_info })
      setIsShippingCompanyDefault(
        updateOrder?.shipping_info?.shipping_company_id === -1 ? true : false
      )
      setParamsFilter({ ...paramsFilter, warehouse_id: updateOrder.warehouse_id })
    }
  }, [updateOrder])

  const _getOrderInfo = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      var reponse = await getExportOrders(order_id)

      if (reponse.status == 200) {
        setUpdateOrder(reponse.data.data[0])
      } else {
        setIsShowLoading(false)
        setVisibleError(true)
      }
      dispatch({ type: 'LOADING', data: false })
    } catch (err) {
      dispatch({ type: 'LOADING', data: false })
      setIsShowLoading(false)
      setVisibleError(true)
    }
  }

  useEffect(() => {
    if (user && user.customer_id) _getShippingCompany()
  }, [user])

  useEffect(() => {
    _getWarehouses()
    _getDataProvince()
    _getOrderInfo()
  }, [])

  return (
    <div style={{ flex: 1 }}>
      {!isShowLoading && (
        <div className={styles['body-export']}>
          <Row justify="space-between" className="page-title" wrap={false} align="middle">
            <Col>
              <Space
                onClick={() => history.push(ROUTES.EXPORT_WAREHOUSE)}
                style={{ cursor: 'pointer' }}
              >
                <ArrowLeftOutlined />
                <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
                  {updateOrder
                    ? `Cập nhật phiếu xuất kho #${updateOrder.code}`
                    : 'Thêm phiếu xuất kho'}
                </span>
              </Space>
            </Col>
            <Col>
              <Permission
                loading={loadingTable}
                permissions={
                  updateOrder
                    ? [PERMISSIONS.cap_nhat_phieu_xuat_kho]
                    : [PERMISSIONS.tao_phieu_xuat_kho]
                }
              >
                <Button type="primary" onClick={_submitOrder}>
                  {updateOrder ? 'Cập nhật' : 'Thêm'}
                </Button>
              </Permission>
            </Col>
          </Row>
          <Form form={form} className={styles['body-export-content']} layout="vertical">
            <Row gutter={[50, 30]} style={{ marginBottom: 20 }}>
              <Col xs={24} xl={12}>
                <h2 style={{ fontWeight: 'bold', color: '#223B4F' }}>Thông tin khác</h2>
                <Form.Item
                  name="warehouse_id"
                  label="Kho"
                  rules={[{ required: true, message: 'Vui lòng chọn kho' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    placeholder="Chọn kho"
                    suffixIcon={<SuffixIconCustom />}
                    allowClear
                    disabled={isDisable}
                    onChange={(value) => setParamsFilter({ ...paramsFilter, warehouse_id: value })}
                  >
                    {warehouses.map((item) => {
                      return (
                        <Option key={item.warehouse_id} value={item.warehouse_id}>
                          {item.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item initialValue="" name="customer_order_code" label="Tên phiếu">
                  <Input placeholder="Nhập tên phiếu" />
                </Form.Item>
                <Form.Item
                  name="shipping_company_id"
                  label="Đơn vị vận chuyển"
                  rules={[{ required: false, message: 'Vui lòng chọn đơn vị vận chuyển' }]}
                >
                  <Select
                    onChange={(value) => {
                      const shippingCompanyDetail = shippingCompany.find(
                        (item) => item.shipping_company_id === value
                      )
                      if (shippingCompanyDetail && shippingCompanyDetail.is_default)
                        setIsShippingCompanyDefault(true)
                      else setIsShippingCompanyDefault(false)
                    }}
                    style={{ width: '100%' }}
                    placeholder="Chọn đơn vị vận chuyển"
                    suffixIcon={<SuffixIconCustom />}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            value={shippingNameCreate}
                            onPressEnter={_createShippingCompany}
                            placeholder="Nhập tên đơn vị vận chuyển"
                            onChange={(e) => setShippingNameCreate(e.target.value)}
                          />
                          <Button
                            loading={loadingBtn}
                            onClick={_createShippingCompany}
                            type="primary"
                            style={{ marginLeft: 10 }}
                          >
                            Tạo mới
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    {shippingCompany.map((item) => (
                      <Option key={item.shipping_company_id} value={item.shipping_company_id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="shipping_code"
                  label="Mã vận đơn"
                  rules={[{ required: false, message: 'Vui lòng nhập mã vận đơn' }]}
                >
                  <Input style={{ width: '100%' }} placeholder="Nhập mã vận đơn" allowClear />
                </Form.Item>
                <Form.Item
                  name="shipping_fee"
                  label={`Phí vận chuyển (tự điền hoặc chọn "vận chuyển SGL" để tự tạo)`}
                >
                  <InputNumber
                    disabled={isShippingCompanyDefault}
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    placeholder="Nhập phí vận chuyển"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="cod" label="Tiền thu hộ">
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    placeholder="Nhập tiền thu hộ"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="cost_of_goods" label="Tổng giá trị hàng hoá">
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    // disabled={isDisable}
                    style={{ width: '100%' }}
                    placeholder="Nhập tổng giá trị hàng hoá"
                    allowClear
                  />
                </Form.Item>
                <br />
                <div>
                  <Upload
                    listType="picture"
                    fileList={
                      file
                        ? [
                            {
                              uid: '1',
                              name: getFileName(file),
                              status: 'done',
                              url: file,
                            },
                          ]
                        : []
                    }
                    name="file"
                    onChange={handleUploadFileTracking}
                    onRemove={() => setFile('')}
                  >
                    <Button icon={<UploadOutlined />} loading={loadingUpload}>
                      Tải lên file vận đơn
                    </Button>
                  </Upload>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <h2 style={{ fontWeight: 'bold', color: '#223B4F' }}>Thông tin người nhận</h2>
                <Form.Item
                  name="to_name"
                  label="Họ tên"
                  rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
                >
                  <Input style={{ width: '100%' }} placeholder="Nhập họ tên" allowClear />
                </Form.Item>
                <Form.Item name="to_phone" label="Số điện thoại">
                  <Input style={{ width: '100%' }} placeholder="Nhập số điện thoại" allowClear />
                </Form.Item>
                <Form.Item name="to_email" label="E-mail">
                  <Input style={{ width: '100%' }} placeholder="Nhập email" allowClear />
                </Form.Item>
                <Form.Item name="to_province" label={'Tỉnh/Thành phố'}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder={
                      <label style={{ fontSize: 16, fontWeight: 500 }}>Chọn tỉnh/thành phố</label>
                    }
                    suffixIcon={<SuffixIconCustom />}
                    onChange={(e) => setValueProvince(e)}
                  >
                    {dataProvince.map((item, index) => (
                      <Select.Option key={index} value={item.province_name}>
                        {item.province_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="to_district" label={'Quận/Huyện'}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn quận/huyện"
                    suffixIcon={<SuffixIconCustom />}
                    value="Huyện Xi Ma Cai"
                    onChange={(e) => setValueDistrict(e)}
                  >
                    {dataDistrict.map((item, index) => (
                      <Select.Option key={index} value={item.district_name}>
                        {item.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="to_ward" label={'Phường/Xã'}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn phường/xã"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {dataWard.map((item, index) => (
                      <Select.Option key={index} value={item.ward_name}>
                        {item.ward_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="to_address" label="Địa chỉ">
                  <Input style={{ width: '100%' }} placeholder="Nhập địa chỉ" allowClear />
                </Form.Item>
                <Form.Item name="note" label="Ghi chú">
                  <TextArea
                    placeholder="Nhập ghi chú"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <h2 style={{ fontWeight: 'bold', color: '#223B4F' }}>Danh sách sản phẩm</h2>
                <Row>
                  <Button
                    style={{ minWidth: 130, borderRadius: 5 }}
                    onClick={deleteProductTable}
                    type="danger"
                    disabled={isDisable ? true : selectKeyProduct.length ? false : true}
                  >
                    Xóa
                  </Button>
                  <Dropdown disabled={isDisable} trigger={['focus']} overlay={menu}>
                    <Input
                      allowClear
                      placeholder="Tìm kiếm sản phẩm"
                      style={{ width: '50%' }}
                      onChange={_handleSearchProduct}
                      value={valueSearch}
                    />
                  </Dropdown>
                  <Select
                    value={valueOption}
                    style={{ width: 200, alignSelf: 'center' }}
                    onChange={_HandleChangeValueOption}
                    suffixIcon={<SuffixIconCustom />}
                  >
                    <Select.Option value="name">Tên</Select.Option>
                    <Select.Option value="barcode">Mã hàng/barcode</Select.Option>
                  </Select>
                </Row>
              </Col>
            </Row>
            <Table
              rowKey="product_id"
              size="small"
              loading={loadingTable}
              scroll={{ x: 1500 }}
              columns={columnsProduct}
              dataSource={dataTableProduct}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectKeyProduct,
                onChange: (keys, records) => {
                  setSelectKeyProduct(keys)
                },
              }}
              summary={() => (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row></Table.Summary.Row>
                </Table.Summary>
              )}
              sticky
            />
          </Form>
        </div>
      )}

      {isShowLoading && (
        <div style={{ flex: 1 }}>
          <Loading />
        </div>
      )}
      <ModalError setVisibleModalError={setVisibleError} visible={visibleError} />
    </div>
  )
}
