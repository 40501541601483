const inventoryColumns = [
  {
    key: 'stt',
    title: 'STT',
    width: 70,
    fixed: 'left',
  },
  {
    width: 250,
    title: 'Số phiếu',
    dataIndex: 'order_customer_order_code',
    fixed: 'left',
  },
  {
    title: 'Mã đơn từ sàn',
    dataIndex: 'code_ecommerce',
    fixed: 'left',
    width: 250,
  },
  {
    title: 'Mã đơn hàng',
    dataIndex: 'order_code',
    width: 250,
  },
  {
    title: 'Tên doanh nghiệp',
    dataIndex: 'customer_name',
    width: 150,
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'customer_phone',
    width: 150,
  },
  {
    title: 'Email',
    width: 150,
    dataIndex: 'customer_email',
  },
  {
    title: 'Địa chỉ',
    width: 150,
    dataIndex: 'customer_address',
  },
  {
    title: 'Tên kho',
    width: 200,
    dataIndex: 'warehouse_name',
  },
  {
    title: 'Địa chỉ kho',
    dataIndex: 'warehouse_address',
    width: 200,
  },
  {
    title: 'Ngày tạo',
    key: 'order_create_date',
    width: 150,
  },
  {
    title: 'Nhân viên xuất hàng',
    dataIndex: 'export_employee_name',
    width: 150,
  },
  {
    title: 'Thời điểm xuất hàng',
    key: 'product_export_date',
    width: 150,
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'product_barcode',
    width: 200,
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'product_name',
    width: 250,
  },
  {
    title: 'Nhóm sản phẩm',
    dataIndex: 'product_group',
    width: 100,
  },
  {
    title: 'Loại hàng',
    dataIndex: 'product_type',
    width: 100,
  },
  {
    title: 'Dài',
    width: 100,
    key: 'product_length',
  },
  {
    title: 'Rộng',
    width: 100,
    key: 'product_width',
  },
  {
    title: 'Cao',
    width: 100,
    key: 'product_height',
  },
  {
    title: 'CBM',
    width: 100,
    key: 'product_cbm',
  },
  {
    title: 'Diện tích sáu mặt',
    width: 100,
    key: 'product_area',
  },
  {
    title: 'Quy cách',
    dataIndex: 'product_convention',
    width: 100,
  },
  {
    title: 'Đơn vị tính',
    dataIndex: 'product_unit',
    width: 100,
  },
  {
    title: 'Số lượng xuất hàng',
    key: 'product_export_quantity',
    width: 100,
  },
  {
    title: 'Số lượng theo đơn vị nhỏ nhất',
    key: 'product_conversion_quantity',
    width: 100,
  },
  {
    title: 'Quy đổi số lượng',
    key: 'product_conversion_require_quantity',
    width: 100,
  },
]

export default inventoryColumns
