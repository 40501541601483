import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import localStorage from 'localstorage-memory'
import { decodeToken } from 'react-jwt'
import queryString from 'query-string'
import { Button, Form, Input, Modal, notification } from 'antd'
import { ROUTES } from 'consts'
import { ACTION } from 'consts'
//apis
import { createSite } from '../../apis/connect'
import { useDispatch } from 'react-redux'

const ConnectShopee = () => {
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const query = queryString.parse(location.search)
  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState(true)
  const data = { code: query.code, shop_id: query.shop_id }
  const [dataUser, setDataUser] = useState(decodeToken(localStorage.getItem('accessToken')))
  const showInput = () => {
    if (data.code?.length && data.shop_id?.length) {
      setIsModalVisible(true)
    } else {
      notification.error({ message: 'Thất bại' })
      history.push(ROUTES.DASHBOARD)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }
  const onFinish = async () => {
    try {
      const dataForm = form.getFieldsValue()
      var body = {
        ...data,
        site_name: dataForm.site_name,
        platform: 'Shopee',
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await createSite(body)
      console.log('res', res)
      if (res.status === 200) {
        notification.success({ message: 'Tạo kết nối thành công' })
      } else
        notification.error({
          message: 'Error',
          description: res.data.message || res.data.mess || 'Tạo kết nối thất bại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.SITE)
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.DASHBOARD)
    }
  }
  const handleCancel = () => {
    // setIsModalVisible(false)
  }

  useEffect(() => {
    showInput()
  }, [])

  return (
    <div>
      <Modal
        title="Đặt tên cổng kết nối (Shop)"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={(e) => {}}
        footer={
          <>
            <Button type="primary" onClick={() => onFinish()}>
              {' '}
              Tạo
            </Button>
          </>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="site_name">
            <Input placeholder="Nhập tên ..." />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ConnectShopee
