import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './export-warehouse.module.scss'
import moment from 'moment'
import { IMAGE_DEFAULT, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import {
  compare,
  formatCash,
  handleGetDataExportExcel,
  removeAccents,
  saveFiltersToLocalStorage,
  getFileName,
  isAcceptPermissions,
  filterOptionSelect,
} from 'utils'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'

// antd
import {
  DeleteOutlined,
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'
import {
  Button,
  Input,
  Select,
  Table,
  Row,
  Col,
  Modal,
  Space,
  notification,
  Popover,
  Tag,
  Upload,
  message,
  Tabs,
  Badge,
  AutoComplete,
  Popconfirm,
} from 'antd'

// apis
import {
  getExportOrders,
  updateExportOrders,
  getEnumTabExportOrders,
  importFileOrder,
  deleteExportOrders,
  importFileOrderFromLazada,
  importFileOrderFromTiki,
  importFileOrderFromShoppe,
  uploadPdf,
} from 'apis/order'
import { getWarehouses } from 'apis/warehouse'
import { getShippingCompany } from 'apis/shipping-company'
import { uploadFiles } from 'apis/upload'
import { getPlatforms, enumExportOrderProcess } from 'apis/enum'
import { saveActionFile } from 'apis/action'
import { getCategories } from 'apis/categories-product'
import { exportReportExportInventory } from 'apis/report'
import { getUsers } from 'apis/user'

// components
import SettingColumns from 'components/setting-columns'
import Export from 'components/export-csv'
import ImportFile from 'components/import-csv'
import BackToTop from 'components/back-to-top'
import Permission from 'views/permission'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import columnsExport from './columns'
import ModalViewReasons from './view-reason'
import ModalViewFees from './view-fee'
import ModalViewProduct from './view-product'

const { Option } = Select
export default function ExportWareHouse() {
  const dataUser = useSelector((state) => state.login.dataUser)
  const history = useHistory()
  const location = useLocation()
  const KEY_FILTER = 'exportWarehouseFilters'
  const INIT_PARAMS = { page: 1, page_size: 100, this_week: true, sort_time: null }
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const user = useSelector((state) => state.login.dataUser)
  const queries = new URLSearchParams(location.search)
  const processing = queries.get('processing')

  const [warehouse, setWarehouse] = useState()
  const [employees, setEmployees] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? processing
        ? { ...filters, processing: processing }
        : { ...filters }
      : processing
      ? { ...INIT_PARAMS, processing: processing }
      : { ...INIT_PARAMS }
  )
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [modalReceiptVisible, setModalReceiptVisible] = useState(false)
  const [dataDetail, setDataDetail] = useState()
  const [receiptData, setReceiptData] = useState('')
  const [columns, setColumns] = useState([])
  const typingTimeoutRef = useRef(null)
  const [filesUpload, setFilesUpload] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [dataEnum, setDataEnum] = useState([])
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [exportOrders, setExportOrders] = useState([])
  const [countOrder, setCountOrder] = useState(0)

  const [shippingsCompany, setShippingsCompany] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const toggleModalReceipt = (data) => {
    setModalReceiptVisible(!modalReceiptVisible)
    setReceiptData(data)
  }

  const _exportStatusTranslate = (text) => {
    const tagData = dataEnum?.status?.find((e) => e.value === text)
    if (!tagData) return ''
    return <Tag color={tagData.color}>{tagData.label}</Tag>
  }

  const _getEnum = async () => {
    try {
      const res = await getEnumTabExportOrders(paramsFilter)
      if (res.status === 200) setDataEnum(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _enumExportOrderProcess = async () => {
    try {
      const res = await enumExportOrderProcess()
      if (res.status === 200) {
        const platform = res.data.data
        const waiting = platform.find((e) => e.value === 'WAITING')
        if (waiting) {
          if (!paramsFilter.processing) paramsFilter.processing = waiting.value
          setParamsFilter({ ...paramsFilter })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getListShippingCompany = async () => {
    try {
      const res = await getShippingCompany({ customer_id: user.customer_id })
      if (res.status === 200) {
        setShippingsCompany(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const UploadPdf = () => {
    const _uploadFiles = (files) => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => {
        setLoadingUpload(true)
        const filesNew = files
          .filter((file) => file.originFileObj)
          .map(
            (file) =>
              new File([file.originFileObj], removeAccents(file.originFileObj.name, true, '_'), {
                type: file.originFileObj.type,
              })
          )
        const urls = await uploadFiles(filesNew)
        if (urls) setFilesUpload([...filesUpload, ...urls])
        setLoadingUpload(false)
      }, 350)
    }

    const _removeFile = (file) => {
      const filesUploadNew = [...filesUpload]
      const indexFilesUpload = filesUploadNew.findIndex((f) => f === file.url)
      if (indexFilesUpload !== -1) filesUploadNew.splice(indexFilesUpload, 1)
      setFilesUpload([...filesUploadNew])
    }

    return (
      <Upload
        multiple
        fileList={filesUpload.map((file, index) => {
          const fileNames = file.split('/')
          const fileName = fileNames[fileNames.length - 1]
          return { uid: index + '', name: fileName, status: 'done', url: file }
        })}
        maxCount={4}
        beforeUpload={(file) => {
          if (file.type !== 'application/pdf') {
            message.error(`${file.name} không phải là file pdf`)
          }
          return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
        }}
        onChange={(info) => {
          if (info.file.status !== 'removed') _uploadFiles(info.fileList)
          if (info.file.status !== 'done') info.file.status = 'done'
        }}
        onRemove={_removeFile}
      >
        <Space>
          <Button loading={loadingUpload}>Tải lên file pdf</Button>
          <div style={{ color: 'red' }}>Tối đa tải được 4 file pdf</div>
        </Space>
      </Upload>
    )
  }

  const ModalUploadPdtWithPlatform = ({ txt = '' }) => {
    const [pdf, setPdf] = useState('')
    const [visibleUploadPdf, setVisibleUploadPdf] = useState(false)
    const toggleUploadPdf = () => setVisibleUploadPdf(!visibleUploadPdf)
    const [platform, setPlatform] = useState()
    const [loading, setLoading] = useState(false)

    const _uploadFiles = (files) => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => {
        const filesNew = files
          .filter((file) => file.originFileObj)
          .map(
            (file) =>
              new File([file.originFileObj], removeAccents(file.originFileObj.name, true, '_'), {
                type: file.originFileObj.type,
              })
          )
        setLoading(true)
        const urls = await uploadFiles(filesNew)
        if (urls) setPdf(urls[0] || '')
        setLoading(false)
      }, 350)
    }

    const _removeFile = () => setPdf('')

    const _uploadPdf = async () => {
      try {
        if (!platform) {
          notification.error({ message: 'Vui lòng chọn nền tảng' })
          return
        }

        if (!pdf) {
          notification.error({ message: 'Vui tải lên file pdf' })
          return
        }

        const body = {
          customer_id: dataUser && dataUser?.user_id,
          platform: platform,
          urls: [pdf],
        }

        const res = await uploadPdf(body)
        if (res.status === 200) {
          if (res.data.success) {
            const body = {
              action_name: 'Nhập file pdf từ các nền tảng',
              file_name: 'File pdf',
              links: [pdf || ''],
              type: 'Import File',
            }
            await saveActionFile(body)
            toggleUploadPdf()
            notification.success({ message: 'Upload file pdf thành công' })
          } else notification.error({ message: res.data.message || 'Upload file pdf thất bại' })
        } else notification.error({ message: res.data.message || 'Upload file pdf thất bại' })
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <>
        <Button
          style={{ width: 250 }}
          onClick={toggleUploadPdf}
          icon={<DownloadOutlined />}
          type="primary"
        >
          {txt}
        </Button>
        <Modal
          width="40%"
          onCancel={toggleUploadPdf}
          visible={visibleUploadPdf}
          okText="Xác nhận"
          cancelText="Đóng"
          title="Nhập phiếu xuất kho bằng file pdf"
          onOk={_uploadPdf}
        >
          <div>
            <div>Nền tảng</div>
            <Select
              onChange={setPlatform}
              value={platform}
              style={{ width: 300 }}
              placeholder="Chọn nền tảng"
            >
              <Select value="shopee">Shopee</Select>
              <Select value="tiki">Tiki</Select>
              <Select value="lazada">Lazada</Select>
            </Select>
          </div>
          <br />
          <Upload
            fileList={
              pdf
                ? [pdf].map((file, index) => {
                    const fileNames = file.split('/')
                    const fileName = fileNames[fileNames.length - 1]
                    return { uid: index + '', name: fileName, status: 'done', url: file }
                  })
                : []
            }
            maxCount={1}
            beforeUpload={(file) => {
              if (file.type !== 'application/pdf') {
                message.error(`${file.name} không phải là file pdf`)
              }
              return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
            }}
            onChange={(info) => {
              if (info.file.status !== 'removed') _uploadFiles(info.fileList)
              if (info.file.status !== 'done') info.file.status = 'done'
            }}
            onRemove={_removeFile}
          >
            <Button loading={loading}>Tải lên file pdf</Button>
          </Upload>
        </Modal>
      </>
    )
  }

  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _processing = (text) => {
    const status = {
      is_received: 'Đã tiếp nhận',
      is_exported: 'Đã xuất khỏi vị trí',
      is_packed: 'Đã đóng gói',
      is_transported: 'Đã bàn giao vận chuyển',
      is_shipping: 'Đã vận chuyển',
    }
    return status[text] || ''
  }

  const _getPlatforms = async () => {
    try {
      const res = await getPlatforms()
      if (res.status === 200) setPlatforms(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)
        const warehouse = res.data.data.find(
          (w) => dataUser && w.warehouse_id === dataUser.warehouse_id
        )
        if (warehouse) setWarehouse(warehouse.warehouse_id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      const res = await getUsers()
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const ModalListReasonIssues = ({ fees }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const columns = [
      { title: 'Tên chi phí', dataIndex: 'fee_name' },
      { title: 'Mã chi phí', dataIndex: 'fee_code' },
      { title: 'Chi phí', render: (text, record) => formatCash(record.fee || 0) },
      { title: 'Mô tả', dataIndex: 'description' },
    ]

    return (
      <>
        <a onClick={toggle}>Xem chi tiết</a>
        <Modal
          style={{ top: 20 }}
          footer={null}
          width="70%"
          title="Danh sách chi phí kho"
          onCancel={toggle}
          visible={visible}
        >
          <Table dataSource={fees || []} columns={columns} size="small" />
        </Modal>
      </>
    )
  }

  const showConfirmDeleteOrder = () => {
    Modal.confirm({
      title:
        'Vui lòng liên hệ SGL để hỗ trợ xoá phiếu, chúng tôi cần đảm bảo hàng hoá của bạn chưa được nhập/xuất kho tránh ảnh hướng tồn kho.',
      cancelButtonProps: { style: { display: 'none' } },
      okText: 'Đã hiểu',
      onOk: () => setSelectedRowKeys([]),
    })
  }

  const ModalUpdateShippingCompany = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [shippingName, setShippingName] = useState('')
    const [loading, setLoading] = useState(false)

    const _updateShippingCompany = async () => {
      try {
        setLoading(true)

        const shippingCompany = shippingsCompany.find((shipping) => shipping.name === shippingName)

        //cập nhật 1 đơn hàng
        if (record) {
          const body = {
            shipping_info: {
              ...record.shipping_info,
              shipping_company_name: shippingName,
              shipping_company_id: shippingCompany ? shippingCompany.shippingcompany_id : 0,
            },
          }
          const res = await updateExportOrders(record.order_id, body)
          if (res.status === 200) {
            if (res.data.success) {
              notification.success({ message: 'Cập nhật đơn vị vận chuyển thành công' })
              _getExportOrders()
              toggle()
            } else
              notification.error({
                message:
                  res.data.message || 'Cập nhật đơn vị vận chuyển thất bại, vui lòng thử lại',
              })
          } else
            notification.error({
              message: res.data.message || 'Cập nhật đơn vị vận chuyển thất bại, vui lòng thử lại',
            })
        } else {
          //cập nhật hàng loạt
          const listPromise = selectedRowKeys.map(async (order_id) => {
            const order = exportOrders.find((od) => od.order_id === order_id)
            const body = {
              shipping_info: {
                ...order.shipping_info,
                shipping_company_name: shippingName,
                shipping_company_id: shippingCompany ? shippingCompany.shippingcompany_id : 0,
              },
            }
            const res = await updateExportOrders(order_id, body)
            return res
          })

          await Promise.all(listPromise)

          notification.success({ message: 'Cập nhật đơn vị vận chuyển thành công' })
          _getExportOrders()
          toggle()
        }

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    useEffect(() => {
      if (!visible) setShippingName('')
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          width="50%"
          title={
            record
              ? `Cập nhật đơn vị vận chuyển cho PO #${record.customer_order_code}`
              : 'Cập nhật đơn vị vận chuyển hàng loạt'
          }
          onCancel={toggle}
          visible={visible}
          footer={
            <Row justify="end">
              <Space>
                <Button onClick={toggle}>Hủy bỏ</Button>
                <Button
                  disabled={shippingName ? false : true}
                  loading={loading}
                  type="primary"
                  onClick={_updateShippingCompany}
                >
                  Cập nhật
                </Button>
              </Space>
            </Row>
          }
        >
          <div>
            <AutoComplete
              placeholder="Nhập hoặc chọn đơn vị vận chuyển"
              style={{ width: '100%', height: 60, color: 'black' }}
              onChange={setShippingName}
              value={shippingName}
            >
              {shippingsCompany.map((item, index) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </AutoComplete>
          </div>
        </Modal>
      </>
    )
  }

  const _resubmitExportOrder = async (record) => {
    try {
      setLoadingTable(true)
      const products = await _getProductsById(record.order_id)
      const body = { ...record, products: products }
      delete body.basket_ids
      const res = await updateExportOrders(record.order_id, body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getExportOrders()
          notification.success({ message: 'Kích hoạt xuất kho thành công' })
        } else
          notification.error({
            message: res.data.message || 'Kích hoạt xuất kho thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Kích hoạt xuất kho thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }
  const _deleteExportOrder = async (recordId, note) => {
    const body = { order_id: [recordId], note: note }
    try {
      setLoadingTable(true)
      const res = await deleteExportOrders(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getExportOrders()
          _getEnum()
          notification.success({ message: 'Xóa đơn hàng thành công' })
        } else
          notification.error({
            message: res.data.message || 'Xóa đơn hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa đơn hàng thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _getProductsById = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getExportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) return order.products
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
      return []
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
      return []
    }
  }

  const _getOrderDetail = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getExportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) setDataDetail(order)
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const _getExportOrders = async () => {
    try {
      setLoadingTable(true)
      setExportOrders([])
      setSelectedRowKeys([])
      const res = await getExportOrders({ ...paramsFilter, customer_id: dataUser.customer_id })
      setLoadingTable(false)
      if (res.status === 200) {
        setExportOrders(res.data.data)
        setCountOrder(res.data.count || 0)
      }
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user && user.customer_id) _getListShippingCompany()
  }, [user])

  useEffect(() => {
    _getPlatforms()
    _getWarehouses()
    _getEmployees()
    _enumExportOrderProcess()

    _getGroups()
    _getTypes()
  }, [])

  useEffect(() => {
    if (paramsFilter.processing) {
      _getExportOrders()
      saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
    }
    _getEnum()
  }, [paramsFilter])

  const _getReportExportInventoryToExport = async (getAll = false) => {
    try {
      let query = {}

      if (getAll) {
        const queries = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'this_month',
          'last_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]

        queries.map((key) => {
          if (Object.keys(paramsFilter).includes(key)) query[key] = paramsFilter[key]
        })
      } else query = { ...paramsFilter, is_create_date: true }

      delete query.page
      delete query.page_size
      setLoadingTable(true)
      const res = await exportReportExportInventory({
        ...query,
        is_create_date: true,
        customer_id: dataUser ? dataUser.customer_id : '',
      })
      setLoadingTable(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoặc filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _resetFilter = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 10 })
  }

  const _resetStateImport = () => {
    setWarehouse()
    setFilesUpload([])
  }

  return (
    <>
      <div className={styles['body-export']}>
        <BackToTop />
        <ModalViewProduct dataDetail={dataDetail} setDataDetail={setDataDetail} />

        <Modal
          title={<h4>Biên lai {receiptData.export_code}</h4>}
          centered
          visible={modalReceiptVisible}
          onCancel={toggleModalReceipt}
          footer={null}
          className="custom-modal-debit"
          width={960}
        >
          <img
            src={receiptData.receipt}
            alt="receipt"
            style={{ display: 'block', width: '100%' }}
          />
        </Modal>

        <Row className="page-title" justify="space-between">
          <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
            Quản lý phiếu xuất kho
          </span>
          <Permission permissions={[PERMISSIONS.tao_phieu_xuat_kho]}>
            <div>
              {/* <ImportFile
                reset={_resetStateImport}
                importPdf={<UploadPdf />}
                colorCode="#f53d2d"
                txt="Nhập excel từ Shopee"
                keyForm={{
                  links: filesUpload || [],
                  warehouse_id: warehouse || '',
                  customer_id: dataUser ? dataUser.customer_id : '',
                }}
                width="95%"
                title={
                  <Row wrap={false} align="middle">
                    <div style={{ marginRight: 20, fontWeight: 600 }}>
                      Nhập phiếu xuất kho bằng file excel từ Shopee
                    </div>

                    <div>
                      <div style={{ fontSize: 13 }}>Chọn kho</div>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={warehouse}
                        onChange={setWarehouse}
                        placeholder="Chọn kho"
                        size="small"
                        style={{ width: 250 }}
                        allowClear
                      >
                        {warehouses.map((warehouse, index) => (
                          <Select.Option key={index} value={warehouse.warehouse_id}>
                            {warehouse.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Row>
                }
                action_name="Nhập phiếu xuất kho từ Shopee"
                fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/17/e56ff4df-4c85-43a7-89df-3c94c56eee8d/file_order_shopee.xlsx"
                upload={importFileOrderFromShoppe}
                reload={_getExportOrders}
              />

              <ImportFile
                reset={_resetStateImport}
                importPdf={<UploadPdf />}
                colorCode="#1A94FF"
                txt="Nhập excel từ Tiki"
                keyForm={{
                  links: filesUpload || [],
                  warehouse_id: warehouse || '',
                  customer_id: dataUser ? dataUser.customer_id : '',
                }}
                width="95%"
                style={{ marginLeft: 8 }}
                title={
                  <Row wrap={false} align="middle">
                    <div style={{ marginRight: 20, fontWeight: 600 }}>
                      Nhập phiếu xuất kho bằng file excel từ Tiki
                    </div>

                    <div>
                      <div style={{ fontSize: 13 }}>Chọn kho</div>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={warehouse}
                        onChange={setWarehouse}
                        placeholder="Chọn kho"
                        size="small"
                        style={{ width: 250 }}
                        allowClear
                      >
                        {warehouses.map((warehouse, index) => (
                          <Select.Option key={index} value={warehouse.warehouse_id}>
                            {warehouse.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Row>
                }
                fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/17/07471252-cd0c-4f1d-a7f4-fadf560ab749/file_order_tiki.xlsx"
                upload={importFileOrderFromTiki}
                reload={_getExportOrders}
                action_name="Nhập phiếu xuất kho từ Tiki"
              />
              <ImportFile
                reset={_resetStateImport}
                importPdf={<UploadPdf />}
                colorCode="#F57224"
                txt="Nhập excel từ Lazada"
                keyForm={{
                  links: filesUpload || [],
                  warehouse_id: warehouse || '',
                  customer_id: dataUser ? dataUser.customer_id : '',
                }}
                width="95%"
                style={{ marginRight: 8, marginLeft: 8 }}
                title={
                  <Row wrap={false} align="middle">
                    <div style={{ marginRight: 20, fontWeight: 600 }}>
                      Nhập phiếu xuất kho bằng file excel từ Lazada
                    </div>

                    <div>
                      <div style={{ fontSize: 13 }}>Chọn kho</div>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={warehouse}
                        onChange={setWarehouse}
                        placeholder="Chọn kho"
                        size="small"
                        style={{ width: 250 }}
                        allowClear
                      >
                        {warehouses.map((warehouse, index) => (
                          <Select.Option key={index} value={warehouse.warehouse_id}>
                            {warehouse.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Row>
                }
                fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/02/13/116c2c8e-4829-47ab-9a01-d406ec9820f9/don-hang.xlsx"
                upload={importFileOrderFromLazada}
                reload={_getExportOrders}
                action_name="Nhập phiếu xuất kho từ Lazada"
              /> */}
              <Space direction="vertical">
                <Permission permissions={[PERMISSIONS.tao_phieu_xuat_kho]}>
                  <ImportFile
                    reset={_resetStateImport}
                    colorCode="#12D8E5"
                    keyForm={{
                      warehouse_id: warehouse || '',
                      customer_id: dataUser ? dataUser.customer_id : '',
                    }}
                    txt="Tạo phiếu xuất bằng file"
                    width="70%"
                    title={
                      <Row wrap={false} align="middle">
                        <div style={{ marginRight: 20, fontWeight: 600 }}>
                          Nhập phiếu xuất kho bằng file excel
                        </div>
                        <div>
                          <div style={{ fontSize: 13, color: 'red' }}>
                            Vui lòng chọn kho xuất hàng
                          </div>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            value={warehouse}
                            onChange={setWarehouse}
                            placeholder="Chọn kho"
                            size="small"
                            style={{ width: 250 }}
                            allowClear
                          >
                            {warehouses.map((warehouse, index) => (
                              <Select.Option key={index} value={warehouse.warehouse_id}>
                                {warehouse.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </Row>
                    }
                    fileTemplated="https://s3.ap-southeast-1.wasabisys.com/aubiz/1683797401025688083899/mau_xuat_kho.xlsx"
                    upload={importFileOrder}
                    reload={_getExportOrders}
                    action_name="Nhập phiếu xuất kho từ file excel đơn hàng mẫu SGL"
                  />
                </Permission>
                {/* <ModalUploadPdtWithPlatform txt="Nhập file pdf từ các nền tảng" /> */}
              </Space>
            </div>
          </Permission>
        </Row>
        <Row gutter={[10, 10]}>
          <Col xs={24} xl={6}>
            <Input
              placeholder="Tìm kiếm theo mã phiếu"
              allowClear
              prefix={<SearchOutlined />}
              onChange={_search}
              value={valueSearch}
            />
          </Col>
          <Col xs={24} xl={5}>
            <FilterDate
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
          <Col xs={24} xl={5}>
            <Select
              placeholder="Lọc theo kênh bán hàng"
              onChange={(value) => _onFilter('platform', value)}
              allowClear
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
              value={paramsFilter.platform}
            >
              {platforms.map((platform, index) => (
                <Select.Option value={platform.value} key={index}>
                  {platform.value}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} xl={4}>
            <Select
              value={paramsFilter['type_id'] || undefined}
              onChange={(value) => _onFilter('type_id', value)}
              allowClear
              showSearch
              filterOption={filterOptionSelect}
              placeholder="Lọc theo loại hàng"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
            >
              {types.map((type, index) => (
                <Select.Option value={type.category_id} key={index}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} xl={4}>
            <Select
              value={paramsFilter['group_id'] || undefined}
              onChange={(value) => _onFilter('group_id', value)}
              allowClear
              showSearch
              filterOption={filterOptionSelect}
              placeholder="Lọc theo nhóm hàng"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
            >
              {groups.map((group, index) => (
                <Select.Option value={group.category_id} key={index}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} xl={6}>
            <Select
              value={paramsFilter['creator_id'] || undefined}
              onChange={(value) => _onFilter('creator_id', value)}
              allowClear
              showSearch
              filterOption={filterOptionSelect}
              placeholder="Lọc theo nhân viên lên đơn"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
            >
              {employees.map((employee) => (
                <Select.Option value={employee.user_id} key={employee.user_id}>
                  {employee.fullname}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} xl={5}>
            <Select
              style={{ width: '100%' }}
              value={paramsFilter['sort_time'] || null}
              onChange={(value) => _onFilter('sort_time', value)}
            >
              <Select.Option value={null}>Tất cả</Select.Option>
              <Select.Option value="exported">Sắp xếp theo thời gian hoàn thành xuất</Select.Option>
              <Select.Option value="packed">
                Sắp xếp theo thời gian hoàn thành đóng gói
              </Select.Option>
              <Select.Option value="transported">
                Sắp xếp theo thời gian hoàn thành bàn giao
              </Select.Option>
            </Select>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: 5, marginTop: 20 }}>
          <div>
            {selectedRowKeys.length ? (
              <Space>
                <Permission permissions={[PERMISSIONS.cap_nhat_don_vi_van_chuyen_phieu_xuat_kho]}>
                  <ModalUpdateShippingCompany>
                    <Button type="primary">Cập nhật đơn vị vận chuyển</Button>
                  </ModalUpdateShippingCompany>
                </Permission>
                {paramsFilter.processing !== 'WAITING' && (
                  <Button onClick={showConfirmDeleteOrder} danger type="primary">
                    Xóa phiếu
                  </Button>
                )}
              </Space>
            ) : (
              ''
            )}
          </div>

          <Space>
            {Object.keys(paramsFilter).length > 4 && (
              <Button
                onClick={_resetFilter}
                type="danger"
                style={{ borderRadius: 5 }}
                icon={<FilterOutlined />}
              >
                Xóa bộ lọc
              </Button>
            )}
            <Permission permissions={[PERMISSIONS.giao_dien_xuat_nhap_file]}>
              <Button
                type="primary"
                onClick={() =>
                  history.push(ROUTES.IMPORTEXPORTMANAGEMENT + '?action_name=file-xuat-hang')
                }
              >
                Lịch sử nhập file
              </Button>
            </Permission>

            <SettingColumns
              columnsDefault={columnsExport}
              nameColumn="columnsExport"
              columns={columns}
              setColumns={setColumns}
            />
            <Button
              onClick={() => _getReportExportInventoryToExport()}
              icon={<VerticalAlignTopOutlined />}
              type="primary"
              className="btn-export"
            >
              Xuất theo bộ lọc
            </Button>
            <Button
              onClick={() => _getReportExportInventoryToExport(true)}
              icon={<VerticalAlignTopOutlined />}
              type="primary"
              className="btn-export"
            >
              Xuất toàn bộ
            </Button>
            <Permission permissions={[PERMISSIONS.tao_phieu_xuat_kho]}>
              <Link to={{ pathname: ROUTES.EXPORT_WAREHOUSE_CREATE }}>
                <Button type="primary">Thêm thủ công</Button>
              </Link>
            </Permission>
          </Space>
        </Row>

        <Tabs
          tabBarStyle={{ height: 55 }}
          activeKey={paramsFilter.processing}
          onChange={(value) => setParamsFilter({ ...paramsFilter, page: 1, processing: value })}
        >
          {dataEnum.map((_enumTab) => {
            return (
              <Tabs.TabPane
                disabled={loadingTable}
                tab={
                  <Badge offset={[5, -8]} showZero count={_enumTab.total} overflowCount={99999}>
                    {_enumTab.label}
                  </Badge>
                }
                key={_enumTab.value}
              />
            )
          })}
        </Tabs>

        <Table
          rowKey="order_id"
          size={SIZE_TABLE}
          style={{ width: '100%' }}
          scroll={{ x: 'max-content', y: '66vh' }}
          loading={loadingTable}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (keys) => setSelectedRowKeys(keys),
          }}
          columns={columns.map((column) => {
            if (column.key === 'stt') {
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                width: 50,
                fixed: 'left',
              }
            }
            if (column.dataIndex === 'total_cost') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'total_cost'),
                render: (text) => <span>{formatCash(text)}</span>,
              }
            }

            if (column.key === 'customer_order_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'customer_order_code'),
              }
            }

            if (column.key === 'code') {
              return {
                ...column,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'code'),
                render: (text, record) =>
                  isAcceptPermissions([PERMISSIONS.cap_nhat_phieu_xuat_kho]) ? (
                    <Link
                      to={{
                        pathname: ROUTES.EXPORT_WAREHOUSE_UPDATE + '/' + record.order_id,
                        state: record,
                      }}
                    >
                      #{text}
                    </Link>
                  ) : (
                    <b>#{text}</b>
                  ),
              }
            }
            if (column.key === 'warehouse_info') {
              return {
                ...column,
                sorter: (a, b) => compare(a.warehouse_info, b.warehouse_info, 'name'),
                render: (warehouse_info) => warehouse_info && warehouse_info.name,
              }
            }
            if (column.key === 'car_number') {
              return {
                ...column,
                render: (text, record) => record.shipping_info && record.shipping_info.car_number,
              }
            }
            if (column.key === 'shipping_company_name') {
              return {
                ...column,
                render: (shipping_info, record) => (
                  <ModalUpdateShippingCompany record={record}>
                    <a>{record.shipping_info && record.shipping_info.shipping_company_name}</a>
                  </ModalUpdateShippingCompany>
                ),
              }
            }

            if (column.key === 'to_name') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'to_name'),
                render: (shipping_info) => shipping_info && shipping_info.to_name,
              }
            }
            if (column.key === 'transport_date') {
              return {
                ...column,
                sorter: (a, b) => {
                  const aMoment = a.transport_date ? moment(a.transport_date).unix() : 0
                  const bMoment = b.transport_date ? moment(b.transport_date).unix() : 0
                  return aMoment - bMoment
                },
                render: (text, record) => {
                  return (
                    record.transport_date &&
                    moment(record.transport_date).format('DD/MM/YYYY HH:mm')
                  )
                },
              }
            }
            if (column.key === 'shipping_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'shipping_code'),
                render: (shipping_info) => {
                  return shipping_info && shipping_info.shipping_code
                },
              }
            }
            if (column.key === 'creator_id') {
              return {
                ...column,
                render: (text, record) => record.creator_info?.fullname,
                sorter: (a, b) => compare(a, b, 'creator_id'),
              }
            }

            if (column.key === 'export_status') {
              return {
                ...column,
                render: (text, record) => (
                  <Tag
                    color={
                      (!record.is_received && 'warning') ||
                      (record.processing === 'complete' && 'success') ||
                      'processing'
                    }
                  >
                    {(!record.is_received && 'Chưa tiếp nhận') ||
                      (record.processing === 'complete' && 'Đã xuất kho') ||
                      'Đang thực hiện'}
                  </Tag>
                ),
              }
            }
            if (column.key === 'export_status') {
              return {
                ...column,
                render: (export_status) => export_status && _exportStatusTranslate(export_status),
                sorter: (a, b) => compare(a, b, 'export_status'),
              }
            }
            if (column.key === 'shipping_bill') {
              return {
                ...column,
                render: (text, record) => {
                  const src =
                    record.shipping_info && record.shipping_info.shipping_employee_signature

                  return (
                    <Row align="middle" justify="center" style={{ width: '100%' }}>
                      <Col>
                        {src && (
                          <Popover
                            placement="left"
                            content={
                              <img src={src} style={{ height: '450px', width: '450px' }} alt="" />
                            }
                          >
                            <img src={src} style={{ height: 45, width: 45 }} alt="" />
                          </Popover>
                        )}
                      </Col>
                    </Row>
                  )
                },
              }
            }
            if (column.key === 'receive_reason') {
              return {
                ...column,
                render: (text, record) => {
                  if (record.is_received === false) {
                    return <ModalViewReasons record={record} />
                  }
                },
              }
            }
            if (column.key === 'fee') {
              return {
                ...column,
                render: (text, record) => <ModalViewFees fees={record.fees} />,
              }
            }

            if (column.key === 'products_list') {
              return {
                ...column,
                render: (text, record) => (
                  <div>
                    <a onClick={() => _getOrderDetail(record.order_id)}>Xem chi tiết</a>
                  </div>
                ),
              }
            }
            if (column.key === 'delete_button') {
              return {
                ...column,
                width: 380,
                render: (text, record) => (
                  <div>
                    <Space>
                      <Permission permissions={[PERMISSIONS.xoa_phieu_xuat_kho]}>
                        {paramsFilter.processing === 'WAITING' ? (
                          <ModalConfirmDelete
                            record={record}
                            onDelete={(note) => _deleteExportOrder(record?.order_id, note)}
                          >
                            <Button type="primary" danger icon={<DeleteOutlined />}></Button>
                          </ModalConfirmDelete>
                        ) : (
                          <Button
                            onClick={showConfirmDeleteOrder}
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                          ></Button>
                        )}
                      </Permission>
                      <Button
                        onClick={() => _resubmitExportOrder(record)}
                        type="primary"
                        style={{ display: record.is_received && 'none' }}
                      >
                        Kích hoạt xuất kho
                      </Button>
                      <Button type="primary">
                        <Link
                          target="_blank"
                          to={`${ROUTES.IMPORT_WAREHOUSE_CREATE}?order_export_id=${record.order_id}`}
                        >
                          Tạo phiếu nhập kho
                        </Link>
                      </Button>
                    </Space>
                  </div>
                ),
              }
            }

            return column
          })}
          dataSource={exportOrders}
          pagination={{
            position: POSITION_TABLE,
            total: countOrder,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            onChange(page, pageSize) {
              setParamsFilter({
                ...paramsFilter,
                page: page,
                page_size: pageSize,
              })
            },
          }}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </div>
    </>
  )
}
