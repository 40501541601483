import React, { useState, memo, useEffect } from 'react'
import { filterOptionSelect } from 'utils'

//antd
import { Input, Select, Row, Table } from 'antd'
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons'
import { getBanks } from 'apis/bank'

export default memo(function BankAccount({ bankAccounts = [], setBankAccounts }) {
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      width: '35%',
      title: 'Tên ngân hàng',
      render: (text, record, index) => (
        <Select
          allowClear
          showSearch
          filterOption={filterOptionSelect}
          value={record.bank_id}
          bordered={false}
          style={{ width: '100%' }}
          placeholder="Chọn ngân hàng"
          onChange={(value) => handleUpdateItem('bank_id', value, index)}
        >
          {banks.map((bank) => (
            <Select.Option value={bank.bank_id} key={bank.bank_id}>
              {bank.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Tên chi nhánh',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.address}
            placeholder="Nhập chi nhánh"
            onBlur={(e) => handleUpdateItem('address', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('address', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Số tài khoản',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.account_no}
            placeholder="Nhập số tài khoản"
            onBlur={(e) => handleUpdateItem('account_no', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('account_no', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: '',
      render: (text, record, index) => (
        <CloseCircleFilled
          onClick={() => handleDeleteItem(index)}
          style={{ fontSize: 20, cursor: 'pointer', color: 'red' }}
        />
      ),
      width: 38,
    },
  ]

  const [banks, setBanks] = useState([])

  const _getBanks = async () => {
    try {
      const res = await getBanks({ page: 1, page_size: 10000 })
      if (res.status === 200) {
        if (res.data.success) {
          setBanks(res.data.data)
        }
      }
    } catch (error) {}
  }

  const handleUpdateItem = (key, value, index) => {
    const bankAccountsNew = [...bankAccounts]
    bankAccountsNew[index][key] = value
    setBankAccounts([...bankAccountsNew])
  }

  const handleAddItem = () => {
    const initItem = {
      bank_id: '',
      address: '',
      account_no: '',
      account_holder: '',
    }
    bankAccounts.push(initItem)
    setBankAccounts([...bankAccounts])
  }

  const handleDeleteItem = (index) => {
    if (index !== -1) {
      const bankAccountsNew = [...bankAccounts]
      bankAccountsNew.splice(index, 1)
      setBankAccounts([...bankAccountsNew])
    }
  }

  useEffect(() => {
    _getBanks()
  }, [])

  return (
    <div className="card">
      <b>TÀI KHOẢN NGÂN HÀNG</b>
      <Table
        pagination={false}
        bordered={true}
        dataSource={bankAccounts}
        columns={columns}
        size="small"
        footer={() => (
          <Row
            onClick={handleAddItem}
            justify="center"
            align="middle"
            style={{ color: '#6C757D', cursor: 'pointer' }}
          >
            <PlusOutlined style={{ marginRight: 5, fontSize: 10 }} />
            <div>Thêm mới tài khoản ngân hàng</div>
          </Row>
        )}
      />
    </div>
  )
})
