import React, { memo, useEffect, useRef, useState } from 'react'
import { IMAGE_DEFAULT, PERMISSIONS, ROUTES, SIZE_TABLE } from 'consts'
import styles from './order-e-commerce.module.scss'
import moment from 'moment'
import { formatCash, saveFiltersToLocalStorage, compare, isAcceptPermissions } from 'utils'
import { POSITION_TABLE, PAGE_SIZE_OPTIONS } from 'consts'
import delay from 'delay'
import { useDispatch, useSelector } from 'react-redux'
import printJS from 'print-js'
import { Link } from 'react-router-dom'
import { getDistrict, getProvince, getWard } from 'apis/address'
//images
import lazada from 'assets/icons/lazada.png'
import shopee from 'assets/icons/shopee.jpg'
import tiki from 'assets/icons/tiki.png'
import tiktok from 'assets/icons/tiktok.png'
import fire from 'assets/images/fire.jpg'
import sapo from 'assets/icons/sapo.jpeg'

// antd
import {
  CarOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Input,
  Table,
  Row,
  Col,
  Modal,
  Tabs,
  Badge,
  Select,
  notification,
  Button,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  InputNumber,
  Form,
  Popover,
  Checkbox,
  Dropdown,
  Menu,
  Spin,
} from 'antd'

//apis
import {
  getOrdersEcom,
  getTrackingNumberOrderEcom,
  reloadOrdersEcom,
  createOrdersEcom,
  callShipper,
  readyShipLazada,
  updateProductEcom,
  addProductEcom,
  deleteProductEcom,
  reloadEcomOrder,
  addProductsEcom,
} from 'apis/order-ecom'
import { getSites, scanOrders } from 'apis/site'
import { enumExportOrderProcess } from 'apis/enum'
import { deleteExportOrders, getExportOrders, updateExportOrders } from 'apis/order'
import { checkShippingFee } from 'apis/shipping-company'
import { getProducts } from 'apis/product'

// components
import BackToTop from 'components/back-to-top'
import FilterDate from 'components/filter-date'
import SuffixIconCustom from 'components/suffix-icon-custom'
import columnsExport from 'views/export-warehouse/columns'
import ModalViewReasons from 'views/export-warehouse/view-reason'
import ModalViewFees from 'views/export-warehouse/view-fee'
import ModalViewProduct from 'views/export-warehouse/view-product'
import Permission from 'components/permission'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import QrCodeProduct from 'components/qr-code-product'
import ZoomImage from 'components/zoom-image'

const { TabPane } = Tabs
export default function OrderEcommerce() {
  const typingTimeoutRef = useRef(null)
  const dataUser = useSelector((state) => state.login.dataUser)
  const dispatch = useDispatch()
  const ID_DIV_PRINT = 'print-tracking-number'

  const KEY_FILTER = 'ordersEcomFilters'
  const PLATFORMS = ['Tiki', 'Shopee', 'Lazada', 'Tiktok', 'Sapo']
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [loadingTable, setLoadingTable] = useState(false)
  const [orders, setOrders] = useState([])
  const [sites, setSites] = useState([])
  const [countOrder, setCountOrder] = useState(0)
  const [exportOrders, setExportOrders] = useState([])
  const [countExportOrder, setCountExportOrder] = useState(0)
  const [statusList, setStatusList] = useState([
    { name: 'Tất cả', key: '', count: 0 },
    { name: 'Chưa thanh toán', key: 'UNPAID', count: 0 },
    { name: 'Đợi xác nhận', key: 'PROGRESSING', count: 0 },
    { name: 'Đợi đóng gói', key: 'PACKED', count: 0 },
    { name: 'Không đủ tiêu chuẩn', key: 'UNQUALIFIED', count: 0 },
    { name: 'Đã đóng gói & Đợi bàn giao', key: 'READY_TO_SHIP', count: 0 },
    { name: 'Đang vận chuyển', key: 'SHIPPING', count: 0 },
    { name: 'Đã giao cho khách', key: 'SHIPPED', count: 0 },
    { name: 'Đã huỷ đơn', key: 'CANCELED', count: 0 },
    { name: 'Có sự cố', key: 'REPORT', count: 0 },
  ])
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )
  const [countUnqualified, setCountUnqualified] = useState(0)
  const [countOrderIsFast, setCountOrderIsFast] = useState(0)

  const [orderDetail, setOrderDetail] = useState()
  const [resOrderEcom, setResOrderEcom] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [barcodeSearch, setBarcodeSearch] = useState(filters ? filters.product_barcode || '' : '')
  const [dataDetail, setDataDetail] = useState()
  const [fromPrice, setFromPrice] = useState(null)
  const [toPrice, setToPrice] = useState(null)

  const [visibleModalPackageCallShip, setVisibleModalPackageCallShip] = useState(false)
  const [dataProvince, setDataProvince] = useState([])
  const [dataDistrict, setDataDistrict] = useState([])
  const [dataWard, setDataWard] = useState([])
  const [feeShippingCheck, setFeeShippingCheck] = useState()
  const [orderSapoSelected, setOrderSapoSelected] = useState()

  const [formPackage] = Form.useForm()

  const handleFindByPrices = () => {
    const paramsFilterNew = { ...paramsFilter }
    if (fromPrice !== null && toPrice !== null) {
      paramsFilterNew.from_price = fromPrice
      paramsFilterNew.to_price = toPrice
    } else {
      delete paramsFilterNew.from_price
      delete paramsFilterNew.to_price
    }
    setParamsFilter({ ...paramsFilterNew })
  }

  const ModalAddProductsEcom = memo(({ children }) => {
    const typingTimeoutRef = useRef(null)
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [quantity, setQuantity] = useState(1)
    const [products, setProducts] = useState([])
    const [productSelect, setProductSelect] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [valueSearch, setValueSearch] = useState('')

    const _getProducts = async (search = '') => {
      try {
        setLoading(true)
        const query = {}
        if (search) query.name = search
        else delete query.name
        const res = await getProducts({ ...query, customer_id: dataUser.customer_id })
        if (res.status === 200) {
          if (res.data.success) {
            const data = res.data.data
            setProducts(data)
          }
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    const ProductItem = (
      <Menu style={{ maxHeight: 350, overflowX: 'auto' }}>
        {loading ? (
          <Spin />
        ) : (
          products.map((data, index) => {
            return (
              <Menu.Item key={index} onClick={() => setProductSelect(data)}>
                <Space>
                  <div Style={{ width: 200 }}>
                    <img
                      src={data.images.length ? data.images[0] || IMAGE_DEFAULT : IMAGE_DEFAULT}
                      alt=""
                      height={70}
                      width={70}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                      Tên :<b> {data?.name}</b>
                    </span>
                    <span>
                      Mã hàng/barcode:<b> {data?.barcode}</b>
                    </span>
                  </div>
                </Space>
              </Menu.Item>
            )
          })
        )}
      </Menu>
    )

    const _handleSearchProduct = (e) => {
      const value = e.target.value
      setValueSearch(value)
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(() => {
        _getProducts(value)
      }, 650)
    }

    const _addProductsEcom = async () => {
      try {
        if (!productSelect) {
          notification.error({ message: 'Vui lòng chọn sản phẩm' })
          return
        }
        setLoadingSubmit(true)
        let body = {
          order_ids: selectedRowKeys,
          product_barcode: productSelect.barcode,
          export_quantity: quantity,
        }
        const res = await addProductsEcom(body)
        setLoadingSubmit(false)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Thêm sản phẩm vào đơn hàng thành công' })
            toggle()
            _getOrdersEcom()
          } else
            notification.error({
              message: res.data.message || 'Thêm sản phẩm thất bại',
            })
        } else notification.error({ message: res.data.message || 'Thêm sản phẩm thất bại' })
      } catch (err) {
        console.log(err)
        setLoadingSubmit(false)
      }
    }

    useEffect(() => {
      _getProducts()
    }, [])

    useEffect(() => {
      if (visible) {
        setQuantity(1)
        setProductSelect()
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          okButtonProps={{ disabled: !productSelect, loading: loadingSubmit }}
          style={{ marginTop: 20 }}
          okText="Thêm hàng loạt"
          width="50%"
          title="Thêm sản phẩm vào đơn hàng"
          visible={visible}
          onCancel={toggle}
          onOk={_addProductsEcom}
        >
          <h2>Bạn đang chọn {selectedRowKeys.length} đơn hàng</h2>
          <Dropdown trigger={['focus']} overlay={ProductItem}>
            <div>
              Tìm kiếm sản phẩm
              <Input
                allowClear
                placeholder="Tìm kiếm theo tên sản phẩm"
                onChange={_handleSearchProduct}
                value={valueSearch}
                style={{ width: '100%', borderRadius: '0px' }}
              />
            </div>
          </Dropdown>
          {productSelect && (
            <div>
              <br />
              <b style={{ fontSize: 18 }}>
                Bạn đang chọn sản phẩm {productSelect.name} - {productSelect.barcode}
              </b>
              <br />
            </div>
          )}
          <br />
          <div>
            <div>Số lượng</div>
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              value={quantity}
              onChange={setQuantity}
              size="large"
              placeholder="Nhập số lượng"
              onPressEnter={_addProductsEcom}
            />
          </div>
        </Modal>
      </>
    )
  })

  const ModalAddProductEcom = ({ children, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [barcode, setBarcode] = useState('')
    const [quantity, setQuantity] = useState(1)

    const _addProductEcom = async () => {
      try {
        let body = {
          order_id: record.code_ecommerce,
          product_barcode: barcode,
          export_quantity: quantity,
        }
        setLoadingTable(true)
        const res = await addProductEcom(body)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Thêm mã sản phẩm thành công' })
            toggle()
            _getOrdersEcom()
          } else
            notification.error({
              message: res.data.message || 'Thêm mã sản phẩm thất bại',
            })
        } else notification.error({ message: res.data.message || 'Thêm mã sản phẩm thất bại' })
        setLoadingTable(false)
      } catch (err) {
        setLoadingTable(false)
        console.log(err)
        setLoadingTable(false)
      }
    }

    useEffect(() => {
      if (visible) {
        setBarcode('')
        setQuantity(1)
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          width="50%"
          title={`Thêm mã sản phẩm đơn hàng #${record.code}`}
          visible={visible}
          onCancel={toggle}
          onOk={_addProductEcom}
        >
          <b style={{ fontSize: 18 }}>Mã kho/barcode của sản phẩm tại kho</b>
          <Input
            autoFocus
            allowClear
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            size="large"
            placeholder="Nhập mã sản phẩm"
            onPressEnter={_addProductEcom}
          />
          <br />
          <br />
          <div>
            <b style={{ fontSize: 18 }}>Số lượng xuất</b>
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              value={quantity}
              onChange={setQuantity}
              size="large"
              placeholder="Nhập số lượng xuất"
              onPressEnter={_addProductEcom}
            />
          </div>
        </Modal>
      </>
    )
  }

  const _getExportOrders = async () => {
    try {
      setLoadingTable(true)
      let queries = { ...paramsFilter }
      if (queries.status === 'UNQUALIFIED') {
        delete queries.status
        queries.processing = 'WAITING'
      } else delete queries.processing
      const res = await getExportOrders({ ...queries, customer_id: dataUser.customer_id })
      setLoadingTable(false)
      if (res.status === 200) {
        setExportOrders(res.data.data)
        setCountExportOrder(res.data.count || 0)
      }
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const _getProvince = async () => {
    try {
      const res = await getProvince()
      if (res.status === 200) {
        setDataProvince(res.data.data)
        //if (!location.state) formPackage.setFieldsValue({ province: res.data.data[0].province_name })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getDistrict = async (value) => {
    try {
      const res = await getDistrict(value || {})
      if (res.status === 200) {
        const data = res.data.data
        setDataDistrict(data)
        //etValueDistrict(data[0]?.district_name)
        // if (!location.state) form.setFieldsValue({ district: data[0]?.district_name })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getWard = async (value) => {
    try {
      const res = await getWard(value || {})
      if (res.status === 200) {
        setDataWard(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _deleteProductEcom = async (body) => {
    try {
      setLoadingTable(true)
      const res = await deleteProductEcom(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa mã sản phẩm thành công' })
          _getOrdersEcom()
        } else
          notification.error({
            message: res.data.message || 'Xóa mã sản phẩm thất bại',
          })
      } else notification.error({ message: res.data.message || 'Xóa mã sản phẩm thất bại' })
    } catch (err) {
      setLoadingTable(false)
      console.log(err)
      setLoadingTable(false)
    }
  }

  const ModalUpdateBarcode = ({ children, record, order }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [barcode, setBarcode] = useState('')
    const [quantity, setQuantity] = useState(0)

    const _updateBarcodeProductEcom = async () => {
      try {
        const productsNew = order.products.map((product) =>
          product.product_id === record.product_id
            ? { ...product, barcode: barcode, export_quantity: quantity }
            : product
        )

        let body = {
          order_id: order.code_ecommerce,
          products: productsNew,
        }
        setLoadingTable(true)
        const res = await updateProductEcom(body)
        setLoadingTable(false)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Cập nhật mã sản phẩm thành công' })
            toggle()
            _getOrdersEcom()
          } else
            notification.error({
              message: res.data.message || 'Cập nhật mã sản phẩm thất bại',
            })
        } else notification.error({ message: res.data.message || 'Cập nhật mã sản phẩm thất bại' })
      } catch (err) {
        setLoadingTable(false)
        console.log(err)
        setLoadingTable(false)
      }
    }

    useEffect(() => {
      if (visible && record) {
        setBarcode(record.barcode || '')
        setQuantity(record.require_quantity || 1)
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          width="50%"
          title={`Cập nhật mã sản phẩm ${record && record.name}`}
          visible={visible}
          onCancel={toggle}
          onOk={_updateBarcodeProductEcom}
        >
          <b style={{ fontSize: 18 }}>Mã kho/barcode của sản phẩm tại kho</b>
          <Input
            onPressEnter={_updateBarcodeProductEcom}
            autoFocus
            allowClear
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            size="large"
            placeholder="Nhập mã sản phẩm mới"
          />
          <br />
          <br />
          <b style={{ fontSize: 18 }}>Số lượng xuất</b>
          <InputNumber
            min={1}
            style={{ width: '100%' }}
            value={quantity}
            onChange={setQuantity}
            size="large"
            placeholder="Nhập số lượng xuất"
            onPressEnter={_updateBarcodeProductEcom}
          />
        </Modal>
      </>
    )
  }

  const onCheckFee = async (body) => {
    try {
      var dataForm = formPackage.getFieldValue()
      var address = {
        ward_code: dataForm.ward_code || orderSapoSelected?.shipping_info?.ward_code,
        province_id: dataForm.province_id || orderSapoSelected?.shipping_info?.province_id,
        district_id: dataForm.district_id || orderSapoSelected?.shipping_info?.district_id,
      }

      var body = {
        address: address,
        warehouse_id: orderSapoSelected?.warehouse_id,
        package_weight: dataForm.package_weight,
        package_length: dataForm.package_length,
        package_width: dataForm.package_width,
        package_height: dataForm.package_height,
        cod: orderSapoSelected?.shipping_info?.cod,
        products: orderSapoSelected?.products,
        cost_of_goods: 0,
      }
      let response = await checkShippingFee(body)
      if (response.status == 200 && response.data.data) {
        setFeeShippingCheck(response.data.data.fee)
      } else {
        notification.error({
          message: response.data?.message || 'Có lỗi xảy ra, vui lòng liên hệ kỹ thuật SGL',
        })
      }
    } catch (e) {}
  }

  const onSubmitPackageCallShip = async () => {
    setFeeShippingCheck()
    var data = formPackage.getFieldValue()
    _callShipperOrReadyShipLazada(
      'call-shipper',
      true,
      data.package_weight,
      data.package_length,
      data.package_width,
      data.package_height
    )
  }

  const ModalFormPackageCallShip = () => {
    return (
      <>
        <Modal
          width="70%"
          title={`Điền thông số sau để gọi vận chuyển`}
          visible={visibleModalPackageCallShip}
          onCancel={(e) => {
            setVisibleModalPackageCallShip(false)
          }}
          footer={null}
        >
          <Form form={formPackage}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  label={'Tổng khối lượng (gam)'}
                  name={'package_weight'}
                >
                  <InputNumber
                    min={1}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Nhập tổng khối lượng đơn hàng"
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name={'package_length'}
                  label={'Tổng chiều dài (cm)'}
                >
                  <InputNumber
                    min={1}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Nhập tổng chiều dài đơn hàng"
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name={'package_height'}
                  label={'Tổng chiều cao (cm)'}
                >
                  <InputNumber
                    min={1}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Nhập tổng chiều cao đơn hàng"
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name={'package_width'}
                  label={'Tổng chiều rộng (cm)'}
                >
                  <InputNumber
                    min={1}
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Nhập tổng chiều rộng đơn hàng"
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="required_note"
                  label="Ghi chú"
                >
                  <Select
                    allowClear
                    showSearch
                    defaultValue={'CHOXEMHANGKHONGTHU'}
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {[
                      { value: 'CHOTHUHANG', label: 'Cho thử hàng' },
                      { value: 'CHOXEMHANGKHONGTHU', label: 'Cho xem hàng không thử' },
                      { value: 'KHONGCHOXEMHANG', label: 'Không cho xem hàng' },
                    ].map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="is_need_cod"
                  label="Cần thu hộ"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="cod"
                  label="Số tiền thu hộ"
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    size="large"
                    formatter={formatCash}
                    placeholder="Nhập số tiền cần thu hộ"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="province_id"
                  label="Tỉnh/Thành phố"
                >
                  <Select
                    allowClear
                    showSearch
                    size="large"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn tỉnh/thành phố"
                    suffixIcon={<SuffixIconCustom />}
                    onChange={(e) => {
                      _getDistrict({ province_id: e })
                      formPackage.setFieldsValue({ district_id: '' })
                    }}
                  >
                    {dataProvince.map((item, index) => (
                      <Select.Option key={index} value={item.province_id}>
                        {item.province_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="district_id"
                  label="Quận/Huyện"
                >
                  <Select
                    allowClear
                    showSearch
                    size="large"
                    filterOption={(inputValue, option) => {
                      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    }}
                    onClear={(e) => {
                      _getDistrict()
                    }}
                    onChange={(e) => {
                      _getWard({ district_id: e })
                      formPackage.setFieldsValue({ ward_code: '' })
                    }}
                    style={{ width: '100%' }}
                    placeholder="Chọn quận/huyện"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {dataDistrict.map((item, index) => (
                      <Select.Option key={index} value={item.district_id}>
                        {item.district_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="ward_code"
                  label="Phường/Xã"
                >
                  <Select
                    allowClear
                    size="large"
                    showSearch
                    onClear={(e) => {
                      _getWard()
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                    placeholder="Chọn phường/xã"
                    suffixIcon={<SuffixIconCustom />}
                  >
                    {dataWard.map((item, index) => (
                      <Select.Option key={index} value={item.ward_code}>
                        {item.ward_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  name="address"
                  label="Địa chỉ nhận hàng"
                >
                  <Input
                    allowClear
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Nhập địa chỉ nhận hàng"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="end" style={{ marginTop: 10 }}>
            <Space>
              {feeShippingCheck && <div>{'Phí vận chuyển: ' + formatCash(feeShippingCheck)}</div>}
              <Button
                onClick={(e) => {
                  onCheckFee()
                }}
                type="primary"
              >
                {'Kiểm tra phí vận chuyển'}
              </Button>

              <Button
                loading={loadingTable}
                onClick={(e) => {
                  onSubmitPackageCallShip()
                }}
                type="primary"
              >
                {'Xác nhận & Gọi vận chuyển'}
              </Button>
            </Space>
          </Row>
        </Modal>
      </>
    )
  }

  const getWarehouseStatusName = (id = '') => {
    if (id == 1) return 'Chưa có'
    if (id == 2) return 'Đợi xuất kho'
    if (id == 3) return 'Đang xuất kho'
    if (id == 4) return 'Đợi đóng gói'
    if (id == 5) return 'Đợi bàn giao'
    return ''
  }

  const getWarehouseStatusColor = (id = '') => {
    if (id == 1) return 'warning'
    if (id == 2) return 'success'
    if (id == 3) return 'processing'
    if (id == 4) return 'success'
    if (id == 5) return 'success'
    return ''
  }

  const columnsDetail = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      width: '7%',
      title: 'Mã sản phẩm',
      key: 'code',
    },
    {
      width: 70,
      title: 'Qr code',
      render: (text, record) => <QrCodeProduct value={record.barcode} />,
    },
    {
      width: '11%',
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Giá bán',
      render: (text, record) => formatCash(record.price || 0),
      align: 'center',
    },
    {
      title: 'Mã vận đơn',
      dataIndex: 'tracking_number',
      align: 'center',
    },
    {
      title: 'Mã kiện hàng',
      dataIndex: 'package_number',
      align: 'center',
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'Nhóm hàng',
      render: (text, record) => record.group_info && record.group_info.name,
    },
    {
      title: 'Loại hàng',
      render: (text, record) => record.type_info && record.type_info.name,
    },
    {
      title: 'Hình ảnh',
      align: 'center',
      render: (text, record) =>
        record.images && (
          <img
            style={{ width: 50, height: 50 }}
            alt=""
            src={
              record.images[0] ||
              'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'
            }
          />
        ),
    },
    {
      title: 'Quy đổi số lượng',
      render: (text, record) => {
        const quantity = record.import_details
          ? record?.import_details.reduce((acc, curr) => acc + curr?.quantity, 0)
          : 0
        return quantity * (record.smallest_unit || 1)
      },
      align: 'center',
    },
    {
      title: 'Số lượng yêu cầu',
      dataIndex: 'require_quantity',
      align: 'center',
    },
    {
      title: 'Nhập kho thực tế',
      dataIndex: 'import_details',
      align: 'center',
      render: (text, record) =>
        record.import_details
          ? record?.import_details.reduce((acc, curr) => acc + curr?.quantity, 0)
          : 0,
    },
    {
      title: 'CBM (cm³)',
      dataIndex: 'cbm',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'S mặt phẳng (cm²)',
      dataIndex: 'area',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'Ngày sản xuất',
      align: 'center',
      dataIndex: 'mfg',
      render: (mfg) => mfg && moment(mfg).format('DD-MM-YYYY'),
    },
    {
      title: 'Hạn sử dụng',
      align: 'center',
      dataIndex: 'exp',
      render: (exp) => exp && moment(exp).format('DD-MM-YYYY'),
    },
  ]

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: 150,
      render: (text, record, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{(paramsFilter.page - 1) * paramsFilter.page_size + index + 1}</div>
            <Tag color={record.is_export ? 'green' : 'warning'}>
              {record.is_export ? 'Đã lên phiếu xuất' : 'Chưa lên phiếu xuất'}
            </Tag>

            {record.is_missing_inventory && (
              <Tag
                onClick={(e) => {
                  alert(record.error)
                }}
                color={'red'}
              >
                {'Không đủ tồn kho'}
              </Tag>
            )}
            {record.is_update_order && (
              <Tag color="green" style={{ marginTop: 5 }}>
                Đơn đã chỉnh sửa
              </Tag>
            )}
          </div>
        )
      },
    },
    {
      title: 'Số đơn hàng',
      width: '10%',
      dataIndex: 'code',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {record.is_fast === true && (
            <Row justify="center">
              <img src={fire} alt="" width="35px" height="35px" />
            </Row>
          )}

          {record.code != record.code_ecommerce && (
            <Row justify="center">
              <div>{record.code_ecommerce}</div>
            </Row>
          )}

          <b style={{ color: 'red' }}>{record.fulfillment_type || ''}</b>
          <b>#{text}</b>
          {record.status === 'SHIPPING' && (
            <Tooltip title="Đơn này đang vận chuyển">
              <CarOutlined style={{ fontSize: 19, color: 'green' }} />
            </Tooltip>
          )}
        </div>
      ),
      sorter: (a, b) => a?.name?.length - b?.name?.length,
    },
    {
      title: 'Mã phiếu xuất',
      width: '9%',
      dataIndex: 'code_order_export',
    },
    {
      width: '10%',
      title: 'DVVC',
      render: (text, record) => (
        <Col>
          {record.platform == 'Sapo' && (
            <div>{'Tổng tiền SP: ' + formatCash(Number(record.total_cost || 0))}</div>
          )}
          {record.platform == 'Sapo' && (
            <div>{'Phí Ship: ' + (formatCash(record.shipping_info?.fee) ?? 'Chưa có')}</div>
          )}
          {record.platform == 'Sapo' && <div>{'Nặng: ' + Number(record.total_weight || 0)}</div>}
          {record.platform == 'Sapo' && <div>{'Dài: ' + Number(record.total_length || 0)}</div>}
          {record.platform == 'Sapo' && <div>{'Rộng: ' + Number(record.total_width || 0)}</div>}
          {record.platform == 'Sapo' && <div>{'Cao: ' + Number(record.total_height || 0)}</div>}
          <div>{record.shipping_info && record.shipping_info.shipping_company_name}</div>
        </Col>
      ),
    },
    {
      title: 'Mã vận đơn',
      render: (text, record) => record.shipping_info && record.shipping_info.tracking_number,
    },
    {
      title: 'Trạng thái kho',
      render: (text, record) => (
        <Tag color={getWarehouseStatusColor(record.id_status_warehouse)}>
          {getWarehouseStatusName(record.id_status_warehouse)}
        </Tag>
      ),
    },
    {
      width: 80,
      title: 'Tổng SL xuất',
      render: (text, record) => record.total_quantity && formatCash(record.total_quantity || 0),
    },
    // {
    //   title: 'DS sản phẩm',
    //   render: (text, record) => <a onClick={() => setDataDetail(record)}>Xem chi tiết</a>,
    // },
    {
      width: 100,
      title: 'Nguồn',
      render: (text, record) => {
        if (record.platform === 'Tiki')
          return <img src={tiki} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Shopee')
          return <img src={shopee} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Lazada')
          return <img src={lazada} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Tiktok')
          return <img src={tiktok} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Sapo')
          return <img src={sapo} alt="" style={{ width: 45, height: 45 }} />

        return record.platform
      },
    },
    {
      title: 'Trạng thái đơn',
      width: 150,
      render: (text, record) =>
        record.status && (
          <Tag color={getStatusColor(record.status)}>{getStatusName(record.status)}</Tag>
        ),
    },
    {
      title: 'Kho xuất hàng',
      dataIndex: 'warehouse_info',
      render: (text, record) => record.warehouse_info && record.warehouse_info.name,
    },
    {
      width: 140,
      title: 'In trước vận đơn',
      render: (text, record) => {
        if (['PACKED', 'READY_TO_SHIP'].includes(record.status)) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PrinterOutlined
                style={{ fontSize: 20, color: 'green', marginBottom: 5 }}
                onClick={() => _getTrackingNumber(record)}
              />
              <Tag color={record.link_tracking_number != '' ? 'green' : 'warning'}>
                {record.link_tracking_number != '' ? 'Đã in vận đơn' : 'Chưa in vận đơn'}
              </Tag>
            </div>
          )
        } else {
          return <Tag color={'warning'}>Cần gọi vận chuyển</Tag>
        }
      },
    },
    {
      width: 300,
      title: 'Thông tin vận chuyển',
      render: (text, record) => {
        const shipping = record.shipping_info
        return (
          <div>
            {shipping ? (
              <div>
                {shipping.shipping_company_name && <a>{shipping.shipping_company_name} - </a>}
                {`${shipping.to_address}, ${shipping.to_ward}, ${shipping.to_district}, ${shipping.to_province}`}
              </div>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      title: 'Ngày tạo đơn từ sàn',
      dataIndex: 'create_date_ecom',
    },
    {
      title: 'Trạng thái in',
      render: (text, record) =>
        record.amount_print && record.amount_print > 0 ? (
          <b style={{ color: 'green' }}>Đã in ({record.amount_print})</b>
        ) : (
          <b style={{ color: 'orange' }}>Chưa in</b>
        ),
    },
    // {
    //   title: 'Ghi chú',
    //   dataIndex: 'note',
    // },
    {
      title: 'Danh sách sự cố',
      render: (text, record) =>
        record.issues && record.issues.length ? (
          <a style={{ color: 'red' }} onClick={() => setOrderDetail(record)}>
            Xem chi tiết
          </a>
        ) : (
          ''
        ),
    },
    {
      title: '',
      width: 150,
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() =>
            _reloadOrdersEcom({ order_id: record.code_ecommerce, id_site: record.id_site })
          }
        >
          Tải lại đơn
        </Button>
      ),
    },
  ]

  const showConfirmDeleteOrder = () => {
    Modal.confirm({
      title:
        'Vui lòng liên hệ SGL để hỗ trợ xoá phiếu, chúng tôi cần đảm bảo hàng hoá của bạn chưa được nhập/xuất kho tránh ảnh hướng tồn kho.',
      cancelButtonProps: { style: { display: 'none' } },
      okText: 'Đã hiểu',
      onOk: () => setSelectedRowKeys([]),
    })
  }

  const columnsOrderEcomCreated = [
    {
      title: 'STT',
      align: 'center',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã vận đơn',
      dataIndex: 'tracking_number',
    },
    {
      title: 'Số đơn hàng',
      dataIndex: 'code',
    },
    {
      width: 80,
      title: 'Tổng SL xuất',
      render: (text, record) => record.total_quantity && formatCash(record.total_quantity || 0),
    },
    {
      width: 100,
      title: 'Nguồn',
      render: (text, record) => {
        if (record.platform === 'Tiki')
          return <img src={tiki} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Shopee')
          return <img src={shopee} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Lazada')
          return <img src={lazada} alt="" style={{ width: 45, height: 45 }} />
        if (record.platform === 'Tiktok')
          return <img src={tiktok} alt="" style={{ width: 45, height: 45 }} />
        return record.platform
      },
    },
    {
      title: 'Kho xuất hàng',
      dataIndex: 'warehouse_info',
      render: (text, record) => record.warehouse_info && record.warehouse_info.name,
    },
    {
      width: 300,
      title: 'Thông tin vận chuyển',
      render: (text, record) => {
        const shipping = record.shipping_info
        return (
          <div>
            {shipping ? (
              <div>
                {shipping.shipping_company_name && <a>{shipping.shipping_company_name} - </a>}
                {`${shipping.to_address}, ${shipping.to_ward}, ${shipping.to_district}, ${shipping.to_province}`}
              </div>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      title: 'Lý do',
      render: (text, record) => <b style={{ color: 'red' }}>{record.reject_reason || ''}</b>,
    },
  ]

  const getStatusName = (name = '') => {
    if (name === 'UNPAID') return 'Chưa thanh toán'
    if (name === 'PROGRESSING') return 'Đợi xác nhận'
    if (name === 'PACKED') return 'Đợi đóng gói'
    if (name === 'READY_TO_SHIP') return 'Đã đóng gói & Đợi bàn giao'
    if (name === 'SHIPPING') return 'Đang vận chuyển'
    if (name === 'SHIPPED') return 'Đã giao cho khách'
    if (name === 'CANCELED') return 'Đã hủy đơn'
    if (name === 'REPORT') return 'Có sự cố'
  }

  const getStatusColor = (name = '') => {
    if (name === 'UNPAID') return 'warning'
    if (name === 'PROGRESSING') return 'processing'
    if (name === 'PACKED') return 'processing'
    if (name === 'READY_TO_SHIP') return 'warning'
    if (name === 'SHIPPING') return 'processing'
    if (name === 'SHIPPED') return 'success'
    if (name === 'CANCELED') return 'error'
    if (name === 'REPORT') return 'error'
  }

  const _getOrderDetail = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getExportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) setDataDetail(order)
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const _getOrdersEcom = async () => {
    try {
      if (paramsFilter.status === 'UNQUALIFIED') _getExportOrders()

      setLoadingTable(true)
      setOrders([])
      setSelectedRowKeys([])
      const res = await getOrdersEcom(paramsFilter)
      setLoadingTable(false)
      if (res.status === 200) {
        setOrders(res.data.data)
        setCountOrder(res.data.count)
        setCountOrderIsFast(res.data.count_is_fast)
        setStatusList([
          { name: 'Tất cả', key: '', count: res.data.count_all || 0 },
          { name: 'Chưa thanh toán', key: 'UNPAID', count: res.data.count_unpaid || 0 },
          { name: 'Đợi xác nhận', key: 'PROGRESSING', count: res.data.count_progressing || 0 },
          { name: 'Đợi đóng gói', key: 'PACKED', count: res.data.count_packed || 0 },
          { name: 'Không đủ tiêu chuẩn', key: 'UNQUALIFIED', count: countUnqualified },
          {
            name: 'Đã đóng gói & Đợi bàn giao',
            key: 'READY_TO_SHIP',
            count: res.data.count_ready_to_ship || 0,
          },
          { name: 'Đang vận chuyển', key: 'SHIPPING', count: res.data.count_shipping || 0 },
          { name: 'Đã giao cho khách', key: 'SHIPPED', count: res.data.count_shipped || 0 },
          { name: 'Đã huỷ đơn', key: 'CANCELED', count: res.data.count_canceled || 0 },
          { name: 'Có sự cố', key: 'REPORT', count: res.data.count_report || 0 },
        ])
      }
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const ModalScanOrder = ({ children, type = 'scan-by-code-order' }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [site, setSite] = useState()
    const [orderId, setOrderId] = useState('')

    useEffect(() => {
      if (!visible) {
        setSite()
        setOrderId('')
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          title={type === 'scan-by-code-order' ? 'Quét theo mã đơn' : 'Quét 100 đơn gần nhất'}
          onCancel={toggle}
          visible={visible}
          onOk={() => {
            if (site) {
              if (type === 'scan-by-code-order') {
                if (orderId) _reloadOrdersEcom({ id_site: site, order_id: orderId })
                else notification.error({ message: 'Vui lòng nhập số đơn hàng' })
              } else _scanOrders(site)
            } else notification.error({ message: 'Vui lòng chọn site' })
          }}
        >
          <div>
            <div>Chọn site</div>
            <Select
              suffixIcon={<SuffixIconCustom size="default" />}
              value={site}
              onChange={setSite}
              allowClear
              showSearch
              placeholder="Chọn site"
              style={{ width: '100%', marginBottom: 5 }}
              dropdownClassName="dropdown-select-custom"
            >
              {sites.map((site) => (
                <Select.Option value={site.id_site} key={site.id_site}>
                  {site.site_name || ''}
                </Select.Option>
              ))}
            </Select>
            <br />
            <br />
            {type === 'scan-by-code-order' && (
              <div>
                <div>Số đơn hàng</div>
                <Input
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  placeholder="Nhập số đơn hàng"
                  allowClear
                />
              </div>
            )}
          </div>
        </Modal>
      </>
    )
  }

  const _scanOrders = async (idSite = '') => {
    try {
      setLoadingTable(true)
      const res = await scanOrders(idSite)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getOrdersEcom()
          notification.success({ message: 'Quét đơn gần nhất thành công' })
        } else notification.error({ message: res.data.message || 'Quét đơn gần nhất thành công' })
      } else notification.error({ message: res.data.message || 'Quét đơn gần nhất thành công' })
    } catch (err) {
      setLoadingTable(false)
      console.log(err)
    }
  }

  const _getSites = async () => {
    try {
      const res = await getSites({ customer_id: dataUser.customer_id })
      if (res.status === 200) setSites(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const ModalIssues = () => {
    return orderDetail ? (
      <Modal
        width={700}
        style={{ top: 20 }}
        footer={null}
        visible={orderDetail}
        onCancel={() => setOrderDetail()}
        title={`Danh sách sự cố #${orderDetail.code}`}
      >
        <div>
          {orderDetail.issues &&
            orderDetail.issues.map((issue, index) => (
              <div style={{ color: 'red' }}>
                {index + 1}. {issue}
              </div>
            ))}
        </div>
      </Modal>
    ) : (
      ''
    )
  }

  const ModalNotiCreateOrdersEcom = () => {
    return resOrderEcom ? (
      <Modal
        width="70%"
        style={{ top: 20 }}
        footer={null}
        visible={resOrderEcom}
        onCancel={() => setResOrderEcom()}
        title="Thông báo"
      >
        <div>
          <b style={{ color: 'red', fontSize: 18 }}>
            {resOrderEcom.data && resOrderEcom.data.message}
          </b>
          <br />
          <br />
          {resOrderEcom.data &&
          resOrderEcom.data.data &&
          resOrderEcom.data.data.order_errors &&
          resOrderEcom.data.data.order_errors.length ? (
            <div>
              <b style={{ fontSize: 15 }}>Danh sách tạo đơn hàng xuất kho có lỗi xảy ra</b>
              <Table
                size="small"
                scroll={{ y: '60vh' }}
                columns={columnsOrderEcomCreated}
                dataSource={resOrderEcom.data.data.order_errors.map((order) => ({
                  ...order.order,
                  reject_reason: order.reject_reason || '',
                }))}
                pagination={false}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
    ) : (
      ''
    )
  }

  const _createOrdersEcom = async () => {
    try {
      setLoadingTable(true)
      const body = {
        code_ecommerces: orders
          .filter((order) => selectedRowKeys.includes(order.code_ecommerce))
          .map((order) => order.code_ecommerce),
      }
      const res = await createOrdersEcom(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Tạo đơn xuất kho cho các đơn đã chọn thành công!' })
          _getOrdersEcom()
        } else setResOrderEcom(res)
      } else setResOrderEcom(res)
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const ModalReloadOrder = () => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [loading, setLoading] = useState(false)

    const _reloadEcomOrder = async () => {
      try {
        await form.validateFields()
        setLoading(true)
        const body = form.getFieldsValue()
        const res = await reloadEcomOrder(body)
        setLoading(false)
        if (res.status === 200) {
          if (res.data.success) {
            toggle()
            _getOrdersEcom()
            notification.success({ message: 'Làm mới đơn hàng loạt thành công' })
          } else notification.error({ message: res.data.message })
        } else notification.error({ message: res.data.message })
      } catch (error) {
        setLoading(false)
      }
    }

    return (
      <>
        <Button onClick={toggle} type="primary" icon={<ReloadOutlined />}>
          Làm mới đơn hàng loạt
        </Button>
        <Modal
          confirmLoading={loading}
          onOk={_reloadEcomOrder}
          visible={visible}
          onCancel={toggle}
          title="Làm mới đơn hàng loạt"
        >
          <div>
            <Form form={form} layout="vertical">
              <Form.Item
                label="Site"
                name="id_site"
                rules={[{ message: 'Vui lòng chọn site', required: true }]}
              >
                <Select
                  suffixIcon={<SuffixIconCustom size="default" />}
                  allowClear
                  showSearch
                  placeholder="Chọn site"
                  style={{ width: '100%', marginBottom: 5 }}
                  dropdownClassName="dropdown-select-custom"
                >
                  {sites.map((site) => (
                    <Select.Option value={site.id_site} key={site.id_site}>
                      {site.site_name || ''}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Trạng thái"
                name="status"
                rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
              >
                <Select
                  suffixIcon={<SuffixIconCustom size="default" />}
                  allowClear
                  showSearch
                  placeholder="Chọn trạng thái"
                  style={{ width: '100%', marginBottom: 5 }}
                  dropdownClassName="dropdown-select-custom"
                >
                  {statusList
                    .filter((status) => status.key)
                    .map((status) => (
                      <Select.Option value={status.key}>{status.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    )
  }

  const _callShipperOrReadyShipLazada = async (
    type = 'ready-ship',
    is_confirm = false,
    package_weight = 0,
    package_length = 0,
    package_width = 0,
    package_height = 0
  ) => {
    try {
      var dataForm = formPackage.getFieldValue()
      var address = {
        ward_code: dataForm.ward_code || orderSapoSelected?.shipping_info?.ward_code,
        province_id: dataForm.province_id || orderSapoSelected?.shipping_info?.province_id,
        district_id: dataForm.district_id || orderSapoSelected?.shipping_info?.district_id,
      }

      const body = {
        orders: orders
          .filter((order) => selectedRowKeys.includes(order.code_ecommerce))
          .map((order) => ({
            id_site: order.id_site,
            order_id: order.code_ecommerce,
            platform: order.platform,
            package_height: package_height,
            package_length: package_length,
            package_weight: package_weight,
            package_width: package_width,
            warehouse_id: orderSapoSelected?.warehouse_id,
            cod: dataForm.cod,
            products: orderSapoSelected?.products,
            address: address,
            is_need_cod: dataForm.is_need_cod,
          })),
      }

      for (var i = 0; i < body.orders.length; i++) {
        if (body.orders[i].platform == 'Sapo' && !is_confirm) {
          _callShipperByPlatformSapo()
          return
        }
      }

      setLoadingTable(true)
      let res
      if (type === 'ready-ship') res = await readyShipLazada(body)
      else res = await callShipper(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message:
              type === 'ready-ship'
                ? 'Sẵn sàng giao hàng loạt thành công'
                : 'Gọi vận chuyển thành công',
          })
          setVisibleModalPackageCallShip(false)
          _getOrdersEcom()
        } else
          notification.error({
            message:
              res.data.message ||
              (type === 'ready-ship'
                ? 'Sẵn sàng giao hàng loạt đang có lỗi xảy ra'
                : 'Gọi vận chuyển đang có lỗi xảy ra'),
          })
      } else
        notification.error({
          message:
            res.data.message ||
            (type === 'ready-ship'
              ? 'Sẵn sàng giao hàng loạt đang có lỗi xảy ra'
              : 'Gọi vận chuyển đang có lỗi xảy ra'),
        })
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const _callShipperByPlatformSapo = async () => {
    try {
      const body = {
        orders: orders
          .filter((order) => selectedRowKeys.includes(order.code_ecommerce))
          .map((order) => ({
            id_site: order.id_site,
            order_id: order.code_ecommerce,
            shipping_info: order.shipping_info,
          })),
      }
      if (body.orders.length > 1) {
        notification.error({
          message: 'Vui lòng xác nhận lần lượt từng đơn hàng với nền tảng Sapo',
        })
        return
      }

      if (body.orders.length == 1) {
        var _index = orders.findIndex((order) => selectedRowKeys.includes(order.code_ecommerce))
        if (_index >= 0) setOrderSapoSelected({ ...orders[_index] })

        if (body.orders[0].shipping_info?.province_id) {
          _getDistrict({ province_id: body.orders[0].shipping_info?.province_id })
        }
        if (body.orders[0].shipping_info?.district_id) {
          _getWard({ district_id: body.orders[0].shipping_info?.district_id })
        }
        formPackage.setFieldsValue({
          package_height: body.orders[0].total_height || 0,
          package_width: body.orders[0].total_width || 0,
          package_weight: body.orders[0].total_weight || 0,
          package_length: body.orders[0].total_length || 0,
          district_id: body.orders[0].shipping_info?.district_id,
          province_id: body.orders[0].shipping_info?.province_id,
          ward_code: body.orders[0].shipping_info?.ward_code,
          address: body.orders[0].shipping_info?.to_address,
          cod: body.orders[0].shipping_info?.cod,
          is_need_cod: true,
        })
        setVisibleModalPackageCallShip(true)
      }
    } catch (e) {}
  }

  const _getProductsById = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getExportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) return order.products
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
      return []
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
      return []
    }
  }

  const _resubmitExportOrder = async (record) => {
    try {
      setLoadingTable(true)
      const products = await _getProductsById(record.order_id)
      const body = { ...record, products: products }
      delete body.basket_ids
      const res = await updateExportOrders(record.order_id, body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getExportOrders()
          notification.success({ message: 'Kích hoạt xuất kho thành công' })
        } else
          notification.error({
            message: res.data.message || 'Kích hoạt xuất kho thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Kích hoạt xuất kho thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }
  const _deleteExportOrder = async (recordId, note) => {
    const body = { order_id: [recordId], note: note }
    try {
      setLoadingTable(true)
      const res = await deleteExportOrders(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getExportOrders()

          notification.success({ message: 'Xóa đơn hàng thành công' })
        } else
          notification.error({
            message: res.data.message || 'Xóa đơn hàng thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Xóa đơn hàng thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _reloadOrdersEcom = async (body = {}) => {
    try {
      setLoadingTable(true)
      const res = await reloadOrdersEcom(body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: `Tải lại đơn ${body.order_id || ''} thành công` })
          _getOrdersEcom()
        } else notification.error({ message: res.data.message || 'Tải lại đơn đơn xảy ra lỗi' })
      } else notification.error({ message: res.data.message || 'Tải lại đơn đơn xảy ra lỗi' })
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const printTrackingNumberByHtml = (link = '') => {
    const printEl = document.getElementById(ID_DIV_PRINT)
    if (printEl) {
      var iframeEl = document.createElement('iframe')
      iframeEl.src = link
      iframeEl.frameBorder = 0
      iframeEl.style.minHeight = '100vh'
      iframeEl.style.width = '100%'
      iframeEl.style.borderColor = 'none'
      iframeEl.style.outline = 'none'
      iframeEl.style.boxShadow = 'none'
      printEl.appendChild(iframeEl)
      printJS(ID_DIV_PRINT, 'html')
      iframeEl.remove()
    }
  }

  const _getTrackingNumber = async (order) => {
    try {
      let linkTrackingNumber = ''
      if (
        order.shipping_info &&
        order.shipping_info.link_tracking_number &&
        order.shipping_info.link_tracking_number.includes('https://')
      )
        linkTrackingNumber = order.shipping_info.link_tracking_number
      else {
        setLoadingTable(true)
        const body = {
          customer_order_code: order.customer_order_code,
          customer_id: order.customer_id,
        }
        const res = await getTrackingNumberOrderEcom(body)
        setLoadingTable(false)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'In mã vận đơn thành công' })
            linkTrackingNumber = res.data.data
            _getOrdersEcom()
          } else notification.error({ message: res.data.message || 'In mã vận đơn xảy ra lỗi' })
        } else notification.error({ message: res.data.message || 'In mã vận đơn xảy ra lỗi' })
      }

      if (linkTrackingNumber) {
        const arrString = linkTrackingNumber.split('.')
        const typePrint = arrString[arrString.length - 1]
        if (typePrint) {
          if (typePrint === 'html') {
            printTrackingNumberByHtml(linkTrackingNumber)
            return
          }
          if (linkTrackingNumber.includes('sellercenter.lazada.vn')) {
            window.open(linkTrackingNumber, '_blank')
            return
          }

          printJS({
            printable: linkTrackingNumber,
            type: 'pdf',
            showModal: true,
            modalMessage: 'Đang in...',
          })
        }
      }
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const _filterByOption = (attribute = '', value) => {
    if (value !== undefined) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _searchByOrder = (e) => {
    const value = e.target.value

    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      if (value) paramsFilter.search = value.trim()
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 450)
  }

  const _searchByProduct = (e) => {
    const value = e.target.value

    setBarcodeSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      if (value) paramsFilter.product_barcode = value.trim()
      else delete paramsFilter.product_barcode

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 450)
  }

  const _enumExportOrderProcess = async () => {
    try {
      const res = await enumExportOrderProcess()
      if (res.status === 200) {
        const platform = res.data.data
        const unqualified = platform.find((e) => e.value === 'WAITING')
        if (unqualified) setCountUnqualified(unqualified.total)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _enumExportOrderProcess()
    _getWard()
    _getDistrict()
    _getProvince()
  }, [])

  useEffect(() => {
    if (dataUser.customer_id) _getSites()
  }, [dataUser])

  useEffect(() => {
    _getOrdersEcom()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['body-import']}>
      <div id={ID_DIV_PRINT}></div>
      <BackToTop />
      <ModalIssues />
      <ModalNotiCreateOrdersEcom />
      <ModalFormPackageCallShip />
      <ModalViewProduct dataDetail={dataDetail} setDataDetail={setDataDetail} />
      <Row className="page-title" justify="space-between" align="middle">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
          Quản lý đơn thương mại điện tử
        </span>
        <Space>
          <ModalReloadOrder />
          <Button type="primary" onClick={_getOrdersEcom} icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <ModalScanOrder>
            <Button>Quét theo mã đơn</Button>
          </ModalScanOrder>
          <ModalScanOrder type="scan-200-order">
            <Button>Quét 100 đơn gần nhất</Button>
          </ModalScanOrder>
        </Space>
      </Row>
      <Row gutter={[15, 5]}>
        <Col xs={24} xl={8}>
          <Input
            placeholder="Tìm kiếm theo số đơn hàng"
            allowClear
            prefix={<SearchOutlined />}
            onChange={_searchByOrder}
            value={valueSearch}
          />
        </Col>
        <Col xs={24} xl={6}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col xs={24} xl={5}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={paramsFilter['platform'] || undefined}
            onChange={(value) => _filterByOption('platform', value)}
            allowClear
            showSearch
            placeholder="Lọc theo nền tảng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {PLATFORMS.map((platform) => (
              <Select.Option value={platform} key={platform}>
                {platform}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={paramsFilter['id_site'] || undefined}
            onChange={(value) => _filterByOption('id_site', value)}
            allowClear
            showSearch
            placeholder="Lọc theo site"
            style={{ width: '100%', marginBottom: 5 }}
            dropdownClassName="dropdown-select-custom"
          >
            {sites.map((site) => (
              <Select.Option value={site.id_site} key={site.id_site}>
                {site.site_name || ''}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={8}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={paramsFilter['id_status_warehouse'] || undefined}
            onChange={(value) => _filterByOption('id_status_warehouse', value)}
            allowClear
            placeholder="Lọc theo trạng thái kho"
            style={{ width: '100%', marginBottom: 5 }}
            dropdownClassName="dropdown-select-custom"
          >
            <Select.Option value={1}>Chưa có</Select.Option>
            <Select.Option value={2}>Đợi xuất kho</Select.Option>
            <Select.Option value={3}>Đang xuất kho</Select.Option>
            <Select.Option value={4}>Đợi đóng gói</Select.Option>
            <Select.Option value={5}>Đợi bàn giao</Select.Option>
          </Select>
        </Col>
        <Col xs={24} xl={6}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={
              paramsFilter['is_update_order'] !== undefined
                ? paramsFilter['is_update_order']
                : undefined
            }
            onChange={(value) => _filterByOption('is_update_order', value)}
            allowClear
            placeholder="Lọc theo trạng thái chỉnh sửa"
            style={{ width: '100%', marginBottom: 5 }}
            dropdownClassName="dropdown-select-custom"
          >
            <Select.Option value={false}>Đơn không sửa</Select.Option>
            <Select.Option value={true}>Đơn bị sửa</Select.Option>
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={paramsFilter['is_fast'] !== undefined ? paramsFilter['is_fast'] : undefined}
            onChange={(value) => _filterByOption('is_fast', value)}
            allowClear
            placeholder="Lọc theo loại giao hàng"
            style={{ width: '100%', marginBottom: 5 }}
            dropdownClassName="dropdown-select-custom"
          >
            <Select.Option value={true}>Hỏa tốc</Select.Option>
            <Select.Option value={false}>Bình thường</Select.Option>
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={paramsFilter['is_export'] !== undefined ? paramsFilter['is_export'] : undefined}
            onChange={(value) => _filterByOption('is_export', value)}
            allowClear
            placeholder="Lọc theo trạng thái phiếu"
            style={{ width: '100%', marginBottom: 5 }}
            dropdownClassName="dropdown-select-custom"
          >
            <Select.Option value={true}>Đã lên phiếu xuất</Select.Option>
            <Select.Option value={false}>Chưa lên phiếu xuất</Select.Option>
          </Select>
        </Col>
        <Col xs={24} xl={8}>
          <Input
            placeholder="Tìm kiếm theo mã sản phẩm"
            allowClear
            prefix={<SearchOutlined />}
            onChange={_searchByProduct}
            value={barcodeSearch}
          />
        </Col>
        <Col xs={24} xl={8}>
          <Row wrap={false} align="middle">
            <InputNumber
              onPressEnter={handleFindByPrices}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              value={fromPrice}
              onChange={setFromPrice}
              controls={false}
              style={{ width: '100%' }}
              placeholder="From price"
            />
            <div style={{ margin: '0px 7px' }}>~</div>
            <InputNumber
              onPressEnter={handleFindByPrices}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              value={toPrice}
              onChange={setToPrice}
              controls={false}
              style={{ width: '100%', marginRight: 5 }}
              placeholder="To price"
            />
            <Button onClick={handleFindByPrices} type="primary">
              Tìm
            </Button>
          </Row>
        </Col>
        <Col xs={24} xl={8}>
          <Select
            suffixIcon={<SuffixIconCustom size="default" />}
            value={
              paramsFilter['is_missing_inventory'] !== undefined
                ? paramsFilter['is_missing_inventory']
                : undefined
            }
            onChange={(value) => _filterByOption('is_missing_inventory', value)}
            allowClear
            placeholder="Lọc theo tồn kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            <Select.Option value={false}>Đủ tồn kho</Select.Option>
            <Select.Option value={true}>Không đủ tồn kho</Select.Option>
          </Select>
        </Col>
      </Row>
      <div style={{ marginTop: 5, fontWeight: 700, fontSize: 18 }}>
        Đơn hỏa tốc đợi xác nhận: {countOrderIsFast}
      </div>
      <Tabs
        tabBarStyle={{ height: 55, marginBottom: 5, marginTop: 20 }}
        activeKey={paramsFilter.status}
        onChange={(value) => _filterByOption('status', value)}
      >
        {statusList.map((status) => (
          <TabPane
            disabled={loadingTable}
            tab={
              <Badge showZero count={status.count} overflowCount={99999} offset={[5, -8]}>
                <div>{status.name}</div>
              </Badge>
            }
            key={status.key}
          />
        ))}
      </Tabs>

      {selectedRowKeys.length ? (
        <Space style={{ marginBottom: 5 }}>
          {(!paramsFilter.status ||
            (['UNPAID', 'PROGRESSING'].includes(paramsFilter.status) &&
              paramsFilter.status !== 'PACKED')) && (
            <Popconfirm
              onConfirm={() => _callShipperOrReadyShipLazada('call-shipper')}
              title={
                <div>
                  Bạn có muốn xác nhận các đơn{' '}
                  {selectedRowKeys.map((key) => (
                    <b>#{key}, </b>
                  ))}{' '}
                  không?
                </div>
              }
            >
              <Button type="primary">Xác nhận đơn hàng loạt (Bước 1)</Button>
            </Popconfirm>
          )}
          {(!paramsFilter.status ||
            (!['UNPAID', 'PROGRESSING'].includes(paramsFilter.status) &&
              paramsFilter.status === 'PACKED')) && (
            <Popconfirm
              onConfirm={_createOrdersEcom}
              title={
                <div>
                  Bạn có muốn tạo các đơn xuất kho cho các đơn{' '}
                  {selectedRowKeys.map((key) => (
                    <b>#{key}, </b>
                  ))}{' '}
                  không?
                </div>
              }
            >
              <Button type="primary" style={{ backgroundColor: 'green', borderColor: 'green' }}>
                Tạo đơn xuất kho hàng loạt (Bước 2)
              </Button>
            </Popconfirm>
          )}
          {(['PACKED', 'PROGRESSING'].includes(paramsFilter.status) || !paramsFilter.status) && (
            <ModalAddProductsEcom>
              <Button type="primary" style={{ backgroundColor: 'orange', borderColor: 'orange' }}>
                Thêm sản phẩm vào đơn hàng loạt
              </Button>
            </ModalAddProductsEcom>
          )}
          {/* {!['UNPAID', 'PROGRESSING'].includes(paramsFilter.status) &&
                paramsFilter.status !== 'PACKED' && (
                  <Popconfirm
                    title="Bạn có muốn sẵn sàng giao hàng loạt không?"
                    onConfirm={() => _callShipperOrReadyShipLazada()}
                  >
                    <Button
                      style={{ backgroundColor: 'orange', borderColor: 'orange' }}
                      type="primary"
                    >
                      Sẵn sàng giao hàng loạt (Bước 3)
                    </Button>
                  </Popconfirm>
                )} */}
        </Space>
      ) : (
        ''
      )}

      {paramsFilter.status !== 'UNQUALIFIED' ? (
        <Table
          className="table-disable-hover"
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (keys) => setSelectedRowKeys(keys),
          }}
          rowClassName={(record) =>
            record.issues && record.issues.length && 'row-order-ecommerce-issue'
          }
          rowKey="code_ecommerce"
          size={SIZE_TABLE}
          scroll={{ x: 2400, y: '65vh' }}
          loading={loadingTable}
          columns={columns}
          dataSource={orders}
          pagination={{
            pageSizeOptions: [20, 40, 50, 60, 80, 100, 200, 300],
            position: POSITION_TABLE,
            total: countOrder,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            showQuickJumper: true,
            onChange(page, pageSize) {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
          }}
          expandable={{
            expandedRowRender: (order) => {
              return (
                <div>
                  <Row align="middle">
                    <h3 style={{ textAlign: 'start', fontWeight: 600, marginRight: 8 }}>
                      Danh sách sản phẩm
                    </h3>
                    <ModalAddProductEcom record={order}>
                      <Button
                        type="primary"
                        style={{ backgroundColor: 'green', borderColor: 'green', marginBottom: 5 }}
                      >
                        Thêm mã
                      </Button>
                    </ModalAddProductEcom>
                  </Row>
                  <Table
                    style={{ width: '100%' }}
                    size="small"
                    columns={columnsDetail.map((column) => {
                      if (column.key === 'code')
                        return {
                          ...column,
                          render: (text, record) => (
                            <div>
                              <b>#{record.barcode || ''}</b>
                              <ModalUpdateBarcode order={order} record={record}>
                                <Button
                                  type="primary"
                                  style={{ backgroundColor: 'green', borderColor: 'green' }}
                                >
                                  Cập nhật
                                </Button>
                              </ModalUpdateBarcode>
                              <Popconfirm
                                onConfirm={() => {
                                  let body = {
                                    order_id: order.code_ecommerce,
                                    ecommerce_barcode: record.ecommerce_barcode,
                                  }
                                  _deleteProductEcom(body)
                                }}
                                title="Bạn có muốn xóa sản phẩm này không?"
                              >
                                <Button type="primary" danger style={{ width: 90, marginTop: 5 }}>
                                  Xóa
                                </Button>
                              </Popconfirm>
                            </div>
                          ),
                        }

                      return column
                    })}
                    dataSource={order.products}
                    pagination={false}
                  />
                </div>
              )
            },
          }}
        />
      ) : (
        <Table
          rowKey="order_id"
          size={SIZE_TABLE}
          style={{ width: '100%' }}
          scroll={{ x: 3000, y: '66vh' }}
          loading={loadingTable}
          columns={columnsExport.map((column) => {
            if (column.key === 'stt') {
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                width: 50,
                fixed: 'left',
              }
            }
            if (column.key === 'code') {
              return {
                ...column,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'code'),
                render: (text, record) =>
                  isAcceptPermissions([PERMISSIONS.cap_nhat_phieu_xuat_kho]) ? (
                    <Link
                      to={{
                        pathname: ROUTES.EXPORT_WAREHOUSE_UPDATE + '/' + record.order_id,
                        state: record,
                      }}
                    >
                      #{text}
                    </Link>
                  ) : (
                    <b>#{text}</b>
                  ),
              }
            }
            if (column.dataIndex === 'total_cost') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'total_cost'),
                render: (text) => <span>{formatCash(text)}</span>,
              }
            }

            if (column.key === 'customer_order_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a, b, 'customer_order_code'),
              }
            }

            if (column.key === 'code') {
              return {
                ...column,
                fixed: 'left',
                sorter: (a, b) => compare(a, b, 'code'),
              }
            }
            if (column.key === 'warehouse_info') {
              return {
                ...column,
                sorter: (a, b) => compare(a.warehouse_info, b.warehouse_info, 'name'),
                render: (warehouse_info) => warehouse_info && warehouse_info.name,
              }
            }
            if (column.key === 'car_number') {
              return {
                ...column,
                render: (text, record) => record.shipping_info && record.shipping_info.car_number,
              }
            }
            if (column.key === 'shipping_company_name') {
              return {
                ...column,
                render: (shipping_info, record) =>
                  record.shipping_info && record.shipping_info.shipping_company_name,
              }
            }

            if (column.key === 'to_name') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'to_name'),
                render: (shipping_info) => shipping_info && shipping_info.to_name,
              }
            }
            if (column.key === 'transport_date') {
              return {
                ...column,
                sorter: (a, b) => {
                  const aMoment = a.transport_date ? moment(a.transport_date).unix() : 0
                  const bMoment = b.transport_date ? moment(b.transport_date).unix() : 0
                  return aMoment - bMoment
                },
                render: (text, record) => {
                  return (
                    text.transport_date && moment(text.transport_date).format('DD/MM/YYYY HH:mm')
                  )
                },
              }
            }
            if (column.key === 'receive_reason') {
              return {
                ...column,
                render: (text, record) => {
                  if (record.is_received === false) {
                    return <ModalViewReasons record={record} />
                  }
                },
              }
            }
            if (column.key === 'fee') {
              return {
                ...column,
                render: (text, record) => <ModalViewFees fees={record.fees} />,
              }
            }
            if (column.key === 'products_list') {
              return {
                ...column,
                render: (text, record) => (
                  <div>
                    <a onClick={() => _getOrderDetail(record.order_id)}>Xem chi tiết</a>
                  </div>
                ),
              }
            }

            if (column.key === 'shipping_code') {
              return {
                ...column,
                sorter: (a, b) => compare(a.shipping_info, b.shipping_info, 'shipping_code'),
                render: (shipping_info) => {
                  return shipping_info && shipping_info.shipping_code
                },
              }
            }
            if (column.dataIndex === 'creator_id') {
              return {
                ...column,
                render: (text, record) => record.creator_info?.fullname,
                sorter: (a, b) => compare(a, b, 'creator_id'),
              }
            }

            if (column.key === 'export_status') {
              return {
                ...column,
                render: (text, record) => (
                  <Tag
                    color={
                      (!record.is_received && 'warning') ||
                      (record.processing === 'complete' && 'success') ||
                      'processing'
                    }
                  >
                    {(!record.is_received && 'Chưa tiếp nhận') ||
                      (record.processing === 'complete' && 'Đã xuất kho') ||
                      'Đang thực hiện'}
                  </Tag>
                ),
              }
            }
            if (column.key === 'export_status') {
              return {
                ...column,
                render: (export_status) => export_status && <Tag>{export_status}</Tag>,
              }
            }
            if (column.key === 'shipping_bill') {
              return {
                ...column,
                render: (shipping_info, record) => {
                  const src =
                    (shipping_info &&
                      shipping_info.shipping_bill &&
                      shipping_info.shipping_bill[0]) ||
                    ''
                  return (
                    <Row
                      align="middle"
                      justify="center"
                      style={{
                        width: '100%',
                        // minWidth: 160
                      }}
                    >
                      <Col>
                        {src && (
                          <Popover
                            placement="left"
                            content={
                              <img src={src} style={{ height: '450px', width: '450px' }} alt="" />
                            }
                          >
                            <img
                              src={src}
                              style={{ height: '100%', maxHeight: '40px', width: '100%' }}
                              alt=""
                            />
                          </Popover>
                        )}
                      </Col>
                    </Row>
                  )
                },
              }
            }

            if (column.key === 'delete_button') {
              return {
                ...column,
                width: 380,
                render: (text, record) => (
                  <div>
                    <Space>
                      <Permission permissions={[PERMISSIONS.xoa_phieu_xuat_kho]}>
                        <ModalConfirmDelete
                          record={record}
                          onDelete={(note) => _deleteExportOrder(record?.order_id, note)}
                        >
                          <Button type="primary" danger icon={<DeleteOutlined />}></Button>
                        </ModalConfirmDelete>
                      </Permission>
                      <Button
                        onClick={() => _resubmitExportOrder(record)}
                        type="primary"
                        style={{ display: record.is_received && 'none' }}
                      >
                        Kích hoạt xuất kho
                      </Button>
                      <Button type="primary">
                        <Link
                          target="_blank"
                          to={`${ROUTES.IMPORT_WAREHOUSE_CREATE}?order_export_id=${record.order_id}`}
                        >
                          Tạo phiếu nhập kho
                        </Link>
                      </Button>
                    </Space>
                  </div>
                ),
              }
            }

            return column
          })}
          dataSource={exportOrders}
          pagination={{
            pageSizeOptions: [20, 40, 50, 60, 80, 100, 200, 300],
            position: POSITION_TABLE,
            total: countExportOrder,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            onChange(page, pageSize) {
              setParamsFilter({
                ...paramsFilter,
                page: page,
                page_size: pageSize,
              })
            },
          }}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      )}
    </div>
  )
}
