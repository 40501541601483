import React, { useEffect, useState } from 'react'
import styles from 'views/login/login.module.scss'
import { Link, useHistory, useLocation } from 'react-router-dom'

import banner from 'assets/images/SGL_banner.png'

// antd
import { Col, Row, Form, Input, Button, notification } from 'antd'
import { ROUTES } from 'consts'
import { verifyOTP } from 'apis/auth'

export default function VerifyOtp() {
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const queries = new URLSearchParams(location.search)
  const type = queries.get('type')
  const username = queries.get('username')

  const [loading, setLoading] = useState(false)

  const _verifyOtp = async () => {
    await form.validateFields()
    const otp = form.getFieldValue('otp_code')
    const body = {
      username: localStorage.getItem('userName') || username || '',
      otp_code: otp,
    }
    let res
    try {
      setLoading(true)
      res = await verifyOTP(body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          if (type === 'register') {
            notification.success({ message: 'Đăng ký tài khoản thành công' })
            history.push(ROUTES.LOGIN)
          } else {
            notification.success({ message: 'Xác minh mã OTP thành công' })
            history.push(`${ROUTES.RECOVERY_PASSWORD}?username=${username}`)
          }
        } else notification.error({ message: res.data.message || 'Xác minh mã OTP thất bại' })
      } else notification.error({ message: res.data.message || 'Xác minh mã OTP thất bại' })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!username) history.push(ROUTES.LOGIN)
  }, [])

  return (
    <div className={styles['login']}>
      <Row>
        <Col xs={24} lg={12} xl={10}>
          <img src={banner} alt="" style={{ minWidth: '1024px' }} />
          <div className={styles['login-form']}>
            <h2 align="center">Xác minh mã OTP</h2>
            <h3 align="center">Mã OTP đã được gửi vào email của bạn</h3>
            <Form form={form} onFinish={_verifyOtp} layout="vertical" requiredMark={false}>
              <Form.Item
                name="otp_code"
                label="Mã OTP"
                rules={[{ required: true, message: 'Vui lòng nhập mã xác thực OTP' }]}
                style={{ marginBottom: 5 }}
              >
                <Input
                  allowClear
                  size="large"
                  placeholder="Nhập mã xác thực OTP"
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Link
                style={{ display: 'block', textAlign: 'right', color: 'black' }}
                to={ROUTES.LOGIN}
              >
                Trở về đăng nhập
              </Link>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    marginTop: 20,
                    width: '100%',
                  }}
                  loading={loading}
                >
                  Xác nhận
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
