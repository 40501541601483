const columns = [
  {
    title: 'STT',
    key: 'stt',
    width: 55,
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Ảnh',
    key: 'image',
    width: 85,
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    width: 150,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 150,
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'address',
    width: 250,
  },
  {
    title: 'Giám đốc CN',
    dataIndex: 'manager_name',
    width: 150,
  },
  {
    title: 'Người tạo',
    key: 'creator_info',
    width: 150,
  },
  {
    title: 'Ngày tạo',
    key: 'create_date',
    width: 110,
  },
  {
    title: 'Hành động',
    key: 'action',
    width: 100,
  },
]

export default columns
