import React, { useState } from 'react'
import styles from 'views/login/login.module.scss'
import { Link, useHistory } from 'react-router-dom'

// img
import banner from 'assets/images/SGL_banner.png'

// antd
import { Col, Row, Form, Input, Button, notification } from 'antd'
import { ACTION, ROUTES } from 'consts'
import { UserOutlined } from '@ant-design/icons'
import { getOTP } from 'apis/auth'
import { useDispatch } from 'react-redux'

export default function ForgetPassword() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const _submitForm = async () => {
    await form.validateFields()
    const formData = form.getFieldsValue()

    let res
    try {
      setLoading(true)
      res = await getOTP(formData)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: res.data.data || 'Gửi mã OTP thành công', duration: 5 })
          history.push(`${ROUTES.VERIFY_OTP}?type=forget_password&username=${formData.username}`)
        } else {
          notification.error({
            message: res.data.mess || res.data.message || 'Gửi mã OTP thất bại',
          })
        }
      } else {
        notification.error({
          message: res.data.mess || res.data.message || 'Gửi mã OTP thất bại',
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div className={styles['login']}>
      <Row>
        <Col xs={24} lg={12} xl={10}>
          <img src={banner} alt="" style={{ minWidth: '1024px' }} />
          <div className={styles['login-form']}>
            <h2 align="center">Quên mật khẩu</h2>
            <h3 align="center">Nhập tên tài khoản của bạn để đặt lại mật khẩu</h3>
            <Form form={form} onFinish={_submitForm} layout="vertical" requiredMark={false}>
              <Form.Item
                name="username"
                label="Tên tài khoản"
                rules={[{ required: true, message: 'Vui lòng nhập tài khoản' }]}
                style={{ marginBottom: 5 }}
              >
                <Input
                  size="large"
                  placeholder="Nhập tài khoản"
                  prefix={<UserOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Link
                style={{
                  display: 'block',
                  textAlign: 'right',
                  color: 'black',
                }}
                to={ROUTES.LOGIN}
              >
                Quay về trang đăng nhập
              </Link>

              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  Xác nhận
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
