import React, { memo } from 'react'

//antd
import { Input, Row, Table } from 'antd'
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons'

export default memo(function Contact({ contacts = [], setContacts }) {
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: 'Họ tên',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.name}
            placeholder="Nhập họ tên"
            onBlur={(e) => handleUpdateItem('name', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('name', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Chức danh',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.position}
            placeholder="Nhập chức danh"
            onBlur={(e) => handleUpdateItem('position', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('position', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Bộ phận',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.department}
            placeholder="Nhập bộ phận"
            onBlur={(e) => handleUpdateItem('department', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('department', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Email',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.email}
            placeholder="Nhập email"
            onBlur={(e) => handleUpdateItem('email', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('email', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Địa chỉ',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.address}
            placeholder="Nhập địa chỉ"
            onBlur={(e) => handleUpdateItem('address', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('address', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Số điện thoại 1',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.phones[0]}
            placeholder="Nhập số điện thoại 1"
            onBlur={(e) => handleUpdatePhoneItem(e.target.value, index, 0)}
            onPressEnter={(e) => handleUpdatePhoneItem(e.target.value, index, 0)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Số điện thoại 2',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.phones[1]}
            placeholder="Nhập số điện thoại 2"
            onBlur={(e) => handleUpdatePhoneItem(e.target.value, index, 1)}
            onPressEnter={(e) => handleUpdatePhoneItem(e.target.value, index, 1)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Ghi chú',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.note}
            placeholder="Nhập ghi chú"
            onBlur={(e) => handleUpdateItem('note', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('note', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: '',
      render: (text, record, index) => (
        <CloseCircleFilled
          onClick={() => handleDeleteItem(index)}
          style={{ fontSize: 20, cursor: 'pointer', color: 'red' }}
        />
      ),
      width: 38,
    },
  ]

  const handleUpdateItem = (key, value, index) => {
    const contactsNew = [...contacts]
    contactsNew[index][key] = value
    setContacts([...contactsNew])
  }

  const handleUpdatePhoneItem = (value, indexContact, indexPhone) => {
    const contactsNew = [...contacts]
    contactsNew[indexContact].phones[indexPhone] = value
    setContacts([...contactsNew])
  }

  const handleAddItem = () => {
    const initItem = {
      name: '',
      position: '',
      department: '',
      email: '',
      address: '',
      phones: ['', ''],
      note: '',
    }
    contacts.push(initItem)
    setContacts([...contacts])
  }

  const handleDeleteItem = (index) => {
    if (index !== -1) {
      const contactsNew = [...contacts]
      contactsNew.splice(index, 1)
      setContacts([...contactsNew])
    }
  }

  return (
    <div className="card">
      <b>NGƯỜI LIÊN HỆ (ÔNG/ BÀ)</b>
      <Table
        pagination={false}
        bordered={true}
        dataSource={contacts}
        columns={columns}
        size="small"
        footer={() => (
          <Row
            onClick={handleAddItem}
            justify="center"
            align="middle"
            style={{ color: '#6C757D', cursor: 'pointer' }}
          >
            <PlusOutlined style={{ marginRight: 5, fontSize: 10 }} />
            <div>Thêm mới người liên hệ</div>
          </Row>
        )}
      />
    </div>
  )
})
