import { ACTION } from 'consts'
import jwt_decode from 'jwt-decode'
import { clearLocalStorage } from 'utils'

const initialState = {
  dataUser: {},
  loading: false,
}

var login = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN: {
      const token = action.data.accessToken
      const reToken = action.data.refreshToken
      if (token) localStorage.setItem('accessToken', token)
      if (reToken) localStorage.setItem('refreshToken', reToken)

      const data = token && jwt_decode(token)

      return data ? { ...state, dataUser: { ...data } } : { ...state, dataUser: {} }
    }
    case ACTION.LOGOUT: {
      clearLocalStorage()
      return {
        ...state,
        dataUser: {},
      }
    }

    case ACTION.LOADING: {
      state.loading = action.data
      return state
    }

    default:
      return state
  }
}
export default login
