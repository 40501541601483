import React, { useRef, useState } from 'react'
//ant
import { Button, Checkbox, Dropdown, Form } from 'antd'
import filter from 'assets/icons/filter.svg'
import styles from './ordercollection.module.scss'

export default function DropdownButton({ orderType, is_shipping, status, setParamsFilter }) {
  const [isFiltering, setIsFiltering] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [form] = Form.useForm()
  const _header = () => {
    switch (orderType) {
      case 'is_checked':
        return 'Kiểm hàng'
      case 'is_imported':
        return 'Nhập hàng vào vị trí'
      default:
        return ''
    }
  }
  const _filterOrders = async () => {
    const formData = form.getFieldsValue(true)
    const formDataValues = Object.values(formData)

    let checkboxNameTrue = []
    for (const property in formData) {
      if (formData[property] === true) {
        checkboxNameTrue.push(property)
      }
    }
    checkboxNameTrue = checkboxNameTrue.join('-')

    if (formDataValues.indexOf(true) > -1) {
      setIsFiltering(true)

      setParamsFilter((paramsFilter) => {
        paramsFilter[orderType] = true
        paramsFilter[status] = checkboxNameTrue
        return { ...paramsFilter }
      })
    } else {
      //Không có checkbox nào checked
      setIsFiltering(false)
      setParamsFilter((paramsFilter) => {
        delete paramsFilter[orderType]
        delete paramsFilter[status]
        return { ...paramsFilter }
      })
    }
  }
  const _clearFilter = () => {
    setParamsFilter((paramsFilter) => {
      delete paramsFilter[orderType]
      delete paramsFilter[status]
      return { ...paramsFilter }
    })
    form.resetFields()
    setIsFiltering(false)
    setIsDropdownVisible(false)
  }
  const buttonRef = useRef(null)
  const contentRef = useRef(null)
  const closeOpenMenus = (e) => {
    if (e.target === buttonRef.current) return false
    if (contentRef.current && isDropdownVisible && !contentRef.current.contains(e.target)) {
      setIsDropdownVisible(!isDropdownVisible)
    }
  }
  document.addEventListener('mousedown', closeOpenMenus)

  const menu = (
    <div ref={contentRef}>
      <Form
        style={{ padding: 10, backgroundColor: 'white' }}
        form={form}
        onFieldsChange={_filterOrders}
      >
        <Form.Item name="WAITING" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>Đang chờ</Checkbox>
        </Form.Item>
        <Form.Item name="PROCESSING" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>Đang thực hiện</Checkbox>
        </Form.Item>
        <Form.Item name="COMPLETE" valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>Đã thực hiện</Checkbox>
        </Form.Item>
        {/* <Form.Item name="REPORT" valuePropName="checked" style={{ marginBottom: 10 }}>
          <Checkbox>Có sự cố</Checkbox>
        </Form.Item> */}
        <Button size="small" onClick={_clearFilter}>
          Clear
        </Button>
      </Form>
    </div>
  )
  return (
    <div
      style={{
        borderRadius: '5px 5px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 35,
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 5,
        backgroundColor: '#f3f3f3',
      }}
    >
      {_header()}

      <Dropdown
        overlay={menu}
        visible={isDropdownVisible}
        onClick={() => {
          setIsDropdownVisible(!isDropdownVisible)
        }}
      >
        <div
          style={{ border: isFiltering ? '1px solid #13D8E5' : '' }}
          className={styles['dropdown-wrapper']}
        >
          <img src={filter} ref={buttonRef} style={{ width: 11, height: 11 }} alt="" />
        </div>
      </Dropdown>
    </div>
  )
}
