import React, { useState, useEffect, useRef } from 'react'
import style from './product.module.scss'
import { ROUTES, PAGE_SIZE_OPTIONS, ACTION, SIZE_TABLE, PERMISSIONS, IMAGE_DEFAULT } from 'consts'
import { useHistory, Link } from 'react-router-dom'
import {
  formatCash,
  handleGetDataExportExcel,
  isAcceptPermissions,
  saveFiltersToLocalStorage,
} from 'utils'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'
import columnsProduct from './columns'
import SettingColumns from 'components/setting-columns'
import Export from 'components/export-csv'
import ImportFile from 'components/import-csv'
import Permission from 'views/permission'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'
import ModalOptionsPrint from 'components/modal-opions-print'
import QrCodeProduct from 'components/qr-code-product'

//antd
import {
  Row,
  Popconfirm,
  Button,
  Input,
  Select,
  DatePicker,
  Space,
  Table,
  Col,
  notification,
  Tooltip,
} from 'antd'

//icons
import { DeleteOutlined, PrinterOutlined, WarningOutlined } from '@ant-design/icons'

//apis
import { getProducts, deleteProducts } from 'apis/product'
import { getWarehouses } from 'apis/warehouse'
import { getCategories } from 'apis/categories-product'
import { uploadProduct } from 'apis/upload'

export default function Products() {
  const history = useHistory()
  const typingTimeoutRef = useRef()
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const KEY_FILTER = 'productFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [products, setProducts] = useState([])
  const [statistical, setStatistical] = useState(null)
  const [countProduct, setCountProduct] = useState(0)

  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100 }
  )

  const [valueSearch, setValueSearch] = useState(
    filters ? filters.name || filters.barcode || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters ? (filters.name && 'name') || (filters.barcode && 'barcode') || 'barcode' : 'barcode'
  )

  const [selectRows, setSelectRows] = useState([])
  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setValueSearch('')
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    setParamsFilter({ ...paramsFilter })
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]

      setParamsFilter({ ...paramsFilter })
    }, 650)
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _getProducts = async () => {
    try {
      setLoading(true)
      setSelectRows([])
      setProducts([])
      const res = await getProducts({
        ...paramsFilter,
        inventory_info: true,
        expiry_detach: true,
        unit_info: true,
        customer_id: dataUser.customer_id,
      })
      if (res.status === 200) {
        setProducts(res.data.data)
        setStatistical(res.data.statis_products)
        setCountProduct(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _deleteProducts = async (id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteProducts({ product_id: [id] })
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        if (res.data.success) {
          setSelectRows([])
          _getProducts()
          notification.success({ message: 'Xóa sản phẩm thành công' })
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Xóa sản phẩm thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Xóa sản phẩm thất bại',
        })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getProductsExport = async (query = {}) => {
    try {
      const dataExport = await handleGetDataExportExcel(getProducts, {
        ...query,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (dataExport) {
        return dataExport.map((data, index) => {
          return {
            STT: index + 1,
            'Tên sản phẩm(*)': data.name || '',
            'Mã sản phẩm(*)': data.barcode || data.code || '',
            'Mã ERP': data.ecommerce_barcode || '',
            'Nhóm sản phẩm': data.group_info ? data.group_info.name : '',
            'Loại sản phẩm': data.type_info ? data.type_info.name : '',
            'Hình ảnh': data.images ? data.images.join(', ') : '',
            'Chiều dài(cm)(*)': data.length || '',
            'Chiều rộng(cm)(*)': data.width || '',
            'Chiều cao(cm)(*)': data.height || '',
            'Khối lượng (kg)(*)': data.weight || '',
            'Quy cách': data.convention || '',
            'Đơn vị tính': data.unit_info ? data.unit_info.name : '',
            'Đơn vị nhỏ nhất': data.smallest_unit || 0,
            'Giá trị': formatCash(data.price || 0),
            'Xuất xứ': data.origin || '',
            'Ngày sản xuất':
              data.locations && data.locations.mfg
                ? moment(data.locations.mfg).format('DD-MM-YYYY HH:mm')
                : '',
            'Hạn sử dụng':
              data.locations && data.locations.exp
                ? moment(data.locations.exp).format('DD-MM-YYYY HH:mm')
                : '',
            // 'Mã khách hàng': data.customer_info ? data.customer_info.fullname || '' : '',
            // 'Tên khách hàng': data.customer_info ? data.customer_info.code || '' : '',
            'Ghi chú': data.note || '',
            'Số lượng hiện tại': data.locations.available_quantity || '',
            'Số lượng có thể xuất': data.locations.available_quantity || '',
          }
        })
      }
      return []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses({ get_all: true })
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({
        group: true,
        detach: true,
        get_all: true,
        customer_id: dataUser.customer_id,
      })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({
        type: true,
        detach: true,
        get_all: true,
        customer_id: dataUser.customer_id,
      })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]

    if (valueSearch) {
      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
    }
  }

  useEffect(() => {
    _getWarehouses()
  }, [])

  useEffect(() => {
    if (dataUser && dataUser.customer_id) {
      _getGroups()
      _getTypes()
    }
  }, [dataUser])

  useEffect(() => {
    if (dataUser && dataUser.customer_id) {
      _getProducts()
      saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
    }
  }, [paramsFilter, dataUser])

  return (
    <div>
      <Row className="page-title" justify="space-between" align="middle">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>Danh sách sản phẩm</span>
        <Space>
          {/* <Permission permissions={[PERMISSIONS.giao_dien_xuat_nhap_file]}>
            <Button
              type="primary"
              onClick={() =>
                history.push(ROUTES.IMPORTEXPORTMANAGEMENT + '?action_name=file-nhap-san-pham')
              }
            >
              Xem lịch sử nhập file
            </Button>
          </Permission> */}

          <Permission permissions={[PERMISSIONS.tao_san_pham]}>
            <ImportFile
              keyForm={{ customer_id: dataUser && dataUser.customer_id }}
              upload={uploadProduct}
              reload={_getProducts}
              title="Nhập sản phẩm bằng file excel"
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_09_14_122627b2-c69e-4104-a1f2-1987ff755b73_file_import_san_pham.xlsx"
              action_name="Nhập sản phẩm bằng file excel"
            />
          </Permission>

          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách sản phẩm"
            fileName={`Danh sách sản phẩm ${moment().format('DD-MM-YYYY')}`}
            exportData={() => _getProductsExport(paramsFilter)}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách sản phẩm"
            fileName={`Danh sách sản phẩm ${moment().format('DD-MM-YYYY')}`}
            exportData={() => _getProductsExport()}
          />
          <SettingColumns
            columnsDefault={columnsProduct}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsProduct"
          />
          <Permission permissions={[PERMISSIONS.tao_san_pham]}>
            <Button onClick={() => history.push(ROUTES.PRODUCT_DETAIL)} type="primary">
              Tạo sản phẩm
            </Button>
          </Permission>
        </Space>
      </Row>
      <Row style={{ marginBottom: 15 }} gutter={[16, 16]}>
        <Col lg={8}>
          <Input.Group compact>
            <Input
              style={{ width: '64%' }}
              enterButton
              allowClear
              value={valueSearch}
              onChange={_onSearch}
              placeholder="Tìm kiếm sản phẩm"
            />
            <Select
              style={{ width: '36%' }}
              suffixIcon={<SuffixIconCustom />}
              value={optionSearch}
              onChange={(value) => _selectOption(value)}
            >
              <Select.Option value="name">Tên sản phẩm</Select.Option>
              <Select.Option value="barcode">Mã hàng</Select.Option>
              <Select.Option value="ecommerce_barcode">Mã ERP</Select.Option>
            </Select>
          </Input.Group>
        </Col>
        <Col lg={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['type_id'] || undefined}
            onChange={(value) => _filterByOption('type_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo loại hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {types.map((type, index) => (
              <Select.Option value={type.category_id} key={index}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['group_id'] || undefined}
            onChange={(value) => _filterByOption('group_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo nhóm hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {groups.map((group, index) => (
              <Select.Option value={group.category_id} key={index}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col lg={8}>
          <Select
            value={paramsFilter['sort'] || undefined}
            allowClear
            onChange={(value) => _filterByOption('sort', value)}
            placeholder="Sắp xếp theo số lượng trong kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            <Select.Option value="highest_inventory">Tăng dần</Select.Option>
            <Select.Option value="lowest_inventory">Giảm dần</Select.Option>
          </Select>
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['warehouse_id'] || undefined}
            onChange={(value) => _filterByOption('warehouse_id', value)}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouses.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        {/* <Col lg={5}>
          <DatePicker
            value={paramsFilter['sort_by_date'] ? moment(paramsFilter['sort_by_date']) : undefined}
            onChange={(value, dateString) => _filterByOption('sort_by_date', dateString)}
            style={{ width: '100%' }}
            placeholder="Sắp xếp sản phẩm theo ngày"
          />
        </Col> */}
        <Col lg={10}>
          <Button
            className={style['btn-clear']}
            onClick={_clearFilters}
            type="primary"
            style={{
              display: Object.keys(paramsFilter).length <= 2 && 'none',
            }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      <ModalOptionsPrint
        records={selectRows.map((item) => {
          const product = products.find((product) => product.product_id === item)
          return product || {}
        })}
      >
        <Button
          className={style['btn-print']}
          style={{
            display: !selectRows.length && 'none',
            backgroundColor: '#238800',
            borderColor: '#238800',
            marginBottom: 5,
          }}
          icon={<PrinterOutlined />}
          type="primary"
        >
          In qrcode
        </Button>
      </ModalOptionsPrint>

      <Table
        scroll={{ x: 'max-content', y: '66vh' }}
        loading={loading}
        rowKey="product_id"
        dataSource={products}
        rowSelection={{
          rowSelection: selectRows,
          onChange: (keys) => setSelectRows(keys),
        }}
        rowClassName={(product) => {
          if (product.locations.exp) {
            const thisDay = moment().tz('Asia/Ho_Chi_Minh')
            const exp = moment(moment(product.locations.exp).format('YYYY-MM-DD')).tz(
              'Asia/Ho_Chi_Minh'
            )
            if (moment(exp).diff(thisDay, 'days') < 10) return 'bg-orange'
          }
          return ''
        }}
        style={{ width: '100%' }}
        columns={columns.map((column) => {
          if (column.key === 'number')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'name')
            return {
              ...column,
              render: (text, record) =>
                isAcceptPermissions([PERMISSIONS.xem_san_pham]) ? (
                  <Link
                    style={{ color: '#0017E3' }}
                    to={`${ROUTES.PRODUCT_DETAIL}?product_id=${record.product_id}`}
                  >
                    {text}
                  </Link>
                ) : (
                  <b>{text}</b>
                ),
            }
          if (column.key === 'qr_code')
            return {
              ...column,
              render: (text, record) => <QrCodeProduct value={record.barcode} />,
            }
          if (column.key === 'group')
            return {
              ...column,
              render: (text, record) => record.group_info && record.group_info.name,
            }
          if (column.key === 'type')
            return {
              ...column,
              render: (text, record) => record.type_info && record.type_info.name,
            }
          if (column.key === 'price') return { ...column, render: (text) => formatCash(+text) }
          if (column.key === 'available_quantity')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.available_quantity
                  ? formatCash(+record.locations.available_quantity)
                  : 0,
            }
          if (column.key === 'current_quantity')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.current_quantity
                  ? formatCash(+record.locations.current_quantity)
                  : 0,
            }
          if (column.key === 'unit')
            return {
              ...column,
              render: (text, record) => record.unit_info && record.unit_info.name,
            }
          if (column.key === 'smallest_unit')
            return {
              ...column,
              render: (text, record) => formatCash(record.smallest_unit || 0),
            }
          if (column.key === 'warehouse')
            return {
              ...column,
              render: (text, record) =>
                record.locations &&
                record.locations.warehouse_info &&
                (record.locations.warehouse_info.name || ''),
            }
          if (column.key === 'exp')
            return {
              ...column,
              render: (text, record) => {
                const timeDateNow = new Date().getTime()
                const timeDateExpire = record.locations
                  ? new Date(record.locations.exp).getTime()
                  : 0
                const isExpired = timeDateNow > timeDateExpire
                const isExpire = Math.abs(timeDateNow - timeDateExpire) < 3600000 * 24
                return record.locations && record.locations.exp ? (
                  <Space>
                    {moment(record.locations.exp).format('DD-MM-YYYY HH:mm')}
                    {(isExpired || isExpire) && (
                      <Tooltip
                        title={
                          (isExpired && 'Đã hết hạn sử dụng') || (isExpire && 'Sắp hết hạn sử dụng')
                        }
                      >
                        <WarningOutlined style={{ color: 'red' }} />
                      </Tooltip>
                    )}
                  </Space>
                ) : (
                  ''
                )
              },
            }
          if (column.key === 'mfg')
            return {
              ...column,
              render: (text, record) =>
                record.locations && record.locations.mfg
                  ? moment(record.locations.mfg).format('DD-MM-YYYY HH:mm')
                  : '',
            }
          if (column.key === 'customer')
            return {
              ...column,
              render: (text, record) => record.customer_info && record.customer_info.fullname,
            }
          if (column.key === 'image')
            return {
              ...column,
              render: (text, record) =>
                record.images && (
                  <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
                    <img
                      style={{ width: 50, height: 50 }}
                      alt=""
                      src={
                        record.images[0] ||
                        'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'
                      }
                    />
                  </ZoomImage>
                ),
            }
          if (column.key === 'action')
            return {
              ...column,
              width: 180,
              render: (text, record) => (
                <Space>
                  <ModalOptionsPrint records={[record]}>
                    <Button
                      className={style['btn-print']}
                      style={{ backgroundColor: '#238800', borderColor: '#238800' }}
                      icon={<PrinterOutlined />}
                      type="primary"
                    >
                      In qrcode
                    </Button>
                  </ModalOptionsPrint>
                  <Permission permissions={[PERMISSIONS.xoa_san_pham]}>
                    <Popconfirm
                      placement="topRight"
                      onConfirm={() => _deleteProducts(record.product_id)}
                      title="Bạn có muốn xóa sản phẩm này không?"
                      okText="Đồng ý"
                      cancelText="Từ chối"
                    >
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        type="primary"
                        style={{
                          display: record.locations.quantity ? 'none' : '',
                        }}
                      ></Button>
                    </Popconfirm>
                  </Permission>
                </Space>
              ),
            }
          return column
        })}
        size={SIZE_TABLE}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.page_size = pageSize

            setParamsFilter({ ...paramsFilter })
          },
          total: countProduct,
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
