import React, { useState, useRef } from 'react'
import { ExcelRenderer } from 'react-excel-renderer'
import style from './import-csv.module.scss'
import * as XLSX from 'xlsx'

// antd
import { Row, Button, Modal, Upload, message, notification, Table, Col } from 'antd'

//icons
import { DownloadOutlined } from '@ant-design/icons'

//apis
import { saveActionFile } from 'apis/action'
import { uploadFile } from 'apis/upload'
import moment from 'moment'

export default function ImportFile({
  title = '',
  fileTemplated = '',
  action_name = 'Nhập file',
  upload,
  txt = 'Nhập excel',
  reload,
  keyForm = {},
  width = '60%',
  colorCode = '#377BFF',
  style = {},
  importPdf,
  reset,
  warning = '',
}) {
  const typingTimeoutRef = useRef()

  const [visible, setVisible] = useState(false)
  const [fileUpload, setFileUpload] = useState(null)
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [dataView, setDataView] = useState([])

  const toggle = () => {
    if (visible) if (reset) reset()
    setVisible(!visible)
    setFileUpload(null)
  }

  const _saveActionFile = async () => {
    try {
      const url = await uploadFile(fileUpload)
      const body = {
        action_name: action_name,
        file_name: fileUpload.name,
        links: [url || ''],
        type: 'Import File',
      }
      const res = await saveActionFile(body)
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  const _handleUpload = async () => {
    if (fileUpload) {
      let formData = new FormData()
      formData.append('file', fileUpload)

      //add them key khac neu co
      const objKey = Object.keys(keyForm)

      if (keyForm.warehouse_id !== undefined && keyForm.warehouse_id === '') {
        notification.warning({ message: 'Vui lòng chọn kho' })
        return
      }

      if (objKey.length !== 0) objKey.map((key) => formData.append(key, keyForm[key]))

      try {
        setLoading(true)
        const res = await upload(formData)
        console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            await _saveActionFile()
            reload()
            toggle()
            notification.success({ message: res.data.message || 'Nhập file excel thành công' })
          } else notification.warning({ message: res.data.message || 'Nhập file excel thất bại' })
        } else notification.warning({ message: res.data.message || 'Nhập file excel thất bại' })

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
        toggle()
        notification.error({ message: 'Nhập file excel thất bại, vui lòng thử lại!' })
      }
    }
  }

  return (
    <>
      <Button
        style={{ ...style, backgroundColor: colorCode, borderColor: colorCode, borderRadius: 5 }}
        type="primary"
        icon={<DownloadOutlined />}
        onClick={toggle}
      >
        {txt}
      </Button>
      <Modal
        style={{ top: 20 }}
        width={width}
        title={title}
        visible={visible}
        onCancel={toggle}
        maskClosable={false}
        footer={
          <Row justify="end">
            <Button type="primary" onClick={_handleUpload} disabled={!fileUpload} loading={loading}>
              Xác nhận
            </Button>
          </Row>
        }
      >
        <Row gutter={16} wrap={false}>
          <Col
            xl={importPdf ? 18 : 24}
            lg={importPdf ? 18 : 24}
            md={importPdf ? 18 : 24}
            sm={importPdf ? 18 : 24}
            xs={importPdf ? 18 : 24}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a download href={fileTemplated} style={{ marginBottom: 15, color: 'blue' }}>
                Tải xuống file mẫu
              </a>
              <Upload
                fileList={fileUpload ? [fileUpload] : []}
                maxCount={1}
                beforeUpload={(file) => {
                  if (
                    file.type !==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  ) {
                    message.error(`${file.name} không phải là file excel`)
                  }
                  return file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ? true
                    : Upload.LIST_IGNORE
                }}
                onChange={(info) => {
                  if (info.file.status !== 'done') info.file.status = 'done'
                  const file = info.file.originFileObj
                  setFileUpload(file)
                  if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current)
                  typingTimeoutRef.current = setTimeout(() => {
                    if (file) {
                      ExcelRenderer(
                        file,
                        (err, resp) => {
                          if (err) console.log(err)
                          else {
                            if (resp.rows[0]) {
                              const columns = resp.rows[0].map((item) => ({
                                title: item,
                                dataIndex: item,
                              }))
                              setColumns([...columns])
                            }
                            let result = []
                            for (let i = 1; i < resp.rows.length; ++i) {
                              if (resp.rows[i].length) {
                                let obj = {}
                                for (let j = 0; j < resp.rows[0].length; ++j)
                                  if (resp.rows[0][j]) obj[resp.rows[0][j]] = resp.rows[i][j] || ''
                                result.push(obj)
                              }
                            }
                            setDataView([...result])
                          }
                        },
                        { cellDates: true }
                      )
                    } else {
                      setColumns([])
                      setDataView([])
                    }
                  }, 400)
                }}
              >
                <div>
                  <Button>Chọn file excel</Button>
                  <div style={{ color: 'red' }}>
                    * Vui lòng xoá các hàng dữ liệu trống trong file trong quá trình soạn file, thời
                    gian đọc file của phần mềm từ 1-2 giây
                  </div>
                </div>
              </Upload>
              {fileUpload && warning && <div style={{ color: 'red' }}>* {warning}</div>}
              <Table
                scroll={{ x: 'max-content', y: '50vh' }}
                size="small"
                style={{ width: '100%', display: !fileUpload && 'none', marginTop: 15 }}
                dataSource={dataView}
                columns={columns
                  .filter((c) => c)
                  .map((column) => {
                    if (column.title === 'Hình ảnh') return { ...column, width: 300 }
                    return { ...column, width: column.title.length * 15 }
                  })}
              />
            </div>
          </Col>
          {importPdf && (
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <div style={{ marginTop: 40 }}>{importPdf}</div>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  )
}
