import React, { useContext, useState } from 'react'
import { Button, Modal } from 'antd'
import { testEventError } from 'apis/error'
export default function ModalError({ visible, setVisibleModalError }) {
  return (
    <Modal
      style={{ top: 220 }}
      footer={null}
      width="40%"
      title="Thông báo lỗi "
      onCancel={(e) => {
        setVisibleModalError(false)
      }}
      visible={visible}
    >
      <div
        style={{
          height: 120,
          width: '100%',
          display: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div>{'Có lỗi xảy ra, vui lòng thử lại'}</div>
        <div>{'(Chúng tôi đã gửi báo cáo lỗi đến bộ phận IT)'}</div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={(e) => {
              setVisibleModalError(false)
            }}
            style={{ width: 120, height: 40, marginTop: 50 }}
            type="primary"
          >
            {'Đã hiểu'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
