const columnsImport = [
  {
    title: 'STT',
    align: 'center',
    width: 50,
    key: 'stt',
  },
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    width: 250,
  },
  {
    title: 'Số PO',
    dataIndex: 'customer_order_code',
    width: 250,
  },

  {
    title: 'Kho nhập hàng',
    dataIndex: 'warehouse_info',
    width: 150,
  },
  // {
  //   title: 'Kênh bán hàng',
  //   sorter: (a, b) => a?.category - b?.category,
  // },
  {
    title: 'Ngày nhập kho',
    dataIndex: 'import_date',
    width: 150,
  },
  {
    title: 'Tổng tiền sản phẩm',
    dataIndex: 'total_cost',
    width: 160,
  },

  // {
  //   title: 'Tổng số lượng nhập',
  //   dataIndex: 'total_quantity',
  // },
  {
    title: 'Nhân viên lên đơn',
    dataIndex: 'customer_info',
    width: 170,
  },

  {
    title: 'Ngày tiếp nhận phiếu',
    dataIndex: 'receive_date',
    width: 150,
  },
  {
    title: 'Biên lai ký nhận',
    key: 'signature',
    width: 90,
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    width: 200,
  },
  {
    title: 'Lý do',
    dataIndex: 'receive_reason',
    width: 200,
  },
  // {
  //   key: 'import_status',
  //   title: 'Trạng thái',
  //   dataIndex: 'import_status',
  // },
  {
    title: 'Danh sách chi phí kho',
    key: 'fee',
    width: 150,
  },
  {
    title: 'Danh sách sản phẩm',
    key: 'products',
    width: 150,
  },
  {
    title: 'Hành động',
    key: 'action',
    width: 90,
  },
]

export default columnsImport
