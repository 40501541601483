import React, { useState } from 'react'
import styles from './register.module.scss'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES, REGEX_EMAIL, REGEX_PASSWORD } from 'consts'

// img
import banner from 'assets/images/SGL_banner.png'

// api
import { register } from 'apis/auth'

// antd
import { Col, Row, Form, Input, Button, notification } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'

export default function Register() {
  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const _register = async (body) => {
    try {
      await form.validateFields()

      if (body.password !== body.password_again) {
        notification.error({
          message: 'Các mật khẩu đã nhập không khớp, vui lòng thử lại',
          duration: 5,
        })
        return
      }

      if (!REGEX_EMAIL.test(body.email)) {
        notification.error({
          message: 'Định dạng email không hợp lệ, vui lòng thử lại',
          duration: 5,
        })
        return
      }

      if (!REGEX_PASSWORD.test(body.password)) {
        notification.error({
          message:
            'Vui lòng sử dụng mật khẩu có ít nhất tám ký tự, ít nhất một chữ cái, một số và một ký tự đặc biệt.',
          duration: 5,
        })
        return
      }

      setLoading(true)
      delete body.password_again
      const res = await register(body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Vui lòng kiểm tra email để nhận mã OTP', duration: 3 })
          history.push(`${ROUTES.VERIFY_OTP}?type=register&username=${body.username}`)
        } else
          notification.error({ message: res.data.message || 'Đăng ký thất bại, vui lòng thử lại' })
      } else
        notification.error({ message: res.data.message || 'Đăng ký thất bại, vui lòng thử lại' })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  return (
    <div className={styles['login']}>
      <Row>
        <Col xs={24} lg={12} xl={10}>
          <img src={banner} alt="" style={{ minWidth: '1024px' }} />
          <div className={styles['login-form']}>
            <h3 align="center">
              {process.env.REACT_APP_API_ENDPOINT_DEV === 'https://apisgl.cbox.vn/api'
                ? 'SAND BOX'
                : 'LIVE'}
            </h3>
            <h2 align="center">Đăng ký tài khoản</h2>
            <Form form={form} onFinish={_register} layout="vertical">
              <Form.Item
                name="username"
                label="Tên tài khoản"
                rules={[{ required: true, message: 'Vui lòng nhập tài khoản' }]}
              >
                <Input
                  allowClear
                  size="large"
                  placeholder="Nhập tài khoản"
                  prefix={<UserOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Vui lòng nhập mail' }]}
              >
                <Input
                  allowClear
                  size="large"
                  placeholder="Nhập mail"
                  prefix={<UserOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
              >
                <Input.Password
                  allowClear
                  size="large"
                  placeholder="Nhập mật khẩu"
                  prefix={<LockOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="password_again"
                label="Xác nhận mật khẩu"
                rules={[{ required: true, message: 'Xác nhận mật khẩu của bạn' }]}
              >
                <Input.Password
                  allowClear
                  size="large"
                  placeholder="Xác nhận mật khẩu"
                  prefix={<LockOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Row justify="end">
                <Link
                  style={{ display: 'block', textAlign: 'right', color: 'black' }}
                  to={ROUTES.LOGIN}
                >
                  Quay về trang đăng nhập
                </Link>
              </Row>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    marginTop: 20,
                    width: '100%',
                  }}
                  loading={loading}
                >
                  Đăng ký
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
