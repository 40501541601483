import React from 'react'
import QR from 'qrcode-base64'
import Barcode from 'react-barcode'
import { Row } from 'antd'

const PrintQRCode = ({ record }) => {
  var imgData = QR.drawImg(record.barcode || '', {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  return (
    // <div
    //   style={{
    //     width: '85%',
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     paddingTop: 15,
    //   }}
    // >
    //   <Barcode fontSize={12} height={30} value={record.code || ''} />
    //   <img src={imgData} alt="" style={{ height: 40, width: 40 }} />
    //   <div
    //     style={{
    //       fontSize: 11,
    //       lineHeight: 1,
    //       fontWeight: 700,
    //       textAlign: 'center',
    //       padding: '5px 10px',
    //     }}
    //   >
    //     {record.code}
    //   </div>
    //   <div
    //     style={{
    //       fontSize: 11,
    //       lineHeight: 1,
    //       fontWeight: 700,
    //       textAlign: 'center',
    //       paddingLeft: 10,
    //       paddingRight: 10,
    //     }}
    //   >
    //     {record.name}
    //   </div>
    // </div>

    <div style={{ width: '100%', height: '100%', padding: '5px 5px 5px 8px' }}>
      {/* <Barcode fontSize={12} height={30} value={record.barcode || ''} /> */}

      <Row wrap={false} style={{ width: '100%', height: 'calc(100vh - 10px)' }}>
        <Row style={{ height: '100%', width: 60 }} justify="center" align="middle">
          <img src={imgData} alt="" style={{ height: 60, width: 60 }} />
        </Row>
        <div style={{ marginLeft: 3 }}>
          <div style={{ fontSize: 16, lineHeight: 1, fontWeight: 700, textAlign: 'center' }}>
            {record.barcode}
          </div>
          <div style={{ fontSize: 9.5, lineHeight: 1, fontWeight: 600 }}>{record.name}</div>
          <Row justify="center">
            <div style={{ fontSize: 7.5, lineHeight: 1, marginRight: 2 }}>
              {record.convention ? `${record.convention} -` : ''}
            </div>
            <div style={{ fontSize: 7, lineHeight: 1 }}>
              {record.unit_info ? record.unit_info.name : ''}
            </div>
          </Row>
        </div>
      </Row>
    </div>
  )
}
class ComponentPrint extends React.Component {
  render() {
    const { records } = this.props
    let printingPages = []
    for (const record of records) {
      const tempTemplate = <PrintQRCode record={record} />
      printingPages.push(tempTemplate)
    }
    return <div>{printingPages}</div>
  }
}

function QRCodePrinter({ records, componentRef }) {
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrint records={records} ref={componentRef} />
      </div>
    </>
  )
}

export default QRCodePrinter
