import React, { useState, useEffect, useRef } from 'react'
import { PAGE_SIZE, PERMISSIONS, POSITION_TABLE } from 'consts'
import moment from 'moment'
import { filterOptionSelect } from 'utils'

//icons
import { SearchOutlined, PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

//antd
import { Input, Row, Col, notification, Select, Table, Button, Space, Popconfirm } from 'antd'

//components
import BranchForm from './branch-form'
import PageTitle from 'components/page-title'
import columnsBranch from './columns'
import FilterDate from 'components/filter-date'
import Permission from 'views/permission'
import CompanyBranchForm from './branch-form'

//apis
import { getDistricts, getProvinces } from 'apis/address'
import { getBranches, deleteBranch } from 'apis/branch'
import { getUsers } from 'apis/user'

const { Option } = Select
export default function Branch() {
  const typingTimeoutRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [countBranch, setCountBranch] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 100 })
  const [districts, setDistricts] = useState([])
  const [provinces, setProvinces] = useState([])
  const [employees, setEmployees] = useState([])
  const [districtsDefault, setDistrictsDefault] = useState([])
  const [valueSearch, setValueSearch] = useState('')

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 750)
  }

  const _getBranches = async () => {
    try {
      setLoading(true)
      const res = await getBranches({ ...paramsFilter, _creator: true })

      if (res.status === 200) {
        setBranches(res.data.data)
        setCountBranch(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _deleteBranch = async (id) => {
    try {
      setLoading(true)
      const res = await deleteBranch([id])
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa chi nhánh thành công' })
          _getBranches()
        } else notification.error({ message: res.data.message })
      } else notification.error({ message: res.data.message })
    } catch (error) {
      setLoading(false)
    }
  }

  const _getDistricts = async () => {
    try {
      const res = await getDistricts()
      if (res.status === 200) {
        setDistricts(res.data.data)
        setDistrictsDefault(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const _getProvinces = async () => {
    try {
      const res = await getProvinces()
      console.log(res)
      if (res.status === 200) {
        setProvinces(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      const res = await getUsers()
      if (res.status === 200) {
        setEmployees(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilterByOption = (option, value) => {
    if (value !== undefined) paramsFilter[option] = value
    else delete paramsFilter[option]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  useEffect(() => {
    _getBranches()
  }, [paramsFilter])

  useEffect(() => {
    _getProvinces()
    _getDistricts()
    _getEmployees()
  }, [])

  return (
    <>
      <div className="card">
        <PageTitle title="Quản lý chi nhánh">
          <Space>
            <Permission permissions={[PERMISSIONS.tao_chi_nhanh]}>
              <BranchForm reload={_getBranches}>
                <Button size="medium" icon={<PlusCircleOutlined />} type="primary">
                  Thêm chi nhánh
                </Button>
              </BranchForm>
            </Permission>
          </Space>
        </PageTitle>
        <div style={{ marginTop: 15 }}>
          <Row gutter={[10, 10]}>
            <Col md={5} xs={6}>
              <Input
                style={{ width: '100%' }}
                name="name"
                value={valueSearch}
                enterButton
                onChange={onSearch}
                placeholder="Tìm kiếm chi nhánh"
                allowClear
                prefix={<SearchOutlined />}
              />
            </Col>
            <Col md={5} xs={6}>
              <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
            </Col>
            {/* <Col md={5} xs={6}>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Tỉnh/ thành phố"
                optionFilterProp="children"
                showSearch
                filterOption={filterOptionSelect}
                value={paramsFilter.province}
                onChange={(value) => {
                  if (value) {
                    paramsFilter.province = value
                    const districtsByProvince = districtsDefault.filter(
                      (e) => e.province_name === value
                    )
                    setDistricts([...districtsByProvince])
                  } else {
                    delete paramsFilter.province
                    setDistricts([...districtsDefault])
                  }

                  setParamsFilter({ ...paramsFilter, page: 1 })
                }}
              >
                {provinces.map((values, index) => {
                  return (
                    <Option value={values.province_name} key={index}>
                      {values.province_name}
                    </Option>
                  )
                })}
              </Select>
            </Col>
            <Col md={5} xs={6}>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Quận/ huyện"
                optionFilterProp="children"
                showSearch
                filterOption={filterOptionSelect}
                value={paramsFilter.district}
                onChange={(value) => {
                  if (value) paramsFilter.district = value
                  else delete paramsFilter.district

                  setParamsFilter({ ...paramsFilter, page: 1 })
                }}
              >
                {districts.map((values, index) => {
                  return (
                    <Option value={values.district_name} key={index}>
                      {values.district_name}
                    </Option>
                  )
                })}
              </Select>
            </Col> */}
            <Col md={4} xs={6}>
              <Select
                allowClear
                showSearch
                filterOption={filterOptionSelect}
                placeholder="Người tạo"
                style={{ width: '100%' }}
                onChange={(value) => handleFilterByOption('creator_id', value)}
                value={paramsFilter.creator_id}
              >
                {employees.map((user) => (
                  <Select.Option value={user.user_id} key={user.user_id}>
                    {user.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>

        <Table
          style={{ width: '100%', marginTop: 10 }}
          rowKey="branch_id"
          size="small"
          scroll={{ y: '50vh', x: 'max-content' }}
          loading={loading}
          columns={columnsBranch.map((column) => {
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (['name', 'phone', 'email', 'address', 'manager_name'].includes(column.dataIndex))
              return {
                ...column,
                sorter: (a, b) => a[column.dataIndex].length && b[column.dataIndex].length,
              }
            if (column.key === 'creator_info')
              return {
                ...column,
                sorter: (a, b) => a?.creator_info?.name?.length && b?.creator_info?.name?.length,
                render: (text, record) => record.creator_info && record.creator_info.full_name,
              }
            if (column.key === 'create_date')
              return {
                ...column,
                sorter: (a, b) =>
                  moment(a.create_date || undefined) - moment(b.create_date || undefined),
                render: (text, record) =>
                  record.create_date && moment(record.create_date).format('DD/MM/YYYY'),
              }

            if (column.key === 'image')
              return {
                ...column,
                render: (text, record) =>
                  record.logo_urls && record.logo_urls.length ? (
                    <img src={record.logo_urls[0]} alt="" width="65px" height="65px" />
                  ) : (
                    ''
                  ),
              }
            if (column.key === 'action')
              return {
                ...column,
                render: (text, record) => (
                  <Space>
                    <Permission permissions={[PERMISSIONS.xoa_chi_nhanh]}>
                      <Popconfirm
                        onConfirm={() => _deleteBranch(record.branch_id)}
                        title="Bạn có muốn xóa chi nhánh này không?"
                      >
                        <DeleteOutlined style={{ color: 'red', fontSize: 17, cursor: 'pointer' }} />
                      </Popconfirm>
                    </Permission>
                    <Permission permissions={[PERMISSIONS.cap_nhat_chi_nhanh]}>
                      <CompanyBranchForm reload={_getBranches} record={record}>
                        <EditOutlined style={{ color: 'green', fontSize: 17, cursor: 'pointer' }} />
                      </CompanyBranchForm>
                    </Permission>
                  </Space>
                ),
              }

            return column
          })}
          dataSource={branches}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: [20, 30, 50, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) =>
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize }),
            total: countBranch,
          }}
        />
      </div>
    </>
  )
}
