const columnsActivity = [
  {
    key: 'stt',
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    width: 50,
  },
  {
    key: 'fullname',
    title: 'Tên người thao tác',
    dataIndex: 'fullname',
    width: 150,
  },
  {
    key: 'code',
    title: 'Số chứng từ',
    width: 150,
  },
  {
    title: 'Tên chức năng',
    dataIndex: 'name',
    width: 200,
  },
  {
    key: 'date',
    title: 'Thời gian thao tác',
    dataIndex: 'date',
    align: 'center',
    width: 150,
  },
  {
    title: 'Thao tác',
    dataIndex: 'type',
    align: 'center',
    width: 150,
  },
  {
    title: 'Giao diện',
    dataIndex: 'properties',
    align: 'center',
    width: 150,
  },
]
export default columnsActivity
