import React from 'react'
import styles from './page-title.module.scss'
import { Row, Affix } from 'antd'

export default function PageTitle({ children, title = '', isAffix = false, top = 48, style = {} }) {
  return isAffix ? (
    <Affix offsetTop={top}>
      <Row
        style={{ ...style }}
        wrap={false}
        align="middle"
        justify="space-between"
        className={styles['page-title-wrap']}
      >
        <h3 className={styles['page-title']}>{title}</h3>
        {children}
      </Row>
    </Affix>
  ) : (
    <Row
      wrap={false}
      align="middle"
      justify="space-between"
      className={styles['page-title-wrap']}
      style={{ ...style, paddingTop: 5 }}
    >
      <h3 className={styles['page-title']}>{title}</h3>
      {children}
    </Row>
  )
}
