import React, { cloneElement } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'
import { notification } from 'antd'
import jwt_decode from 'jwt-decode'
import { clearLocalStorage } from 'utils'

/**
 *
 * @param {Object} props
 * @param {Array<String>} props.permissions
 * @param {React.ReactChildren} props.children
 */
const Authentication = ({ permissions, title, children, ...props }) => {
  const history = useHistory()
  const payload = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken'))
    : null

  const domain = window.location.hostname
  const apiSandbox = 'https://api-sgl.cpos.vn/api'
  const apiProduction = 'https://admin.saigonlogistics.vn/api'

  //check host api có đang dùng api của production
  // if (
  //   (domain === 'sandbox-merchant.saigonlogistics.vn' &&
  //     process.env.REACT_APP_API_ENDPOINT_DEV === apiSandbox) ||
  //   (domain === 'merchant.saigonlogistics.vn' &&
  //     process.env.REACT_APP_API_ENDPOINT_DEV === apiProduction) ||
  //   (domain === 'localhost' &&
  //     [apiSandbox, apiProduction].includes(process.env.REACT_APP_API_ENDPOINT_DEV))
  //   // (domain === 'localhost' &&
  //   //   process.env.REACT_APP_API_ENDPOINT_DEV === 'http://localhost:4000/api')
  // ) {
  // } else {
  //   notification.error({
  //     key: 'error-system',
  //     message: 'Hệ thống đang gặp sự cố, vui lòng liên hệ với đội ngũ bảo trì',
  //     duration: 5,
  //   })
  //   history.push(ROUTES.LOGIN)
  //   clearLocalStorage()
  //   return ''
  // }

  //modify title
  document.title = title

  // //check đã đăng nhập chưa hoặc token còn hạn -> vào trang home
  // if (!permissions) {
  //   if (!payload || new Date(payload.exp * 1000).getTime() < Date.now()) {
  //     return cloneElement(children, props)
  //   }
  //   return <Redirect to={ROUTES.DASHBOARD} />
  // }

  //check login ?
  if (!payload) return <Redirect to={ROUTES.LOGIN} />

  // permissions.length = 0 -> screen public
  // permissions.length > 0 -> check user có quyền truy cập vào màn hình này
  if (
    permissions.length === 0 ||
    permissions.filter((p) => payload.role_info.permissions.includes(p)).length > 0
  ) {
    return cloneElement(children, props)
  }

  notification.warning({
    message: 'Permission Denied',
  })

  history.goBack()

  return <div />
}

export default Authentication
