import React, { useState } from 'react'

import { Modal } from 'antd'

export default function ModalViewReasons({ record }) {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const reasons = record.receive_reason.split('\n')

  return (
    <>
      <a onClick={toggle} style={{ color: 'red' }}>
        Xem chi tiết
      </a>
      <Modal
        style={{ top: 20 }}
        footer={null}
        width="70%"
        title={`Danh sách lý do của đơn #${record.code}`}
        onCancel={toggle}
        visible={visible}
      >
        <div>
          {reasons.map((reason) => (
            <div style={{ fontSize: 14, fontWeight: 600, display: !reason && 'none' }}>
              - {reason}
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
