import React, { useEffect, useState, useRef } from 'react'
import styles from './activityhistory.module.scss'
import moment from 'moment'
import { IMAGE_DEFAULT, PAGE_SIZE_OPTIONS, SIZE_TABLE } from 'consts'
import { saveFiltersToLocalStorage, formatCash } from 'utils'
import { isEqual, uniq } from 'lodash'

//components
import SuffixIconCustom from 'components/suffixIconCustom'
import optionProperties from './optionProperties'
import columnsActivity from './columnsActivity'
import BackToTop from 'components/back-to-top'
import SettingColumns from 'components/setting-columns'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'

// antd
import { Col, Row, Button, Input, Select, Table, Space, Modal } from 'antd'

//icons
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons'

//apis
import { getAction } from 'apis/action'

export default function ActivityHistory() {
  const KEY_FILTER = 'activityHistoryFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const typingTimeoutRef = useRef(null)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )

  const [valueSearch, setValueSearch] = useState(filters ? filters.name || '' : '')

  const [columns, setColumns] = useState([])

  const [countActivity, setCountActivity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [activityHistory, setActivityHistory] = useState([])

  const _getActionsHistory = async () => {
    try {
      setLoading(true)
      const res = await getAction(paramsFilter)
      setLoading(false)
      if (res.status === 200) {
        setActivityHistory(res.data.data)
        setCountActivity(res.data.count)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.name = value
      else delete paramsFilter.name

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const columnsProduct = [
    {
      title: 'STT',
      key: 'number',
      align: 'center',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 250,
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
      fixed: 'left',
    },
    {
      title: 'Mã SP từ sàn thương mại',
      dataIndex: 'ecommerce_barcode',
    },
    {
      title: 'Nhóm hàng',
      key: 'group',
    },
    {
      title: 'Loại hàng',
      key: 'type',
    },
    {
      title: 'Xuất xứ',
      dataIndex: 'origin',
    },
    {
      title: 'Mã hàng/Barcode',
      dataIndex: 'barcode',
    },
    {
      title: 'Hình ảnh',
      key: 'image',
    },
    {
      title: 'Số lượng có thể xuất',
      key: 'available_quantity',
      align: 'center',
      width: 100,
    },
    {
      title: 'Số lượng hiện tại',
      key: 'current_quantity',
      align: 'center',
      width: 100,
    },
    {
      title: 'Giá tiền',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
    },
    {
      title: 'Cbm (cm³)',
      dataIndex: 'cbm',
      key: 'cbm',
      align: 'center',
    },
    {
      title: 'D.Tích mặt phẳng (cm²)',
      dataIndex: 'area',
      key: 'area',
      align: 'center',
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Đơn vị tính',
      key: 'unit',
    },
    {
      title: 'Đơn vị nhỏ nhất',
      key: 'smallest_unit',
    },
    {
      title: 'Kho',
      key: 'warehouse',
    },
    {
      title: 'Hạn sử dụng',
      key: 'exp',
    },
    {
      title: 'Ngày sản xuất',
      key: 'mfg',
    },
    {
      title: 'Doanh nghiệp',
      key: 'customer',
    },
    {
      title: 'Khả dụng/không khả dụng',
      key: 'available',
    },
  ]

  const getUpdatedKeys = (oldObj = {}, newObj = {}) => {
    const data = uniq([...Object.keys(oldObj), ...Object.keys(newObj)])
    const keys = []
    for (const key of data) {
      if (!isEqual(oldObj[key], newObj[key])) {
        keys.push(key)
      }
    }

    return keys
  }

  const RowCompereInfo = ({ keyObj = '', objOld = {}, objNew = {} }) => {
    return (
      <Row>
        <div>{/* {getKeyName(keyObj)}: {objOld[keyObj]} */}</div>
        <div>{`->`}</div>
        <div>{objNew[keyObj]}</div>
      </Row>
    )
  }

  const renderRowInfo = (objOld = {}, objNew = {}) => {
    Object.keys(objNew).map((key) => {
      if (typeof objNew[key] === 'string')
        return <RowCompereInfo keyObj={key} objOld={objOld} objNew={objNew} />
      // else renderRowInfo(objOld, objNew)
    })
  }

  const ModalCompereInfo = ({ children, order = {} }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [keysChange, setKeysChange] = useState([])

    useEffect(() => {
      if (visible) {
        const keys = getUpdatedKeys(order.old_data, order.new_data)
        setKeysChange(keys)
      }
    }, [visible])

    return (
      <>
        <div onClick={toggle}>{children}</div>
        <Modal
          width="90%"
          style={{ top: 20 }}
          onCancel={toggle}
          visible={visible}
          title={`Các thông tin bị thay đổi #${order.new_data.code}`}
          footer={null}
        >
          <div>
            {keysChange.map((key) => {
              if (typeof order.new_data[key] === 'string') {
                return (
                  <RowCompereInfo keyObj={key} objOld={order.old_data} objNew={order.new_data} />
                )
              } else {
                if (Array.isArray(order.new_data[key])) return ''
                else
                  return (
                    <div>
                      {/* <div>{getKeyName(key)}</div> */}
                      <div>{renderRowInfo(order.new_data[key], order.old_data[key])}</div>
                    </div>
                  )
              }
            })}
          </div>
          {/* <div>
            <Space size="large">
              <Row align="middle">
                <div className={`${styles['dot-note']} ${styles['dot-note-black']}`}></div>
                <div>Dữ liệu cũ</div>
              </Row>
              <Row align="middle">
                <div className={`${styles['dot-note']} ${styles['dot-note-green']}`}></div>
                <div>Dữ liệu bị thay đổi</div>
              </Row>
            </Space>
            <Table
              loading={loading}
              rowKey="product_id"
              dataSource={order.old_data.products || []}
              style={{ width: '100%' }}
              scroll={{ x: 2600, y: '66vh' }}
              columns={columnsProduct.map((column) => {
                if (column.key === 'number')
                  return {
                    ...column,
                    render: (text, record, index) =>
                      (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
                  }
                if (column.key === 'name')
                  return {
                    ...column,
                    render: (text, record) => (
                      <div>
                        <div className={styles['data-old']}>{record.name}</div>
                        <div className={styles['data-new']}>123</div>
                      </div>
                    ),
                  }
                if (column.key === 'group')
                  return {
                    ...column,
                    render: (text, record) => record.group_info && record.group_info.name,
                  }
                if (column.key === 'type')
                  return {
                    ...column,
                    render: (text, record) => record.type_info && record.type_info.name,
                  }
                if (column.key === 'price')
                  return { ...column, render: (text) => formatCash(+text) }
                if (column.key === 'available_quantity')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.locations && record.locations.available_quantity
                        ? formatCash(+record.locations.available_quantity)
                        : 0,
                  }
                if (column.key === 'current_quantity')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.locations && record.locations.current_quantity
                        ? formatCash(+record.locations.current_quantity)
                        : 0,
                  }
                if (column.key === 'unit')
                  return {
                    ...column,
                    render: (text, record) => record.unit_info && record.unit_info.name,
                  }
                if (column.key === 'smallest_unit')
                  return {
                    ...column,
                    render: (text, record) => formatCash(record.smallest_unit || 0),
                  }
                if (column.key === 'warehouse')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.locations &&
                      record.locations.warehouse_info &&
                      (record.locations.warehouse_info.name || ''),
                  }

                if (column.key === 'mfg')
                  return {
                    ...column,
                    render: (text, record) =>
                      record.locations && record.locations.mfg
                        ? moment(record.locations.mfg).format('DD-MM-YYYY HH:mm')
                        : '',
                  }
                if (column.key === 'customer')
                  return {
                    ...column,
                    render: (text, record) => record.customer_info && record.customer_info.fullname,
                  }

                if (column.key === 'image')
                  return {
                    ...column,
                    render: (text, record) => (
                      <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
                        <img
                          style={{ width: 50, height: 50 }}
                          alt=""
                          src={record.images[0] || IMAGE_DEFAULT}
                        />
                      </ZoomImage>
                    ),
                  }

                return column
              })}
              size={SIZE_TABLE}
              summary={() => (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row></Table.Summary.Row>
                </Table.Summary>
              )}
              sticky
            />
          </div> */}
        </Modal>
      </>
    )
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setParamsFilter({ ...paramsFilter, page: 1, page_size: 20 })
    setValueSearch('')
  }

  useEffect(() => {
    _getActionsHistory()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['activityhistory']}>
      <BackToTop />
      <Row className="page-title" justify="space-between">
        <h3>Lịch sử thao tác</h3>
        <Space>
          <Button
            danger
            type="primary"
            onClick={_clearFilters}
            icon={<CloseCircleOutlined />}
            style={{
              display: Object.keys(paramsFilter).length <= 2 && 'none',
            }}
          >
            Xoá bộ lọc
          </Button>
          <Button onClick={_getActionsHistory} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumns
            columnsRender={columnsActivity}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsActivityHistory"
          />
        </Space>
      </Row>
      <div className={styles['activityhistory-content']}>
        <Row gutter={20} style={{ marginBottom: 15 }}>
          <Col span={7}>
            <Input.Search
              allowClear
              enterButton
              onChange={_search}
              value={valueSearch}
              placeholder="Tìm kiếm theo tên chức năng"
              className={styles['activityhistory-content-input--search']}
            />
          </Col>
          <Col span={7}>
            <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
          </Col>
          <Col span={5}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={styles['activityhistory-content-select--type']}
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo thao tác"
              style={{ width: '100%' }}
              value={paramsFilter['type' || undefined]}
              onChange={(value) => _filterByOption('type', value)}
            >
              <Select.Option value="Tạo">Tạo</Select.Option>
              <Select.Option value="Cập nhật">Cập nhật</Select.Option>
              <Select.Option value="Xóa">Xóa</Select.Option>
            </Select>
          </Col>
          <Col span={5}>
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={styles['activityhistory-content-select--properties']}
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo giao diện"
              style={{ width: '100%' }}
              value={paramsFilter['properties'] || undefined}
              onChange={(value) => _filterByOption('properties', value)}
            >
              {optionProperties.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Table
          scroll={{ y: '53vh', x: 'max-content' }}
          size={SIZE_TABLE}
          dataSource={activityHistory}
          loading={loading}
          columns={columns.map((column) => {
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (column.key === 'code')
              return {
                ...column,
                render: (text, record) => `#${record.new_data.code}`,
                // record.new_data &&
                // (record.type === 'Cập nhật' ? (
                //   <ModalCompereInfo order={record}>
                //     <a>#{record.new_data.code}</a>
                //   </ModalCompereInfo>
                // ) : (
                //   `#${record.new_data.code}`
                // )),
              }
            if (column.key === 'fullname')
              return {
                ...column,
                render: (text, record) => record.performer_info && record.performer_info.fullname,
              }
            if (column.key === 'date')
              return {
                ...column,
                render: (text, record) => moment(text).format('DD/MM/YYYY HH:mm'),
              }

            return column
          })}
          pagination={{
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showQuickJumper: true,
            total: countActivity,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
          }}
        />
      </div>
    </div>
  )
}
