import React, { useState, memo, useEffect } from 'react'
import { filterOptionSelect } from 'utils'
import { ROUTES } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Button, Input, Form, notification, Select, Radio, Row, Col, InputNumber } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

//apis
import { getSuppliers, addSupplier, updateSupplier } from 'apis/supplier'

//components
import BankAccount from './bank-account'
import DeliveryAddress from './delivery-address'
import Contact from './contact'
import Permission from 'components/permission'

export default memo(function SupplierForm() {
  const [form] = Form.useForm()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const queries = new URLSearchParams(location.search)
  const userId = queries.get('supplier_id')

  const [loading, setLoading] = useState(false)
  const [subjectType, setSubjectType] = useState('company')
  const [bankAccounts, setBankAccounts] = useState([])
  const [contacts, setContacts] = useState([])
  const [deliveryAddresses, setDeliveryAddresses] = useState([])

  const routeGoBack = ROUTES.SUPPLIER_LIST
  const goBack = () => history.push(routeGoBack)

  const _createOrUpdateSupplier = async () => {
    try {
      await form.validateFields()
      const formData = form.getFieldsValue()
      let body = {
        ...formData,
        supplier_group: formData.supplier_group || '',
        is_active: true,
        type: 'SUPPLIER',
        bank_accounts: bankAccounts,
        biz_id: dataUser.biz_id,
        permission: { is_view_all: false, viewer_ids: [] },
      }
      delete body.code
      if (body.tax_code !== undefined) {
        body.contacts = contacts
        body.delivery_address = deliveryAddresses
      }
      setLoading(true)
      let res
      if (userId) res = await updateSupplier(body, userId)
      else res = await addSupplier(body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${userId ? 'Cập nhật' : 'Thêm'} nhà cung cấp thành công!`,
          })
          goBack()
        } else
          notification.error({
            message: res.data.message || `${userId ? 'Cập nhật' : 'Thêm'} nhà cung cấp thất bại!`,
          })
      } else
        notification.error({
          message: res.data.message || `${userId ? 'Cập nhật' : 'Thêm'} nhà cung cấp thất bại!`,
        })
    } catch (error) {
      setLoading(false)
    }
  }

  const _getUserDetail = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await getSuppliers({ supplier_id: userId })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200) {
        if (res.data.data) {
          const subject = res.data.data
          form.setFieldsValue({ ...res.data.data })
          setBankAccounts(subject.bank_accounts)
          setContacts(subject.contacts)
          setDeliveryAddresses(subject.delivery_address)
        }
      }
    } catch (error) {
      dispatch({ type: 'LOADING', data: false })
      console.log(error)
    }
  }

  useEffect(() => {
    if (userId) _getUserDetail()
  }, [])

  return (
    <div>
      <Row
        className="page-title"
        justify="space-between"
        style={{ cursor: 'pointer' }}
        onClick={goBack}
      >
        <div>
          <ArrowLeftOutlined style={{ marginRight: '10px' }} />
          <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
            {userId ? 'Cập nhật' : 'Thêm mới'} nhà cung cấp
          </span>
        </div>
        <Button
          loading={loading}
          type="primary"
          style={{ minWidth: 100 }}
          onClick={_createOrUpdateSupplier}
        >
          {userId ? 'Cập nhật' : 'Thêm mới'}
        </Button>
      </Row>
      <div className="card">
        <Form
          labelAlign="left"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          labelWrap={true}
          form={form}
        >
          <div className="card">
            <Radio.Group value={subjectType} onChange={(e) => setSubjectType(e.target.value)}>
              <Radio value="company">
                <b style={{ fontSize: 15 }}>Tổ chức/ Doanh nghiệp</b>
              </Radio>
              <Radio value="individual">
                <b style={{ fontSize: 15 }}>Cá nhân</b>
              </Radio>
            </Radio.Group>
            <br />
            <br />
            <Row gutter={[60, 0]}>
              {subjectType === 'company' && (
                <Col lg={12} md={24} xs={24}>
                  <Form.Item initialValue="" name="tax_code" label="Mã số thuế">
                    <Input placeholder="Nhập mã số thuế" />
                  </Form.Item>
                </Col>
              )}
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="code" label="Mã nhà cung cấp">
                  <Input placeholder="Hệ thống tự tạo" disabled />
                </Form.Item>
              </Col>
              {/* {subjectType !== 'company' && ( */}
              <Col lg={12} md={24} xs={24}>
                <Form.Item
                  style={{ width: '100%' }}
                  name="supplier_group"
                  label="Nhóm nhà cung cấp"
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={filterOptionSelect}
                    placeholder="Chọn nhóm nhà cung cấp"
                  >
                    <Select.Option value="Vãng lai">Vãng lai</Select.Option>
                    <Select.Option value="Thân thiết">Thân thiết</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* )} */}
              <Col lg={12} md={24} xs={24}>
                <Form.Item
                  // rules={[
                  //   {
                  //     message: `Vui lòng nhập tên ${
                  //       subjectType === 'company' ? 'doanh nghiệp' : 'nhà cung cấp'
                  //     }`,
                  //     required: true,
                  //   },
                  // ]}
                  initialValue=""
                  name="name"
                  label={`Tên ${subjectType === 'company' ? 'doanh nghiệp' : 'nhà cung cấp'}`}
                >
                  <Input
                    placeholder={
                      subjectType === 'company'
                        ? 'Tự động điền thông tin sau khi nhập mã số thuế'
                        : 'Nhập tên nhà cung cấp'
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="address" label="Địa chỉ">
                  <Input
                    placeholder={
                      subjectType === 'company'
                        ? 'Tự động điền thông tin sau khi nhập mã số thuế'
                        : 'Nhập địa chỉ'
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="deputy" label="Người đại diện pháp luật">
                  <Input placeholder="Nhập người đại diện pháp luật" />
                </Form.Item>
              </Col>
              {subjectType === 'company' && (
                <Col lg={12} md={24} xs={24}>
                  <Form.Item initialValue="" name="website_url" label="Website">
                    <Input placeholder="Nhập website" />
                  </Form.Item>
                </Col>
              )}
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="email" label="Email">
                  <Input placeholder="Nhập email" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="maximum_debt" label="Số nợ tối đa">
                  <InputNumber style={{ width: '100%' }} placeholder="Nhập số nợ tối đa" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="phone" label="Điện thoại">
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="day_can_debt" label="Số ngày được nợ">
                  <InputNumber style={{ width: '100%' }} placeholder="Nhập số ngày được nợ" />
                </Form.Item>
              </Col>
              {/* <Col lg={12} md={24} xs={24}>
                <Row align="middle" wrap={false} style={{ width: '100%' }}>
                  <Form.Item
                    style={{ width: '100%' }}
                    initialValue=""
                    name="supporter_id"
                    label="Nhân viên mua hàng phụ trách"
                  >
                    <Select
                      style={{ marginLeft: 3, width: '98%' }}
                      allowClear
                      showSearch
                      filterOption={filterOptionSelect}
                      placeholder="Chọn nhân viên bán hàng phụ trách"
                      
                    >
                      {users.map((user) => (
                        <Select.Option value={user.user_id} key={user.user_id}>
                          {user.name || 'Chưa có'}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Link
                    target="_blank"
                    to={ROUTES.USER_CREATE.replace(':business', dataUser.slug_name)}
                  >
                    <PlusCircleOutlined
                      style={{ marginBottom: 25, fontSize: 16, color: '#FC7900' }}
                    />
                  </Link>
                </Row>
              </Col> */}
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="terms_of_payment" label="Điều khoản thanh toán">
                  <Input placeholder="Nhập điều khoản thanh toán" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="note_1" label="Ghi chú 1">
                  <Input placeholder="Nhập ghi chú 1" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} xs={24}>
                <Form.Item initialValue="" name="note_2" label="Ghi chú 2">
                  <Input placeholder="Nhập ghi chú 2" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <br />
          <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />
          <br />

          {subjectType === 'company' && (
            <>
              <DeliveryAddress
                deliveryAddresses={deliveryAddresses}
                setDeliveryAddresses={setDeliveryAddresses}
              />
              <br />

              <Contact contacts={contacts} setContacts={setContacts} />
            </>
          )}
        </Form>
      </div>
    </div>
  )
})
