import React, { memo } from 'react'

//antd
import { Input, Row, Table } from 'antd'
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons'

export default memo(function DeliveryAddress({ deliveryAddresses = [], setDeliveryAddresses }) {
  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 70,
    },
    {
      title: 'Người nhận',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.receiver_name}
            placeholder="Nhập tên người nhận"
            onBlur={(e) => handleUpdateItem('receiver_name', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('receiver_name', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Số điện thoại',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.phone}
            placeholder="Nhập số điện thoại"
            onBlur={(e) => handleUpdateItem('phone', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('phone', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: 'Địa chỉ',
      render: (text, record, index) => {
        const InputValue = () => (
          <Input
            bordered={false}
            allowClear
            defaultValue={record.address}
            placeholder="Nhập địa chỉ"
            onBlur={(e) => handleUpdateItem('address', e.target.value, index)}
            onPressEnter={(e) => handleUpdateItem('address', e.target.value, index)}
          />
        )
        return <InputValue />
      },
    },
    {
      title: '',
      render: (text, record, index) => (
        <CloseCircleFilled
          onClick={() => handleDeleteItem(index)}
          style={{ fontSize: 20, cursor: 'pointer', color: 'red' }}
        />
      ),
      width: 38,
    },
  ]

  const handleUpdateItem = (key, value, index) => {
    const deliveryAddressesNew = [...deliveryAddresses]
    deliveryAddressesNew[index][key] = value
    setDeliveryAddresses([...deliveryAddressesNew])
  }

  const handleAddItem = () => {
    const initItem = {
      receiver_name: '',
      phone: '',
      address: '',
    }
    deliveryAddresses.push(initItem)
    setDeliveryAddresses([...deliveryAddresses])
  }

  const handleDeleteItem = (index) => {
    if (index !== -1) {
      const deliveryAddressesNew = [...deliveryAddresses]
      deliveryAddressesNew.splice(index, 1)
      setDeliveryAddresses([...deliveryAddressesNew])
    }
  }

  return (
    <div className="card">
      <b>ĐỊA CHỈ GIAO HÀNG</b>
      <Table
        pagination={false}
        bordered={true}
        dataSource={deliveryAddresses}
        columns={columns}
        size="small"
        footer={() => (
          <Row
            onClick={handleAddItem}
            justify="center"
            align="middle"
            style={{ color: '#6C757D', cursor: 'pointer' }}
          >
            <PlusOutlined style={{ marginRight: 5, fontSize: 10 }} />
            <div>Thêm mới địa chỉ giao hàng</div>
          </Row>
        )}
      />
    </div>
  )
})
