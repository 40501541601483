import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import styles from './employee-management.module.scss'
import { PAGE_SIZE_OPTIONS, PERMISSIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import { isAcceptPermissions, saveFiltersToLocalStorage } from 'utils'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'
import SettingColumns from 'components/setting-columns'
import Permission from 'views/permission'
import columnsEmployeeManagement from './columns'
import FilterDate from 'components/filter-date'

//apis
import { deleteUsers, getUsers } from 'apis/user'
import { getRoles } from 'apis/role'

//antd
import { Row, Button, Input, Space, Select, Table, notification, Col, Popconfirm, Tag } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons'

const defaultOptionSearch = 'fullname'
export default function EmployeeManagement() {
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const KEY_FILTER = 'employeeFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [columns, setColumns] = useState([])

  const [dataUsers, setDataUsers] = useState([])
  const [countEmployee, setCountEmployee] = useState(0)
  const [dataRoles, setDataRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100 }
  )
  const [valueSearch, setValueSearch] = useState(
    filters ? filters.fullname || filters.code || filters.username || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.fullname && 'fullname') ||
          (filters.code && 'code') ||
          (filters.username && 'username') ||
          'fullname'
      : 'fullname'
  )
  const [roleOption, setRoleOption] = useState()
  const [warehouseOption, setWarehouseOption] = useState()

  const _deleteEmployee = async (id) => {
    try {
      const res = await deleteUsers({ user_id: [id] })
      if (res.status === 200) {
        notification.success({ message: 'Xóa user thành công!' })
        _getUsers()
      } else {
        notification.error({
          message: res.data.message || 'Xóa user thất bại!',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _search = (e) => {
    const value = e.target.value
    setValueSearch(value)

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _clearFilters = () => {
    setValueSearch()
    setWarehouseOption()
    setRoleOption()
    setOptionSearch(defaultOptionSearch)
    setParamsFilter({ page: 1, page_size: 10 })
  }

  const _getUsers = async () => {
    setLoading(true)
    try {
      const res = await getUsers(paramsFilter)
      if (res.status === 200) {
        const data = res.data.data.filter((item) => item.role_id !== -1)
        setDataUsers(data)
        setCountEmployee(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _getRoles = async () => {
    try {
      const res = await getRoles()
      if (res.status === 200) {
        const data = res.data.data.filter((role) => role.role_id !== -1)
        setDataRoles(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getUsers()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getRoles()
  }, [])

  return (
    <div className={styles['employee-management']}>
      <Row className="page-title" justify="space-between">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>Quản lý nhân viên</span>
        <Space>
          {(valueSearch ||
            optionSearch !== defaultOptionSearch ||
            warehouseOption ||
            roleOption) && (
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={_clearFilters}>
              Xóa bộ lọc
            </Button>
          )}
          <Button onClick={_getUsers} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumns
            columnsDefault={columnsEmployeeManagement}
            columns={columns}
            setColumns={setColumns}
            nameColumn="ColumnsEmployeeManagement"
          />
          <Permission permissions={[PERMISSIONS.tao_nhan_vien]}>
            <Button
              className={styles['btn-add']}
              onClick={() => history.push(ROUTES.EMPLOYEE_CREATE)}
              type="primary"
            >
              Thêm nhân viên
            </Button>
          </Permission>
        </Space>
      </Row>
      <Row gutter={20} style={{ marginBottom: 25 }}>
        <Col span={9} style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <Input.Group compact className={styles['employee-management-input-item']}>
            <Input
              style={{ width: '65%' }}
              allowClear
              enterButton
              value={valueSearch}
              onChange={_search}
              placeholder="Tìm kiếm theo"
            />
            <Select
              style={{ width: '35%' }}
              suffixIcon={<SuffixIconCustom />}
              className={styles['employee-management-input-item']}
              value={optionSearch}
              onChange={(value) => {
                delete paramsFilter[optionSearch]
                if (valueSearch) {
                  paramsFilter[value] = valueSearch
                }
                setParamsFilter({ ...paramsFilter })
                setOptionSearch(value)
              }}
            >
              <Select.Option value="fullname">Tên nhân viên</Select.Option>
              <Select.Option value="code">Mã nhân viên</Select.Option>
              <Select.Option value="username">Tên tài khoản</Select.Option>
            </Select>
          </Input.Group>
        </Col>

        <Col span={5}>
          <Select
            dropdownClassName="dropdown-select-custom"
            style={{ width: '100%' }}
            suffixIcon={<SuffixIconCustom />}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={roleOption}
            onChange={(value) => {
              paramsFilter.role_id = value
              setParamsFilter({ ...paramsFilter })
              setRoleOption(value)
            }}
            placeholder="Lọc theo vai trò"
          >
            {dataRoles.map((role) => {
              return (
                <Select.Option style={{ textTransform: 'uppercase' }} value={role.role_id}>
                  {role.name}
                </Select.Option>
              )
            })}
          </Select>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
      </Row>
      <Table
        scroll={{ x: 'max-content' }}
        style={{ width: '100%' }}
        rowKey={'user_id'}
        dataSource={dataUsers}
        columns={columns.map((column) => {
          if (column.key === 'number')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }

          if (column.key === 'create_date')
            return {
              ...column,
              render: (text) => {
                const content = moment(text).format('DD/MM/YYYY hh:mm')
                return <div style={{ paddingRight: 5 }}>{content}</div>
              },
            }
          if (column.key === 'role')
            return {
              ...column,
              render: (text, record) => record.role_info && <Tag>{record.role_info.name}</Tag>,
            }
          if (column.key === 'address')
            return {
              ...column,
              render: (text, record) => {
                const { address, ward, district, province } = record
                // filter để bỏ string rỗng
                const content = [address, ward, district, province]
                  .filter((item) => item)
                  .join(', ')
                return (
                  <div style={{ textAlign: 'left' }}>
                    {address || ward || district || province ? content : ''}
                  </div>
                )
              },
            }

          if (column.key === 'username')
            return {
              ...column,
              render: (text, record) =>
                isAcceptPermissions([PERMISSIONS.cap_nhat_nhan_vien]) ? (
                  <Link
                    to={{ pathname: ROUTES.EMPLOYEE_CREATE, state: record }}
                    style={{ color: '#0017E3' }}
                  >
                    {text}
                  </Link>
                ) : (
                  <b>{text}</b>
                ),
            }
          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <Permission permissions={[PERMISSIONS.xoa_nhan_vien]}>
                  <Popconfirm
                    placement="topRight"
                    title="Bạn có muốn xóa nhân viên này không?"
                    onConfirm={() => _deleteEmployee(record.user_id)}
                    okText="Đồng ý"
                    cancelText="Từ chối"
                  >
                    <Button danger type="primary">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Permission>
              ),
            }

          return column
        })}
        pagination={{
          total: countEmployee,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          position: POSITION_TABLE,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.page_size = pageSize
            setParamsFilter({ ...paramsFilter })
          },
        }}
        size={SIZE_TABLE}
        loading={loading}
      />
    </div>
  )
}
