import React, { useEffect, useState } from 'react'
import styles from './debit.module.scss'
import moment from 'moment'
import { formatCash, tableSum, saveFiltersToLocalStorage, handleGetDataExportExcel } from 'utils'
import { POSITION_TABLE } from 'consts'
import { useSelector } from 'react-redux'

//components
import Export from 'components/export-csv'
import BackToTop from 'components/back-to-top'
import FilterDate from 'components/filter-date'
import SuffixIconCustom from 'components/suffix-icon-custom'

// antd
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Select, Table, Row, Modal, Space } from 'antd'

// apis
import { getDebit } from 'apis/debit'
import { getWarehouses } from 'apis/warehouse'
import { getCustomers } from 'apis/customer'

export default function Debit() {
  const KEY_FILTER = 'debitFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const dataUser = useSelector((state) => state.login.dataUser)

  const [loadingTable, setLoadingTable] = useState(false)
  const [debitList, setDebitList] = useState([])
  const [countPage, setCountPage] = useState('')
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )

  const [modalVisible, setModalVisible] = useState(false)
  const [dataModal, setDataModal] = useState('')

  const [warehouseOption, setWarehouseOption] = useState()
  const [customerOption, setCustomerOption] = useState()
  const [warehouses, setWarehouses] = useState([])
  const [customers, setCustomers] = useState([])

  const toggleModal = (data) => {
    setDataModal(data)
    setModalVisible(!modalVisible)
  }

  const columns = [
    {
      title: 'STT',
      width: 60,
      align: 'center',
      render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
    },
    {
      title: 'Doanh nghiệp',
      align: 'center',
      render: (text, record) => record?.customer_info && record?.customer_info?.fullname,
    },
    {
      title: 'Kho phát sinh',
      align: 'center',
      render: (text, record) => record?.warehouse_info && record?.warehouse_info?.name,
    },
    {
      title: 'Tên khoản phí',
      dataIndex: 'name',
      align: 'center',
      render: (text, record) => text && text,
    },
    {
      title: 'Khoản chi',
      dataIndex: 'value',
      align: 'center',
      sorter: (a, b) => a.content - b.content,
      render: (text, record) => <span>{text && formatCash(text)}</span>,
    },
    {
      title: 'Mô tả',
      dataIndex: `description`,
      align: 'center',
      render: (text, record) => text && text,
    },
    {
      title: 'Loại chi phí',
      dataIndex: 'type',
      align: 'center',
      // render: (text, record) => <a onClick={() => toggleModal(record)}>{text && text}</a>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      align: 'center',
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
      render: (text) => text && moment(text).format('DD/MM/YYYY hh:mm'),
    },
  ]

  const _getDebit = async () => {
    try {
      setLoadingTable(true)
      const res = await getDebit({
        ...paramsFilter,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        setDebitList(res.data.data)
        setCountPage(res.data.count)
        setLoadingTable(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _resetFilter = () => {
    setWarehouseOption()
    setCustomerOption()
    setParamsFilter({ page: 1, page_size: 20 })
  }

  const _getCustomer = async () => {
    try {
      const res = await getCustomers()
      if (res.status === 200) {
        if (res.data.success) {
          setCustomers(res.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getDebitExport = async (query = {}) => {
    try {
      const data = await handleGetDataExportExcel(getDebit, {
        ...query,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (data) {
        return data.map((data, index) => {
          return {
            STT: index + 1,
            'Doanh nghiệp': data.customer_info ? data.customer_info.fullname || '' : '',
            'Kho phát sinh': data.warehouse_info ? data.warehouse_info.name || '' : '',
            'Tên khoản phí': data?.name || '',
            'Khoản phí': formatCash(data?.value) || '',
            'Mô tả': data?.description || '',
            'Loại phí': data?.type || '',
            'Ngày tạo': data.create_date && moment(data.create_date).format('DD-MM-YYYY HH:mm'),
          }
        })
      }
      return []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  useEffect(() => {
    _getCustomer()
    _getWarehouses()
  }, [])

  useEffect(() => {
    _getDebit()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['body-debit']}>
      <BackToTop />
      <Modal
        title="Thông tin chi tiết loại phí"
        centered
        visible={modalVisible}
        onCancel={toggleModal}
        footer={null}
        className="custom-modal-debit"
        width={630}
      >
        <div style={{ fontSize: 15, color: '#223b4f' }}>
          <p>Tên khoản phí: {dataModal.name && dataModal.name}</p>
          <p>Giá trị: {dataModal.value && formatCash(dataModal.value)}</p>
          <p>Ngày thu: </p>
          <p>Người thu:</p>
          <p>Nội dung: {dataModal.description && dataModal.description}</p>
        </div>
      </Modal>
      <Row className="page-title" justify="space-between" style={{ marginBottom: 10 }}>
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>Quản lý công nợ</span>
        <Space size="middle">
          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách công nợ"
            fileName={'Danh sách công nợ ' + moment().format('DD-MM-YYYY')}
            exportData={() => _getDebitExport(paramsFilter)}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách công nợ"
            fileName={'Danh sách công nợ ' + moment().format('DD-MM-YYYY')}
            exportData={() => _getDebitExport()}
          />
        </Space>
      </Row>

      <Row wrap={false} justify="space-between">
        <Space>
          <Select
            style={{ width: 300 }}
            suffixIcon={<SuffixIconCustom />}
            placeholder="Lọc doanh nghiệp"
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={customerOption}
            onChange={(value) => {
              setCustomerOption(value)
              if (value) paramsFilter.customer_id = value
              else delete paramsFilter.customer_id
              setParamsFilter({ ...paramsFilter })
            }}
          >
            {customers.map((customer) => {
              return <Select.Option value={customer.customer_id}>{customer.fullname}</Select.Option>
            })}
          </Select>

          <Select
            style={{ width: 300 }}
            suffixIcon={<SuffixIconCustom />}
            placeholder="Lọc theo kho"
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={warehouseOption}
            onChange={(value) => {
              setWarehouseOption(value)
              if (value) paramsFilter.warehouse_id = value
              else delete paramsFilter.warehouse_id
              setParamsFilter({ ...paramsFilter })
            }}
          >
            {warehouses.map((warehouse) => {
              return <Select.Option value={warehouse.warehouse_id}>{warehouse.name}</Select.Option>
            })}
          </Select>
          <FilterDate width={250} paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />

          <Button
            onClick={_resetFilter}
            type="danger"
            style={{ display: Object.keys(paramsFilter).length < 3 && 'none' }}
            icon={<FilterOutlined />}
          >
            Xóa bộ lọc
          </Button>
        </Space>
        <Button icon={<ReloadOutlined />} type="primary" onClick={_getDebit}>
          Làm mới
        </Button>
      </Row>

      <Table
        style={{ width: '100%', marginTop: 10 }}
        rowKey="debit_id"
        size="small"
        scroll={{ y: '50vh' }}
        loading={loadingTable}
        columns={columns}
        dataSource={debitList}
        pagination={{
          position: POSITION_TABLE,
          total: countPage,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          onChange(page, pageSize) {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
        summary={(pageData) => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              {columns.map((e, index) => {
                if (e.title === 'STT')
                  return (
                    <Table.Summary.Cell>
                      <b>Tổng</b>
                    </Table.Summary.Cell>
                  )
                if (e.dataIndex === 'value')
                  return (
                    <Table.Summary.Cell>
                      <b>{pageData && formatCash(tableSum(pageData, 'value'))}</b>
                    </Table.Summary.Cell>
                  )
                return <Table.Summary.Cell></Table.Summary.Cell>
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
}
