import React, { useState } from 'react'
import { Table, Modal } from 'antd'
import { formatCash } from 'utils'

const ModalFees = ({ fees }) => {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const columns = [
    { title: 'Tên chi phí', dataIndex: 'fee_name' },
    { title: 'Mã chi phí', dataIndex: 'fee_code' },
    { title: 'Chi phí', render: (text, record) => formatCash(record.fee || 0) },
    { title: 'Mô tả', dataIndex: 'description' },
  ]

  return (
    <>
      <a onClick={toggle}>Xem chi tiết</a>
      <Modal
        style={{ top: 20 }}
        footer={null}
        width="70%"
        title="Danh sách chi phí kho"
        onCancel={toggle}
        visible={visible}
      >
        <Table dataSource={fees || []} columns={columns} size="small" />
      </Modal>
    </>
  )
}

export default ModalFees
