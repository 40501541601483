import React, { useEffect, useRef, useState } from 'react'
import { POSITION_TABLE, PAGE_SIZE_OPTIONS } from 'consts'
import styles from './order.module.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { formatCash, tableSum, _processing } from 'utils'

//icons
import { DeleteOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons'

// antd
import {
  Button,
  Input,
  Select,
  Table,
  DatePicker,
  Row,
  Col,
  Form,
  Popconfirm,
  Tabs,
  Badge,
} from 'antd'

// apis
import { getExportOrders } from 'apis/order'
import { enumExport } from 'apis/enum'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'
import BackToTop from 'components/back-to-top'

const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input
export default function Order() {
  const dataUser = useSelector((state) => state.login.dataUser)
  const [form] = Form.useForm()

  const [statusList, setStatusList] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [selectKeys, setSelectKeys] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [countOrder, setCountOrder] = useState(0)
  const [orders, setOrders] = useState([])
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 15 })
  const [attributeDate, setAttributeDate] = useState(undefined)
  const [valueDateSearch, setValueDateSearch] = useState(null)
  const [valueSearch, setValueSearch] = useState('')
  const [openSelect, setOpenSelect] = useState(false)
  const typingTimeoutRef = useRef(null)

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      key: 'code',
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      render: (text, record) => <span>#{text}</span>,
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: 'Kênh bán hàng',
      dataIndex: 'platform',
      sorter: (a, b) => a.platform.length - b.platform.length,
    },
    {
      title: 'Đơn vị vận chuyển',
      render: (text, record) => record.shipping_info && record.shipping_info.shipping_company_name,
    },
    {
      title: 'Mã vận đơn',
      render: (text, record) => record.shipping_info && record?.shipping_info?.shipping_code,
    },
    {
      key: 'cod',
      title: 'COD',
      dataIndex: `shipping_info`,
      render: (shipping_info, record) =>
        shipping_info && <span>{formatCash(shipping_info.cod || 0)}</span>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'create_date',
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
      render: (text) => text && moment(text).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: '',
      render: (text, record) =>
        !record.is_received && (
          <Popconfirm
            okText="Đồng ý"
            cancelText="Từ chối"
            title="Bạn có muốn xóa đơn hàng này không?"
          >
            <Button danger type="primary" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
    },
  ]

  const columnsChild = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Mã sản phẩm',
      // sorter: (a, b) => a.barcode.length - b.barcode.length,
      render: (text, record) => record.barcode ? record.barcode : record.code,
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Hình ảnh',
      render: (text, record) =>
        record.images &&
        record.images.length && (
          <img src={record.images[0]} alt="" style={{ width: 60, height: 60 }} />
        ),
    },
    {
      title: 'Đơn vị',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
      sorter: (a, b) => a.cbm - b.cbm,
      render: (text, record) => text && formatCash(text || 0),
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text, record) => text && formatCash(text || 0),
    },
  ]

  const _getOrders = async () => {
    try {
      setLoadingTable(true)
      const res = await getExportOrders({
        ...paramsFilter,
        customer_id: dataUser ? dataUser.customer_id || '' : '',
      })
      if (res.status === 200) {
        setCountOrder(res.data.count)
        setOrders(res.data.data)
      }
      console.log(res)
      setLoadingTable(false)
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const onChangeOptionSearchDate = (value) => {
    delete paramsFilter[attributeDate]
    if (value) paramsFilter[value] = true
    else delete paramsFilter[value]
    setAttributeDate(value)
    setParamsFilter({ ...paramsFilter })
    if (openSelect) setOpenSelect(!openSelect)
  }

  const _onFilter = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _getEnumOrder = async () => {
    try {
      const res = await enumExport()
      if (res.status === 200)
        if (res.data.data && res.data.data.status) {
          setStatusList(res.data.data.status)
          setParamsFilter({ ...paramsFilter, status: res.data.data.status[0].value })
        }
    } catch (error) {
      console.log(error)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      if (value) paramsFilter.code = value
      else delete paramsFilter.code
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _resetFilter = () => {
    setAttributeDate(undefined)
    setValueSearch('')
    setValueDateSearch(null)
    setParamsFilter({ page: 1, page_size: 15 })
  }

  useEffect(() => {
    _getEnumOrder()
  }, [])

  useEffect(() => {
    if (paramsFilter.status) _getOrders()
  }, [paramsFilter])

  return (
    <div className={styles['body-order']}>
      <Row className="page-title" justify="space-between">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>Quản lý đơn hàng</span>
      </Row>
      <BackToTop />
      <Row style={{ marginTop: 15, marginBottom: 15 }} gutter={[15, 15]}>
        <Col xs={24} xl={6}>
          <Input
            placeholder="Tìm kiếm theo mã đơn hàng"
            allowClear
            prefix={<SearchOutlined />}
            onChange={_search}
            value={valueSearch}
          />
        </Col>
        <Col xs={24} xl={6}>
          <Select
            style={{ width: '100%' }}
            value={attributeDate}
            onChange={onChangeOptionSearchDate}
            placeholder="Lọc theo thời gian"
            allowClear
            open={openSelect}
            suffixIcon={<SuffixIconCustom />}
            onBlur={() => {
              if (openSelect) setOpenSelect(!openSelect)
            }}
            onClick={() => {
              if (!openSelect) setOpenSelect(!openSelect)
            }}
            dropdownRender={(menu) => (
              <>
                <RangePicker
                  style={{ width: '100%' }}
                  onFocus={() => {
                    if (!openSelect) setOpenSelect(!openSelect)
                  }}
                  onBlur={() => {
                    if (openSelect) setOpenSelect(!openSelect)
                  }}
                  value={valueDateSearch}
                  onChange={(dates, dateStrings) => {
                    //khi search hoac filter thi reset page ve 1
                    paramsFilter.page = 1

                    if (openSelect) setOpenSelect(!openSelect)

                    //nếu search date thì xoá các params date
                    delete paramsFilter.to_day
                    delete paramsFilter.yesterday
                    delete paramsFilter.this_week
                    delete paramsFilter.last_week
                    delete paramsFilter.last_month
                    delete paramsFilter.this_month
                    delete paramsFilter.this_year
                    delete paramsFilter.last_year

                    //Kiểm tra xem date có được chọn ko
                    //Nếu ko thì thoát khỏi hàm, tránh cash app
                    //và get danh sách order
                    if (!dateStrings[0] && !dateStrings[1]) {
                      delete paramsFilter.from_date
                      delete paramsFilter.to_date

                      setValueDateSearch(null)
                      setAttributeDate()
                    } else {
                      const dateFirst = dateStrings[0]
                      const dateLast = dateStrings[1]
                      setValueDateSearch(dates)
                      setAttributeDate(`${dateFirst} -> ${dateLast}`)

                      dateFirst.replace(/-/g, '/')
                      dateLast.replace(/-/g, '/')

                      paramsFilter.from_date = dateFirst
                      paramsFilter.to_date = dateLast
                    }

                    setParamsFilter({ ...paramsFilter })
                  }}
                />
                {menu}
              </>
            )}
          >
            <Option value="today">Hôm nay</Option>
            <Option value="yesterday">Hôm qua</Option>
            <Option value="this_week">Tuần này</Option>
            <Option value="last_week">Tuần trước</Option>
            <Option value="this_month">Tháng này</Option>
            <Option value="last_month">Tháng trước</Option>
            <Option value="this_year">Năm này</Option>
            <Option value="last_year">Năm trước</Option>
          </Select>
        </Col>

        <Col xs={24} xl={6}>
          <Select>{}</Select>
        </Col>

        <Col>
          <Button
            onClick={_resetFilter}
            type="danger"
            style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>

      <div>
        <Tabs activeKey={paramsFilter.status} onChange={(value) => _onFilter('status', value)}>
          {statusList.map((status) => (
            <Tabs.TabPane
              tab={
                <Badge overflowCount={9999} offset={[10, -6]} count={status.total || 0} showZero>
                  <div>{status.label}</div>
                </Badge>
              }
              key={status.value}
            />
          ))}
        </Tabs>
      </div>
      <Table
        rowKey="code"
        size="small"
        loading={loadingTable}
        columns={columns}
        dataSource={orders}
        rowSelection={{
          hideSelectAll: true,
          selectedRowKeys: selectKeys,
          onChange: (keys) => {
            setSelectKeys(keys)
          },
        }}
        pagination={{
          position: POSITION_TABLE,
          total: countOrder,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange(page, pageSize) {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
        expandable={{
          expandedRowKeys: selectKeys,
          expandIconColumnIndex: -1,
          expandedRowRender: (order) => {
            return (
              <div style={{ padding: '10px 30px' }}>
                <Row gutter={20}>
                  <Col lg={8} xl={8}>
                    <div style={{ textAlign: 'left' }}>
                      <span style={{ fontSize: 17, fontWeight: 700 }}>Thông tin khách hàng</span>
                      <h4>
                        <b>Tên khách hàng:</b> {order.customer_info && order.customer_info.fullname}
                      </h4>
                      <h4>
                        <b>Mã khách hàng:</b> #{order.customer_info && order.customer_info.code}
                      </h4>
                      <h4>
                        <b>Số điện thoại:</b> #{order.customer_info && order.customer_info.phone}
                      </h4>
                      <h4>
                        <b>Địa chỉ:</b>{' '}
                        {order.customer_info &&
                          `${order.customer_info.address}, ${order.customer_info.ward}, ${order.customer_info.district}, ${order.customer_info.province}`}
                      </h4>
                    </div>
                  </Col>
                  <Col lg={8} xl={8}>
                    <div style={{ textAlign: 'left' }}>
                      <span style={{ fontSize: 17, fontWeight: 700 }}>Thông tin giao hàng</span>
                      <h4>
                        <b>Địa chỉ gửi:</b>{' '}
                        {order.shipping_info &&
                          `${order.shipping_info.return_address}, ${order.shipping_info.return_ward}, ${order.shipping_info.return_district}, ${order.shipping_info.return_province}`}
                      </h4>
                      <h4>
                        <b>Địa chỉ nhận:</b>{' '}
                        {order.shipping_info &&
                          `${order.shipping_info.to_address}, ${order.shipping_info.to_ward}, ${order.shipping_info.to_district}, ${order.shipping_info.to_province}`}
                      </h4>
                      <h4>
                        <b>Nhân viên giao:</b>{' '}
                        {order.shipping_info &&
                          order.shipping_info.shipping_employee?.employee_name}
                      </h4>
                      <h4>
                        <b>Mã nhân viên giao:</b>{' '}
                        {order.shipping_info &&
                          order.shipping_info.shipping_employee?.employee_name}
                      </h4>
                    </div>
                  </Col>
                  {/* <Col lg={8} xl={8}>
                    <div style={{ textAlign: 'left' }}>
                      <span style={{ fontSize: 17, fontWeight: 700 }}>Thông tin đơn hàng</span>
                      <h4>Tổng số lượng sản phẩm: {order.products.length}</h4>
                      <h4>Tổng tiền :  123</h4>
                      <h4>Chiết khấu : 0</h4>
                      <h4>Thành tiền :  123</h4>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <h4
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: '#445268',
                      marginBottom: 0,
                      marginTop: 10,
                    }}
                  >
                    Danh sách sản phẩm
                  </h4>
                  <Table
                    style={{ width: '100%' }}
                    size="small"
                    columns={columnsChild}
                    dataSource={order.products}
                    pagination={false}
                  />
                </Row>
              </div>
            )
          },
        }}
        // summary={(pageData) => {
        //   return (
        //     <Table.Summary.Row>
        //       <Table.Summary.Cell>
        //         <b>Tổng</b>
        //       </Table.Summary.Cell>
        //       {columns.map((e, index) => {
        //         if (e.key === 'cod')
        //           return (
        //             <Table.Summary.Cell>
        //               <b>{formatCash(tableSum(pageData, 'shipping_info.cod'))}</b>
        //             </Table.Summary.Cell>
        //           )
        //         return <Table.Summary.Cell></Table.Summary.Cell>
        //       })}
        //     </Table.Summary.Row>
        //   )
        // }}
      />
    </div>
  )
}
