import React from 'react'
import styles from './login.module.scss'
import { useDispatch } from 'react-redux'
import { Link, Route, useHistory, useLocation } from 'react-router-dom'

// img
import banner from 'assets/images/SGL_banner.png'
import banner2 from 'assets/images/login_banner2.svg'

// api
import { login } from 'apis/auth'

// antd
import { Col, Row, Form, Input, Button, notification } from 'antd'
import { ACTION, ROUTES, TYPE_DEPLOY } from 'consts'
import { LockOutlined, UserOutlined } from '@ant-design/icons'

export default function Login() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()

  const _login = async () => {
    try {
      await form.validateFields()
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        username: form.getFieldsValue().username,
        password: form.getFieldsValue().password,
      }
      const res = await login(body)
      if (res.status === 200) {
        if (res.data.success) {
          dispatch({ type: ACTION.LOGIN, data: res.data.data })
          history.push(ROUTES.DASHBOARD)
        } else
          notification.error({
            message: res.data.message || 'Đăng nhập thất bại, vui lòng thử lại',
          })
      } else
        notification.error({ message: res.data.message || 'Đăng nhập thất bại, vui lòng thử lại' })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (err) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(err)
    }
  }

  return (
    <div className={styles['login']}>
      <Row>
        <Col xs={24} lg={12} xl={10}>
          <img src={banner} alt="" style={{ minWidth: '1024px' }} />
          <div className={styles['login-form']}>
            <h3 align="center">
              {process.env.REACT_APP_API_ENDPOINT_DEV === 'https://apisgl.cbox.vn/api'
                ? 'SAND BOX'
                : 'LIVE'}
            </h3>
            <h2 align="center">Đăng nhập</h2>
            <Form form={form} onFinish={_login} layout="vertical" requiredMark={false}>
              <Form.Item
                name="username"
                label="Tên tài khoản"
                rules={[{ required: true, message: 'Vui lòng nhập tài khoản' }]}
              >
                <Input
                  autoFocus
                  size="large"
                  placeholder="Nhập tài khoản"
                  prefix={<UserOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="Nhập mật khẩu"
                  prefix={<LockOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Row justify="space-between">
                <Link
                  target={'_blank'}
                  style={{
                    display: 'block',
                    textAlign: 'right',
                    color: 'black',
                  }}
                  to={ROUTES.PRIVACY}
                >
                  Chính sách bảo mật
                </Link>
                {/* <Link
                  style={{ display: 'block', textAlign: 'right', color: 'black' }}
                  to={ROUTES.FORGET_PASSWORD}
                >
                  Quên mật khẩu ?
                </Link> */}
              </Row>

              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      marginTop: 20,
                      width: '100%',
                    }}
                  >
                    Đăng nhập
                  </Button>
                </Form.Item>
                {/* <Link to={ROUTES.REGISTER}>
                  <Button
                    type="primary"
                    style={{ marginTop: 10, width: '100%', backgroundColor: 'green' }}
                  >
                    Đăng ký
                  </Button>
                </Link> */}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
