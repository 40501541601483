import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import localStorage from 'localstorage-memory'

import queryString from 'query-string'
import { Button, Form, Input, Modal, notification } from 'antd'
import { ROUTES } from 'consts'
import { ACTION } from 'consts'
//components

//apis
import { addSite } from '../../apis/site'
import { useDispatch } from 'react-redux'

const ConnectTiktok = () => {
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const query = queryString.parse(location.search)
  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState(true)
  const data = { code: query.code, phone: query.state }
  const showInput = () => {
    if (data.code?.length && data.phone?.length) {
      setIsModalVisible(true)
    } else {
      notification.error({ message: 'Thất bại' })
      history.push(ROUTES.DASHBOARD)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }
  const onFinish = async () => {
    try {
      const dataForm = form.getFieldsValue()
      var body = {
        ...data,
        site_name: dataForm.site_name || 'Lazada',
        platform: 'Tiktok',
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await addSite(body)
      console.log('res', res)
      if (res.status === 200) {
        notification.success({ message: 'Kết nối thành công ' })
      } else
        notification.error({
          message: 'Error',
          description: res.data.message || res.data.mess || 'Kết nối thành thất bại',
        })
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.DASHBOARD)
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.DASHBOARD)
    }
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    showInput()
  }, [])

  return (
    <div>
      <Modal
        title="Nhập tên cổng kết nối"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <>
            <Button type="primary" onClick={() => onFinish()}>
              {' '}
              Kết nối
            </Button>
          </>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="site_name">
            <Input placeholder="Nhập tên cổng kết nối" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ConnectTiktok
