const columns = [
  {
    title: 'STT',
    key: 'stt',
    width: 60,
  },
  {
    title: 'Mã nhà cung cấp',
    key: 'code',
    width: 150,
  },
  {
    title: 'Tên nhà cung cấp',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Nhóm NCC',
    width: 150,
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    width: 150,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 150,
  },
  // {
  //   title: 'Người tạo',
  //   key: 'creator',
  // },
  {
    title: 'Ngày tạo',
    key: 'create_date',
    width: 110,
  },
  {
    title: 'Hành động',
    key: 'action',
    width: 100,
  },
]

export default columns
