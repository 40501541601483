import React, { useState, useEffect, useRef } from 'react'
import { FILTER_COL_HEIGHT, PAGE_SIZE, PAGE_SIZE_OPTIONS, PERMISSIONS, ROUTES } from 'consts'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

//antd
import { Input, Button, Table, Row, Col, Space, Popconfirm, notification } from 'antd'

//icons
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'

//components
import PageTitle from 'components/page-title'
import columnsSupplier from './columns'
import FilterDate from 'components/filter-date'
import Permission from 'views/permission'

//apis
import { getSuppliers, deleteSupplier } from 'apis/supplier'
import moment from 'moment'

export default function Supplier() {
  const typingTimeoutRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const [columns, setColumns] = useState([])
  const [loading, setLoading] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [countSupplier, setCountSupplier] = useState(0)
  const [suppliers, setSuppliers] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [paramsFilter, setParamsFilter] = useState({ page: 1, page_size: 100 })

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search
      paramsFilter.page = 1
      setParamsFilter({ ...paramsFilter })
    }, 750)
  }

  const _deleteSupplier = async (ids) => {
    try {
      setLoading(true)
      const res = await deleteSupplier({ supplier_ids: ids })
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Xóa nhà cung cấp thành công' })
          _getSuppliers()
        } else notification.error({ message: res.data.message })
      } else notification.error({ message: res.data.message })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const _getSuppliers = async () => {
    try {
      setLoading(true)
      setSuppliers([])
      const res = await getSuppliers({ ...paramsFilter, _creator: true })
      if (res.status === 200) {
        setSuppliers(res.data.data)
        setCountSupplier(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getSuppliers()
  }, [paramsFilter])

  useEffect(() => {
    setColumns(columnsSupplier)
  }, [])

  return (
    <div className="card">
      <PageTitle title="Quản lý nhà cung cấp">
        <Space>
          <Permission permissions={[PERMISSIONS.tao_nha_cung_cap]}>
            <Button
              onClick={() => history.push(ROUTES.SUPPLIER_CREATE)}
              size="medium"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              Thêm nhà cung cấp
            </Button>
          </Permission>
        </Space>
      </PageTitle>

      <Row gutter={10} style={{ marginTop: '1rem' }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ height: FILTER_COL_HEIGHT }}>
          <Input
            prefix={<SearchOutlined />}
            value={valueSearch}
            enterButton
            onChange={_onSearch}
            placeholder="Tìm kiếm nhà cung cấp"
            allowClear
          />
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <FilterDate
            bordered={true}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: '1rem' }}>
        {selectedRowKeys.length ? (
          <Permission permissions={[PERMISSIONS.xoa_nha_cung_cap]}>
            <Popconfirm
              onConfirm={() => {
                _deleteSupplier(selectedRowKeys)
                setSelectedRowKeys([])
              }}
              title="Bạn có muốn xóa các nhà cung cấp đã chọn không?"
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                Xóa hàng loạt
              </Button>
            </Popconfirm>
          </Permission>
        ) : (
          ''
        )}
      </Row>

      <Table
        rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys }}
        scroll={{ y: '58vh', x: 'max-content' }}
        rowKey="supplier_id"
        loading={loading}
        style={{ width: '100%', marginTop: 5 }}
        size="small"
        dataSource={suppliers}
        columns={columns.map((column) => {
          if (column.key === 'stt')
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          if (column.key === 'code')
            return {
              ...column,
              render: (text, record) => record.code,
            }
          if (column.dataIndex === 'name')
            return {
              ...column,
              sorter: (a, b) => a.name.length - b.name.length,
            }
          if (column.dataIndex === 'phone')
            return {
              ...column,
              sorter: (a, b) => a.phone.length - b.phone.length,
            }
          if (column.dataIndex === 'email')
            return {
              ...column,
              sorter: (a, b) => a.email.length - b.email.length,
            }
          if (column.key === 'create_date')
            return {
              ...column,
              render: (text, record) =>
                record.create_date && moment(record.create_date).format('DD/MM/YYYY'),
            }

          if (column.key === 'action')
            return {
              ...column,
              render: (text, record) => (
                <Space>
                  <Permission permissions={[PERMISSIONS.xoa_nha_cung_cap]}>
                    <Popconfirm
                      onConfirm={() => _deleteSupplier([record.supplier_id])}
                      title="Bạn có muốn xóa nhà cung cấp này không?"
                    >
                      <DeleteOutlined style={{ color: 'red', fontSize: 17, cursor: 'pointer' }} />
                    </Popconfirm>
                  </Permission>
                  <Permission permissions={[PERMISSIONS.cap_nhat_nha_cung_cap]}>
                    <Link to={`${ROUTES.SUPPLIER_UPDATE}?supplier_id=${record.supplier_id}`}>
                      <EditOutlined style={{ color: 'green', fontSize: 17, cursor: 'pointer' }} />
                    </Link>
                  </Permission>
                </Space>
              ),
            }

          return column
        })}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countSupplier,
        }}
      />
    </div>
  )
}
