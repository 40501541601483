import React from 'react'
import QR from 'qrcode-base64'
import { Row } from 'antd'

const PrintQRCode = ({ record }) => {
  var imgData = QR.drawImg(record.code || '', {
    typeNumber: 4,
    errorCorrectLevel: 'M',
    size: 500,
  })

  return (
    <div style={{ width: '100%', height: '100%', padding: 5 }}>
      <Row align="middle" justify="center" style={{ width: '100%', height: '100%' }}>
        <img src={imgData} alt="" style={{ height: 65, width: 65 }} />
        <div style={{ marginLeft: 5, fontSize: 11, textAlign: 'center' }}>{record.name || ''}</div>
      </Row>
    </div>
  )
}
class ComponentPrint extends React.Component {
  render() {
    const { records } = this.props
    let printingPages = []
    for (const record of records) {
      const tempTemplate = <PrintQRCode record={record} />
      printingPages.push(tempTemplate)
    }
    return <div>{printingPages}</div>
  }
}

function QRCodePrinter({ records, componentRef }) {
  return (
    <>
      <div style={{ display: 'none' }}>
        <ComponentPrint records={records} ref={componentRef} />
      </div>
    </>
  )
}

export default QRCodePrinter
