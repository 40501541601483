import React, { Component } from 'react'
class PrivacyPolicy extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ECF2F3' }}>
        <div
          style={{
            padding: '50px 170px',
          }}
        >
          <h1 style={{ textAlign: 'center', fontWeight: 700, fontSize: 38 }}>Chính sách bảo mật</h1>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Bảo vệ dữ liệu cá nhân của bạn là ưu tiên của chúng tôi. Chính sách quyền riêng tư này
            mô tả và áp dụng cho việc xử lý dữ liệu cá nhân của bạn bằng cách sử dụng sản phẩm từ .
            (cùng với bất kỳ đơn vị liên kết nào, SGL, ” "Chúng tôi" hoặc "chúng tôi"). Chính sách
            Bảo mật này giải thích các quyền của bạn và của chúng tôi các nghĩa vụ liên quan đến
            việc xử lý dữ liệu cá nhân của bạn (chúng tôi xác định cả "xử lý" và "dữ liệu cá nhân"
            bên dưới), vì vậy bạn nên đọc kỹ và liên hệ với chúng tôi nếu bạn có thắc mắc hoặc muốn
            thực hiện bất kỳ yêu cầu nào.
          </p>

          <h2 style={{ fontWeight: 700 }}>1. Chính sách được áp dụng khi nào?</h2>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Chính sách Bảo mật này áp dụng cho việc xử lý dữ liệu cá nhân của bạn liên quan đến việc
            bạn duyệt các trang web của chúng tôi. Nếu chúng ta có đã nhận được dữ liệu cá nhân của
            bạn trong các tình huống khác, quá trình xử lý đó là được giải thích trong một chính
            sách bảo mật khác.
          </p>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Chính sách Bảo mật này mô tả và áp dụng cho việc xử lý SGL dữ liệu cá nhân của bạn.
            Chúng tôi không chịu trách nhiệm, cũng như không thực hiện bất kỳ lời hứa nào liên quan
            đến, quá trình xử lý do các bên thứ ba thực hiện (ví dụ: các trang web khác). Nếu bạn tò
            mò về cách các trang web khác hoặc các dịch vụ xử lý dữ liệu cá nhân của bạn, chúng tôi
            khuyến khích bạn kiểm tra ra các chính sách bảo mật của họ.
          </p>

          <h2 style={{ fontWeight: 700 }}>2. Khái niệm dữ liệu người dùng</h2>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            "Dữ liệu cá nhân" có nghĩa là bất kỳ thông tin nào có thể được sử dụng để xác định bạn,
            có thể bao gồm thông tin trong phần sau danh mục: số nhận dạng (ví dụ: tên, địa chỉ
            email, tên người dùng), thông tin thương mại (ví dụ: hồ sơ về sản phẩm hoặc dịch vụ mua
            hàng, thông tin liên quan đến lịch sử mua hàng), internet hoặc thông tin hoạt động mạng
            điện tử khác (ví dụ: duyệt và lịch sử tìm kiếm và thông tin liên quan đến tương tác với
            trang web), dữ liệu vị trí địa lý và liên quan đến nghề nghiệp hoặc việc làm (thông tin)
          </p>

          <h2 style={{ fontWeight: 700 }}>3. Bảo vệ dữ liệu người dùng</h2>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Tùy thuộc vào việc bạn sử dụng Nền Tảng của chúng tôi và/hoặc việc bạn tương tác với các
            Dịch Vụ của chúng tôi (chẳng hạn khi đăng ký các Dịch Vụ của chúng tôi và/hoặc đăng nhập
            vào Nền Tảng), bạn có thể được yêu cầu cung cấp cho chúng tôi một số thông tin nhất
            định. Tuy nhiên, bạn có thể chọn không cung cấp cho chúng tôi một số thông tin nhất định
            và bạn sẽ không thể tận hưởng các lợi ích được mang lại từ nhiều Dịch Vụ mà chúng tôi
            cung cấp cho bạn. Dữ liệu cá nhân mà bạn có thể lựa chọn cung cấp cho chúng tôi như sau:
          </p>
          <p>(a) Dữ liệu sản phẩm, đơn hàng</p>
          <p>(b) Dữ liệu tồn kho sản phẩm </p>
          <p>(c) Dữ liệu đơn vị vận chuyển của đơn hàng nhập/xuất</p>
          <p>(d) Dữ liệu hồ sơ doanh nghiệp khi cam kết các điều khoản của SGL</p>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            "Xử lý" có nghĩa là bất kỳ điều gì chúng tôi có thể làm liên quan đến cá nhân dữ liệu,
            bao gồm thu thập, sử dụng, tiết lộ và xóa nó.
          </p>

          <h2 style={{ fontWeight: 700 }}>4. Vấn đề thu thập dữ liệu người dùng</h2>
          <p>
            SGL thu thập các dữ liệu cá nhân sau đây về bạn:
            <p>Thông tin hoạt động mạng / Internet, địa chỉ IP</p>
            <p>
              Chúng tôi thu thập dữ liệu cá nhân của bạn chủ yếu thông qua các biện pháp công nghệ
              như cookie hoặc nhà cung cấp công nghệ bên thứ ba. Bạn nên tham khảo cài đặt trình
              duyệt của mình liên quan đến cài đặt cookie, nhưng lưu ý rằng việc tắt một số cookie
              nhất định có thể ảnh hưởng đến trải nghiệm duyệt web của bạn.
            </p>
            <p>
              SGL cam kết không thu thập thông tin khác ngoài các thông tin trên, không chia sẻ và
              công khai dữ liệu lưu tạm cho bất kỳ bên thứ 3 nào.
            </p>
          </p>

          <p style={{ fontSize: 13, fontWeight: 500 }}>
            SGL không xử lý dữ liệu cá nhân của bạn mà không có cơ sở hợp pháp để làm như vậy. Trong
            trường hợp này, quá trình xử lý của chúng tôi thúc đẩy lợi ích hợp pháp của chúng tôi
            trong việc mở rộng kinh doanh bằng cách tiếp thị các sản phẩm và dịch vụ của chúng tôi
            một cách hiệu quả thông qua trang web của chúng tôi. Do tính chất hạn chế của việc xử lý
            dữ liệu và các tác động hạn chế đối với chủ thể dữ liệu của chúng tôi (ví dụ: bạn),
            chúng tôi không coi việc xử lý này có hại cho các quyền hoặc tự do của chủ thể dữ liệu.
          </p>

          <h2 style={{ fontWeight: 700 }}>5. Vấn đề tiết lộ dữ liệu</h2>
          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Chúng tôi không bán, thuê hoặc cho thuê dữ liệu cá nhân của bạn cho các bên thứ ba. Tuy
            nhiên, chúng tôi có thể tiết lộ dữ liệu cá nhân cho các bên thứ ba vì mục đích thương
            mại, như được mô tả trong phần này Phần "Tiết lộ của bên thứ ba"
          </p>
          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Các dữ liệu của bạn được mã hoá (theo thuật toán băm mã) và được sử dụng bởi bạn theo
            tài khoản đã đăng ký, khoá xác thực mà bạn sử dụng.
          </p>

          <h2 style={{ fontWeight: 700 }}>6.Dữ liệu của bạn được xử lí như thế nào</h2>
          <p style={{ fontSize: 13, fontWeight: 500 }}>
            Lưu trữ: SGL có trụ sở tại Hồ Chí Minh, thành phố lớn nhất Việt Nam, chúng tôi là đối
            tác của các dịch vụ Cloud như Wasabi, Nhân Hoà tại Việt Nam. SGL kỹ sư tư vấn việc chọn
            Data Center kèm các giải pháp bảo mật, dữ liệu của SGL không trao đổi xuyên biên giới
            quốc gia (chỉ Việt Nam) Xử lí: chúng tôi chỉ xử lí lưu trữ và xác thực thông tin của bạn
            để bảo mật tính toàn vẹn dữ liệu, cài đặt 2 lớp bảo mật để chống các rủi ro dữ liệu, bao
            gồm:
            <p>
              6.1 Mật khẩu của bạn cần được tuân theo quy chuẩn quốc tế: Mật khẩu phải có ít nhất 8
              ký tự, chứa ít nhất một ký tự và một số và một ký tự đặc biệt
            </p>
            <p>
              6.2 Tài khoản của bạn sẽ chỉ được duy trì trạng thái đăng nhập 4 tiếng liên tục, thời
              gian hết hạn phiên đăng nhập là 4 tiếng.
            </p>
          </p>

          <h2 style={{ fontWeight: 700 }}>7. Cập nhật chính sách bảo mật</h2>
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            Chính sách này được cập nhật mới nhất vào ngày 18/10/2020
          </p>

          <h2 style={{ fontWeight: 700 }}>8. Địa chỉ SGL & Liên hệ:</h2>
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            Trụ sở: 97/1 Vo Thi Phai Street, Thoi An Ward, District 12, Ho Chi Minh City.
          </p>
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            Head office: 97/1 Vo Thi Phai Street, Thoi An Ward, District 12, Ho Chi Minh City.
          </p>

          <p style={{ fontSize: 14, fontWeight: 700 }}>Hotline: 0315681169</p>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
