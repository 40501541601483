import axios from 'axios'
import { notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { stringify } from 'querystring'

// import localStorage from 'localstorage-memory'

// export const getNewToken = () => {
//   if (
//     decodeToken(localStorage.getItem('refreshToken')).exp <
//     Math.floor(Date.now() / 1000)
//   ) {
//     throw new Error('Token expired!')
//   }
//   try {
//     return axios({
//       url:
//         (process.env.REACT_APP_API_ENDPOINT_DEV) + '/auth/login',
//       headers: {
//         'Content-type': 'application/json',
//       },
//       method: 'POST',
//       data: {
//         refreshToken: localStorage.getItem('refreshToken'),
//       },
//     })
//   } catch (error) {
//     throw new Error('Token expired!')
//   }
// }

export const FetchAPI = async (
  path,
  method,
  headers,
  body,
  endpoint = process.env.REACT_APP_API_ENDPOINT_DEV
) => {
  // if (!headers || !headers.Authorization) {
  //   const payloadAccessToken = decodeToken(
  //     localStorage.getItem('accessToken')
  //   )
  //   if (
  //     payloadAccessToken &&
  //     payloadAccessToken.exp < Math.floor(Date.now() / 1000) + 5 * 60
  //   ) {
  //     try {
  //       const response = await getNewToken()

  //       if (response.status === 200) {
  //         localStorage.setItem('accessToken', response.data.accessToken)
  //       }
  //     } catch (error) {
  //       localStorage.clear()
  //       notification.warning({
  //         message: 'Session has expired. Please login again',
  //       })
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 500)
  //       return {
  //         status: 401,
  //       }
  //     }
  //   }
  // }
  const defaultHeaders = {
    'Content-type': 'application/json',
    Authorization: localStorage.getItem('accessToken'),
  }
  if (typeof headers === 'object') {
    Object.assign(defaultHeaders, headers)
  }
  try {
    // notification.close('notify error')
    return await axios({
      url: endpoint + path,
      method,
      headers: defaultHeaders,
      data: body,
    })
  } catch (error) {
    if (error.response) {
      return error.response
    }
    // notification.open({
    //   icon: <InfoCircleOutlined style={{ color: 'red' }} />,
    //   message: 'System error, please try again!',
    //   description: <a onClick={() => window.location.reload()}>Reload</a>,
    //   duration: 0,
    //   key: 'notify error',
    // })
    return {
      status: 401,
    }
  }
}

export const get = (path, query = {}, headers = {}, endpoint) => {
  return FetchAPI(`${path}?${stringify(query)}`, 'GET', headers, null, endpoint)
}
export const post = (path, body, headers, endpoint) =>
  FetchAPI(path, 'POST', headers, body, endpoint)
export const patch = (path, body, headers, endpoint) =>
  FetchAPI(path, 'PATCH', headers, body, endpoint)
export const destroy = (path, body, headers, endpoint) =>
  FetchAPI(path, 'DELETE', headers, body, endpoint)
