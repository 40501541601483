import React, { useEffect, useState } from 'react'

import { Row } from 'antd'
import styles from './businessProfile.module.scss'
import { useSelector } from 'react-redux'
import { getCustomers } from 'apis/customer'
import { formatCash } from 'utils'
import moment from 'moment'

export default function BussinessProfile() {
  const dataUser = useSelector((state) => state.login.dataUser)
  const [customer, setCustomer] = useState([])
  const _getCustomer = async () => {
    try {
      const res = await getCustomers({ customer_id: dataUser.customer_id })
      console.log('res', res)
      if (res.status === 200) {
        if (res.data.success) {
          setCustomer(res.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    _getCustomer()
  }, [])

  return (
    <div className={styles['business-profile']}>
      <Row className="page-title" justify="space-between">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>Hồ sơ doanh nghiệp</span>
      </Row>
      <div className={styles['business-profile-content']}>
        <h1 align="center">{customer[0]?.fullname}</h1>
        <div>
          <span>Mã số thuế:&nbsp;</span>
          {customer[0]?.tax_code}
        </div>
        <div>
          <span>Đại diện pháp luật: &nbsp;</span>
          {customer[0]?.legal_representative}
        </div>
        <div>
          <span>Số điện thoại:&nbsp;</span>
          {customer[0]?.phone}
        </div>
        <div>
          <span>Địa chỉ:&nbsp;</span>
          {`${customer[0]?.address}, ${customer[0]?.ward}, ${customer[0]?.district}, ${customer[0]?.province}`}
        </div>
        <div>
          <span>Email:&nbsp;</span>
          {customer[0]?.email}
        </div>
        <Row align="middle">
          <span>Danh sách chi phí kho:&nbsp;</span>
          <div style={{ fontSize: 16 }}>
            {customer[0]?.fees.map((fee) => `${fee.fee_name}(${formatCash(fee.fee || 0)} đ), `)}
          </div>
        </Row>
        <div>
          <span>Ngày ký hợp đồng:&nbsp;</span>
          {customer[0]?.contract_start_date &&
            moment(customer[0]?.contract_start_date).format('DD/MM/YYYY')}
        </div>
        <div>
          <span>Ngày kết thúc hợp đồng:&nbsp;</span>
          {customer[0]?.contract_end_date &&
            moment(customer[0]?.contract_end_date).format('DD/MM/YYYY')}
        </div>
      </div>
    </div>
  )
}
