import React, { useState, useEffect, useRef } from 'react'
import styles from './reportInventory.module.scss'
import { SIZE_TABLE, POSITION_TABLE, PAGE_SIZE_OPTIONS, ROUTES } from 'consts'
import { formatCash, getFileName, saveFiltersToLocalStorage } from 'utils'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import { useHistory } from 'react-router-dom'

// antd
import { Col, Input, Row, Select, Table, Space, Button } from 'antd'
import { ArrowLeftOutlined, ReloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'
import FilterDate from 'components/filter-date'
import ReportInventoryDetail from './report-inventory-detail'

//apis
import { getCategories } from 'apis/categories-product'
import { getReportInventory, exportReportInventory } from 'apis/report'
import { getWarehouses } from 'apis/warehouse'

const { Option } = Select
export default function ReportInventory() {
  const KEY_FILTER = 'reportInventoryFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const dataUser = useSelector((state) => state.login.dataUser)
  const history = useHistory()
  const [warehouses, setWarehouses] = useState([])

  const [inventoryDetail, setInventoryDetail] = useState()
  const [dataReportInventory, setDataReportInventory] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20, today: true }
  )
  const [inventoryCount, setInventoryCount] = useState(0)
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])

  const typingTimeoutRef = useRef(null)
  const [valueSearch, setValueSearch] = useState(
    filters ? filters.product_code || filters.product_name || '' : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.product_code && 'product_code') ||
          (filters.product_name && 'product_name') ||
          'product_code'
      : 'product_code'
  )

  const columns = [
    {
      width: 50,
      title: 'STT',
      key: 'stt',
      align: 'center',
      render: (text, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
    },
    {
      title: 'Mã hàng',
      render: (text, record) =>
        // record.product_info && (
        //   <a
        //     target="_blank"
        //     href={`${ROUTES.REPORT_INVENTORY_DETAIL}?product_id=${record.product_id}&barcode=${record.product_info.barcode}`}
        //   >
        //     {record.product_info.barcode}
        //   </a>
        // ),
        record.product_info && (
          <a style={{ color: 'blue' }} onClick={() => setInventoryDetail(record)}>
            {record.product_info.barcode}
          </a>
        ),
    },
    {
      width: '17%',
      title: 'Tên hàng',
      render: (text, record) => record?.product_info && record?.product_info?.name,
    },
    {
      align: 'center',
      title: 'Xuất xứ',
      render: (text, record) => record?.product_info && record?.product_info?.origin,
    },
    {
      align: 'center',
      title: 'Kích thước',
      render: (text, record) =>
        record?.product_info &&
        `Dài: ${record?.product_info.length}, Rộng: ${record?.product_info.width}, Cao: ${record?.product_info.height}`,
    },
    {
      align: 'center',
      title: 'CBM',
      render: (text, record) => record?.product_info && record?.product_info?.cbm,
    },
    {
      title: 'Nhóm hàng',
      render: (text, record) =>
        record?.product_info &&
        record?.product_info?.group_info &&
        record?.product_info?.group_info?.name,
    },
    {
      title: 'Loại hàng',
      render: (text, record) =>
        record?.product_info &&
        record?.product_info?.type_info &&
        record?.product_info?.type_info?.name,
    },
    {
      title: 'Ngày sản xuất',
      render: (text, record) => record.mfg && moment(record.mfg).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Hạn sử dụng',
      render: (text, record) => record.exp && moment(record.exp).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Tồn kho tối thiểu',
      render: (text, record) => formatCash(record.product_info?.min_quantity),
    },
    {
      title: 'Quy cách',
      render: (text, record) => record.product_info && record.product_info.convention,
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) =>
        record.product_info && record.product_info.unit_info && record.product_info.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => record.product_info && record.product_info.smallest_unit,
    },
    {
      title: 'Quy đổi số lượng',
      render: (text, record) =>
        record.product_info &&
        formatCash((record.product_info.smallest_unit || 0) * (record.end_quantity || 0)),
    },
    {
      title: 'SL đầu kỳ',
      render: (text, record) => (record.begin_quantity ? formatCash(record.begin_quantity) : 0),
    },
    {
      title: 'SL nhập trong kỳ',
      render: (text, record) => (record.import_quantity ? formatCash(record.import_quantity) : 0),
    },
    {
      title: 'SL xuất trong kỳ',
      render: (text, record) => (record.export_quantity ? formatCash(record.export_quantity) : 0),
    },
    {
      title: 'SL cuối kỳ',
      render: (text, record) => (record.end_quantity ? formatCash(record.end_quantity) : 0),
    },
  ]

  const _getReportInventory = async () => {
    try {
      setLoading(true)
      const res = await getReportInventory({
        ...paramsFilter,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        if (res.data.success) {
          setDataReportInventory(res.data.data)
          setInventoryCount(res.data.count)

          const optionSearch = Object.keys(paramsFilter).find(
            (key) => key === 'product_name' || key === 'product_code'
          )

          const detail = res.data.data && res.data.data.length && res.data.data[0]

          if (optionSearch && detail) setInventoryDetail(detail)
          else {
            if (!inventoryDetail && detail) setInventoryDetail(detail)
          }
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]
      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]

    if (valueSearch) {
      paramsFilter.page = 1

      //lấy giá trị select mới để lọc lại dữ liệu
      setParamsFilter({
        ...paramsFilter,
        [value]: valueSearch,
      })
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    paramsFilter.page = 1

    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]

    setParamsFilter({ ...paramsFilter })
  }

  const _clearFilters = () => {
    Object.keys(paramsFilter).map((key) => delete paramsFilter[key])
    setValueSearch('')
    setOptionSearch('product_code')
    paramsFilter.page = 1
    paramsFilter.page_size = 20
    setParamsFilter({ ...paramsFilter, today: true })
  }

  const _getReportInventoryExport = async (getAll = false) => {
    try {
      const queryNew = { ...paramsFilter }
      if (getAll) {
        const keysQueryTime = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'last_month',
          'this_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]
        Object.keys(queryNew).map((key) => !keysQueryTime.includes(key) && delete queryNew[key])
      }

      delete queryNew.page
      delete queryNew.page_size
      setLoading(true)
      const res = await exportReportInventory({
        ...queryNew,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      setLoading(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) history.push(ROUTES.LOGIN)
  }, [])

  useEffect(() => {
    _getGroups()
    _getTypes()
    _getWarehouses()
  }, [])

  useEffect(() => {
    _getReportInventory()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['reportInventory']}>
      <Row className="page-title" justify="space-between">
        <Row>
          <span onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
            <ArrowLeftOutlined /> Báo cáo tồn kho tổng hợp
          </span>
        </Row>
        <Button onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>
          Quay về
        </Button>
      </Row>
      <div className={styles['reportInventory-content']} style={{ marginBottom: 10 }}>
        <Row justify="space-between" gutter={20}>
          <Col span={6}>
            <Input.Group
              compact
              style={{
                width: '100%',
                boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)',
              }}
            >
              <Input
                allowClear
                style={{ width: '70%' }}
                placeholder="Tìm kiếm theo tiêu chí"
                value={valueSearch}
                onChange={_search}
              />
              <Select
                suffixIcon={<SuffixIconCustom />}
                style={{ width: '30%' }}
                value={optionSearch}
                onChange={_selectOption}
              >
                <Option value="product_name">Tên hàng</Option>
                <Option value="product_code">Mã hàng</Option>
              </Select>
            </Input.Group>
          </Col>
          <Col span={4}>
            <Select
              value={paramsFilter['warehouse_id'] || undefined}
              onChange={(value) => _filterByOption('warehouse_id', value)}
              showSearch
              allowClear
              placeholder="Lọc theo kho"
              style={{ width: '100%' }}
              dropdownClassName="dropdown-select-custom"
              suffixIcon={<SuffixIconCustom />}
            >
              {warehouses.map((warehouse, index) => (
                <Select.Option key={index} value={warehouse.warehouse_id}>
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              allowClear
              showSearch
              suffixIcon={<SuffixIconCustom />}
              placeholder="Lọc theo nhóm hàng"
              style={{ width: '100%' }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={paramsFilter['group_id'] || undefined}
              onChange={(value) => _filterByOption('group_id', value)}
            >
              {groups.map((group, index) => (
                <Select.Option value={group.category_id} key={index}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              suffixIcon={<SuffixIconCustom />}
              style={{
                width: '100%',
              }}
              placeholder="Lọc theo loại hàng"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={paramsFilter['type_id'] || undefined}
              onChange={(value) => _filterByOption('type_id', value)}
            >
              {types.map((type, index) => (
                <Select.Option value={type.category_id} key={index}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <FilterDate
              allowClear={false}
              paramsFilter={paramsFilter}
              setParamsFilter={setParamsFilter}
            />
          </Col>
        </Row>
      </div>
      <Row justify="end">
        <Space size="middle">
          <Button
            onClick={_clearFilters}
            type="primary"
            danger
            style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
          <Button onClick={_getReportInventory} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <Button
            onClick={() => _getReportInventoryExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportInventoryExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>
        </Space>
      </Row>

      <Table
        rowClassName={(record) => {
          if (record.exp) {
            const thisDay = moment().tz('Asia/Ho_Chi_Minh')
            const exp = moment(moment(record.exp).format('YYYY-MM-DD')).tz('Asia/Ho_Chi_Minh')
            if (moment(exp).diff(thisDay, 'days') < 10) return 'bg-orange'
          }
          if ((record && record.product_id) === (inventoryDetail && inventoryDetail.product_id))
            return 'bg-blue'
          return ''
        }}
        className="table-disable-hover"
        scroll={{ y: '35vh' }}
        bordered
        style={{ width: '100%', marginTop: 5 }}
        loading={loading}
        size={SIZE_TABLE}
        dataSource={dataReportInventory}
        columns={columns}
        pagination={{
          position: POSITION_TABLE,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: inventoryCount,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
        }}
      />
      <br />
      <ReportInventoryDetail filters={paramsFilter} record={inventoryDetail} />
    </div>
  )
}
