import React, { useEffect, useRef, useState } from 'react'
import { SIZE_TABLE } from 'consts'
import moment from 'moment'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { saveFiltersToLocalStorage } from 'utils'

//antd
import { Input, Table, Row, Col, Button } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

// apis
import { getActionFile } from 'apis/action'

//components
import BackToTop from 'components/back-to-top'
import FilterDate from 'components/filter-date'

export default function ImportExportManagement() {
  const typingTimeoutRef = useRef()
  const location = useLocation()

  const KEY_FILTER = 'importExportManagementFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 20 }
  )
  const [fileActionList, setFileActionList] = useState([])

  const [tableLoading, setTableLoading] = useState(false)
  const [totalRecord, setTotalRecord] = useState(0)
  const [valueSearch, setValueSearch] = useState(filters ? filters.file_name || '' : '')
  const [isFilter, setIsFilter] = useState(false)
  const [noteFilter, setNoteFilter] = useState('')

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1
      delete paramsFilter.file_name
      if (value) paramsFilter.file_name = value
      else delete paramsFilter.file_name

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }
  const _getFileAction = async (params) => {
    try {
      setTableLoading(true)
      const res = await getActionFile(params)
      if (res.data.success) {
        setFileActionList(res.data.data)
        setTotalRecord(res.data.count)
      }
      setTableLoading(false)
    } catch (err) {
      setTableLoading(false)
      console.log(err)
    }
  }
  const _resetFilter = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20 })
    setIsFilter(false)
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      render: (data, record, index) => (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
    },
    {
      title: 'Tên file',
      dataIndex: 'file_name',
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'creator_info',
      render(data) {
        return data && data.fullname
      },
    },
    {
      title: 'Thời gian thao tác',
      dataIndex: 'create_date',
      render: (data) => moment(data).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Thao tác',
      dataIndex: 'action_name',
    },
    {
      title: 'Hành động',
      dataIndex: 'links',
      render: (data) =>
        data &&
        data.length &&
        data[0] && (
          <a href={data[0]} download={true}>
            Tải xuống
          </a>
        ),
    },
  ]

  useEffect(() => {
    _getFileAction(paramsFilter)

    var noteFilter = ''
    if (paramsFilter.type) {
      setIsFilter(true)
      noteFilter += 'Thao tác: ' + paramsFilter.type
    }
    if (paramsFilter.this_week) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Tuần này'
    }
    if (paramsFilter.last_week) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Tuần trước'
    }
    if (paramsFilter.today) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Hôm nay'
    }

    if (paramsFilter.yesterday) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Hôm qua'
    }
    if (paramsFilter.this_month) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Tháng nay'
    }
    if (paramsFilter.last_month) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Tháng trước'
    }
    if (paramsFilter.this_year) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Năm nay'
    }
    if (paramsFilter.last_year) {
      setIsFilter(true)
      noteFilter += ' ,Thời gian: Năm trước'
    }

    setNoteFilter(noteFilter)

    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    const query = queryString.parse(location.search)

    if (query.action_name !== undefined) {
      if (query.action_name === 'file-nhap-san-pham') {
        setParamsFilter({ ...paramsFilter, type: 'File nhập sản phẩm' })
      } else if (query.action_name === 'file-nhap-hang') {
        setParamsFilter({ ...paramsFilter, type: 'File nhập hàng' })
      } else if (query.action_name === 'file-xuat-hang') {
        setParamsFilter({ ...paramsFilter, type: 'File xuất hàng' })
      }
    }
  }, [])
  return (
    <div>
      <BackToTop />
      <Row className="page-title" justify="space-between">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
          Lịch sử xuất/nhập file
        </span>
      </Row>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={10}>
          <Input.Search
            allowClear
            enterButton
            value={valueSearch}
            style={{ width: '100%' }}
            placeholder="Tìm kiếm theo tên file"
            onChange={_search}
          />
        </Col>
        <Col span={6}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
      </Row>
      {isFilter && (
        <Row gutter={20} style={{ marginBottom: 20, marginLeft: 10 }}>
          <div style={{ color: 'red' }}>{'Bạn đang lọc: ' + noteFilter}</div>
        </Row>
      )}
      {isFilter && (
        <Col style={{ marginLeft: 'auto' }}>
          {Object.keys(paramsFilter).length > 3 && (
            <Button
              onClick={_resetFilter}
              type="danger"
              style={{ borderRadius: 5 }}
              icon={<FilterOutlined />}
            >
              Xóa lọc
            </Button>
          )}
        </Col>
      )}
      <Table
        size={SIZE_TABLE}
        style={{ width: '100%' }}
        columns={columns}
        dataSource={fileActionList}
        loading={tableLoading}
        pagination={{
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          total: totalRecord,
          onChange: ({ page, page_size }) => setParamsFilter({ ...paramsFilter, page, page_size }),
        }}
      />
    </div>
  )
}
