import React, { useState, useEffect, memo } from 'react'

//antd
import { Input, Button, Row, notification, Form, Upload, Drawer } from 'antd'

//icons
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

//apis
import { addBranch, updateBranch } from 'apis/branch'
import { uploadFile } from 'apis/upload'

export default memo(function BranchAdd({ reload, children, record }) {
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState('')
  const [loadingUpload, setLoadingUpload] = useState(false)

  const handleUpload = async (file) => {
    try {
      setImage('')
      setLoadingUpload(true)
      const url = await uploadFile(file)
      setLoadingUpload(false)
      if (url) setImage(url)
    } catch (error) {
      setLoadingUpload(false)
    }
  }

  const _createOrUpdateCompanyBranch = async () => {
    try {
      await form.validateFields()

      const formData = form.getFieldsValue()
      const body = {
        ...formData,
        parent_id: '',
        is_manager: false,
        logo_urls: image ? [image] : [],
      }
      setLoading(true)
      let res
      if (record) res = await updateBranch(body, record.branch_id)
      else res = await addBranch(body)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thành công!`,
          })
          form.resetFields()
          toggle()
          if (reload) reload()
        } else
          notification.error({
            message: res.data.message || `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thất bại!`,
          })
      } else
        notification.error({
          message: res.data.message || `${record ? 'Cập nhật' : 'Thêm'} chi nhánh thất bại!`,
        })
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setImage('')
    } else {
      if (record) {
        form.setFieldsValue({ ...record })
        if (record.logo_urls && record.logo_urls.length) setImage(record.logo_urls[0])
      }
    }
  }, [visible])

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Drawer
        extra={
          <Button
            loading={loading}
            onClick={_createOrUpdateCompanyBranch}
            size="medium"
            type="primary"
            style={{ minWidth: 120 }}
          >
            {record ? 'Cập nhật' : 'Thêm'}
          </Button>
        }
        title={record ? `Cập nhật chi nhánh #${record.code}` : 'Thêm chi nhánh'}
        centered
        width="60%"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <Form
          onFinish={_createOrUpdateCompanyBranch}
          labelAlign="left"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          form={form}
        >
          <Form.Item
            name="name"
            label="Tên chi nhánh"
            rules={[{ required: true, message: 'Vui lòng nhập tên chi nhánh!' }]}
          >
            <Input autoFocus placeholder="Nhập tên chi nhánh" />
          </Form.Item>
          <Form.Item initialValue="" name="manager_name" label="Giám đốc chi nhánh">
            <Input placeholder="Nhập tên giám đốc chi nhánh" />
          </Form.Item>
          <Form.Item initialValue="" name="phone" label="Số điện thoại">
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item initialValue="" name="email" label="Email">
            <Input placeholder="Nhập email" />
          </Form.Item>
          <Form.Item initialValue="" name="address" label="Địa chỉ">
            <Input placeholder="Nhập địa chỉ" />
          </Form.Item>
          <Form.Item initialValue="" name="description" label="Mô tả">
            <Input.TextArea rows={2} placeholder="Nhập mô tả" />
          </Form.Item>
          <Form.Item initialValue="" label="Ảnh">
            <Upload
              accept="image/*"
              name="avatar"
              listType="picture-card"
              showUploadList={false}
              data={(file) => handleUpload(file)}
            >
              {image ? (
                <img src={image} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <div>
                  {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Tải lên</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
})
