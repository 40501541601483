import React, { useState, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import delay from 'delay'

import { Modal, Radio } from 'antd'
import PrintQRCode from './qr-code'
import PrintQRCodeBucket from './qr-code-bucket'
import PrintQRCodeBox from './qr-code-box'
import { useSelector } from 'react-redux'

export default function ModalOptionsPrint({
  children,
  printWithInternet,
  records = [],
  isQRCodeBucket = false,
  isQRCodeBox = false,
}) {
  const componentRef = useRef()
  // const typePrint = useSelector((state) => state.print.type)

  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  const [option, setOption] = useState(1)
  const [recordsPrint, setRecordsPrint] = useState([])

  const print = async () => {
    setRecordsPrint([...records])

    await delay(100)
    // if (typePrint === 'internet') printWithInternet()
    // else
    _handlePrint()

    toggle()
  }

  const _handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setRecordsPrint([]),
  })

  return (
    <>
      {recordsPrint.length
        ? (isQRCodeBucket && (
            <PrintQRCodeBucket records={recordsPrint} componentRef={componentRef} />
          )) ||
          (isQRCodeBox && (
            <PrintQRCodeBox records={recordsPrint} componentRef={componentRef} />
          )) || <PrintQRCode records={recordsPrint} componentRef={componentRef} />
        : ''}

      <div onClick={print}>{children}</div>
      {/* <Modal
        onOk={print}
        okText="In"
        cancelText="Đóng"
        title="Tùy chọn in ấn"
        visible={visible}
        onCancel={toggle}
      >
        <Radio.Group onChange={(e) => setOption(e.target.value)} value={option}>
          <Radio value={1}>In qua mạng</Radio>
          <Radio value={2}>In qua USB</Radio>
        </Radio.Group>
      </Modal> */}
    </>
  )
}
