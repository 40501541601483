import { VERSION_APP } from 'consts'
import jwt_decode from 'jwt-decode'

export const compare = (a, b, key, convert) => {
  if (convert)
    return convert(a[key]) > convert(b[key]) ? 1 : convert(a[key]) === convert(b[key]) ? 0 : -1
  return a[key] > b[key] ? 1 : a[key] === b[key] ? 0 : -1
}

export const compareCustom = (a, b) => {
  return a > b ? 1 : a === b ? 0 : -1
}

//xoá dấu
export function removeAccents(text, removeSpace = false, spaceReplace = '') {
  if (removeSpace && typeof removeSpace != 'boolean') {
    throw new Error('Type of removeSpace input must be boolean!')
  }
  text = (text + '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
  if (removeSpace) {
    text = text.replace(/\s/g, spaceReplace)
  }
  return text
}

export function formatNumber(str) {
  if (str)
    return str
      .toString()
      .split('')
      .reverse()
      .reduce((prev, next, index) => {
        return (index % 3 ? next : next + ',') + prev
      })
  else return 0
}
export const _processing = (status) => {
  switch (status) {
    case 'is_received':
      return 'Đã tiếp nhận'
    case 'is_exported':
      return 'Đã xuất khỏi vị trí'
    case 'is_packed':
      return 'Đã đóng gói'
    case 'is_transported':
      return 'Đã bàn giao vận chuyển'
    case 'is_shipping':
      return 'Đã vận chuyển'
    default:
      return ''
  }
}

export const clearBrowserCache = () => {
  let version = localStorage.getItem('version_app')
  if (version !== VERSION_APP) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true)
    }

    for (var key in localStorage)
      if (key !== 'accessToken' || key !== 'refreshToken') localStorage.removeItem(key)

    localStorage.setItem('version_app', VERSION_APP)
  }
}

export const tableSum = (arr, key) => {
  const getValue = (obj, key) => {
    try {
      return key.split('.').reduce((a, b) => {
        return a[b] || 0
      }, obj)
    } catch (e) {
      return 0
    }
  }
  try {
    return arr.reduce((a, b) => a + parseInt(getValue(b, key)), 0)
  } catch (err) {
    console.log(err)
    return 0
  }
}

export function formatCash(str) {
  if (str) return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  else return 0
}

export function formatFixed(str) {
  if (str) {
    const number = (+str).toFixed(6)
    const format = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return format
  } else return 0
}

export const filterOptionSelect = (input, option) =>
  removeAccents(option.children).toLowerCase().includes(removeAccents(input).toLowerCase())

export const saveFiltersToLocalStorage = (filters = {}, key = '') => {
  localStorage.setItem(key, JSON.stringify({ ...filters }))
}

export const handleGetDataExportExcel = async (api, query = {}, page = 1, records = []) => {
  if (api) {
    const res = await api({ ...query, page: page, page_size: 250 })
    if (res.status === 200) {
      const recordsNew = [...records, ...res.data.data]
      if (recordsNew.length < res.data.count) {
        return handleGetDataExportExcel(api, query, page + 1, recordsNew)
      } else {
        return recordsNew
      }
    }
  }
}

export const clearLocalStorage = () => {
  for (var key in localStorage) if (key !== 'version_app') localStorage.removeItem(key)
}

export const getFileName = (file = '') => {
  const nameStrings = file.split('/')
  const name = nameStrings[nameStrings.length - 1]
  return name || ''
}

export const isAcceptPermissions = (permissions = []) => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    const dataUser = jwt_decode(accessToken)
    if (dataUser) {
      const permissionsUser = dataUser.role_info ? dataUser.role_info.permissions : []
      return permissionsUser.filter((permission) => permissions.includes(permission)).length
        ? true
        : false
    }
  }

  return false
}
