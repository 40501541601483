import { PERMISSIONS } from 'consts'
const treeData = [
  {
    title: 'Giao diện sản phẩm',
    key: PERMISSIONS.giao_dien_san_pham,
    children: [
      {
        title: 'Tạo sản phẩm',
        key: PERMISSIONS.tao_san_pham,
      },
      {
        title: 'Cập nhật sản phẩm',
        key: PERMISSIONS.cap_nhat_san_pham,
      },
      {
        title: 'Xóa sản phẩm',
        key: PERMISSIONS.xoa_san_pham,
      },
      {
        title: 'Xem chi tiết sản phẩm',
        key: PERMISSIONS.xem_san_pham,
      },
      {
        title: 'Thêm ảnh sản phẩm',
        key: PERMISSIONS.them_anh_san_pham,
      },
    ],
  },
  {
    title: 'Giao diện chi nhánh',
    key: PERMISSIONS.giao_dien_chi_nhanh,
    children: [
      {
        title: 'Tạo chi nhánh',
        key: PERMISSIONS.tao_chi_nhanh,
      },
      {
        title: 'Cập nhật chi nhánh',
        key: PERMISSIONS.cap_nhat_chi_nhanh,
      },
      {
        title: 'Xóa chi nhánh',
        key: PERMISSIONS.xoa_chi_nhanh,
      },
    ],
  },
  {
    title: 'Giao diện nhà cung cấp',
    key: PERMISSIONS.giao_dien_nha_cung_cap,
    children: [
      {
        title: 'Tạo nhà cung cấp',
        key: PERMISSIONS.tao_nha_cung_cap,
      },
      {
        title: 'Cập nhật nhà cung cấp',
        key: PERMISSIONS.cap_nhat_nha_cung_cap,
      },
      {
        title: 'Xóa nhà cung cấp',
        key: PERMISSIONS.xoa_nha_cung_cap,
      },
    ],
  },
  {
    title: 'Giao diện nhập kho',
    key: PERMISSIONS.giao_dien_nhap_kho,
    children: [
      {
        title: 'Tạo phiếu nhập kho',
        key: PERMISSIONS.tao_phieu_nhap_kho,
      },
      {
        title: 'Cập nhật phiếu nhập kho',
        key: PERMISSIONS.cap_nhat_phieu_nhap_kho,
      },
      {
        title: 'Xóa phiếu nhập kho',
        key: PERMISSIONS.xoa_phieu_nhap_kho,
      },
    ],
  },
  {
    title: 'Giao diện xuất kho',
    key: PERMISSIONS.giao_dien_xuat_kho,
    children: [
      {
        title: 'Tạo phiếu xuất kho',
        key: PERMISSIONS.tao_phieu_xuat_kho,
      },
      {
        title: 'Cập nhật phiếu xuất kho',
        key: PERMISSIONS.cap_nhat_phieu_xuat_kho,
      },
      {
        title: 'Cập nhật đơn vị vận chuyển phiếu xuất kho',
        key: PERMISSIONS.cap_nhat_don_vi_van_chuyen_phieu_xuat_kho,
      },
      {
        title: 'Xóa phiếu xuất kho',
        key: PERMISSIONS.xoa_phieu_xuat_kho,
      },
    ],
  },
  {
    title: 'Giao diện tổng hợp đơn hàng nhập',
    key: PERMISSIONS.giao_dien_tong_hop_don_hang_nhap,
  },
  {
    title: 'Giao diện tổng hợp đơn hàng xuất',
    key: PERMISSIONS.giao_dien_tong_hop_don_hang_xuat,
  },
  {
    title: 'Giao diện quản lý công nợ',
    key: PERMISSIONS.giao_dien_quan_ly_cong_no,
  },
  {
    title: 'Giao diện đơn thương mại điển tự',
    key: PERMISSIONS.giao_dien_don_thuong_mai_dien_tu,
  },
  {
    title: 'Giao diện kết nối sàn TMDT',
    key: PERMISSIONS.giao_dien_ket_noi_san_tmdt,
    children: [
      {
        title: 'Kết nối sàn TMDT',
        key: PERMISSIONS.ket_noi_san_thuong_mai_dien_tu,
      },
      {
        title: 'Cập nhật sàn TMDT',
        key: PERMISSIONS.cap_nhat_san_thuong_mai_dien_tu,
      },
      {
        title: 'Xóa sàn TMDT',
        key: PERMISSIONS.xoa_san_thuong_mai_dien_tu,
      },
    ],
  },
  {
    title: 'Giao diện hồ sơ doanh nghiệp',
    key: PERMISSIONS.giao_dien_ho_so_doanh_nghiep,
  },
  {
    title: 'Giao diện lịch sử xuất/nhập file',
    key: PERMISSIONS.giao_dien_xuat_nhap_file,
  },
  {
    title: 'Giao diện lịch sử thao tác',
    key: PERMISSIONS.giao_dien_lich_su_thao_tac,
  },
  {
    title: 'Giao diện quản lý nhân viên',
    key: PERMISSIONS.giao_dien_quan_ly_nhan_vien,
    children: [
      {
        title: 'Tạo nhân viên',
        key: PERMISSIONS.tao_nhan_vien,
      },
      {
        title: 'Cập nhật nhân viên',
        key: PERMISSIONS.cap_nhat_nhan_vien,
      },
      {
        title: 'Xóa nhân viên',
        key: PERMISSIONS.xoa_nhan_vien,
      },
    ],
  },
  {
    title: 'Giao diện quản lý phân quyền',
    key: PERMISSIONS.giao_dien_quan_ly_phan_quyen,
    children: [
      {
        title: 'Tạo vai trò',
        key: PERMISSIONS.tao_phan_quyen,
      },
      {
        title: 'Cập nhật vai trò',
        key: PERMISSIONS.cap_nhat_phan_quyen,
      },
      {
        title: 'Xóa vai trò',
        key: PERMISSIONS.xoa_phan_quyen,
      },
    ],
  },
  {
    title: 'Giao diện báo cáo tồn kho tổng hợp',
    key: PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop,
  },
  {
    title: 'Giao diện báo cáo nhập kho',
    key: PERMISSIONS.giao_dien_bao_cao_nhap_kho,
  },
  {
    title: 'Giao diện báo cáo xuất kho',
    key: PERMISSIONS.giao_dien_bao_cao_xuat_kho,
  },
  {
    title: 'Giao diện báo cáo kiểm hàng',
    key: PERMISSIONS.giao_dien_bao_cao_kiem_hang,
  },
  {
    title: 'Giao diện báo cáo nhập hàng',
    key: PERMISSIONS.giao_dien_bao_cao_nhap_hang,
  },
  {
    title: 'Giao diện báo cáo xuất hàng',
    key: PERMISSIONS.giao_dien_bao_cao_xuat_hang,
  },
  {
    title: 'Giao diện báo cáo đóng gói',
    key: PERMISSIONS.giao_dien_bao_cao_dong_goi,
  },
  {
    title: 'Giao diện báo cáo bàn giao vận chuyển',
    key: PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen,
  },
]

export default treeData
