import React, { useEffect, useState } from 'react'

import { ACTION, PERMISSIONS, ROUTES } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import style from './product-detail.module.scss'
import { filterOptionSelect } from 'utils'
import iconPrinter from 'assets/icons/icon-printer.png'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'
import Permission from 'views/permission'
import ModalOptionsPrint from 'components/modal-opions-print'
//antd
import {
  Row,
  Checkbox,
  Col,
  Form,
  Input,
  Button,
  Select,
  Space,
  InputNumber,
  DatePicker,
  Upload,
  Spin,
  Badge,
  Popconfirm,
  notification,
  Tooltip,
} from 'antd'

//icons
import {
  ArrowLeftOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'

//apis
import { createProduct, getProducts, updateProduct } from 'apis/product'
import { uploadFile } from 'apis/upload'
import { getUnits, createUnit } from 'apis/unit-product'
import { getCategories, createCategory } from 'apis/categories-product'
import jwt_decode from 'jwt-decode'

export default function ProductDetail() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)
  const queries = new URLSearchParams(location.search)
  const productId = queries.get('product_id')

  const [loadingBtn, setLoadingBtn] = useState(false)
  const [loadingType, setLoadingType] = useState(false)
  const [loadingGroup, setLoadingGroup] = useState(false)

  const [nameUnitCreate, setNameUnitCreate] = useState('')
  const [nameTypeCreate, setNameTypeCreate] = useState('') //loại hàng
  const [nameGroupCreate, setNameGroupCreate] = useState('') //nhóm hàng

  const [units, setUnits] = useState([])
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])
  const [productDefault, setProductDefault] = useState(null)

  const [loadingUpload, setLoadingUpload] = useState(false)
  const [requiredImage, setRequiredImage] = useState(true)
  const [imagesUrl, setImagesUrl] = useState([])
  const [imageView, setImageView] = useState('')
  const [barcodeValue, setBarcodeValue] = useState('')
  const context = localStorage.getItem('accessToken')
    ? jwt_decode(localStorage.getItem('accessToken'))
    : null

  const _createUnit = async () => {
    try {
      if (!nameUnitCreate) {
        notification.warning({ message: 'Vui lòng nhập tên quy cách' })
        return
      }
      setLoadingBtn(true)
      const res = await createUnit({
        name: nameUnitCreate,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        if (res.data.success) {
          await _getUnits()
          notification.success({ message: 'Tạo quy cách thành công' })
          setNameUnitCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo quy cách thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo quy cách thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  const _createType = async () => {
    try {
      if (!nameTypeCreate) {
        notification.warning({ message: 'Vui lòng nhập tên loại hàng' })
        return
      }

      const dataForm = form.getFieldsValue()
      const typeFind = groups.find((e) => e.category_id === dataForm.group_id)

      if (!typeFind) {
        notification.warning({ message: 'Vui lòng chọn nhóm hàng trước' })
        return
      }

      setLoadingBtn(true)
      const res = await createCategory({
        name: nameTypeCreate,
        parent_id: typeFind.category_id,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          await _getTypes()
          notification.success({ message: 'Tạo loại hàng thành công' })
          setNameTypeCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo loại hàng thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo loại hàng thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  const _createGroup = async () => {
    try {
      if (!nameGroupCreate) {
        notification.warning({ message: 'Vui lòng nhập tên nhóm' })
        return
      }
      setLoadingBtn(true)
      const res = await createCategory({
        name: nameGroupCreate,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        if (res.data.success) {
          await _getGroups()
          notification.success({ message: 'Tạo nhóm hàng thành công' })
          setNameGroupCreate('')
        } else
          notification.error({
            message: res.data.mess || res.data.message || 'Tạo nhóm hàng thất bại',
          })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Tạo nhóm hàng thất bại',
        })
      setLoadingBtn(false)
    } catch (error) {
      console.log(error)
      setLoadingBtn(false)
    }
  }

  const generateBarcode = () => {
    let arrBarcode = []
    const dataForm = form.getFieldsValue()

    if (dataForm.merchant_barcode) arrBarcode.push(dataForm.merchant_barcode)
    if (dataForm.origin) arrBarcode.push(dataForm.origin)

    const unit = units.find((u) => u.unit_id === dataForm.unit_id)
    if (unit && unit.representation_string) arrBarcode.push(unit.representation_string)
    const barcodeProduct = arrBarcode.join('-')
    form.setFieldsValue({ barcode: barcodeProduct })
  }

  const _createOrUpdateProduct = async () => {
    await form.validateFields()

    // if (!imagesUrl.length) {
    //   setRequiredImage(false)
    //   return
    // } else setRequiredImage(true)

    try {
      const dataForm = form.getFieldsValue()

      // //validated date
      // if (new Date(dataForm.exp).getTime() < new Date(dataForm.mfg).getTime()) {
      //   notification.warning({ message: 'Ngày sản xuất phải nhỏ hơn ngày hạn sử dụng' })
      //   return
      // }

      // if ((dataForm.barcode + '').length > 13) {
      //   notification.warning({ message: 'Barcode phải nhỏ hơn 14 kí tự' })
      //   return
      // }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        ...dataForm,
        customer_id: (dataUser && dataUser.customer_id) || '',
        group_id: dataForm.group_id || '',
        type_id: dataForm.type_id || '',
        images: imagesUrl ? imagesUrl : [],
        price: dataForm.price || 0,
        unit_id: dataForm.unit_id || '',
        // exp: dataForm.exp ? new Date(dataForm.exp) : '',
        mfg: dataForm.mfg ? new Date(dataForm.mfg) : '',
        min_quantity: dataForm.min_quantity || 0,
      }
      let res
      if (productId) res = await updateProduct(body, productId)
      else res = await createProduct(body)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: `${productId ? 'Cập nhật' : 'Tạo'} sản phẩm thành công!`,
          })

          history.push(ROUTES.PRODUCT)
        } else
          notification.error({
            message: res.data.message || `${productId ? 'Cập nhật' : 'Tạo'} sản phẩm thất bại!`,
          })
      } else
        notification.error({
          message: res.data.message || `${productId ? 'Cập nhật' : 'Tạo'} sản phẩm thất bại!`,
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const onUpdateImage = async (_imagesUrl) => {
    if (context.role_info.permissions.includes('them_anh_san_pham')) {
      var response = await updateProduct({ images: _imagesUrl }, productId)
      if (response && response.status == 200) {
        notification.success({ message: 'Cập nhật ảnh thành công' })
      } else {
        notification.error({ message: response?.data?.message ?? 'Cập nhật ảnh thất bại' })
      }
    } else {
      notification.error({ message: 'Bạn không có quyền cập nhật ảnh sản phẩm' })
    }
  }

  const _getProductDefault = async () => {
    try {
      const res = await getProducts({ page: 1, page_size: 10 })
      if (res.status === 200 && res.data.data && res.data.data.length)
        setProductDefault(res.data.data[0])
    } catch (error) {
      console.log(error)
    }
  }

  const _getProductDetail = async () => {
    try {
      dispatch({ type: 'LOADING', data: true })
      const res = await getProducts({ product_id: productId })
      dispatch({ type: 'LOADING', data: false })
      if (res.status === 200 && res.data.data && res.data.data.length) {
        const productDetail = res.data.data[0]
        if (productDetail.images && productDetail.images.length) {
          setImageView(productDetail.images[0])
          setImagesUrl(productDetail.images)
        }
        setBarcodeValue(productDetail.barcode || productDetail.code)
        form.setFieldsValue({
          ...productDetail,
          // exp: productDetail.exp ? moment(productDetail.exp) : '',
          mfg: productDetail.mfg ? moment(productDetail.mfg) : '',
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'LOADING', data: false })
    }
  }

  const _getUnits = async () => {
    try {
      const res = await getUnits({ customer_id: dataUser.customer_id, get_all: true })
      if (res.status === 200) {
        const unit = res.data.data
        setUnits(unit)
        if (!productId) if (unit && unit.length) form.setFieldsValue({ unit_id: unit[0].unit_id })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      setLoadingGroup(true)
      const res = await getCategories({
        group: true,
        detach: true,
        customer_id: dataUser.customer_id,
        get_all: true,
      })
      if (res.status === 200) {
        const group = res.data.data
        setGroups(group)
        if (!productId)
          if (group && group.length) form.setFieldsValue({ group_id: group[0].category_id })
      }
      setLoadingGroup(false)
    } catch (error) {
      setLoadingGroup(false)
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      setLoadingType(true)
      const res = await getCategories({
        type: true,
        detach: true,
        customer_id: dataUser.customer_id,
        get_all: true,
      })
      if (res.status === 200) {
        const type = res.data.data
        setTypes(type)
        if (!productId)
          if (type && type.length) form.setFieldsValue({ type_id: type[0].category_id })
      }
      setLoadingType(false)
    } catch (error) {
      setLoadingType(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (dataUser && dataUser.customer_id) {
      _getUnits()
      _getGroups()
      _getTypes()
    }
  }, [dataUser])

  useEffect(() => {
    _getProductDefault()
  }, [])

  useEffect(() => {
    if (productId) _getProductDetail(productId)
  }, [])

  return (
    <div>
      <Row justify="space-between" className="page-title" wrap={false} align="middle">
        <Col>
          <Space onClick={() => history.push(ROUTES.PRODUCT)} style={{ cursor: 'pointer' }}>
            <ArrowLeftOutlined />
            <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
              {productId ? 'Cập nhật sản phẩm' : 'Tạo sản phẩm'}
            </span>
          </Space>
        </Col>
        <Col>
          <Checkbox
            style={{ fontWeight: 600, display: productId && 'none' }}
            onChange={(e) => {
              if (e.target.checked) {
                if (productDefault) {
                  if (productDefault.images && productDefault.images.length) {
                    setImageView(productDefault.images[0])
                    setImagesUrl(productDefault.images)
                  }
                  form.setFieldsValue({
                    ...productDefault,
                    // exp: moment(productDefault.exp),
                    mfg: moment(productDefault.mfg),
                  })
                }
              } else {
                form.resetFields()
                setImageView('')
                setImagesUrl([])
              }
            }}
          >
            Điền sản phẩm gần nhất
          </Checkbox>
        </Col>
      </Row>
      <Form form={form} layout="vertical">
        <Row wrap={false} style={{ marginTop: 20 }}>
          <Col md={12} style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Form.Item
              label="Tên sản phẩm"
              name="name"
              rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm' }]}
            >
              <Input placeholder="Nhập tên sản phẩm" />
            </Form.Item>
            <Form.Item label="Nhóm sản phẩm" name="group_id">
              <Select
                notFoundContent={loadingGroup ? <Spin /> : null}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn nhóm sản phẩm"
                style={{ width: '100%' }}
                dropdownClassName="dropdown-select-custom"
                suffixIcon={<SuffixIconCustom />}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        value={nameGroupCreate}
                        onPressEnter={_createGroup}
                        placeholder="Nhập tên nhóm sản phẩm"
                        onChange={(e) => setNameGroupCreate(e.target.value)}
                      />
                      <Button
                        loading={loadingBtn}
                        onClick={_createGroup}
                        type="primary"
                        style={{ marginLeft: 10 }}
                      >
                        Tạo mới
                      </Button>
                    </div>
                  </div>
                )}
              >
                {groups.length ? (
                  groups.map((group, index) => (
                    <Select.Option value={group.category_id} key={index}>
                      {group.name}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option></Select.Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item label="Loại sản phẩm" name="type_id">
              <Select
                notFoundContent={loadingType ? <Spin /> : null}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Chọn loại sản phẩm"
                style={{ width: '100%' }}
                dropdownClassName="dropdown-select-custom"
                suffixIcon={<SuffixIconCustom />}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        value={nameTypeCreate}
                        onPressEnter={_createType}
                        placeholder="Nhập tên loại sản phẩm"
                        onChange={(e) => setNameTypeCreate(e.target.value)}
                      />
                      <Button
                        loading={loadingBtn}
                        onClick={_createType}
                        type="primary"
                        style={{ marginLeft: 10 }}
                      >
                        Tạo mới
                      </Button>
                    </div>
                  </div>
                )}
              >
                {types.length ? (
                  types.map((type, index) => (
                    <Select.Option value={type.category_id} key={index}>
                      {type.name}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option></Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item initialValue="" style={{ width: '100%' }} label="Xuất xứ" name="origin">
              <Input
                // onChange={generateBarcode}
                allowClear
                style={{ width: '100%' }}
                placeholder="Nhập xuất xứ"
              />
            </Form.Item>
            {/* <Form.Item
              style={{ width: '100%' }}
              label="Mã sản phẩm Merchant (Barcode)"
              name="merchant_barcode"
              initialValue=""
            >
              <Input
                onChange={generateBarcode}
                allowClear
                style={{ width: '100%' }}
                placeholder="Nhập mã sản phẩm merchant"
              />
            </Form.Item> */}
            <Row wrap={false} align="middle">
              <Form.Item
                style={{ width: '100%' }}
                label="Mã sản phẩm (Barcode)"
                name="barcode"
                rules={[{ required: true, message: 'Vui lòng nhập mã sản phẩm (barcode)' }]}
              >
                <Input
                  placeholder="Nhập mã sản phẩm (Barcode)"
                  allowClear
                  value={barcodeValue}
                  onChange={(e) => setBarcodeValue(e.target.value)}
                  // style={{ width: '100%', pointerEvents: 'none' }}
                  // readOnly
                />
              </Form.Item>
              <ModalOptionsPrint
                records={[
                  {
                    code: barcodeValue,
                    barcode: barcodeValue,
                    name: form.getFieldsValue().name || '',
                    convention: form.getFieldsValue().convention || '',
                    unit_info: units.find(
                      (u) => u.unit_id === (form.getFieldsValue().unit_id || '')
                    ),
                  },
                ]}
              >
                {barcodeValue && (
                  <Tooltip title="In mã hàng">
                    <img
                      // onClick={_printBarcode}
                      src={iconPrinter}
                      style={{
                        width: 32,
                        height: 32,
                        cursor: 'pointer',
                        marginTop: 20,
                        marginLeft: 15,
                      }}
                      alt=""
                    />
                  </Tooltip>
                )}
              </ModalOptionsPrint>
            </Row>
            <Form.Item
              initialValue=""
              style={{ width: '100%' }}
              label="Mã ERP"
              name="ecommerce_barcode"
            >
              <Input style={{ width: '100%' }} placeholder="Nhập mã ERP" />
            </Form.Item>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                <span style={{ color: 'red' }}>* </span>Kích thước (cm)
              </span>
              <Row wrap={false} justify="space-between">
                <Form.Item
                  style={{ width: '30%' }}
                  name="length"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều dài' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều dài"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '30%' }}
                  name="width"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều rộng' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều rộng"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '30%' }}
                  name="height"
                  rules={[{ required: true, message: 'Vui lòng nhập chiều cao' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder="Chiều cao"
                  />
                </Form.Item>
              </Row>
            </div>

            <Row wrap={false} justify="space-between" align="middle">
              <Form.Item
                initialValue={0}
                style={{ width: '40%' }}
                label="Trọng lượng (kg)"
                name="weight"
                // rules={[{ required: true, message: 'Vui lòng nhập trọng lượng' }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Trọng lượng"
                />
              </Form.Item>
              <Form.Item
                initialValue={0}
                // rules={[{ required: true, message: 'Vui lòng nhập tồn kho tối thiểu' }]}
                style={{ width: '58%' }}
                label="Tồn kho tối thiểu"
                name="min_quantity"
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Nhập số lượng tồn kho"
                />
              </Form.Item>
            </Row>
            <Row wrap={false} justify="space-between" align="middle">
              <Form.Item
                initialValue=""
                style={{ width: '40%' }}
                label="Quy cách"
                name="convention"
                // rules={[{ required: true, message: 'Vui lòng quy cách' }]}
              >
                <Input style={{ width: '100%' }} placeholder="Nhập quy cách" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Vui lòng chọn đơn vị tính' }]}
                style={{ width: '58%' }}
                label="Đơn vị tính"
                name="unit_id"
              >
                <Select
                  showSearch
                  placeholder="Chọn đơn vị tính"
                  style={{ width: '100%' }}
                  dropdownClassName="dropdown-select-custom"
                  suffixIcon={<SuffixIconCustom />}
                  filterOption={filterOptionSelect}
                  // onChange={generateBarcode}
                >
                  {units.map((unit, index) => (
                    <Select.Option value={unit.unit_id} key={index}>
                      {unit.name}{' '}
                      {unit.conversion_quantity ? `- SL quy đổi: ${unit.conversion_quantity}` : ''}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>

            <Form.Item initialValue={false} name="is_create_barcode" valuePropName="checked">
              <Checkbox>Sử dụng barcode SGL</Checkbox>
            </Form.Item>
          </Col>
          <Col md={12} style={{ paddingLeft: 50, paddingRight: 50 }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  Hình ảnh
                </span>
                {/* {!requiredImage && (
                  <span style={{ color: 'red' }}>Vui lòng chọn ít nhất 1 hình ảnh</span>
                )} */}
              </div>
              <div
                style={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  borderRadius: 5,
                  borderColor: 'gray',
                  borderWidth: '1px',
                  minHeight: 400,
                  maxHeight: 400,
                  borderStyle: 'dashed',
                  marginBottom: 20,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={imageView}
                  alt=""
                  style={{
                    width: '100%',
                    minHeight: 400,
                    maxHeight: 400,
                    objectFit: 'contain',
                  }}
                />
              </div>
              <Row justify="space-between" wrap={false} align="middle" style={{ width: '100%' }}>
                {imagesUrl.length !== 4 ? (
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onChange={(info) => {
                      if (info.file.status !== 'done') info.file.status = 'done'
                    }}
                    data={async (file) => {
                      setLoadingUpload(true)
                      const imagesUrlNew = [...imagesUrl]
                      const url = await uploadFile(file)
                      setImageView(url)
                      imagesUrlNew.push(url)
                      setImagesUrl([...imagesUrlNew])
                      setRequiredImage(true)
                      setLoadingUpload(false)
                      onUpdateImage([...imagesUrlNew])
                    }}
                  >
                    <div>
                      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Tải ảnh lên</div>
                    </div>
                  </Upload>
                ) : (
                  ''
                )}
                {imagesUrl.map((url, index) => (
                  <Badge
                    count={
                      <Popconfirm
                        onConfirm={() => {
                          const imagesUrlNew = [...imagesUrl]
                          imagesUrlNew.splice(index, 1)
                          if (imagesUrlNew.length === 0) setImageView('')
                          else setImageView(imagesUrlNew[0])
                          setImagesUrl([...imagesUrlNew])
                          onUpdateImage([...imagesUrlNew])
                        }}
                        title="Bạn có muốn xóa ảnh này không ?"
                        okText="Đồng ý"
                        cancelText="Từ chối"
                      >
                        <CloseCircleTwoTone style={{ fontSize: 19 }} />
                      </Popconfirm>
                    }
                  >
                    <img
                      onClick={() => setImageView(url)}
                      src={url}
                      alt=""
                      style={{
                        width: 85,
                        height: 85,
                        cursor: 'pointer',
                        objectFit: 'cover',
                        borderRadius: 5,
                        overflow: 'hidden',
                      }}
                    />
                  </Badge>
                ))}
              </Row>
            </div>
            <Form.Item initialValue="" style={{ marginTop: 8 }} label="Giá tiền (VND)" name="price">
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                min={0}
                style={{ width: '100%' }}
                placeholder="Nhập giá tiền"
              />
            </Form.Item>
            {/* <Form.Item initialValue="" label="Ngày sản xuất" name="mfg">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item> */}
            {/* <Form.Item
              initialValue=""
              label="Hạn sử dụng mặc định khi tạo phiếu nhập"
              name="exp"
              extra={
                <span style={{ color: 'red' }}>
                  Hệ thống ưu tiên xuất kho sản phẩm có hạn sử dụng gần
                </span>
              }
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item> */}
            <Row wrap={false} justify="space-between" style={{ marginTop: 19 }}>
              <Button
                onClick={() => history.goBack()}
                icon={<CloseCircleOutlined />}
                style={{ width: 130, borderRadius: 5 }}
                type="primary"
                danger
              >
                Hủy
              </Button>
              <Permission
                permissions={
                  productId ? [PERMISSIONS.cap_nhat_san_pham] : [PERMISSIONS.tao_san_pham]
                }
              >
                <Button
                  className={style['btn-create']}
                  onClick={_createOrUpdateProduct}
                  type="primary"
                >
                  {productId ? 'Lưu' : 'Tạo sản phẩm'}
                </Button>
              </Permission>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
