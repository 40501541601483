export const ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
}

export const VERSION_APP = '0.3.5'
export const TYPE_DEPLOY = 'LIVE'

export const FIXEDROLES = [
  'giamdoc',
  'ketoan',
  'quanlyvanhanh',
  'phoquanlyvanhanh',
  'nhanviennhaphang',
  'nhanvienxuathang',
  'nhanviensoanhang',
  'nhanvienbangiao',
]

export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  ORDER_COLLECTION_EXPORT: '/order-collection-export',
  ORDER_COLLECTION_IMPORT: '/order-collection-import',
  ORDER_DETAIL_IMPORT: '/order-detail-import',
  ORDER_DETAIL_EXPORT: '/order-detail-export',
  ORDER_E_COMMERCE: '/order-e-commerce',
  PRODUCT: '/product',
  EMPLOYEE: '/employee',
  EMPLOYEE_CREATE: '/employee/create',
  DEBIT: '/debit',
  SITE: '/site',
  ORDER_FEE: '/order-fee',
  IMPORTEXPORTMANAGEMENT: '/import-export-management',
  BUSSINESS_PROFILE: '/bussiness-profile',
  SHIPPING_CONTROL: '/shipping-control',
  VERIFY_LAZADA: '/verify-ladaza',
  ACTIVITY_HISTORY: '/activity-history',
  SUPPLIER_LIST: '/supplier/list',
  SUPPLIER_CREATE: '/supplier/create',
  SUPPLIER_UPDATE: '/supplier/update',
  BRANCH_LIST: '/branch/list',
  BRANCH_CREATE: '/branch/create',
  BRANCH_UPDATE: '/branch/update',

  // xuất kho
  EXPORT_WAREHOUSE: '/export-warehouse',
  EXPORT_WAREHOUSE_CREATE: '/export-warehouse/create',
  EXPORT_WAREHOUSE_UPDATE: '/export-warehouse-detail',
  // nhập kho
  IMPORT_WAREHOUSE: '/import-warehouse',
  IMPORT_WAREHOUSE_CREATE: '/import-warehouse/create',
  IMPORT_WAREHOUSE_UPDATE: '/import-warehouse/update',
  //
  ORDER: '/order',
  ORDER_DETAIL: '/order-detail',
  PRODUCT_DETAIL: '/product-detail',

  REPORT_INVENTORY: '/report-inventory',
  REPORT_INVENTORY_DETAIL: '/report-inventory-detail',
  REPORT_IMPORT_WAREHOUSE: '/report-import-warehouse',
  REPORT_EXPORT_WAREHOUSE: '/report-export-warehouse',
  REPORT_PRODUCT_CHECKING: '/report-product-checking',
  REPORT_IMPORT_ORDER: '/report-import-order',
  REPORT_EXPORT_ORDER: '/report-export-order',
  REPORT_PACK_ORDER: '/report-pack-order',
  REPORT_TRANSPORT_ORDER: '/report-transport-order',
  // lịch sử nhập xuất kho
  HISTORY_WAREHOUSE: '/history-warehouse',
  FORGET_PASSWORD: '/forget-password',
  VERIFY_OTP: '/verify-otp',
  RECOVERY_PASSWORD: '/recovery-password',
  //phân quyền
  ROLE_MANAGEMENT: '/role-management',
  // sites
  CONNECT_TIKI_CALLBACK: '/connect-tiki/callback',
  CONNECT_LAZADA_CALLBACK: '/connect-lazada/callback',
  CONNECT_SHOPPEE_CALLBACK: '/connect-shopee/callback',
  CONNECT_TIKTOK_CALLBACK: '/connect-tiktok/callback',
  CONNECT_SAPO_CALLBACK: '/connect-sapo/callback',
  PRIVACY: '/privacy',
}
export const FILTER_COL_HEIGHT = ''
export const FILTER_SIZE = ''

export const PERMISSIONS = {
  giao_dien_san_pham: 'giao_dien_san_pham',
  giao_dien_chi_nhanh: 'giao_dien_chi_nhanh',
  giao_dien_nha_cung_cap: 'giao_dien_nha_cung_cap',
  giao_dien_nhap_kho: 'giao_dien_nhap_kho',
  giao_dien_xuat_kho: 'giao_dien_xuat_kho',
  giao_dien_tong_hop_don_hang_xuat: 'giao_dien_tong_hop_don_hang_xuat',
  giao_dien_tong_hop_don_hang_nhap: 'giao_dien_tong_hop_don_hang_nhap',
  giao_dien_quan_ly_cong_no: 'giao_dien_quan_ly_cong_no',
  giao_dien_ket_noi_san_tmdt: 'giao_dien_ket_noi_san_tmdt',
  giao_dien_quan_ly_nhan_vien: 'giao_dien_quan_ly_nhan_vien',
  giao_dien_ho_so_doanh_nghiep: 'giao_dien_ho_so_doanh_nghiep',
  giao_dien_xuat_nhap_file: 'giao_dien_xuat_nhap_file',
  giao_dien_lich_su_thao_tac: 'giao_dien_lich_su_thao_tac',
  giao_dien_bao_cao_ton_kho_tong_hop: 'giao_dien_bao_cao_ton_kho_tong_hop',
  giao_dien_bao_cao_nhap_kho: 'giao_dien_bao_cao_nhap_kho',
  giao_dien_bao_cao_xuat_kho: 'giao_dien_bao_cao_xuat_kho',
  giao_dien_bao_cao_kiem_hang: 'giao_dien_bao_cao_kiem_hang',
  giao_dien_bao_cao_nhap_hang: 'giao_dien_bao_cao_nhap_hang',
  giao_dien_bao_cao_xuat_hang: 'giao_dien_bao_cao_xuat_hang',
  giao_dien_bao_cao_dong_goi: 'giao_dien_bao_cao_dong_goi',
  giao_dien_bao_cao_ban_giao_van_chuyen: 'giao_dien_bao_cao_ban_giao_van_chuyen',
  giao_dien_quan_ly_phan_quyen: 'giao_dien_quan_ly_phan_quyen',
  giao_dien_don_thuong_mai_dien_tu: 'giao_dien_don_thuong_mai_dien_tu',
  giao_dien_doi_soat_van_chuyen: 'giao_dien_doi_soat_van_chuyen',

  tao_san_pham: 'tao_san_pham',
  xem_san_pham: 'xem_san_pham',
  them_anh_san_pham: 'them_anh_san_pham',
  cap_nhat_san_pham: 'cap_nhat_san_pham',
  xoa_san_pham: 'xoa_san_pham',

  tao_chi_nhanh: 'tao_chi_nhanh',
  cap_nhat_chi_nhanh: 'cap_nhat_chi_nhanh',
  xoa_chi_nhanh: 'xoa_chi_nhanh',

  tao_nha_cung_cap: 'tao_nha_cung_cap',
  cap_nhat_nha_cung_cap: 'cap_nhat_nha_cung_cap',
  xoa_nha_cung_cap: 'xoa_nha_cung_cap',

  tao_phieu_nhap_kho: 'tao_phieu_nhap_kho',
  cap_nhat_phieu_nhap_kho: 'cap_nhat_phieu_nhap_kho',
  xoa_phieu_nhap_kho: 'xoa_phieu_nhap_kho',

  tao_phieu_xuat_kho: 'tao_phieu_xuat_kho',
  cap_nhat_phieu_xuat_kho: 'cap_nhat_phieu_xuat_kho',
  cap_nhat_don_vi_van_chuyen_phieu_xuat_kho: 'cap_nhat_don_vi_van_chuyen_phieu_xuat_kho',
  xoa_phieu_xuat_kho: 'xoa_phieu_xuat_kho',

  tao_phan_quyen: 'tao_phan_quyen',
  cap_nhat_phan_quyen: 'cap_nhat_phan_quyen',
  xoa_phan_quyen: 'xoa_phan_quyen',

  tao_nhan_vien: 'tao_nhan_vien',
  cap_nhat_nhan_vien: 'cap_nhat_nhan_vien',
  xoa_nhan_vien: 'xoa_nhan_vien',

  ket_noi_san_thuong_mai_dien_tu: 'ket_noi_san_thuong_mai_dien_tu',
  cap_nhat_san_thuong_mai_dien_tu: 'cap_nhat_san_thuong_mai_dien_tu',
  xoa_san_thuong_mai_dien_tu: 'xoa_san_thuong_mai_dien_tu',
}
export const REGEX_PHONE = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
export const REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ //Tối thiểu tám ký tự, ít nhất một chữ cái, một số và một ký tự đặc biệt
export const REGEX_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
export const REGEX_P = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/

export const IMAGE_DEFAULT =
  'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'

export const SIZE_TABLE = 'small'
export const PAGE_SIZE = 20
export const PAGE_SIZE_OPTIONS = [20, 40, 50, 60, 80, 100]
export const POSITION_TABLE = ['bottomRight']
