import { useEffect, useState, useRef } from 'react'
import styles from './ordercollection.module.scss'
import { PAGE_SIZE_OPTIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { filterOptionSelect, saveFiltersToLocalStorage, getFileName } from 'utils'
import { saveAs } from 'file-saver'

//antd
import { Col, Input, Row, Table, Button, Select, Space } from 'antd'

//icons
import { ReloadOutlined, VerticalAlignTopOutlined } from '@ant-design/icons'

//component
import columns from './columns'
import DropdownButton from './dropdownButton'
import FilterDate from 'components/filter-date'
import SuffixIconCustom from 'components/suffix-icon-custom'

//apis
import { getOrders } from 'apis/order'
import { getWarehouses } from 'apis/warehouse'
import { getCategories } from 'apis/categories-product'
import { exportReportImportInventory } from 'apis/report'

export default function OrderCollection() {
  const typingTimeoutRef = useRef(null)
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const KEY_FILTER = 'orderCollectionImportFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [warehouses, setWarehouses] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 10, today: true }
  ) //Đơn hàng ít nhất phải có is_received = true (ko hiển thị đơn nháp)
  const [exportOrderCount, setExportOrderCount] = useState(0)
  const [dataExportOrder, setDataExportOrder] = useState([])
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])

  const _getOrders = async () => {
    try {
      setLoading(true)
      const res = await getOrders({
        ...paramsFilter,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        setDataExportOrder(res.data.data)
        setExportOrderCount(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _search = (e) => {
    setValueSearch(String(e.target.value).replace(/#/gi, ''))
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _getReportExportInventoryToExport = async (getAll = false) => {
    try {
      let query = {}

      if (getAll) {
        const queries = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'this_month',
          'last_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]

        queries.map((key) => {
          if (Object.keys(paramsFilter).includes(key)) query[key] = paramsFilter[key]
        })
      } else query = { ...paramsFilter }

      delete query.page
      delete query.page_size

      setLoading(true)
      const res = await exportReportImportInventory({
        ...query,
        customer_id: (dataUser && dataUser.customer_id) || '',
        // processing: 'COMPLETE',
      })
      setLoading(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const indicators = [
    {
      title: 'Đang chờ',
      bgColor: 'rgba(255, 192, 134, 0.75)',
    },
    {
      title: 'Đang thực hiện',
      bgColor: '#13D8E5',
    },
    {
      title: 'Đã thực hiện',
      bgColor: '#79CF4F',
    },
    // {
    //   title: 'Có sự cố',
    //   bgColor: '#D63F3F',
    // },
  ]

  const _actionDate = (columnKey, record) => {
    switch (columnKey) {
      case 'is_checked':
        return record.check_date
      case 'is_imported':
        return record.import_date
      default:
        return ''
    }
  }
  const _status = (columnKey) => {
    switch (columnKey) {
      case 'is_checked':
        return 'check_status'
      case 'is_imported':
        return 'import_status'
      default:
        return ''
    }
  }

  const _clearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 10, today: true })
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getGroups()
    _getTypes()
    _getWarehouses()
  }, [])

  return (
    <div className={styles['order-collection']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ fontWeight: 700, fontSize: 20, color: '#223b4f' }}>Tổng hợp đơn hàng nhập</h3>
        <Space>
          <Button
            onClick={() => _getReportExportInventoryToExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>
        </Space>
      </Row>
      <Row gutter={[15, 15]} style={{ marginBottom: '20px' }}>
        <Col span={9}>
          <Input.Group compact className={styles['order-collection-filter-group-item']}>
            <Input
              value={valueSearch}
              allowClear
              onChange={_search}
              style={{ width: '100%' }}
              placeholder="Tìm kiếm theo mã đơn hàng, mã vận đơn, số phiếu"
            />
          </Input.Group>
        </Col>
        <Col span={5}>
          <FilterDate
            allowClear={false}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
          />
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['warehouse_id'] || undefined}
            onChange={(value) => _filterByOption('warehouse_id', value)}
            showSearch
            filterOption={filterOptionSelect}
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouses.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            value={paramsFilter['type_id'] || undefined}
            onChange={(value) => _filterByOption('type_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo loại hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {types.map((type, index) => (
              <Select.Option value={type.category_id} key={index}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            value={paramsFilter['group_id'] || undefined}
            onChange={(value) => _filterByOption('group_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo nhóm hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {groups.map((group, index) => (
              <Select.Option value={group.category_id} key={index}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row justify="space-between">
        <div style={{ display: 'flex', gap: '10px', margin: '10px 0 0 10px' }}>
          {indicators.map((indicator, index) => {
            return (
              <div key={index} style={{ display: 'flex', gap: '5px' }}>
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: indicator.bgColor,
                  }}
                ></div>
                <span style={{ lineHeight: '15px' }}>{indicator.title}</span>
              </div>
            )
          })}
        </div>
        <Space style={{ marginBottom: 10 }}>
          <Button
            onClick={() => _clearFilters()}
            type="primary"
            danger
            style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
          <Button onClick={_getOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <Row>
        <Table
          loading={loading}
          style={{ width: '100%' }}
          scroll={{ y: '48vh' }}
          className={styles['order-collection-table']}
          dataSource={dataExportOrder}
          columns={columns.map((column) => {
            if (['is_checked', 'is_imported'].indexOf(column.key) > -1)
              return {
                ...column,

                render: (is_Action, record) => {
                  const actionDate = _actionDate(column.key, record)
                  return is_Action ? (
                    <div
                      onClick={() =>
                        history.push(
                          ROUTES.ORDER_DETAIL_IMPORT +
                            `?order_id=${record.order_id}&status=${column.key}&type=import`
                        )
                      }
                      style={{
                        width: '100%',
                        height: 130,
                        padding: 5,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        lineHeight: 1.2,
                        textAlign: 'start',
                        fontSize: 13,
                      }}
                      className={styles[record[_status(column.key)]]}
                    >
                      <div>Mã đơn hàng: {'#' + record.code}</div>
                      <div>Số phiếu: {'#' + record.customer_order_code}</div>
                      <div>
                        Mã vận đơn:{' '}
                        {record.shipping_info && `#${record.shipping_info.shipping_code}`}
                      </div>
                      <div>{actionDate && moment(actionDate).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                  ) : (
                    ''
                  )
                },
                title: (
                  <DropdownButton
                    orderType={column.key}
                    setParamsFilter={setParamsFilter}
                    status={_status(column.key)}
                  />
                ),
              }

            return column
          })}
          size={SIZE_TABLE}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: exportOrderCount,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter })
            },
          }}
        />
      </Row>
    </div>
  )
}
