const columnsProduct = [
  {
    key: 'number',
    title: 'STT',
    align: 'center',
    width: 50,
  },
  {
    key: 'employee_code',
    title: 'Mã nhân viên',
    width: 150,
    dataIndex: 'code',
  },
  {
    key: 'username',
    title: 'Tên tài khoản',
    dataIndex: 'username',
    width: 150,
  },
  {
    title: 'Tên nhân viên',
    dataIndex: 'fullname',
    width: 200,
  },
  {
    key: 'address',
    title: 'Địa chỉ',
    align: 'center',
    width: 300,
  },
  {
    key: 'role',
    title: 'Vai trò',
    width: 140,
  },
  {
    key: 'create_date',
    title: 'Ngày tạo',
    dataIndex: 'create_date',
    align: 'center',
    width: 150,
  },
  {
    key: 'action',
    title: 'Hành động',
    align: 'center',
    width: 100,
  },
]

export default columnsProduct
