import React from 'react'

import { DownOutlined } from '@ant-design/icons'

export default function SuffixIconCustom() {
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: '-10px',
          right: '-10.5px',
          left: '-5.5px',
          bottom: '-22px',
          backgroundColor: '#13D8E5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottomRightRadius: 6,
          borderTopRightRadius: 6,
        }}
      >
        <DownOutlined style={{ color: 'white' }} />
      </div>
    </div>
  )
}
