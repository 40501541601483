const columnsProduct = [
  {
    title: 'STT',
    align: 'center',
    key: 'number',
    fixed: 'left',
    width: 50,
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 250,
  },
  {
    title: 'Mã hàng/Barcode',
    dataIndex: 'barcode',
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Mã ERP',
    dataIndex: 'ecommerce_barcode',
    width: 160,
  },
  {
    title: 'QR code',
    key: 'qr_code',
    width: 90,
  },
  {
    title: 'Nhóm hàng',
    key: 'group',
    width: 150,
  },
  {
    title: 'Loại hàng',
    key: 'type',
    width: 150,
  },
  {
    title: 'Xuất xứ',
    dataIndex: 'origin',
    width: 150,
  },
  {
    title: 'Hình ảnh',
    key: 'image',
    width: 80,
  },
  {
    title: 'Số lượng có thể xuất',
    key: 'available_quantity',
    width: 100,
  },
  {
    title: 'Số lượng hiện tại',
    key: 'current_quantity',
    width: 100,
  },
  {
    title: 'Giá tiền',
    dataIndex: 'price',
    key: 'price',
    width: 150,
  },
  {
    title: 'Cbm (cm³)',
    dataIndex: 'cbm',
    width: 150,
  },
  {
    title: 'D.Tích mặt phẳng (cm²)',
    dataIndex: 'area',
    width: 250,
  },
  {
    title: 'Quy cách',
    dataIndex: 'convention',
    width: 100,
  },
  {
    title: 'Đơn vị tính',
    key: 'unit',
    width: 100,
  },
  {
    title: 'Đơn vị nhỏ nhất',
    key: 'smallest_unit',
    width: 90,
  },
  {
    title: 'Kho',
    key: 'warehouse',
    width: 150,
  },
  {
    title: 'Hạn sử dụng',
    key: 'exp',
    width: 150,
  },
  {
    title: 'Ngày sản xuất',
    key: 'mfg',
    width: 150,
  },
  {
    title: 'Hành động',
    key: 'action',
    width: 350,
  },
]

export default columnsProduct
