import React, { useEffect, useState } from 'react'
import styles from 'views/login/login.module.scss'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { REGEX_PASSWORD, ROUTES } from 'consts'

// img
import banner from 'assets/images/SGL_banner.png'

// antd
import { Col, Row, Form, Input, Button, notification } from 'antd'
import { LockOutlined } from '@ant-design/icons'

//apis
import { recoveryPassword } from 'apis/auth'

export default function RecoveryPassword() {
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()

  const queries = new URLSearchParams(location.search)
  const username = queries.get('username')

  const [loading, setLoading] = useState(false)

  const _submitForm = async (body) => {
    await form.validateFields()

    if (body.password !== body.password_again) {
      notification.error({
        message: 'Các mật khẩu đã nhập không khớp, vui lòng thử lại',
        duration: 5,
      })
      return
    }

    if (!REGEX_PASSWORD.test(body.password)) {
      notification.error({
        message:
          'Vui lòng sử dụng mật khẩu có ít nhất tám ký tự, ít nhất một chữ cái, một số và một ký tự đặc biệt.',
        duration: 5,
      })
      return
    }

    const bodySubmit = { username: username, password: body.password }
    try {
      setLoading(true)
      const res = await recoveryPassword(bodySubmit)
      setLoading(false)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({ message: 'Đặt lại mật khẩu thành công' })
          history.push(ROUTES.LOGIN)
        } else {
          notification.error({
            message:
              res.data.mess || res.data.message || 'Đặt lại mật khẩu thất bại, vui lòng thử lại',
          })
        }
      } else {
        notification.error({
          message:
            res.data.mess || res.data.message || 'Đặt lại mật khẩu thất bại, vui lòng thử lại',
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (!username) history.push(ROUTES.LOGIN)
  }, [])

  return (
    <div className={styles['login']}>
      <Row>
        <Col xs={24} lg={12} xl={10}>
          <img src={banner} alt="" style={{ minWidth: '1024px' }} />
          <div className={styles['login-form']}>
            <h2 align="center">Đặt lại mật khẩu</h2>
            <Form form={form} onFinish={_submitForm} layout="vertical">
              <Form.Item
                name="password"
                label="Mật khẩu mới"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="Nhập mật khẩu mới"
                  prefix={<LockOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Form.Item
                name="password_again"
                label="Xác nhận lại mật khẩu"
                rules={[{ required: true, message: 'Vui lòng xác nhận mật khẩu mới' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="Nhập xác nhận lại mật khẩu"
                  prefix={<LockOutlined />}
                  style={{ borderRadius: 5 }}
                />
              </Form.Item>
              <Link
                style={{ display: 'block', textAlign: 'right', color: 'black' }}
                to={ROUTES.LOGIN}
              >
                Quay về trang đăng nhập
              </Link>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    marginTop: 20,
                    width: '100%',
                  }}
                >
                  Xác nhận
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}
