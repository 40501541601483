import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { saveFiltersToLocalStorage, formatCash } from 'utils'
import styles from './storagefee.module.scss'
import moment from 'moment'
import { PAGE_SIZE_OPTIONS, POSITION_TABLE, SIZE_TABLE } from 'consts'
import { useSelector } from 'react-redux'

//components
import SettingColumnsButton from 'components/setting-columns'
import columnsStorageFee from './columns'
import FilterDate from 'components/filter-date'
import SuffixIconCustom from 'components/suffixIconCustom'

// antd
import { Col, Row, Button, Input, Table, Space, Tag, Select } from 'antd'
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons'

//apis
import { getFeeInOrder } from 'apis/fee-in-order'

export default function StorageFee() {
  const history = useHistory()
  const typingTimeoutRef = useRef(null)
  const KEY_FILTER = 'OrderFeeFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))
  const dataUser = useSelector((state) => state.login.dataUser)

  const [columns, setColumns] = useState([])
  const [countFee, setCountFee] = useState(0)
  const [selectKeys, setSelectKeys] = useState([])
  const [loading, setLoading] = useState(false)

  const [storageFees, setStorageFees] = useState([])
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 100 }
  )

  const [valueSearch, setValueSearch] = useState(
    filters
      ? filters.customer_name ||
          filters.warehouse_name ||
          filters.import_order_code ||
          filters.export_order_code ||
          filters.import_order_customer_code ||
          filters.export_order_customer_code ||
          ''
      : ''
  )
  const [optionSearch, setOptionSearch] = useState(
    filters
      ? (filters.customer_name && 'customer_name') ||
          (filters.warehouse_name && 'warehouse_name') ||
          (filters.import_order_code && 'import_order_code') ||
          (filters.export_order_code && 'export_order_code') ||
          (filters.import_order_customer_code && 'import_order_customer_code') ||
          (filters.export_order_customer_code && 'export_order_customer_code') ||
          'customer_name'
      : 'customer_name'
  )

  const _getFeeInOrder = async () => {
    try {
      setSelectKeys([])
      setLoading(true)
      const res = await getFeeInOrder({
        ...paramsFilter,
        customer_id: dataUser.customer_id,
        creator_info: true,
        warehouse_info: true,
        customer_info: true,
      })
      console.log(res)
      if (res.status === 200) {
        const count = res.data.count
        setStorageFees(res.data.data)
        setCountFee(count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _selectOption = (value) => {
    setOptionSearch(value)
    delete paramsFilter[optionSearch]
    if (valueSearch) setParamsFilter({ ...paramsFilter, [value]: valueSearch, page: 1 })
  }

  const _onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter[optionSearch] = value
      else delete paramsFilter[optionSearch]
      setParamsFilter({ ...paramsFilter, page: 1 })
    }, 650)
  }

  const _clearFilters = () => {
    setSelectKeys([])
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20 })
  }

  useEffect(() => {
    _getFeeInOrder()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['storage-fee']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3>Bảng kê chi phí</h3>
        <Space>
          <Button onClick={_getFeeInOrder} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
          <SettingColumnsButton
            columnsDefault={columnsStorageFee}
            columns={columns}
            setColumns={setColumns}
            nameColumn="columnsOrderFee"
          />
        </Space>
      </Row>
      <div className={styles['storage-fee-content']}>
        <Row gutter={20} style={{ marginBottom: 15 }}>
          <Col span={10}>
            <Input.Group
              compact
              style={{
                width: '100%',
                boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)',
                marginRight: 30,
              }}
            >
              <Input
                value={valueSearch}
                allowClear
                onChange={_onSearch}
                enterButton
                placeholder="Tìm kiếm theo tiêu chí"
                style={{ width: '65%', boxShadow: '0px 2px 6px rgba(19, 216, 229, 0.13)' }}
              />
              <Select
                suffixIcon={<SuffixIconCustom />}
                style={{ width: '35%' }}
                onChange={_selectOption}
                value={optionSearch}
              >
                <Select.Option value="customer_name">Tên doanh nghiệp</Select.Option>
                <Select.Option value="warehouse_name">Tên kho</Select.Option>
                <Select.Option value="import_order_code">Mã phiếu nhập</Select.Option>
                <Select.Option value="export_order_code">Mã phiếu xuất</Select.Option>
                <Select.Option value="import_order_customer_code">Số phiếu nhập</Select.Option>
                <Select.Option value="export_order_customer_code">Số phiếu xuất</Select.Option>
              </Select>
            </Input.Group>
          </Col>
          <Col span={5}>
            <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
          </Col>
          <Col span={6}>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Lọc theo trạng thái kiểm duyệt"
              suffixIcon={<SuffixIconCustom />}
              onChange={(value) => {
                if (value !== undefined) paramsFilter.is_verify = value
                else delete paramsFilter.is_verify
                setParamsFilter({ ...paramsFilter, page: 1 })
              }}
              value={paramsFilter.is_verify}
            >
              <Select.Option value={true}>Đã duyệt</Select.Option>
              <Select.Option value={false}>Từ chối</Select.Option>
            </Select>
          </Col>
          <Col span={3}>
            {Object.keys(paramsFilter).length > 2 && (
              <Button onClick={_clearFilters} type="primary" danger icon={<CloseCircleOutlined />}>
                Xóa bộ lọc
              </Button>
            )}
          </Col>
        </Row>
        <Table
          loading={loading}
          rowKey="_id"
          scroll={{ y: '60vh', x: 1700 }}
          style={{ width: '100%' }}
          dataSource={storageFees}
          columns={columns.map((column) => {
            if (column.key === 'customer_info')
              return {
                ...column,
                render: (text, record) => record.customer_info && record.customer_info.fullname,
              }
            if (column.key === 'stt')
              return {
                ...column,
                render: (text, record, index) =>
                  (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
              }
            if (column.key === 'warehouse_info')
              return {
                ...column,
                render: (text, record) => record.warehouse_info && record.warehouse_info.name,
              }
            if (column.key === 'group_info')
              return {
                ...column,
                render: (text, record) => record.group_info && record.group_info.name,
              }
            if (column.key === 'fee_name')
              return {
                ...column,
                render: (text, record) =>
                  record.fee_info && (record.fee_info.expense_name || record.fee_info.fee_name),
              }
            if (column.key === 'fee_cost')
              return {
                ...column,
                render: (text, record) => record.fee_info && formatCash(record.fee_info.fee || 0),
              }
            if (column.key === 'quantity')
              return {
                ...column,
                render: (text, record) =>
                  record.fee_info &&
                  `${formatCash(record.fee_info.unit_quantity || 0)} ${
                    record.fee_info.unit_info && record.fee_info.unit_info.extension
                      ? `(${record.fee_info.unit_info.extension})`
                      : ''
                  }`,
              }
            if (column.key === 'final_cost')
              return {
                ...column,
                render: (text, record) =>
                  record.fee_info && formatCash(record.fee_info.payment_value || 0),
              }
            if (column.key === 'order')
              return {
                ...column,
                render: (text, record) => {
                  const order = record.import_order_info || record.export_order_info
                  return order ? (
                    <b>
                      #{order.code} ({record.import_order_info ? 'Phiếu nhập' : 'Phiếu xuất'})
                    </b>
                  ) : (
                    ''
                  )
                },
              }
            if (column.key === 'creator_info')
              return {
                ...column,
                render: (text, record) => record.creator_info && record.creator_info.fullname,
              }
            if (column.key === 'verify')
              return {
                ...column,
                render: (text, record) => (
                  <Tag color={record.is_verify ? 'success' : 'error'}>
                    {record.is_verify ? 'Đã duyệt' : 'Từ chối'}
                  </Tag>
                ),
              }
            if (column.key === 'create_date')
              return {
                ...column,
                render: (text) => moment(text).format('DD/MM/YYYY'),
              }

            return column
          })}
          size={SIZE_TABLE}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: countFee,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter })
            },
          }}
          summary={(data) => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <b style={{ whiteSpace: 'nowrap' }}>
                    Tổng thành tiền:{' '}
                    {formatCash(
                      data.reduce(
                        (total, current) =>
                          +total + +(current.fee_info ? current.fee_info.payment_value || 0 : 0),
                        0
                      )
                    )}
                  </b>{' '}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
    </div>
  )
}
