import { useEffect, useState, useRef } from 'react'
import styles from './ordercollection.module.scss'
import { PAGE_SIZE_OPTIONS, POSITION_TABLE, ROUTES, SIZE_TABLE } from 'consts'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  saveFiltersToLocalStorage,
  filterOptionSelect,
  handleGetDataExportExcel,
  formatCash,
} from 'utils'

//antd
import { Col, Input, Row, Table, Button, Select, Space, notification } from 'antd'

//icons
import { ReloadOutlined } from '@ant-design/icons'

//component
import columns from './columns'
import DropdownButton from './dropdownButton'
import FilterDate from 'components/filter-date'
import Export from 'components/export-csv'
import SuffixIconCustom from 'components/suffix-icon-custom'

//apis
import { getExportOrders } from 'apis/order'
import { getWarehouses } from 'apis/warehouse'
import { getCategories } from 'apis/categories-product'

export default function OrderCollection() {
  const typingTimeoutRef = useRef(null)
  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const KEY_FILTER = 'orderCollectionExportFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [warehouses, setWarehouses] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(
    filters ? { ...filters } : { page: 1, page_size: 10, is_received: true }
  ) //Đơn hàng ít nhất phải có is_received = true (ko hiển thị đơn nháp)
  const [exportOrderCount, setExportOrderCount] = useState(0)
  const [dataExportOrder, setDataExportOrder] = useState([])
  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])

  const _getExportOrders = async () => {
    try {
      setLoading(true)
      const res = await getExportOrders({
        ...paramsFilter,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })
      if (res.status === 200) {
        setDataExportOrder(res.data.data)
        setExportOrderCount(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _search = (e) => {
    setValueSearch(String(e.target.value).replace(/#/gi, ''))
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value

      //khi search hoac filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _processing = (text) => {
    const status = {
      is_received: 'Đã tiếp nhận',
      is_exported: 'Đã xuất khỏi vị trí',
      is_packed: 'Đã đóng gói',
      is_transported: 'Đã bàn giao vận chuyển',
      is_shipping: 'Đã vận chuyển',
    }
    return status[text] || ''
  }

  const handleExportData = async (query = {}) => {
    try {
      const data = await handleGetDataExportExcel(getExportOrders, {
        ...query,
        customer_id: (dataUser && dataUser.customer_id) || '',
      })

      if (data) {
        const dataExport = data.map((order) => {
          let order_template = {
            'Mã đơn hàng': order.code || '',
            'Số phiếu': order.customer_order_code || '',
            'Tên doanh nghiệp': (order.customer_info && order.customer_info.fullname) || '',
            'Số điện thoại': (order.customer_info && order.customer_info.phone) || '',
            Email: (order.customer_info && order.customer_info.email) || '',
            'Địa chỉ': (order.customer_info && order.customer_info.address) || '',
            'Phường/Xã': (order.customer_info && order.customer_info.ward) || '',
            'Quận/huyện': (order.customer_info && order.customer_info.district) || '',
            'Tỉnh/Thành Phố': (order.customer_info && order.customer_info.province) || '',
            'Tên kho': order.warehouse_info && order.warehouse_info.name,
            'Địa chỉ kho':
              order.warehouse_info &&
              `${order.warehouse_info.address || ''}, ${order.warehouse_info.ward || ''}, ${
                order.warehouse_info.district || ''
              }, ${order.warehouse_info.province || ''}`,
            'Trạng thái đơn hàng': _processing(order.processing),

            'Ngày tạo': order.create_date
              ? moment(order.create_date).format('YYYY-MM-DD HH:mm')
              : '',

            'Nhân viên quét xuất khỏi vị trí': order.pack_employee_info?.fullname || '',

            'Ngày giờ quét hàng khỏi vị trí': order.export_date
              ? moment(order.export_date).format('YYYY-MM-DD HH:mm')
              : '',

            'Nhân viên đóng gói': order.pack_employee_info?.fullname || '',

            'Ngày giờ đóng gói đơn hàng': order.pack_date
              ? moment(order.pack_date).format('YYYY-MM-DD HH:mm')
              : '',
            'Nhân viên giao đơn vị vận chuyển': order.transport_employee_info?.fullname || '',

            /*  'Mã thùng chứa': (order.basket_info && order.basket_info[0]?.basket_code) || '', */
            /*  'Ngày giờ quét hàng khỏi vị trí': order.export_date
              ? moment(order.export_date).format('YYYY-MM-DD HH:mm')
              : '',
            'Ngày giờ bàn giao vận chuyển': order.transport_date
              ? moment(order.transport_date).format('YYYY-MM-DD HH:mm')
              : '', */

            'Mã vận đơn': (order.shipping_info && order.shipping_info.shipping_code) || '',
            'Đơn vị vận chuyển':
              (order.shipping_info && order.shipping_info.shipping_company_name) || '',
            'Biển số xe': (order.shipping_info && order.shipping_info.car_number) || '',
            // COD: (order.shipping_info && order.shipping_info.cod) || '',
          }
          return order.packages && order.packages.length
            ? order.packages.map((pkg) => {
                return pkg.products.length
                  ? pkg.products.map((product) => {
                      return {
                        ...order_template,
                        'Mã kiện hàng': pkg.package_code || '',
                        'Barcode/Qrcode': product.barcode || '',
                        'Tên sản phẩm': product.name || '',
                        'Nhóm hàng': product.group_info ? product.group_info.name : '',
                        'Loại hàng': product.type_info ? product.type_info.name : '',
                        Dài: product.length || '',
                        Rộng: product.width || '',
                        Cao: product.height || '',
                        Cbm: product.cbm || '',
                        'Quy cách': product.convention || '',
                        'Đơn vị tính': product.unit_info ? product.unit_info?.name : '',
                        'Đơn vị nhỏ nhất': product.smallest_unit || '',
                        'Quy đổi số lượng':
                          (product.exported_quantity || 1) * (product.smallest_unit || 1),
                        'Số lượng': product.export_quantity || '',
                        'Tổng giá trị đơn hàng': order.price || '',
                      }
                    })
                  : {
                      ...order_template,
                      'Mã kiện hàng': pkg.package_code || '',
                      'Barcode/Qrcode': '',
                      'Tên sản phẩm': '',
                      'Nhóm hàng': '',
                      'Loại hàng': '',
                      Dài: '',
                      Rộng: '',
                      Cao: '',
                      Cbm: '',
                      'Quy cách': '',
                      'Đơn vị tính': '',
                      'Đơn vị nhỏ nhất': '',
                      'Quy đổi số lượng': '',
                      'Số lượng': '',
                      'Tổng giá trị đơn hàng': order.price || '',
                    }
              })
            : {
                ...order_template,
                'Mã kiện hàng': '',
                'Barcode/Qrcode': '',
                'Tên sản phẩm': '',
                'Nhóm hàng': '',
                'Loại hàng': '',
                Dài: '',
                Rộng: '',
                Cao: '',
                Cbm: '',
                'Quy cách': '',
                'Đơn vị tính': '',
                'Đơn vị nhỏ nhất': '',
                'Quy đổi số lượng': '',
                'Số lượng': '',
                'Tổng giá trị đơn hàng': order.price || '',
              }
        })

        return dataExport.flat(2)
      } else {
        notification.error({ message: 'Đã xảy ra lỗi!' })
        return []
      }
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const indicators = [
    {
      title: 'Đang chờ',
      bgColor: 'rgba(255, 192, 134, 0.75)',
    },
    {
      title: 'Đang thực hiện',
      bgColor: '#13D8E5',
    },
    {
      title: 'Đã thực hiện',
      bgColor: '#79CF4F',
    },
    // {
    //   title: 'Có sự cố',
    //   bgColor: '#D63F3F',
    // },
  ]

  const _actionDate = (columnKey, record) => {
    switch (columnKey) {
      case 'is_received':
        return record.receive_date
      case 'is_exported':
        return record.export_date
      case 'is_packed':
        return record.pack_date
      case 'is_transported':
        return record.transport_date
      default:
        return ''
    }
  }
  const _status = (columnKey) => {
    switch (columnKey) {
      case 'is_received':
        return 'receive_status'
      case 'is_exported':
        return 'export_status'
      case 'is_packed':
        return 'pack_status'
      case 'is_transported':
        return 'transport_status'
      case 'is_shipping':
        return 'shipping_status'
      default:
        return ''
    }
  }

  const _clearFilters = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 10, is_received: true })
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) setWarehouses(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getExportOrders()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  useEffect(() => {
    _getGroups()
    _getTypes()
    _getWarehouses()
  }, [])

  return (
    <div className={styles['order-collection']}>
      <Row className="page-title" justify="space-between" style={{ marginBottom: '25px' }}>
        <h3 style={{ fontWeight: 700, fontSize: 20, color: '#223b4f' }}>Tổng hợp đơn hàng xuất</h3>
        <Space>
          <Export
            text="Xuất theo bộ lọc"
            actionName="Xuất excel danh sách đơn hàng xuất"
            exportData={() => handleExportData(paramsFilter)}
            fileName={'Danh sách đơn hàng xuất ' + moment().format('DD-MM-YYYY')}
          />
          <Export
            text="Xuất toàn bộ"
            actionName="Xuất excel danh sách đơn hàng xuất"
            exportData={() => handleExportData()}
            fileName={'Danh sách đơn hàng xuất ' + moment().format('DD-MM-YYYY')}
          />
        </Space>
      </Row>
      <Row gutter={[15, 15]} style={{ marginBottom: '20px' }}>
        <Col span={9}>
          <Input.Group compact className={styles['order-collection-filter-group-item']}>
            <Input
              value={valueSearch}
              allowClear
              onChange={_search}
              style={{ width: '100%' }}
              placeholder="Tìm kiếm theo mã đơn hàng, mã vận đơn, số phiếu"
            />
          </Input.Group>
        </Col>
        <Col span={5}>
          <FilterDate paramsFilter={paramsFilter} setParamsFilter={setParamsFilter} />
        </Col>
        <Col lg={5}>
          <Select
            value={paramsFilter['warehouse_id'] || undefined}
            onChange={(value) => _filterByOption('warehouse_id', value)}
            showSearch
            filterOption={filterOptionSelect}
            allowClear
            placeholder="Lọc theo kho"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
            suffixIcon={<SuffixIconCustom />}
          >
            {warehouses.map((warehouse, index) => (
              <Select.Option key={index} value={warehouse.warehouse_id}>
                {warehouse.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            value={paramsFilter['type_id'] || undefined}
            onChange={(value) => _filterByOption('type_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo loại hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {types.map((type, index) => (
              <Select.Option value={type.category_id} key={index}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            value={paramsFilter['group_id'] || undefined}
            onChange={(value) => _filterByOption('group_id', value)}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Lọc theo nhóm hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {groups.map((group, index) => (
              <Select.Option value={group.category_id} key={index}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row justify="space-between">
        <div style={{ display: 'flex', gap: '10px', margin: '10px 0 0 10px' }}>
          {indicators.map((indicator, index) => {
            return (
              <div key={index} style={{ display: 'flex', gap: '5px' }}>
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: indicator.bgColor,
                  }}
                ></div>
                <span style={{ lineHeight: '15px' }}>{indicator.title}</span>
              </div>
            )
          })}
        </div>
        <Space style={{ marginBottom: 10 }}>
          <Button
            onClick={() => _clearFilters()}
            type="primary"
            danger
            style={{ display: Object.keys(paramsFilter).length <= 3 && 'none' }}
          >
            Xóa bộ lọc
          </Button>
          <Button onClick={_getExportOrders} type="primary" icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </Row>
      <Row>
        <Table
          loading={loading}
          style={{ width: '100%' }}
          scroll={{ y: '48vh' }}
          className={styles['order-collection-table']}
          dataSource={dataExportOrder}
          columns={columns.map((column) => {
            if (
              ['is_received', 'is_exported', 'is_packed', 'is_transported'].indexOf(column.key) > -1
            )
              return {
                ...column,

                render: (is_Action, record) => {
                  const actionDate = _actionDate(column.key, record)
                  return is_Action ? (
                    <div
                      onClick={() =>
                        history.push(
                          ROUTES.ORDER_DETAIL_EXPORT +
                            `?order_id=${record.order_id}&status=${column.key}&type=export`
                        )
                      }
                      style={{
                        width: '100%',
                        height: 130,
                        padding: 5,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        lineHeight: 1.2,
                        textAlign: 'start',
                        fontSize: 13,
                      }}
                      className={styles[record[_status(column.key)]]}
                    >
                      <div>Mã đơn hàng: {'#' + record.code}</div>
                      <div>Số phiếu: {'#' + record.customer_order_code}</div>
                      <div>
                        Mã vận đơn:{' '}
                        {record.shipping_info && `#${record.shipping_info.shipping_code || ''}`}
                      </div>
                      <div>
                        Tiền thu hộ:{' '}
                        {record.shipping_info && formatCash(record.shipping_info.cod || 0)}
                      </div>
                      {record.shipping_info && record.shipping_info.car_number && (
                        <div>Biển số xe: {`#${record.shipping_info.car_number}`}</div>
                      )}
                      <div>{actionDate && moment(actionDate).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                  ) : (
                    ''
                  )
                },
                title: (
                  <DropdownButton
                    orderType={column.key}
                    setParamsFilter={setParamsFilter}
                    status={_status(column.key)}
                  />
                ),
              }
            if (column.key === 'is_shipping')
              return {
                ...column,
                render: (is_shipping, record) => {
                  if (is_shipping) {
                    return (
                      <div
                        onClick={() =>
                          history.push(
                            ROUTES.ORDER_DETAIL_EXPORT +
                              `?order_id=${record.order_id}&status=${column.key}&type=export`
                          )
                        }
                        style={{
                          width: '100%',
                          height: 100,
                          padding: 10,
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        className={styles[record.shipping_status]}
                      >
                        <div> {'#' + record.code}</div>
                        <div>
                          {record.shipping_info?.shipping_code &&
                            `#${record.shipping_info?.shipping_code}`}
                        </div>

                        <div>
                          {record.create_date &&
                            moment(record.create_date).format('DD/MM/YYYY HH:mm')}
                        </div>
                        <div>
                          {record.shipping_info?.shipping_company_name &&
                            record.shipping_info?.shipping_company_name}
                        </div>
                      </div>
                    )
                  }
                },
                title: (
                  <DropdownButton
                    is_shipping={true}
                    orderType={column.key}
                    setParamsFilter={setParamsFilter}
                    status={_status(column.key)}
                  />
                ),
              }
            return column
          })}
          size={SIZE_TABLE}
          pagination={{
            position: POSITION_TABLE,
            current: paramsFilter.page,
            pageSize: paramsFilter.page_size,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: exportOrderCount,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.page_size = pageSize
              setParamsFilter({ ...paramsFilter })
            },
          }}
        />
      </Row>
    </div>
  )
}
