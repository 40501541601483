import { get, post } from './axiosClient'

export const getOrdersEcom = (query) => get('/ecom-order', query)
export const getTrackingNumberOrderEcom = (body) => post('/ecom-order/link-tracking-number', body)
export const reloadOrdersEcom = (body) => post('/ecom-order/reload-order', body)
export const createOrdersEcom = (body) => post('/exportorder/create-from-ecom-order', body)
export const callShipper = (body) => post('/ecom-order/call-shipper', body)
export const readyShipLazada = (body) => post('/ecom-order/ready-ship-lazada', body)
export const updateProductEcom = (body) => post('/ecom-order/update-order-detail', body)
export const addProductEcom = (body) => post('/ecom-order/add-product', body)
export const deleteProductEcom = (body) => post('/ecom-order/remove-product', body)
export const reloadEcomOrder = (body) => post('/ecom-order/reload-order-bulk', body)
export const addProductsEcom = (body) => post('/ecom-order/add-bulk-product', body)
