import { get, post, patch, destroy } from './axiosClient'

export const getShippingCompany = (query) => get('/shipping-company', query)
export const addShippingCompany = (body) =>
  post('/shipping-company/create', { ...body, from_platform: 'Merchant' })
export const updateShippingCompany = (id, body) =>
  patch(`/shipping-company/update/${id}`, { ...body, from_platform: 'Merchant' })
export const deleteShippingCompany = (body) =>
  destroy('/shipping-company/delete', { ...body, from_platform: 'Merchant' })
export const checkShippingFee = (body) => post('/exportorder/fee-shipping', body)
