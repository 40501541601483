import { Table, Modal } from 'antd'
import ZoomImage from 'components/zoom-image'
import { IMAGE_DEFAULT } from 'consts'
import { compare, formatCash } from 'utils'

export default function ModalViewProduct({ dataDetail, setDataDetail }) {
  const columnsDetail = [
    {
      title: 'STT',
      width: 50,
      align: 'center',
      render: (text, record, index) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Mã sản phẩm',
      // sorter: (a, b) => compare(a, b, 'code'),
      render: (text, record) => (record?.barcode ? record?.barcode : record?.code),
      fixed: 'left',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      align: 'center',
      sorter: (a, b) => compare(a, b, 'name'),
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'group_info',
      align: 'center',
      render: (group_info) => group_info && group_info.name,
      sorter: (a, b) => compare(a.group_info, b.group_info, 'name'),
    },
    {
      title: 'Loại sản phẩm',
      dataIndex: `type_info`,
      align: 'center',
      render: (type_info) => type_info && type_info.name,
      sorter: (a, b) => compare(a.type_info, b.type_info, 'name'),
    },
    {
      title: 'Quy đổi số lượng',
      render: (text, record) => (record.exported_quantity || 1) * (record.smallest_unit || 1),
      align: 'center',
    },
    {
      title: 'Hình ảnh',
      dataIndex: `images`,
      align: 'center',
      render: (text, record) =>
        record.images && (
          <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
            <img
              style={{ width: 50, height: 50 }}
              alt=""
              src={
                record.images[0] ||
                'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'
              }
            />
          </ZoomImage>
        ),
    },
    {
      title: 'Đơn vị vận chuyển',
      align: 'center',
      render: () => dataDetail.shipping_info && dataDetail.shipping_info.shipping_company_name,
    },
    {
      title: 'Mã vận đơn',
      align: 'center',
      render: () => dataDetail.shipping_info && dataDetail.shipping_info.shipping_code,
    },
    {
      title: 'Người nhận',
      align: 'center',
      render: () => dataDetail.shipping_info && dataDetail.shipping_info.to_name,
    },
    {
      title: 'Số điện thoại',
      align: 'center',
      render: () => dataDetail.shipping_info && dataDetail.shipping_info.to_phone,
    },
    {
      title: 'Địa chỉ',
      align: 'center',
      render: () => dataDetail.shipping_info && dataDetail.shipping_info.to_address,
    },
    {
      title: 'Giá tiền',
      dataIndex: `price`,
      align: 'center',
      render: (text) => <span>{formatCash(text)}</span>,
      sorter: (a, b) => compare(a, b, 'price'),
    },
    {
      title: 'CBM (cm³)',
      dataIndex: 'cbm',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'S mặt phẳng (cm²)',
      dataIndex: 'area',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'Số lượng yêu cầu',
      dataIndex: `require_quantity`,
      align: 'center',
      render: (text) => <span>{formatCash(text)}</span>,
      sorter: (a, b) => compare(a, b, 'require_quantity'),
    },
  ]

  return (
    <Modal
      style={{ top: 20 }}
      title={
        <h4>
          Danh sách sản phẩm của đơn hàng <b>#{dataDetail && dataDetail.code}</b>
        </h4>
      }
      visible={dataDetail ? true : false}
      onCancel={() => setDataDetail()}
      footer={null}
      width="80%"
    >
      <Table
        rowKey="product_id"
        size="small"
        scroll={{ x: 2000, y: '63vh' }}
        columns={columnsDetail}
        dataSource={dataDetail ? dataDetail.products : []}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </Modal>
  )
}
