const columnsExport = [
  {
    key: 'stt',
    title: 'STT',
    width: 60,
  },
  {
    key: 'code',
    title: 'Mã phiếu',
    dataIndex: 'code',
    width: 250,
  },
  {
    key: 'customer_order_code',
    title: 'Số PO',
    dataIndex: 'customer_order_code',
    width: 250,
  },
  {
    key: 'shipping_company_name',
    title: 'Tên ĐVVC',
    width: 160,
  },
  {
    key: 'car_number',
    title: 'Biển số xe',
    width: 150,
  },
  {
    key: 'shipping_code',
    title: 'Mã vận đơn',
    width: 150,
  },
  {
    title: 'Nguồn',
    dataIndex: 'platform',
    width: 150,
  },
  {
    key: 'to_name',
    title: 'Tên người nhận',
    width: 160,
  },

  {
    key: 'warehouse_info',
    title: 'Kho xuất hàng',
    width: 160,
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'total_cost',
    width: 150,
  },
  {
    key: 'creator_id',
    title: 'Nhân viên lên đơn',
    width: 160,
  },
  {
    key: 'transport_date',
    title: 'Ngày bàn giao',
    width: 150,
  },
  {
    key: 'shipping_bill',
    title: 'Biên lai bàn giao',
    width: 90,
  },
  {
    title: 'Kênh bán hàng',
    dataIndex: 'platform',
    width: 150,
  },
  {
    key: 'receive_reason',
    title: 'Lý do',
    align: 'center',
    width: '10%',
  },
  {
    key: 'products_list',
    title: 'Danh sách sản phẩm',
    align: 'center',
    width: 150,
  },
  {
    title: 'Danh sách chi phí kho',
    align: 'center',
    key: 'fee',
    width: 150,
  },
  // {
  //   key: 'export_status',
  //   title: 'Trạng thái',
  //   // dataIndex: 'export_status',
  //   align: 'center',
  // },
  {
    title: 'Hành động',
    key: 'delete_button',
    width: 200,
  },
]

export default columnsExport
