import React, { useState, useEffect, useRef } from 'react'
import styles from './sites.module.scss'
import logo from '../../assets/images/logo.svg'
import {
  Col,
  Row,
  notification,
  Dropdown,
  Table,
  Form,
  Button,
  Modal,
  Select,
  Input,
  Menu,
  Badge,
  Space,
} from 'antd'
import queryString from 'query-string'
import { ACTION, PERMISSIONS } from 'consts'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import moment from 'moment'
import querystring from 'querystring'
// import localStorage from 'localstorage-memory'
import { useHistory } from 'react-router-dom'
//images
//import iconShopify from 'assets/img/iconShopify.png'
import right from 'assets/icons/pagi-left.svg'
import left from 'assets/icons/pagi-right.svg'

//components
import SuffixIconCustom from 'components/suffix-icon-custom'

//apis
import { getSites, updateSite, deleteSite } from 'apis/site'

import { _connectShoppe } from 'apis/connect'
import {
  PlusOutlined,
  EditOutlined,
  SettingOutlined,
  IeOutlined,
  SaveOutlined,
  SearchOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import icon_tiki from 'assets/icons/tiki.png'
import icon_lazada from 'assets/icons/lazada.png'
import icon_shopee from 'assets/icons/shopee.jpg'
import icon_tiktok from 'assets/icons/tiktok.png'
import icon_sapo from 'assets/icons/sapo.jpeg'
import Permission from 'components/permission'

const { Option } = Select
const Sites = () => {
  const dataUser = useSelector((state) => state.login.dataUser)
  const dispatch = useDispatch()
  const location = useLocation()

  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const [sites, setSites] = useState([])
  const [siteSelected, setSiteSelected] = useState()
  const [titleDrawer, setTitleDrawer] = useState('Add new site')
  const [isAddSite, setIsAddSite] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalConnectTiki, setIsModalConnectTiki] = useState(false)
  const [isModalConnectLazada, setIsModalConnectLazada] = useState(false)
  const [isModalConnectShopee, setIsModalConnectShopee] = useState(false)
  const [isModalConnectTiktok, setIsModalConnectTiktok] = useState(false)
  const [isModalConnectSapo, setIsModalConnectSapo] = useState(false)
  const [valueNameShopSapo, setValueNameShopSapo] = useState('')
  const [valueSecretSapo, setValueSecretSapo] = useState('')
  const [valueKeySapo, setValueKeySapo] = useState('')

  //connect shopify
  const endpoint = '/admin/oauth/authorize'
  const params = {
    client_id: process.env.REACT_APP_API_KEY_SHOPIFY,
    scope: process.env.REACT_APP_API_SCOPES_SHOPIFY,
    redirect_uri: `${process.env.REACT_APP_HOST}/auth`,
    state: JSON.stringify({
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    }),
    'grant_options[]': 'online',
  }
  const query_string = querystring.stringify(params).replace(/%20/g, '+')

  const toggle = () => setVisible(!visible)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const MenuSetting = (props) => (
    <Menu>
      <Permission permissions={[PERMISSIONS.cap_nhat_san_thuong_mai_dien_tu]}>
        <Menu.Item
          icon={<EditOutlined style={{ color: '#1890FF' }} />}
          onClick={() => {
            setIsAddSite(false)
            setTitleDrawer('Cập nhật tên Shop liên kết')
            setSiteSelected(props.site.site_name)
            setTimeout(() => {
              form.setFieldsValue({ name: props.site.name })
            }, 500)
            setSiteSelected(props.site)
            toggle()
          }}
        >
          Chỉnh sửa
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.xoa_san_thuong_mai_dien_tu]}>
        <Menu.Item
          icon={<DeleteOutlined style={{ color: 'orange' }} />}
          onClick={() => {
            Modal.confirm({
              title:
                'Xác nhận xoá, dữ liệu đơn hàng xuất sẽ vẫn giữ trong hệ thống lại sau khi xoá?',
              icon: <ExclamationCircleOutlined />,
              onOk() {
                _onRemoveSite(props.site.id_site)
              },
            })
          }}
        >
          Xoá
        </Menu.Item>
      </Permission>
    </Menu>
  )

  const Setting = (props) => (
    <Dropdown overlay={<MenuSetting site={props.site} />} trigger={['hover']}>
      <SettingOutlined style={{ color: '#1890FF', cursor: 'pointer' }} />
    </Dropdown>
  )

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'site_name',
      key: 'site_name',
    },
    {
      title: 'Nền tảng',
      width: 100,
      key: 'platform',
      dataIndex: 'platform',
    },
    {
      title: 'Ngày kết nối',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (text, record) =>
        text || record.create_date
          ? moment(text || record.create_date).format('DD-MM-YYYY HH:mm:ss')
          : '',
    },
    {
      title: 'Trạng thái',
      render: (text, record) =>
        text && (
          <Badge
            status={record.active == false ? 'warning' : 'success'}
            text={record.active == false ? 'Tắt kết nối' : 'Đang kích hoạt'}
          />
        ),
    },
    {
      title: 'Hành động',
      width: 100,
      render: (text, record) => <Setting site={record} />,
    },
  ]

  const searchSitesStatusData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      // Search site
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  function handleChangeSites(value) {
    if (value === 'default') {
      getAllSites()
    } else {
      searchSitesStatusData(value)
    }
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      // searchSitesData(value)
    }, 300)
  }

  const getAllSites = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getSites()
      console.log(res)
      if (res.status === 200) {
        setSites(res.data.data)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onUpdateSite = async (id_site) => {
    try {
      var body = form.getFieldsValue()
      body.id_site = id_site
      console.log(body)
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateSite(body)
      if (res.status === 200) {
        setVisible(false)
        getAllSites()
      } else {
        notification.open({ message: 'Cập nhật thất bại' })
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _onRemoveSite = async (id_site) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteSite(id_site)
      if (res.status === 200) {
        getAllSites()
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const connectShopee = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await _connectShoppe()
      console.log('duyshoppe', res)

      if (res.status === 200) {
        //   notification.success({ message: 'Kết nối thành công' })
        window.location.href = res.data.link
      } else notification.error({ message: 'Kết nối thất bại' })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Kết nối thất bại',
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const showModalConnectTiki = () => {
    setIsModalVisible(false)
    setIsModalConnectTiki(true)
  }
  const showModalConnectLazada = () => {
    setIsModalVisible(false)
    setIsModalConnectLazada(true)
  }
  const showModalConnectShopee = () => {
    setIsModalVisible(false)
    setIsModalConnectShopee(true)
  }

  const showModalConnectTiktok = () => {
    setIsModalVisible(false)
    setIsModalConnectTiktok(true)
  }

  useEffect(() => {
    getAllSites()
    if (location.state && location.state === 'open') setTimeout(() => setVisible(true), 500)
  }, [])

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])

  return (
    <div className={styles['orders_manager']}>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <h3 style={{ fontWeight: 700, fontSize: 20, marginBottom: 0 }}>Quản lý đơn hàng đa kênh</h3>
        {/* <Button
          className="btn-container"
          onClick={() => {
            toggle()
            setTitleDrawer('Add new site')
            setIsAddSite(true)
          }}
          type="primary"
          icon={<PlusOutlined />}>
          Add new site
        </Button> */}
        <Permission permissions={[PERMISSIONS.ket_noi_san_thuong_mai_dien_tu]}>
          <Button
            className="btn-container"
            onClick={showModal}
            type="primary"
            icon={<PlusOutlined />}
          >
            Kết nối kênh bán hàng
          </Button>
        </Permission>

        <Modal
          title="Kết nối Shopee"
          visible={isModalConnectShopee}
          onCancel={() => setIsModalConnectShopee(false)}
          footer={
            <>
              <Button type="primary" onClick={() => connectShopee()}>
                Kết nối
              </Button>
            </>
          }
        >
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img
                  style={{ width: 45 }}
                  src="https://inkythuatso.com/uploads/images/2021/11/logo-shopee-inkythuatso-4-01-24-14-51-57.jpg"
                ></img>
              </div>
              <ArrowRightOutlined />
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img style={{ width: 47, marginTop: 26 }} src={logo}></img>
              </div>
              <div style={{ marginLeft: 5 }}>
                <h2 style={{ fontWeight: 600 }}>Kết nối Shopee qua 1 bước</h2>
                <p style={{ marginTop: -17, fontSize: 16 }}>
                  Giúp bạn đồng bộ đơn hàng với kho SGL
                </p>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Đồng bộ đơn hàng và lên đơn xuất kho tự động
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>Tự động lấy mã vận đơn để dán kiện</p>
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Báo cáo kết quả kinh doanh nhiều gian hàng
                </p>
              </div>  */}
            </div>
            <hr style={{ color: '#F2F2F2' }}></hr>
            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 16 }}> Chỉ 1 bước</p>
                <h2 style={{ fontWeight: 600 }}>
                  Sử dụng Email để đăng nhập vào Seller Center của Shopee
                </h2>
              </div>
              <div>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/06/09/cbe0a264-2944-43b6-81d1-c7b270953d19/shopee.png"
                ></img>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          title="Kết nối Lazada"
          visible={isModalConnectLazada}
          onCancel={() => setIsModalConnectLazada(false)}
          footer={
            <>
              <Button
                href={`https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://merchant.saigonlogistics.vn/connect-lazada/callback&client_id=111900&state=12121212&country=vn`}
                type="primary"
              >
                Kết nối
              </Button>
            </>
          }
        >
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img
                  style={{ width: 45, marginTop: 11, borderRadius: 50 }}
                  src="https://cdn.chanhtuoi.com/uploads/2020/06/logo-lazada-2.png"
                ></img>
              </div>
              <ArrowRightOutlined />
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img style={{ width: 47, marginTop: 26 }} src={logo}></img>
              </div>
              <div style={{ marginLeft: 5 }}>
                <h2 style={{ fontWeight: 600 }}>Kết nối Lazada qua 1 bước</h2>
                <p style={{ marginTop: -17, fontSize: 16 }}>
                  Giúp bạn đồng bộ đơn hàng ,tồn kho sản phẩm
                </p>
              </div>
            </div>

            <div style={{ marginTop: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ paddingTop: 4, marginLeft: 5 }}>
                  Đồng bộ đơn hàng nhiều gian hàng về một nơi để SGL xuất hàng nhanh chóng
                </p>
              </div>
            </div>
            <hr style={{ color: '#F2F2F2' }}></hr>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 16 }}> Chỉ 1 bước</p>
                <h2 style={{ fontWeight: 600 }}>
                  Sử dụng Email để đăng nhập vào Seller Center của Lazada
                </h2>
              </div>
              <div>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/06/08/0e4b7436-4a49-4455-9590-0814d520e37e/tempsnipss.png"
                ></img>
              </div>
            </div>
          </Form>
        </Modal>
        <Modal
          title="Kết nối Tiki"
          visible={isModalConnectTiki}
          onCancel={() => setIsModalConnectTiki(false)}
          footer={
            <>
              <Button
                href="https://api.tiki.vn/sc/oauth2/auth?response_type=code&client_id=1804354976175160&redirect_uri=https://merchant.saigonlogistics.vn/connect-tiki/callback&scope=offline%20product%20order&state=0969025915zz"
                type="primary"
              >
                Kết nối
              </Button>
            </>
          }
        >
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img
                  style={{ width: 45, marginTop: 11, borderRadius: 50 }}
                  src="https://salt.tikicdn.com/cache/w500/ts/seller/ee/fa/a0/98f3f134f85cff2c6972c31777629aa0.png"
                ></img>
              </div>
              <ArrowRightOutlined />
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img style={{ width: 47, marginTop: 26 }} src={logo}></img>
              </div>
              <div style={{ marginLeft: 5 }}>
                <h2 style={{ fontWeight: 600 }}>Kết nối TIKI qua 1 bước</h2>
                <p style={{ marginTop: -17, fontSize: 16 }}>
                  Giúp bạn đồng bộ đơn hàng ,tồn kho sản phẩm
                </p>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Đồng bộ đơn hàng nhiều gian hàng về một nơi để đóng gói nhanh hơn
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>Đồng bộ tồn kho</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Báo cáo kết quả kinh doanh tập trung
                </p>
              </div>
            </div>
            <hr style={{ color: '#F2F2F2' }}></hr>
            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 16 }}> Chỉ 1 bước</p>
                <h2 style={{ fontWeight: 600 }}>
                  Sử dụng Email để đăng nhập vào Seller Center của Tiki
                </h2>
              </div>
              <div>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/06/08/d378f38d-a71f-472e-86b1-91d6437d235b/tempsnip.png"
                ></img>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Kết nối Tiktok"
          visible={isModalConnectTiktok}
          onCancel={() => setIsModalConnectTiktok(false)}
          footer={
            <>
              <Button
                href={`https://auth.tiktok-shops.com/oauth/authorize?app_key=65tivqir9qc7l&state=${new Date().getTime()}`}
                type="primary"
              >
                Kết nối
              </Button>
            </>
          }
        >
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img
                  style={{ width: 45, marginTop: 11, borderRadius: 50 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_08_23_485eba38-6c06-4ba4-bbf1-fa47ce3f43e3_1971e99be0d67160f34f39fb1d66a0e5_c5_720x720.jpeg"
                ></img>
              </div>
              <ArrowRightOutlined />
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img style={{ width: 47, marginTop: 26 }} src={logo}></img>
              </div>
              <div style={{ marginLeft: 5 }}>
                <h2 style={{ fontWeight: 600 }}>Kết nối TIKTOK qua 3 bước</h2>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  B1: Đăng nhập Website Seller Tiktok Shop, chọn khu vực Asia, Việt Nam
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ paddingTop: 13, marginLeft: 5 }}>B2: Chọn thời gian uỷ quyền "1 năm"</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  B3: Bấm đồng ý điều khoản và bấm "Uỷ quyền"
                </p>
              </div>
            </div>
            <hr style={{ color: '#F2F2F2' }}></hr>
            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
              <div>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_08_24_935908ca-98e5-4c3e-b0aa-6c2d455b7355_b1.jpg"
                ></img>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_08_24_5602232c-8656-40ac-b71a-117192b31a65_b2.jpg"
                ></img>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Kết nối Sapo"
          visible={isModalConnectSapo}
          onCancel={() => setIsModalConnectSapo(false)}
          footer={
            <Col>
              <Row justify="space-around">
                <div style={{ fontSize: 13, fontWeight: 'bold' }}>
                  {'Liên hệ Sapo để lấy Key ID và Secret ID, Tên Shop là tên miền của trên Sapo'}
                </div>
              </Row>
              <Row justify="space-around">
                <Input
                  value={valueKeySapo}
                  onChange={(e) => {
                    setValueKeySapo(e.target.value)
                  }}
                  placeholder="Key ID (Client ID) từ Sapo"
                  style={{ width: 220, height: 45 }}
                />
                <Input
                  value={valueSecretSapo}
                  onChange={(e) => {
                    setValueSecretSapo(e.target.value)
                  }}
                  placeholder="Secret ID từ Sapo"
                  style={{ width: 220, height: 45 }}
                />
              </Row>
              <Row justify="space-around" style={{ marginTop: 5 }}>
                <Input
                  value={valueNameShopSapo}
                  onChange={(e) => {
                    setValueNameShopSapo(e.target.value)
                  }}
                  placeholder="Tên Shop, ví dụ sgl"
                  style={{ width: 220, height: 45 }}
                />
                <div
                  style={{
                    width: 220,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Button
                    disabled={valueNameShopSapo.length == 0}
                    onClick={(e) => {
                      window.open(
                        `https://${valueNameShopSapo}.mysapogo.com/admin/oauth/authorize?client_id=${valueKeySapo}&scope=read_products,write_products,read_customers,write_customers,read_suppliers,write_suppliers,read_orders,write_orders,read_fulfillments,write_fulfillments,read_order_returns,write_order_returns,read_stock_adjustments,write_stock_adjustments,read_stock_transfers,write_stock_transfers,read_stock_transfers,write_stock_transfers,read_purchase_orders,write_purchase_orders&redirect_uri=https://merchant.saigonlogistics.vn/connect-sapo/callback&state=${valueKeySapo}123456${valueSecretSapo}`
                      )
                    }}
                    type="primary"
                  >
                    Kết nối
                  </Button>
                </div>
              </Row>
            </Col>
          }
        >
          <Form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img
                  style={{ width: 45, marginTop: 11, borderRadius: 50 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/sgl-warehouse/2023/03/13/dc04e2ad-f083-4368-8714-2ac90c268e26_1678701950248_sgl-warehouse.jpeg"
                ></img>
              </div>
              <ArrowRightOutlined />
              <div
                style={{
                  width: 70,
                  height: 70,
                  background: 'white',
                  border: '1px solid rgba(12,48,71,0.35)',
                  textAlign: 'center',
                  borderRadius: 10,
                }}
              >
                <img style={{ width: 47, marginTop: 26 }} src={logo}></img>
              </div>
              <div style={{ marginLeft: 5 }}>
                <h2 style={{ fontWeight: 600 }}>Kết nối Sapo qua 1 bước</h2>
                <p style={{ marginTop: -17, fontSize: 16 }}>Giúp bạn đồng bộ đơn hàng</p>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Đồng bộ đơn hàng nhiều gian hàng về một nơi để đóng gói nhanh hơn
                </p>
              </div> */}
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>Đồng bộ tồn kho</p>
              </div> */}
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: 'darkgreen' }} />
                <p style={{ paddingTop: 13, marginLeft: 5 }}>
                  Báo cáo kết quả kinh doanh tập trung tại Web SGL
                </p>
              </div> */}
            </div>
            <hr style={{ color: '#F2F2F2' }}></hr>
            <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: 16 }}> Chỉ 1 bước</p>
                <h2 style={{ fontWeight: 600 }}>Bấm "Cài đặt"</h2>
              </div>
              <div>
                <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/sgl-warehouse/2023/03/13/f1d3ad57-2dff-4a77-93cc-f019d052f8e0_1678701498439_sgl-warehouse.png"
                ></img>
                {/* <img
                  style={{ width: 250, marginTop: 11 }}
                  src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022_08_24_5602232c-8656-40ac-b71a-117192b31a65_b2.jpg"
                ></img> */}
              </div>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Chọn kênh bán hàng"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<CloseOutlined style={{ color: '#256BFE', fontSize: 21 }} />}
          className="modal-add-site"
          footer={null}
        >
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 120,
                width: 120,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: 80, height: 80, alignSelf: 'center' }} src={icon_tiki}></img>

              <Button style={{ marginTop: 10 }} onClick={() => showModalConnectTiki()}>
                Kết nối
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 120,
                width: 120,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: 80, height: 80, alignSelf: 'center' }} src={icon_shopee}></img>
              <Button
                style={{ marginTop: 10, textAlign: 'center' }}
                //  onClick={() => connectShopee()}
                onClick={() => showModalConnectShopee()}
              >
                Kết nối
              </Button>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingTop: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 120,
                width: 120,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: 80, height: 80, alignSelf: 'center' }} src={icon_lazada}></img>
              <Button style={{ marginTop: 10 }} onClick={() => showModalConnectLazada()}>
                Kết nối
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 120,
                width: 120,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: 80, height: 80, alignSelf: 'center' }} src={icon_tiktok}></img>
              <Button
                //disabled
                // href="https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://drop.hihihi.vn/connect-lazada/callback&client_id=109510&state=0969025915&country=vn"
                style={{
                  marginTop: 10,
                  textAlign: 'center',
                  alignSelf: 'center',
                  fontWeight: 'bold',
                }}
                onClick={() => showModalConnectTiktok()}
              >
                Kết nối
              </Button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingTop: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 120,
                width: 120,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: 80, height: 80, alignSelf: 'center' }} src={icon_sapo}></img>
              <Button
                style={{
                  marginTop: 10,
                  textAlign: 'center',
                  alignSelf: 'center',
                  fontWeight: 'bold',
                }}
                onClick={() => setIsModalConnectSapo(true)}
              >
                Kết nối
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          closeIcon={<CloseOutlined style={{ color: '#256BFE', fontSize: 21 }} />}
          className="modal-add-site"
          width={410}
          onCancel={toggle}
          visible={visible}
          footer={null}
        >
          <Form form={form} layout="vertical" hideRequiredMark>
            <Row justify="center">
              <img
                src="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png"
                alt="logo shopify"
                style={{
                  width: 140,
                  objectFit: 'contain',
                }}
              />
            </Row>
            <p
              style={{
                marginTop: 10,
                textAlign: 'center',
                fontSize: 17,
                color: '#5D8D39',
                fontWeight: 600,
              }}
            >
              {titleDrawer}
            </p>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name={'site_name'}
                  label="Name"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter shop name',
                    },
                  ]}
                >
                  <Input placeholder="Enter shop name" className="input-container-custom" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <div
                  onClick={async (e) => {
                    _onUpdateSite(siteSelected.id_site)
                  }}
                >
                  <Button
                    className="btn-container"
                    style={{
                      marginTop: 15,
                      width: '100%',
                      boxShadow: '0px 8px 25px rgba(37, 107, 254, 0.15)',
                    }}
                    loading={loading}
                    type="primary"
                    icon={isAddSite ? <IeOutlined /> : <SaveOutlined />}
                  >
                    {'Lưu'}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Row>
      <div className={styles['orders_manager_content']}>
        <Space>
          <Input
            className="input-container-custom"
            //addonAfter={<SearchOutlined style={{ color: 'white' }} />}
            style={{ width: 350 }}
            onChange={onSearch}
            placeholder="Search by name"
            allowClear
          />
          {/* <Select
            suffixIcon={<SuffixIconCustom />}
            dropdownClassName="dropdown-select-custom"
            style={{ width: 250 }}
            defaultValue="default"
            onChange={handleChangeSites}
          >
            <Option value="default">All status</Option>
            <Option value="active">active</Option>
            <Option value="pending">pending</Option>
            <Option value="deactive">deactive</Option>
          </Select> */}
        </Space>
      </div>

      <div className={styles['table-container']}>
        <Table
          className={styles['table-order']}
          columns={columns.map((column) => {
            return {
              ...column,
              title: (
                <span
                  style={{
                    color: '#256BFE',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {column.title}
                </span>
              ),
            }
          })}
          dataSource={sites}
          pagination={{
            itemRender: (current, type, originalElement) => {
              if (type === 'prev') {
                return <img src={right} alt="" style={{ width: 10 }} />
              }
              if (type === 'next') {
                return <img src={left} alt="" style={{ width: 10 }} />
              }
              return originalElement
            },
          }}
          size="small"
        />
      </div>
      <div
        style={{
          width: '100%',
          marginTop: 5,
          position: 'fixed',
          bottom: 0,
          paddingBottom: 10,
          left: 220,
        }}
      ></div>
    </div>
  )
}

export default Sites
