import React from 'react'
import jwt_decode from 'jwt-decode'

const Permission = ({ permissions, children, ...props }) => {
  try {
    const context = localStorage.getItem('accessToken')
      ? jwt_decode(localStorage.getItem('accessToken'))
      : null
    if (!context) return null

    if (
      !permissions ||
      permissions.length === 0 ||
      permissions.filter((p) => context.role_info.permissions.includes(p)).length
    ) {
      return React.cloneElement(children, props)
    }

    return null
  } catch (error) {
    return null
  }
}

export default Permission
