import React, { useEffect } from 'react'

//component
import Views from './views'
import Loading from 'components/loading/Loading'
import { notification } from 'antd'

import { clearBrowserCache } from 'utils'
import { useDispatch } from 'react-redux'
import { ACTION } from 'consts'

//apis
import { refresh } from 'apis/auth'

export default function App() {
  const dispatch = useDispatch()
  const token = localStorage.getItem('accessToken')
  const reToken = localStorage.getItem('refreshToken')

  //config notification
  notification.config({ duration: 3 })

  const _refreshToken = async () => {
    try {
      if (reToken) {
        const res = await refresh(reToken)
        if (res.status === 200) {
          if (res.data.success) {
            dispatch({
              type: ACTION.LOGIN,
              data: {
                accessToken: res.data.data.accessToken,
                refreshToken: res.data.data.refreshToken,
              },
            })
          }
        }
      } else {
        if (token) dispatch({ type: ACTION.LOGIN, data: { accessToken: token } })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    clearBrowserCache()
    _refreshToken()
  }, [])

  return (
    <>
      <Loading />
      <Views />
    </>
  )
}
