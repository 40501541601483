import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { IMAGE_DEFAULT, SIZE_TABLE } from 'consts'
import styles from './import-warehouse.module.scss'
import moment from 'moment'
import { saveAs } from 'file-saver'

// antd
import {
  DeleteOutlined,
  FilterOutlined,
  SearchOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons'
import {
  Button,
  Input,
  message,
  Select,
  Table,
  Row,
  Col,
  Modal,
  Space,
  Tag,
  Tabs,
  Badge,
  notification,
  Popconfirm,
  Popover,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { POSITION_TABLE, ROUTES, PAGE_SIZE_OPTIONS, PERMISSIONS } from 'consts'

//apis
import { deleteImportOrders, getImportOrders, updateImportOrders } from 'apis/order'
import { uploadImportOrder } from 'apis/upload'
import { getCustomers } from 'apis/customer'
import { getWarehouses } from 'apis/warehouse'
import { enumImport, enumImportOrderProcess } from 'apis/enum'
import { getCategories } from 'apis/categories-product'
import { exportReportImportInventory } from 'apis/report'
import { getUsers } from 'apis/user'

// components
import {
  compare,
  filterOptionSelect,
  formatCash,
  getFileName,
  handleGetDataExportExcel,
  isAcceptPermissions,
  saveFiltersToLocalStorage,
} from 'utils'
import SettingColumns from 'components/setting-columns'
import ImportFile from 'components/import-csv'
import Export from 'components/export-csv'
import BackToTop from 'components/back-to-top'
import Permission from 'views/permission'
import FilterDate from 'components/filter-date'
import ZoomImage from 'components/zoom-image'
import ModalConfirmDelete from 'components/modal-confirm-delete'
import columnsImport from './columns'

export default function ImportWareHouse() {
  const KEY_FILTER = 'importWarehouseFilters'
  const filters = localStorage.getItem(KEY_FILTER) && JSON.parse(localStorage.getItem(KEY_FILTER))

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [warehouse, setWarehouse] = useState()
  const [employees, setEmployees] = useState([])
  const [customers, setCustomers] = useState([])
  const [dataEnum, setDataEnum] = useState({})
  const [countWaiting, setCountWaiting] = useState(0)
  const [countNotComplete, setCountNotComplete] = useState(0)
  const [countComplete, setCountComplete] = useState(0)
  const [warehouses, setWarehouses] = useState([])
  const [dataOrder, setDataOrder] = useState({})
  const [loadingTable, setLoadingTable] = useState(false)
  const [importOrders, setImportOrders] = useState([])
  const [countImportOrders, setCountImportOrders] = useState(0)
  const [paramsFilter, setParamsFilter] = useState(
    filters
      ? { ...filters }
      : { page: 1, page_size: 100, processing: 'not_complete', this_week: true }
  )
  const [groups, setGroups] = useState([])
  const [types, setTypes] = useState([])

  const [valueSearch, setValueSearch] = useState(filters ? filters.search || '' : '')
  const [dataDetail, setDataDetail] = useState()
  const [columns, setColumns] = useState([])
  const typingTimeoutRef = useRef(null)

  const history = useHistory()
  const dataUser = useSelector((state) => state.login.dataUser)
  const [textNote, setTextNote] = useState('* Đơn hàng đang đợi kiểm hàng tại kho SGL')

  const { TabPane } = Tabs

  const columnsDetail = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => index + 1,
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Mã sản phẩm',
      render: (text, record) => record.barcode || record.code,
      fixed: 'left',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Quy cách',
      dataIndex: 'convention',
    },
    {
      title: 'Đơn vị tính',
      render: (text, record) => record.unit_info && record.unit_info.name,
    },
    {
      title: 'Đơn vị nhỏ nhất',
      render: (text, record) => formatCash(record.smallest_unit || 0),
    },
    {
      title: 'Nhóm hàng',
      render: (text, record) => record.group_info && record.group_info.name,
    },
    {
      title: 'Loại hàng',
      render: (text, record) => record.type_info && record.type_info.name,
    },
    {
      title: 'Hình ảnh',
      align: 'center',
      render: (text, record) =>
        record.images && (
          <ZoomImage image={record.images[0] || IMAGE_DEFAULT}>
            <img
              style={{ width: 50, height: 50 }}
              alt=""
              src={
                record.images[0] ||
                'https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/01/08/aac1e09f-0278-4430-9912-be8261e25b60/2022-01-08%2012.17%201.png'
              }
            />
          </ZoomImage>
        ),
    },
    {
      title: 'Quy đổi số lượng',
      render: (text, record) => {
        const quantity = record.import_details
          ? record?.import_details.reduce((acc, curr) => acc + curr?.quantity, 0)
          : 0
        return quantity * (record.smallest_unit || 1)
      },
      align: 'center',
    },
    {
      title: 'Số lượng yêu cầu',
      dataIndex: 'require_quantity',
      align: 'center',
    },
    {
      title: 'Nhập kho thực tế',
      dataIndex: 'import_details',
      align: 'center',
      render: (text, record) =>
        record.import_details
          ? record?.import_details.reduce((acc, curr) => acc + curr?.quantity, 0)
          : 0,
    },
    {
      title: 'CBM (cm³)',
      dataIndex: 'cbm',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'S mặt phẳng (cm²)',
      dataIndex: 'area',
      align: 'center',
      render: (text) => formatCash(text),
    },
    {
      title: 'Ngày sản xuất',
      align: 'center',
      dataIndex: 'mfg',
      render: (mfg) => mfg && moment(mfg).format('DD-MM-YYYY'),
    },
    {
      title: 'Hạn sử dụng',
      align: 'center',
      dataIndex: 'exp',
      render: (exp) => exp && moment(exp).format('DD-MM-YYYY'),
    },
  ]

  const ModalFees = ({ fees }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const columns = [
      { title: 'Tên chi phí', dataIndex: 'fee_name' },
      { title: 'Mã chi phí', dataIndex: 'fee_code' },
      { title: 'Chi phí', render: (text, record) => formatCash(record.fee || 0) },
      { title: 'Mô tả', dataIndex: 'description' },
    ]

    return (
      <>
        <a onClick={toggle}>Xem chi tiết</a>
        <Modal
          footer={null}
          width="70%"
          title="Danh sách chi phí"
          onCancel={toggle}
          visible={visible}
        >
          <Table dataSource={fees} columns={columns} size="small" />
        </Modal>
      </>
    )
  }

  const _getOrderDetail = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getImportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) setDataDetail(order)
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const _getImport = async () => {
    try {
      setLoadingTable(true)
      const res = await getImportOrders({ ...paramsFilter, customer_id: dataUser.customer_id })
      if (res.status === 200) {
        setImportOrders(res.data.data)
        setCountImportOrders(res.data.count)
        setDataOrder(res.data)
      }
      setLoadingTable(false)
    } catch (err) {
      console.log(err)
      setLoadingTable(false)
    }
  }

  const _getCustomers = async () => {
    try {
      const res = await getCustomers()
      if (res.status === 200) setCustomers(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getWarehouses = async () => {
    try {
      const res = await getWarehouses()
      if (res.status === 200) {
        setWarehouses(res.data.data)
        const warehouse = res.data.data.find(
          (w) => dataUser && w.warehouse_id === dataUser.warehouse_id
        )
        if (warehouse) setWarehouse(warehouse.warehouse_id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getEnum = async () => {
    try {
      const res = await enumImport()
      if (res.status === 200) if (res.data.success) setDataEnum(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //nhóm hàng
  const _getGroups = async () => {
    try {
      const res = await getCategories({ group: true, detach: true })
      if (res.status === 200) setGroups(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //loại hàng
  const _getTypes = async () => {
    try {
      const res = await getCategories({ type: true, detach: true })
      if (res.status === 200) setTypes(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _enumImportOrderProcess = async () => {
    try {
      const res = await enumImportOrderProcess(paramsFilter)
      if (res.status === 200) {
        const platform = res.data.data
        const waiting = platform.find((e) => e.value === 'WAITING')
        const noteComplete = platform.find((e) => e.value === 'NOT_COMPLETE')
        const complete = platform.find((e) => e.value === 'COMPLETE')
        if (waiting) setCountWaiting(waiting.total)
        if (noteComplete) setCountNotComplete(noteComplete.total)
        if (complete) setCountComplete(complete.total)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getProductsById = async (order_id = '') => {
    try {
      setLoadingTable(true)
      const res = await getImportOrders({ order_id: order_id })
      setLoadingTable(false)
      if (res.status === 200) {
        const order = res.data.data && res.data.data.length && res.data.data[0]
        if (order) return order.products
        else notification.error({ message: 'Không tìm thấy đơn hàng này' })
      }
      return []
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
      return []
    }
  }

  const _resubmitExportOrder = async (record) => {
    try {
      const products = await _getProductsById(record.order_id)
      const body = { ...record, products: products }
      setLoadingTable(true)
      const res = await updateImportOrders(record.order_id, body)
      setLoadingTable(false)
      if (res.status === 200) {
        if (res.data.success) {
          _getImport()
          notification.success({ message: 'Kích hoạt nhập kho thành công' })
        } else
          notification.error({
            message: res.data.message || 'Kích hoạt nhập kho thất bại, vui lòng thử lại',
          })
      } else
        notification.error({
          message: res.data.message || 'Kích hoạt nhập kho thất bại, vui lòng thử lại',
        })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _filterByOption = (attribute = '', value = '') => {
    if (value) paramsFilter[attribute] = value
    else delete paramsFilter[attribute]
    setParamsFilter({ ...paramsFilter, page: 1 })
  }

  const _deleteImport = async (id, note = '') => {
    try {
      const res = await deleteImportOrders({ order_id: [id], note: note })
      if (res.status === 200) {
        if (res.data.success) {
          message.success('Xóa phiếu nhập kho thành công')
          _getImport()
        } else {
          message.error(res.data.message || 'Xóa phiếu nhập kho không thành công')
        }
      } else {
        message.error('Xóa phiếu nhập kho không thành công')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const showConfirmDeleteOrder = () => {
    Modal.confirm({
      title:
        'Vui lòng liên hệ SGL để hỗ trợ xoá phiếu, chúng tôi cần đảm bảo hàng hoá của bạn chưa được nhập/xuất kho tránh ảnh hướng tồn kho.',
      cancelButtonProps: { style: { display: 'none' } },
      okText: 'Đã hiểu',
      onOk: () => setSelectedRowKeys([]),
    })
  }

  const _search = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value || e.target.value

      //khi search hoặc filter thi reset page ve 1
      paramsFilter.page = 1

      if (value) paramsFilter.search = value.trim()
      else delete paramsFilter.search

      setParamsFilter({ ...paramsFilter })
    }, 450)
  }

  const _resetFilter = () => {
    setValueSearch('')
    setParamsFilter({ page: 1, page_size: 20, processing: 'not_complete' })
  }

  const _ImportStatusTranslate = (text) => {
    const tagData = dataEnum?.status?.find((e) => e.value === text)
    if (!tagData) return ''
    return <Tag color={tagData?.color}>{tagData?.label}</Tag>
  }

  const _getReportExportInventoryToExport = async (getAll = false) => {
    try {
      let query = {}

      if (getAll) {
        const queries = [
          'today',
          'yesterday',
          'this_week',
          'last_week',
          'this_month',
          'last_month',
          'this_year',
          'last_year',
          'from_date',
          'to_date',
        ]

        queries.map((key) => {
          if (Object.keys(paramsFilter).includes(key)) query[key] = paramsFilter[key]
        })
      } else query = { ...paramsFilter }

      delete query.page
      delete query.page_size

      setLoadingTable(true)
      const res = await exportReportImportInventory({
        ...query,
        customer_id: dataUser ? dataUser.customer_id : '',
      })
      setLoadingTable(false)
      if (res.status === 200) {
        const link = res.data.data
        if (link) saveAs(link, getFileName(link))
      } else notification.warning({ message: res.data.message || 'Vui lòng chọn thời gian' })
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _getEmployees = async () => {
    try {
      const res = await getUsers()
      if (res.status === 200) setEmployees(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getCustomers()
    _getWarehouses()
    _getEnum()
    _getGroups()
    _getTypes()
    _getEmployees()
  }, [])

  useEffect(() => {
    _getImport()
    _enumImportOrderProcess()
    saveFiltersToLocalStorage(paramsFilter, KEY_FILTER)
  }, [paramsFilter])

  return (
    <div className={styles['body-import']}>
      <BackToTop />
      <Modal
        style={{ top: 20 }}
        title={
          <h3>
            Danh sách sản phẩm của đơn hàng <b>#{dataDetail && dataDetail.code}</b>
          </h3>
        }
        centered
        visible={dataDetail ? true : false}
        onCancel={() => setDataDetail()}
        footer={null}
        width="80%"
      >
        <Table
          rowKey="product_id"
          size="small"
          scroll={{ x: 2000, y: '63vh' }}
          loading={loadingTable}
          columns={columnsDetail}
          dataSource={dataDetail ? dataDetail.products : []}
          summary={() => (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row></Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </Modal>
      <Row className="page-title" justify="space-between" align="middle">
        <span style={{ fontWeight: 700, fontSize: 20, color: '#223B4F' }}>
          Quản lý phiếu nhập kho
        </span>
        <Space>
          <Permission permissions={[PERMISSIONS.giao_dien_xuat_nhap_file]}>
            <Button
              type="primary"
              onClick={() =>
                history.push(ROUTES.IMPORTEXPORTMANAGEMENT + '?action_name=file-nhap-hang')
              }
            >
              Xem lịch sử nhập
            </Button>
          </Permission>

          <Permission permissions={[PERMISSIONS.tao_phieu_nhap_kho]}>
            <ImportFile
              keyForm={{
                warehouse_id: warehouse,
                customer_id: dataUser ? dataUser.customer_id : '',
              }}
              width="70%"
              reload={_getImport}
              upload={uploadImportOrder}
              title={
                <Row wrap={false} align="middle">
                  <div style={{ marginRight: 20, fontWeight: 600 }}>
                    Nhập phiếu nhập kho bằng file excel
                  </div>
                  <div>
                    <div style={{ fontSize: 13, color: 'red' }}>Vui lòng chọn kho nhập hàng</div>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      value={warehouse}
                      onChange={setWarehouse}
                      placeholder="Chọn kho"
                      size="small"
                      style={{ width: 250 }}
                      allowClear
                    >
                      {warehouses.map((warehouse, index) => (
                        <Select.Option key={index} value={warehouse.warehouse_id}>
                          {warehouse.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Row>
              }
              warning="Ngày sản xuất và hạn sử dụng nếu để trống sẽ lấy từ sản phẩm"
              action_name="Nhập phiếu nhập kho bằng file excel"
              fileTemplated="https://s3.ap-northeast-1.wasabisys.com/admin-order/2022/06/02/9b9f5999-bec6-4698-b42d-bc4496cbf0c5/ImportOrderSGL.xlsx"
            />
          </Permission>

          <Button
            onClick={() => _getReportExportInventoryToExport()}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất theo bộ lọc
          </Button>
          <Button
            onClick={() => _getReportExportInventoryToExport(true)}
            icon={<VerticalAlignTopOutlined />}
            type="primary"
            className="btn-export"
          >
            Xuất toàn bộ
          </Button>

          <SettingColumns
            columnsDefault={columnsImport}
            nameColumn="columnsImport"
            columns={columns}
            setColumns={setColumns}
          />
          <Permission permissions={[PERMISSIONS.tao_phieu_nhap_kho]}>
            <Button type="primary" onClick={() => history.push(ROUTES.IMPORT_WAREHOUSE_CREATE)}>
              Thêm thủ công
            </Button>
          </Permission>
        </Space>
      </Row>
      <Row gutter={[15, 15]}>
        <Col xs={24} xl={8}>
          <Input
            placeholder="Tìm kiếm theo mã phiếu"
            allowClear
            prefix={<SearchOutlined />}
            onChange={_search}
            value={valueSearch}
          />
        </Col>
        <Col xs={24} xl={6}>
          <FilterDate
            allowClear={false}
            paramsFilter={paramsFilter}
            setParamsFilter={setParamsFilter}
          />
        </Col>
        <Col xs={24} xl={5}>
          <Select
            value={paramsFilter['type_id'] || undefined}
            onChange={(value) => _filterByOption('type_id', value)}
            allowClear
            showSearch
            filterOption={filterOptionSelect}
            placeholder="Lọc theo loại hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {types.map((type, index) => (
              <Select.Option value={type.category_id} key={index}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            value={paramsFilter['group_id'] || undefined}
            onChange={(value) => _filterByOption('group_id', value)}
            allowClear
            showSearch
            filterOption={filterOptionSelect}
            placeholder="Lọc theo nhóm hàng"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {groups.map((group, index) => (
              <Select.Option value={group.category_id} key={index}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={8}>
          <Select
            value={paramsFilter['creator_id'] || undefined}
            onChange={(value) => _filterByOption('creator_id', value)}
            allowClear
            showSearch
            filterOption={filterOptionSelect}
            placeholder="Lọc theo nhân viên lên đơn"
            style={{ width: '100%' }}
            dropdownClassName="dropdown-select-custom"
          >
            {employees.map((employee) => (
              <Select.Option value={employee.user_id} key={employee.user_id}>
                {employee.fullname}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button
            onClick={_resetFilter}
            type="danger"
            style={{ display: Object.keys(paramsFilter).length <= 4 && 'none' }}
            icon={<FilterOutlined />}
          >
            Xóa bộ lọc
          </Button>
        </Col>
      </Row>
      {/* <Row gutter={[30, 30]} style={{ marginTop: 25, marginBottom: 20 }}>
        <Col xs={32} xl={6}>
          <div className={styles['body-import-total-item']}>
            <h3>Đơn đợi xử lý</h3>

            <h3>{dataOrder.count_waiting ? formatCash(dataOrder.count_waiting) : 0}</h3>
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <div className={styles['body-import-total-item']}>
            <h3>Đơn đang xử lý</h3>
            <h3>{dataOrder.count_processing ? formatCash(dataOrder.count_processing) : 0}</h3>
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <div className={styles['body-import-total-item']}>
            <h3>Đơn đã nhập kho</h3>
            <h3>{dataOrder.count_complete ? formatCash(dataOrder.count_complete) : 0}</h3>
          </div>
        </Col>
      </Row> */}

      {selectedRowKeys.length && paramsFilter.processing !== undefined ? (
        <Button style={{ marginTop: 10 }} onClick={showConfirmDeleteOrder} danger type="primary">
          Xóa phiếu
        </Button>
      ) : (
        ''
      )}

      <Tabs
        tabBarStyle={{ height: 55, marginBottom: 5, marginTop: 20 }}
        activeKey={paramsFilter.processing}
        onChange={(value) => {
          if (value != 1) {
            delete paramsFilter.is_received
            setParamsFilter({ ...paramsFilter, page: 1, processing: value })
          } else {
            delete paramsFilter.processing
            setParamsFilter({ ...paramsFilter, page: 1, is_received: false })
          }

          if (value === 'not_complete') setTextNote('* Đơn hàng đang đợi kiểm hàng tại kho SGL')
          if (value === 'complete') setTextNote('* Đơn hàng đã được nhập vào hệ thống kho SGL')
          if (value === '1') setTextNote('* Đơn hàng có thông số sai hoặc thông tin không hợp lệ')
        }}
      >
        <TabPane
          disabled={loadingTable}
          tab={
            <Badge showZero count={countNotComplete} overflowCount={99999} offset={[5, -8]}>
              <div>Chưa nhập kho</div>
            </Badge>
          }
          key="not_complete"
        />
        <TabPane
          disabled={loadingTable}
          tab={
            <Badge showZero count={countComplete} overflowCount={99999} offset={[5, -8]}>
              <div>Đã nhập kho</div>
            </Badge>
          }
          key="complete"
        />
        <TabPane
          disabled={loadingTable}
          tab={
            <Badge showZero count={countWaiting} overflowCount={99999} offset={[5, -8]}>
              <div>Không đủ tiêu chuẩn</div>
            </Badge>
          }
          key="1"
        />
      </Tabs>
      {textNote && <div style={{ color: 'red' }}>{textNote}</div>}
      <br />
      <Table
        rowKey="order_id"
        size={SIZE_TABLE}
        scroll={{ x: 'max-content', y: '65vh' }}
        loading={loadingTable}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: (keys) => setSelectedRowKeys(keys),
        }}
        columns={[...columns].map((column) => {
          if (column.key === 'stt') {
            return {
              ...column,
              render: (text, record, index) =>
                (paramsFilter.page - 1) * paramsFilter.page_size + index + 1,
            }
          }

          if (column.dataIndex === 'customer_order_code') {
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'customer_order_code'),
            }
          }

          if (column.dataIndex === 'code') {
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'code'),
              render: (text, record) =>
                isAcceptPermissions([PERMISSIONS.cap_nhat_phieu_nhap_kho]) ? (
                  <Link to={`${ROUTES.IMPORT_WAREHOUSE_UPDATE}?order_id=${record.order_id}`}>
                    #{text}
                  </Link>
                ) : (
                  <b>#{text}</b>
                ),
            }
          }
          if (column.dataIndex === 'import_date') {
            return {
              ...column,
              sorter: (a, b) => {
                const aMoment = a?.import_date ? moment(a?.import_date).unix() : 0
                const bMoment = b?.import_date ? moment(b?.import_date).unix() : 0
                return aMoment - bMoment
              },
              render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
            }
          }
          if (column.dataIndex === 'warehouse_info') {
            return {
              ...column,
              render: (warehouse_info) => warehouse_info?.name,
              sorter: (a, b) => compare(a, b, 'warehouse_id'),
            }
          }
          if (column.key === 'products') {
            return {
              ...column,
              render: (text, record) => (
                <a onClick={() => _getOrderDetail(record.order_id)}>Xem chi tiết</a>
              ),
            }
          }

          if (column.dataIndex === 'total_cost') {
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'total_cost'),
              render: (text) => <span>{text && formatCash(text)}</span>,
            }
          }
          if (column.dataIndex === 'total_quantity') {
            return {
              ...column,
              sorter: (a, b) => compare(a, b, 'total_quantity'),
              render: (text, record) => <span>{text && formatCash(text)}</span>,
            }
          }

          if (column.dataIndex === 'receive_date') {
            return {
              ...column,
              render: (text) => text && moment(text).format('DD/MM/YYYY HH:mm:ss'),
            }
          }
          if (column.key === 'fee') {
            return {
              ...column,
              render: (text, record) => <ModalFees fees={record.fees} />,
            }
          }
          if (column.dataIndex === 'customer_info') {
            return {
              ...column,
              render: (text, record) => record.customer_info && record.customer_info.fullname,
            }
          }
          if (column.dataIndex === 'import_status') {
            return {
              ...column,
              render: (text) => text && _ImportStatusTranslate(text),
            }
          }
          if (column.key === 'signature') {
            return {
              ...column,
              render: (text, record) => {
                const src = record.shipping_info && record.shipping_info.shipping_employee_signature

                return (
                  <Row align="middle" justify="center" style={{ width: '100%' }}>
                    <Col>
                      {src && (
                        <Popover
                          placement="left"
                          content={
                            <img src={src} style={{ height: '450px', width: '450px' }} alt="" />
                          }
                        >
                          <img src={src} style={{ height: 45, width: 45 }} alt="" />
                        </Popover>
                      )}
                    </Col>
                  </Row>
                )
              },
            }
          }

          if (column.key === 'action') {
            return {
              ...column,
              render: (text, record) => (
                <Space>
                  <Permission permissions={[PERMISSIONS.xoa_phieu_nhap_kho]}>
                    {paramsFilter.is_received === false ? (
                      <ModalConfirmDelete
                        record={record}
                        onDelete={(note) => _deleteImport(record?.order_id, note)}
                      >
                        <Button type="danger" icon={<DeleteOutlined />}></Button>
                      </ModalConfirmDelete>
                    ) : (
                      <Button
                        onClick={showConfirmDeleteOrder}
                        type="danger"
                        icon={<DeleteOutlined />}
                      ></Button>
                    )}
                  </Permission>
                  <Button
                    onClick={() => _resubmitExportOrder(record)}
                    type="primary"
                    style={{ display: record.is_received && 'none' }}
                  >
                    Kích hoạt nhập kho
                  </Button>
                </Space>
              ),
            }
          }
          return column
        })}
        dataSource={importOrders}
        pagination={{
          position: POSITION_TABLE,
          total: countImportOrders,
          current: paramsFilter.page,
          pageSize: paramsFilter.page_size,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange(page, pageSize) {
            setParamsFilter({
              ...paramsFilter,
              page: page,
              page_size: pageSize,
            })
          },
        }}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row></Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}
