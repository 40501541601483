import React from 'react'

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { PERMISSIONS, ROUTES } from 'consts'

//base layout
import UI from 'components/Layout/UI'
import Authentication from 'components/authentication'

//views
import NotFound from './not-found/404'
import Dashboard from './dashboard'
import Employee from './employee'
import EmployeeCreate from './employee-create'
import Product from './product'
import ProductDetail from './product-detail'
import Site from './site'
import Login from './login'
import Register from './register'
import VerifyLazada from './verify-lazada'
import BussinessProfile from './bussiness-profile'
import Order from './order'
import OrderEcommerce from './order-e-commerce'
import ImportWareHouse from './import-warehouse'
import ImportWarehouseCreate from './import-warehouse-create'
import ExportWareHouse from './export-warehouse'
import ExportWarehouseCreate from './export-warehouse-create'
import ExportWarehouseUpdate from './export-warehouse-update'
import ReportInventory from './report-inventory'
import ReportInventoryDetail from './report-inventory-detail'
import ReportImportWarehouse from './report-import-warehouse'
import ReportExportWarehouse from './report-export-warehouse'
import ReportProductChecking from './report-product-checking'
import ReportImportOrder from './report-import-order'
import ReportExportOrder from './report-export-order'
import ReportPackOrder from './report-pack-order'
import ReportTransportOrder from './report-transport-order'
import ImportExportManagement from './import-export-management'
import ActivityHistory from './activity-history'
import ForgetPassword from './forget-password'
import VerifyOtp from './verify-otp'
import OrderCollectionExport from './order-collection-export'
import OrderCollectionImport from './order-collection-import'
import OrderDetail from './order-detail'
import RecoveryPassword from './recovery-password'
import RoleManagement from './role-management'
import ShippingControl from './shipping-control'
import Privacy from './privacy'
import Debit from './debit'
import OrderFee from './order-fee'
import ConnectTiki from './connect-tiki'
import ConnectLazada from './connect-lazada'
import ConnectShopee from './connect-shopee'
import ConnectTiktok from './connect-tiktok'
import ConnectSapo from './connect-sapo'
import Supplier from './supplier'
import Branch from './branch'
import SupplierForm from './supplier/supplier-form'

const DEFINE_ROUTER = [
  {
    path: ROUTES.DASHBOARD,
    Component: () => <Dashboard />,
    title: 'Dashboard',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BRANCH_LIST,
    Component: () => <Branch />,
    title: 'Danh sách chi nhánh',
    permissions: [PERMISSIONS.giao_dien_chi_nhanh],
    exact: true,
  },
  {
    path: ROUTES.SUPPLIER_LIST,
    Component: () => <Supplier />,
    title: 'Danh sách nhà cung cấp',
    permissions: [PERMISSIONS.giao_dien_nha_cung_cap],
    exact: true,
  },
  {
    path: ROUTES.SUPPLIER_CREATE,
    Component: () => <SupplierForm />,
    title: 'Thêm nhà cung cấp',
    permissions: [PERMISSIONS.tao_nha_cung_cap],
    exact: true,
  },
  {
    path: ROUTES.SUPPLIER_UPDATE,
    Component: () => <SupplierForm />,
    title: 'Cập nhật nhà cung cấp',
    permissions: [PERMISSIONS.cap_nhat_nha_cung_cap],
    exact: true,
  },
  {
    path: ROUTES.ORDER_COLLECTION_EXPORT,
    Component: () => <OrderCollectionExport />,
    title: 'Tổng hợp đơn hàng xuất',
    permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_xuat],
    exact: true,
  },
  {
    path: ROUTES.ORDER_COLLECTION_IMPORT,
    Component: () => <OrderCollectionImport />,
    title: 'Tổng hợp đơn hàng nhập',
    permissions: [PERMISSIONS.giao_dien_tong_hop_don_hang_nhap],
    exact: true,
  },
  {
    path: ROUTES.ORDER_DETAIL_IMPORT,
    Component: () => <OrderDetail />,
    title: 'Chi tiết đơn hàng nhập',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDER_DETAIL_EXPORT,
    Component: () => <OrderDetail />,
    title: 'Chi tiết đơn hàng xuất',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT,
    Component: () => <Product />,
    title: 'Product',
    permissions: [PERMISSIONS.giao_dien_san_pham],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_DETAIL,
    Component: () => <ProductDetail />,
    title: 'Product detail',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.EMPLOYEE,
    Component: () => <Employee />,
    title: 'Employee',
    permissions: [PERMISSIONS.giao_dien_quan_ly_nhan_vien],
    exact: true,
  },
  {
    path: ROUTES.EMPLOYEE_CREATE,
    Component: () => <EmployeeCreate />,
    title: 'Employee Create',
    permissions: [PERMISSIONS.tao_nhan_vien],
    exact: true,
  },
  {
    path: ROUTES.DEBIT,
    Component: () => <Debit />,
    title: 'Quản lý công nợ',
    permissions: [PERMISSIONS.giao_dien_quan_ly_cong_no],
    exact: true,
  },
  {
    path: ROUTES.ORDER_FEE,
    Component: () => <OrderFee />,
    title: 'Bảng kê chi phí',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SITE,
    Component: () => <Site />,
    title: 'Kết nối sàn TMDT',
    permissions: [PERMISSIONS.giao_dien_ket_noi_san_tmdt],
    exact: true,
  },
  {
    path: ROUTES.IMPORTEXPORTMANAGEMENT,
    Component: () => <ImportExportManagement />,
    title: 'Lịch sử xuất/nhập file',
    permissions: [PERMISSIONS.giao_dien_xuat_nhap_file],
    exact: true,
  },
  {
    path: ROUTES.ACTIVITY_HISTORY,
    Component: () => <ActivityHistory />,
    title: 'Lịch sử thao tác',
    permissions: [PERMISSIONS.giao_dien_lich_su_thao_tac],
    exact: true,
  },
  {
    path: ROUTES.BUSSINESS_PROFILE,
    Component: () => <BussinessProfile />,
    title: 'Hồ sơ doanh nghiệp',
    permissions: [PERMISSIONS.giao_dien_ho_so_doanh_nghiep],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_WAREHOUSE,
    Component: () => <ReportImportWarehouse />,
    title: 'Báo cáo nhập kho',
    permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_kho],
    exact: true,
  },
  {
    path: ROUTES.REPORT_EXPORT_WAREHOUSE,
    Component: () => <ReportExportWarehouse />,
    title: 'Báo cáo xuất kho',
    permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_kho],
    exact: true,
  },
  {
    path: ROUTES.REPORT_PRODUCT_CHECKING,
    Component: () => <ReportProductChecking />,
    title: 'Báo cáo kiểm hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_kiem_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_ORDER,
    Component: () => <ReportImportOrder />,
    title: 'Báo cáo nhập hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_nhap_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_EXPORT_ORDER,
    Component: () => <ReportExportOrder />,
    title: 'Báo cáo xuất hàng',
    permissions: [PERMISSIONS.giao_dien_bao_cao_xuat_hang],
    exact: true,
  },
  {
    path: ROUTES.REPORT_PACK_ORDER,
    Component: () => <ReportPackOrder />,
    title: 'Báo cáo đóng gói',
    permissions: [PERMISSIONS.giao_dien_bao_cao_dong_goi],
    exact: true,
  },
  {
    path: ROUTES.REPORT_TRANSPORT_ORDER,
    Component: () => <ReportTransportOrder />,
    title: 'Báo cáo bàn giao vận chuyển',
    permissions: [PERMISSIONS.giao_dien_bao_cao_ban_giao_van_chuyen],
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY_DETAIL,
    Component: () => <ReportInventoryDetail />,
    title: 'Báo cáo tồn kho chi tiết',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONNECT_TIKTOK_CALLBACK,
    Component: () => <ConnectTiktok />,
    title: 'CONNECT TIKTOK CALLBACK',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONNECT_TIKI_CALLBACK,
    Component: () => <ConnectTiki />,
    title: 'CONNECT TIKI CALLBACK',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONNECT_LAZADA_CALLBACK,
    Component: () => <ConnectLazada />,
    title: 'CONNECT LAZADA CALLBACK',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONNECT_SHOPPEE_CALLBACK,
    Component: () => <ConnectShopee />,
    title: 'CONNECT SHOPEE CALLBACK',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.CONNECT_SAPO_CALLBACK,
    Component: () => <ConnectSapo />,
    title: 'CONNECT SAPO CALLBACK',
    permissions: [],
    exact: true,
  },
  /* {
    path: ROUTES.REPORT_INVENTORY_DETAIL,
    Component: () => <ReportInventoryDetail />,
    title: 'Report inventory detail',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.REPORT_IMPORT_WAREHOUSE,
    Component: () => <ReportImportWarehouse />,
    title: 'Report import warehouse',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.REPORT_EXPORT_WAREHOUSE,
    Component: () => <ReportExportWarehouse />,
    title: 'Report export warehouse',
    permissions: [],
    exact: true,
  }, */
  // {
  //   path: ROUTES.SHIPPING_CONTROL,
  //   Component: () => <ShippingControl />,
  //   title: 'Shipping Control',
  //   permissions: [PERMISSIONS.giao_dien_doi_soat_van_chuyen],
  //   exact: true,
  // },
  // {
  //   path: ROUTES.ORDER,
  //   Component: () => <Order />,
  //   title: 'Order',
  //   permissions: [],
  //   exact: true,
  // },
  {
    path: ROUTES.ORDER_E_COMMERCE,
    Component: () => <OrderEcommerce />,
    title: 'Order Ecommerce',
    permissions: [PERMISSIONS.giao_dien_don_thuong_mai_dien_tu],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_WAREHOUSE,
    Component: () => <ImportWareHouse />,
    title: 'Import Warehouse',
    permissions: [PERMISSIONS.giao_dien_nhap_kho],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_WAREHOUSE_CREATE,
    Component: () => <ImportWarehouseCreate />,
    title: 'Import Warehouse Create',
    permissions: [PERMISSIONS.tao_phieu_nhap_kho],
    exact: true,
  },
  {
    path: ROUTES.IMPORT_WAREHOUSE_UPDATE,
    Component: () => <ImportWarehouseCreate />,
    title: 'Import Warehouse Update',
    permissions: [PERMISSIONS.cap_nhat_phieu_nhap_kho],
    exact: true,
  },
  {
    path: ROUTES.EXPORT_WAREHOUSE,
    Component: () => <ExportWareHouse />,
    title: 'Export Warehouse',
    permissions: [PERMISSIONS.giao_dien_xuat_kho],
    exact: true,
  },
  {
    path: ROUTES.EXPORT_WAREHOUSE_CREATE,
    Component: () => <ExportWarehouseCreate />,
    title: 'Export Warehouse Create',
    permissions: [PERMISSIONS.tao_phieu_xuat_kho],
    exact: true,
  },
  {
    path: ROUTES.EXPORT_WAREHOUSE_UPDATE + '/:order_id',
    Component: () => <ExportWarehouseUpdate />,
    title: 'Export Warehouse Update',
    permissions: [PERMISSIONS.cap_nhat_phieu_xuat_kho],
    exact: true,
  },
  {
    path: ROUTES.ROLE_MANAGEMENT,
    Component: () => <RoleManagement />,
    title: 'Role management',
    permissions: [PERMISSIONS.giao_dien_quan_ly_phan_quyen],
    exact: true,
  },
  // {
  //   path: ROUTES.HISTORY_WAREHOUSE,
  //   Component: () => <HistoryWareHouse />,
  //   title: 'History warehouse',
  //   permissions: [],
  //   exact: true,
  // },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    title: 'Login',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.REGISTER,
    Component: () => <Register />,
    title: 'Register',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRIVACY,
    Component: () => <Privacy />,
    title: 'Chính sách bảo mật',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.FORGET_PASSWORD,
    Component: () => <ForgetPassword />,
    title: 'Forget password',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.VERIFY_OTP,
    Component: () => <VerifyOtp />,
    title: 'Verify Otp',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.RECOVERY_PASSWORD,
    Component: () => <RecoveryPassword />,
    title: 'Recovery password',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.VERIFY_LAZADA,
    Component: () => <VerifyLazada />,
    title: 'Verify Lazada',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.REPORT_INVENTORY,
    Component: () => <ReportInventory />,
    title: 'Report inventory',
    permissions: [PERMISSIONS.giao_dien_bao_cao_ton_kho_tong_hop],
    exact: true,
  },
]

export default function Views() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.DASHBOARD} />
        </Route>

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <UI>
                <Component />
              </UI>
            </Authentication>
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
